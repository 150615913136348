const tailwindConfig = require('@tectonicfi/tectonic-ui-kit').tailwindConfig;

module.exports = tailwindConfig({
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './stories/**/*.mdx',
    // Purge tectonic-ui-kit, so we preserve css classes used only there.
    './node_modules/\\@tectonicfi/tectonic-ui-kit/dist/**/*.js',
  ],
  plugins: [],
  theme: {
    colors: {
      rainbowBlue: '#8BEAFF',
      rainbowPink: '#FF82B9',
      rainbowPinkSecondary: '#FF96DF',
      rainbowRed: '#FFA1A1',
      rainbowYellow: '#E7F499',
      testBlue: '#191945',
      semiTransparent: 'rgba(255, 255, 255, 0.6)',
      tonicApyBackgroundGradientFrom: 'rgba(244, 244, 153, 0.3)',
      tonicApyBackgroundGradientTo: 'rgba(255, 150, 223, 0.3)',
      borderGradientTo: 'rgb(255, 150, 223)',
      semiBorderGradientTo: 'rgba(255, 150, 223, 0.6)',
      semiYellowBorder: 'rgba(244, 244, 153, 0.6)',
      yellowBorder: 'rgba(244, 244, 153, 0.12)',
      yellowBorderActive: 'rgba(244, 244, 153, 1)',
      greyBorder: 'rgba(255, 255, 255, 0.3)',
      greyBackground: 'rgba(255, 255, 255, 0.12)',
      blueElevatedSurface: '#1F1F3F',
      blueElevatedSurfaceTransparent: 'rgba(31, 31, 63, 0.9)',
      darkerBlue: '#0D0D2F',
      highlightBorderColorFrom: '#99EBF4',
      highlightBorderColorTo: '#FF96DF',
      vaultChart6Months: '#F4F499',
      vaultChart12Months: '#6CD1A9',
      vaultChart24Month: '#FFA1A1',
      vaultChart48Months: '#FF7C00',
      semiTransparentBlue: 'rgba(31, 31, 63, 0.75)',
      semiTransparentTestBlue: 'rgba(25, 25, 69, 0.75)',
    },
    extend: {
      borderWidth: {
        1: '1px',
      },
      boxShadow: {
        yellow: '0px 0px 5px 2px rgba(244, 244, 153)',
        pink: '0px 0px 5px 2px rgba(255, 150, 223)',
        market: 'inset 0 0 4px 5px #000024',
      },
      backgroundImage: {
        'mushroom-market-mobile':
          "url('/assets/images/mushroomMarketMobile.png')",
        'mushroom-market': "url('/assets/images/mushroomMarket.png')",
        'lava-tornado-market': "url('/assets/images/lavaTornadoMarket.png')",
        'mushroom-supply': "url('/assets/images/mushroomSupply.png')",
        'lava-tornado-borrow': "url('/assets/images/lavaTornadoBorrow.png')",
        'landscape-vaults-mobile':
          "url('/assets/images/landscapeVaultsMobile.png')",
        'landscape-vaults': "url('/assets/images/landscapeVaults.png')",
        'mobile-slider-close': "url('/assets/images/mobile-slider-close.svg')",
        'arrow-up': "url('/assets/images/arrow-up.svg')",
        'arrow-down': "url('/assets/images/arrow-down.svg')",
        close: "url('/assets/images/close.svg')",
        'dashboard-info-mobile':
          "url('/assets/images/dashboardInfoMobile.svg')",
        'dashboard-supply-mobile':
          "url('/assets/images/dashboardSupplyMobile.png')",
        'dashboard-borrow-mobile':
          "url('/assets/images/dashboardBorrowMobile.png')",
        'dashboard-banner': "url('/assets/images/dashbordBanner.svg')",
        'market-banner-firstly':
          "url('/assets/images/marketBannerFirstly.svg')",
        'market-banner-second': "url('/assets/images/marketBannerSecond.svg')",
        'vault-card-1-month': "url('/assets/images/vault-card-1-month.svg')",
        'vault-card-6-month': "url('/assets/images/vault-card-6-month.svg')",
        'vault-card-12-month': "url('/assets/images/vault-card-12-month.svg')",
        'vault-card-48-month': "url('/assets/images/vault-card-48-month.svg')",
        selected: "url('/assets/images/selected.svg')",
        completed: "url('/assets/images/completed.svg')",
      },
    },
  },
});
