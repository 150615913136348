/* eslint-disable @typescript-eslint/no-unused-vars */
/** Feature flags */

import { AppEnvironment } from '@types';
type DisabledEnvironments = AppEnvironment[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const devOnly: DisabledEnvironments = [
  AppEnvironment.Staging,
  AppEnvironment.Staging1,
  AppEnvironment.UAT,
  AppEnvironment.Production,
];

const devAndSta: DisabledEnvironments = [
  AppEnvironment.Staging1,
  AppEnvironment.UAT,
  AppEnvironment.Production,
];

const noEnv: DisabledEnvironments = [
  AppEnvironment.Dev,
  AppEnvironment.Staging,
  AppEnvironment.Staging1,
  AppEnvironment.UAT,
  AppEnvironment.Production,
];

const noProdAndUat: DisabledEnvironments = [
  AppEnvironment.Production,
  AppEnvironment.UAT,
];

const noProd: DisabledEnvironments = [AppEnvironment.Production];
const all: DisabledEnvironments = [];
const features: Record<string, AppEnvironment[]> = {
  Vaults: all,
  ConversionBounty: all,
  TToken: all,
  MarketInsights: all,
  Analytics: all,
  AnalyticsMarkets: all,
  TonicAnalyticsModeSwitch: all,
  CronosId: all,
  Earn: all,
  PartnerTokenRewards: all,
};

export default features;
