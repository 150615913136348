import React, { Dispatch, SetStateAction } from 'react';

import { VaultsBlockState } from '@components/VaultsPageView/VaultsBlock/types';

export const INITIAL_VAULTS_BLOCK_STATE: VaultsBlockState = {
  activeView: 'PoolOverview',
};

export const VaultsBlockStateContext = React.createContext<{
  vaultsBlockState: VaultsBlockState;
  setVaultsBlockState: Dispatch<SetStateAction<VaultsBlockState>>;
}>({
  vaultsBlockState: INITIAL_VAULTS_BLOCK_STATE,
  setVaultsBlockState: () => undefined,
});
