import { useRouter } from 'next/router';

import TonicVaultsContainer from './TonicVaultsContainer';
import { Mode } from './types';

interface Props {
  defaultMode: Mode;
}

export default function EarnPageView({ defaultMode }: Props) {
  const { push } = useRouter();

  const onModeChange = (m: Mode) => {
    push(`/${m}`);
  };

  return (
    <div className="-mt-3 flex flex-1 flex-col overflow-auto overflow-x-hidden mobile:mt-auto">
      <TonicVaultsContainer mode={defaultMode} onModeChange={onModeChange} />
    </div>
  );
}
