import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';

export interface HarvestVaultEarningsProps {
  tonicRewards: string;
  tonicRewardsInUsd: string;
  hasRewards: boolean;
  onHarvest: () => void;
}
export default function HarvestVaultEarnings({
  tonicRewards,
  tonicRewardsInUsd,
  hasRewards,
  onHarvest,
}: HarvestVaultEarningsProps) {
  return (
    <div className="flex w-full flex-row justify-between p-3 mobile:h-[200px] mobile:flex-col mobile:p-2">
      <div className="flex flex-col">
        <Text variant="semiTransparent" className="mb-2 text-mediumSmall">
          Pending Rewards
        </Text>
        <div className="mb-1 flex items-center">
          <Icon.Tonic width={24} height={24} className="mr-1" />
          <Text variant="heading3">{tonicRewards}</Text>
        </div>
        <Text variant="semiTransparent">{tonicRewardsInUsd} USD</Text>
      </div>
      <div className="relative">
        <Button
          onClick={onHarvest}
          disabled={!hasRewards}
          className="absolute bottom-0 right-0 w-full min-w-[128px] mobile:static"
        >
          Harvest
        </Button>
      </div>
    </div>
  );
}
