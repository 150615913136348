import { StackView, Text, Button } from '@tectonicfi/tectonic-ui-kit';

import Card from '@components/Card';
import useWallets from '@hooks/useWallets';

function NoConnectedWalletBlock({ prompt }: { prompt: string }) {
  const { onShowConnectWalletModal } = useWallets();

  return (
    <Card className="text-center" padding="large">
      <StackView spacing={2} className="place-items-center">
        <Text variant="heading3">Connect your wallet</Text>
        <Text>{prompt}</Text>
        <Button onClick={onShowConnectWalletModal}>Connect wallet</Button>
      </StackView>
    </Card>
  );
}

export default NoConnectedWalletBlock;
