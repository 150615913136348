import { Pie } from '@visx/shape';
import { Group } from '@visx/group';

export interface Vault {
  period: string;
  value: number;
}

interface Props {
  vaults: Vault[];
}

export const COLOR_MAPPING: Record<string, string> = {
  // NOTE: prod colors
  '6months': '#F4F499',
  '12months': '#6CD1A9',
  '24months': '#FFA1A1',
  '48months': '#FF7C00',

  // NOTE: dev colors
  '1months': '#F4F499',
  '30s': '#F4F499',
  '60s': '#6CD1A9',
  '120s': '#FFA1A1',
  '240s': '#FF7C00',
  '36months': '#FF7C00',
};

export default function VaultsPieChart({ vaults }: Props) {
  return (
    <svg viewBox="0 0 100 100">
      <Group className="translate-x-1/2 translate-y-1/2">
        <Pie
          data={vaults.map((_, index) => index)}
          pieValue={(m: number) => vaults[m]?.value}
          outerRadius={50}
        >
          {(pie) => {
            return pie.arcs.map((arc, index) => {
              const arcPath = pie.path(arc);
              const arcFill = COLOR_MAPPING[vaults[index].period];

              if (!arcPath) {
                return null;
              }
              return (
                <g key={`arc-${index}`}>
                  <path d={arcPath} fill={arcFill} />
                </g>
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  );
}
