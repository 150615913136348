import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';

export default function CommunityLists() {
  return (
    <>
      <Text className="mt-8 pb-2 text-onSurface60 desktop:pt-3 mobile:mt-10">
        Join the Community
      </Text>
      <div className="flex flex-row desktop:justify-center tablet:justify-center">
        <a
          className="pr-3"
          href="https://twitter.com/TectonicFi"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.X />
        </a>
        <a
          className="pr-3"
          href="https://t.me/TectonicOfficial"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Telegram />
        </a>
        <a
          className="pr-3"
          href="https://discord.gg/jqaxqK7KBR"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Discord />
        </a>
        <a
          className="pr-3"
          href="https://medium.com/@0xTectonic"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Medium />
        </a>
        {/* <a
                href="https://github.com/tectonicfi"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon.Github />
              </a> */}
      </div>
    </>
  );
}
