import React, { FunctionComponent } from 'react';

import Feature, { FeatureProps } from '@components/Feature';
import { AppEnvironment } from '@types';

export function createFeatureComponent(
  featureName: string,
  disabledEnvironments: AppEnvironment[]
) {
  const Component: FunctionComponent<Pick<FeatureProps, 'children'>> = ({
    children,
  }) => React.createElement(Feature, { disabledEnvironments }, children);
  Component.displayName = featureName;
  return Component;
}
