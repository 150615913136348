import clsx from 'clsx';
import { Text, Icon } from '@tectonicfi/tectonic-ui-kit';
import VVSSVG from '@public/assets/images/swap-vvs.svg';
import WOWMAXSVG from '@public/assets/images/wowmax.svg';

import useIsMobile from '@hooks/useIsMobile';
import { Dexes } from '@components/ShortModal/utils';

interface ModeSwitchProps {
  mode: string;
  onModeChange: (mode: Dexes) => void;
  className?: string;
}

function SwapModeSwitch({
  mode,
  onModeChange,
  className,
}: ModeSwitchProps): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={clsx(
        'mx-auto flex w-full flex-row justify-around text-[16px] font-semibold mobile:justify-between',
        className
      )}
    >
      <div
        key={Dexes.vvs}
        className={clsx(
          {
            'border-b-2 border-yellowBorderActive': mode === 'vvs',
          },
          'flex  w-6/12 cursor-pointer items-center justify-center pb-1'
        )}
        onClick={(): void => onModeChange(Dexes.vvs)}
      >
        <VVSSVG
          width={isMobile ? '17' : '25'}
          height={isMobile ? '17' : '25'}
          color={mode === 'vvs' ? 'rgba(244, 244, 153, 1)' : ''}
        />
        <Text
          className={clsx(
            { 'text-yellowBorderActive': mode === 'vvs' },
            'ml-1 mobile:text-small'
          )}
        >
          {'VVS Finance'}
        </Text>
        <a
          href={'https://vvs.finance/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.ExternalURL
            height={24}
            width={24}
            className="text-yellowPrimary"
          />
        </a>
      </div>
      <div
        key={Dexes.wowmax}
        className={clsx(
          {
            'border-b-2 border-yellowBorderActive': mode === 'wowmax',
          },
          'flex w-6/12 cursor-pointer justify-center py-0.5'
        )}
        onClick={(): void => onModeChange(Dexes.wowmax)}
      >
        <WOWMAXSVG
          width={isMobile ? '97' : '139'}
          height={isMobile ? '10' : '14'}
          stroke={mode === 'wowmax' ? 'rgba(244, 244, 153, 1)' : ''}
        />
        <a
          href={'https://wowmax.exchange/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.ExternalURL
            height={24}
            width={24}
            className="m-[-5px] ml-0.5 text-yellowPrimary"
          />
        </a>
      </div>
    </div>
  );
}

export default SwapModeSwitch;
