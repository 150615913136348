import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import { Network } from '@types';

export const WCRO_ADDRESS_MAINNET =
  '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23';

export const WCRO_ADDRESS_TESTNET =
  '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4';
const VVS_ADDRESS_TESTNET = '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a';

export const checkPriceImpact = (priceImpact: number): boolean => {
  return priceImpact > 5;
};

const singlePathTokens = ['CRO', 'TUSD'];

export const getSwapPath = (
  outAsset: TectonicAsset,
  inAsset: TectonicAsset
) => {
  const entryPath = getUnderlyingAddress(inAsset);
  const outPath = getUnderlyingAddress(outAsset);

  if (
    singlePathTokens.includes(outAsset.symbol) ||
    singlePathTokens.includes(inAsset.symbol)
  ) {
    return [entryPath, outPath];
  }

  return [entryPath, getWcroAddress(), outPath];
};

export const getUnderlyingAddress = (asset: TectonicAsset): string => {
  switch (asset.symbol) {
    case 'CRO':
      return getWcroAddress();
    default:
      return asset.underlyingAddress;
  }
};

export const getWcroAddress = (): string => {
  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos:
      return WCRO_ADDRESS_MAINNET;
    case Network.cronos_uat:
      return WCRO_ADDRESS_MAINNET;
    case Network.cronos_local:
      return WCRO_ADDRESS_TESTNET;
    default:
      return WCRO_ADDRESS_TESTNET;
  }
};
