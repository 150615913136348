import { BigNumber } from '@ethersproject/bignumber';
import { TectonicAsset } from '@tectonicfi/sdk';

import { isAmountZeroEquivalent } from '@lib/utils';
import { parseInputAmountToBN } from '@lib/units';

interface ValidateWithdrawAmountParams {
  amount: string;
  asset: TectonicAsset | null;
  assetIsCollateral: boolean;
  supplyAmount: BigNumber | null;
  totalMarketAvailableAmount: BigNumber | null;
  userMaxWithdrawAmount: BigNumber | null;
}

export interface ValidateWithdrawAmountResult {
  reason: 'invalid' | 'insufficientLiquidity' | null;
  valid: boolean;
}

function validateWithdrawAmount({
  amount,
  asset,
  assetIsCollateral,
  supplyAmount,
  totalMarketAvailableAmount,
  userMaxWithdrawAmount,
}: ValidateWithdrawAmountParams): ValidateWithdrawAmountResult {
  const invalidResult: ValidateWithdrawAmountResult = {
    valid: false,
    reason: 'invalid',
  };
  if (
    amount &&
    asset &&
    supplyAmount &&
    totalMarketAvailableAmount &&
    userMaxWithdrawAmount
  ) {
    try {
      const parsedAmount = parseInputAmountToBN(amount, asset.decimals);
      const invalidInput =
        isAmountZeroEquivalent(amount) ||
        parsedAmount.isZero() ||
        parsedAmount.isNegative();
      const noSupply = supplyAmount.isZero();
      const greaterThanUserSupplyAmount = parsedAmount.gt(supplyAmount);
      const greaterThanUserMaxWithdrawAmount = parsedAmount.gt(
        userMaxWithdrawAmount
      );
      const greaterThanTotalMarketAvailableAmount = parsedAmount.gt(
        totalMarketAvailableAmount
      );

      if (invalidInput || noSupply) {
        return invalidResult;
      }

      if (
        (assetIsCollateral && greaterThanUserMaxWithdrawAmount) ||
        greaterThanUserSupplyAmount ||
        greaterThanTotalMarketAvailableAmount
      ) {
        return { valid: false, reason: 'insufficientLiquidity' };
      }

      return { valid: true, reason: null };
    } catch (error) {
      return invalidResult;
    }
  }
  return invalidResult;
}

export default validateWithdrawAmount;
