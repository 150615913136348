import TonicSummary from './TonicSummary';
import { MIN_CLAIM_TONIC_AMOUNT } from './TonicBalanceModal';

interface Props {
  unclaimValueAmount: string;
  totalBalanceToUsdValue: string;
  unclaimBoostValueAmount: string;
  boostTonicToUsdValue: string;
}

export default function Empty({
  unclaimValueAmount,
  totalBalanceToUsdValue,
  unclaimBoostValueAmount,
  boostTonicToUsdValue,
}: Props) {
  return (
    <div>
      <TonicSummary
        totalBalanceToUsdValue={totalBalanceToUsdValue}
        unclaimValueAmount={unclaimValueAmount}
        unclaimBoostValueAmount={unclaimBoostValueAmount}
        boostTonicToUsdValue={boostTonicToUsdValue}
      />

      <div className="mb-2 text-left text-mediumSmall font-normal leading-[21px] text-white mobile:mb-3">
        The minimum claimable amount is {MIN_CLAIM_TONIC_AMOUNT} TONIC tokens.{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://tectonic.gitbook.io/docs/guides/claiming-tonic-rewards"
        >
          <span className="font-semibold text-yellowPrimary">Learn More</span>
        </a>
      </div>
    </div>
  );
}
