import { useReducer } from 'react';

import { DepositSelectionState } from './types';

export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export interface SelectAllAction {
  allIds: number[];
  type: typeof TOGGLE_SELECT_ALL;
}
export const TOGGLE = 'TOGGLE';
export interface ToggleAction {
  id: number;
  type: typeof TOGGLE;
}
export type UpgradeLockingPeriodAction = SelectAllAction | ToggleAction;

export function depositSelectionReducer(
  state: DepositSelectionState,
  action: UpgradeLockingPeriodAction
): DepositSelectionState {
  switch (action.type) {
    case TOGGLE_SELECT_ALL:
      return {
        selectedAll: !state.selectedAll,
        selectedVaults: state.selectedAll ? [] : action.allIds,
      };
    case TOGGLE:
      return {
        selectedAll: false,
        selectedVaults: state.selectedVaults.includes(action.id)
          ? state.selectedVaults.filter((val) => val !== action.id)
          : [...state.selectedVaults, action.id],
      };
    default:
      return state;
  }
}

const initState: DepositSelectionState = {
  selectedAll: false,
  selectedVaults: [],
};

export default function useDepositSelectionState() {
  return useReducer(depositSelectionReducer, initState);
}
