import { useMemo, useState } from 'react';
import { Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import useWalletBalance from '@hooks/useWalletBalance';
import { isAmountLessThanAllowance } from '@lib/utils';
import EnableErc20Content from '@components/EnableErc20Content';
import Spinner from '@components/Spinner';
import { Mode } from '@components/MarketsPageView/types';
import ModeSwitch from '@components/ModeSwitch/ModeSwitch';

import RepayWithWallet from './RepayWithWallet';
import RepayWithCollateral from './RepaywithCollateral';

export interface RepayModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  allowance: BigNumber;
  amount: string;
  asset: TectonicAsset | null;
  isAllowanceLoaded: boolean;
  isAllowanceLoading: boolean;
  onAmountChange(value: string): void;
  onEnable(): void;
  onRepay(): void;
  onRepaySome(amount: string): void;
  onRepayAllWithCollateral: (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => void;
  onRepaySomeWithCollateral: (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => void;
  onGetTTokenAmountIn: (
    tectonicSocketAddress: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ) => Promise<[BigNumber, BigNumber]>;
  mode: Mode;
}
export const modes = [
  { mode: 'wallet', text: 'Repay with wallet balance' },
  { mode: 'collateral', text: 'Repay with collateral' },
];

function RepayModal({
  allowance,
  amount,
  asset,
  isAllowanceLoaded,
  isAllowanceLoading,
  onAmountChange,
  onEnable,
  onRepay,
  onRepaySome,
  onRepayAllWithCollateral,
  onRepaySomeWithCollateral,
  onGetTTokenAmountIn,
  transactionStatus,
  mode,
  ...props
}: RepayModalProps): JSX.Element {
  const [repayMode, setRepayMode] = useState('wallet');
  const symbol = asset?.symbol || '';
  const options = useMemo(
    () => ({ skip: !props.isOpen, mode }),
    [props.isOpen, mode]
  );
  const erc20IsEnabled = isAmountLessThanAllowance(allowance, amount, asset);

  const { data: walletData } = useWalletBalance(mode, asset, options);

  return (
    <BaseTransactionModal
      className="max-h-[calc(100%-1rem)] desktop:!w-[510px]"
      title={
        isAllowanceLoaded && !allowance.isZero()
          ? `Repay ${symbol}`
          : `Enable ${symbol} Market`
      }
      {...props}
      transactionStatus={transactionStatus}
    >
      {(!isAllowanceLoaded || isAllowanceLoading) && (
        <div className="flex flex-col items-center justify-center">
          <Spinner />
          <Text className="mt-4">Loading data, please wait...</Text>
        </div>
      )}

      {isAllowanceLoaded && allowance.isZero() && (
        <EnableErc20Content
          onEnable={onEnable}
          symbol={symbol}
          walletData={walletData}
        />
      )}

      {isAllowanceLoaded && !allowance.isZero() && (
        <>
          <div>
            {erc20IsEnabled ? (
              <>
                <ModeSwitch
                  className="!mb-3 mt-5 !ml-0 max-h-[48px] gap-4 desktop:!justify-start tablet:!justify-start mobile:!justify-start"
                  modes={modes}
                  variant="tabs"
                  mode={repayMode}
                  onModeChange={(newMode) => setRepayMode(newMode)}
                />
              </>
            ) : (
              <Text>
                {`To repay ${symbol} to the Tectonic Protocol, you need to enable it first.`}
              </Text>
            )}
          </div>
          {repayMode === 'wallet' && (
            <RepayWithWallet
              allowance={allowance}
              amount={amount}
              asset={asset}
              isAllowanceLoaded={isAllowanceLoaded}
              onAmountChange={onAmountChange}
              onEnable={onEnable}
              onRepay={onRepay}
              onRepaySome={onRepaySome}
              transactionStatus={transactionStatus}
              mode={mode}
              erc20IsEnabled={erc20IsEnabled}
              {...props}
            />
          )}
          {repayMode === 'collateral' && (
            <RepayWithCollateral
              allowance={allowance}
              amount={amount}
              asset={asset}
              isAllowanceLoaded={isAllowanceLoaded}
              onAmountChange={onAmountChange}
              onEnable={onEnable}
              onRepayAllWithCollateral={onRepayAllWithCollateral}
              onRepaySomeWithCollateral={onRepaySomeWithCollateral}
              onGetTTokenAmountIn={onGetTTokenAmountIn}
              transactionStatus={transactionStatus}
              mode={mode}
              erc20IsEnabled={erc20IsEnabled}
              {...props}
            />
          )}
        </>
      )}
    </BaseTransactionModal>
  );
}

export default RepayModal;
