import { utils } from 'ethers';

import { isAmountZeroEquivalent } from '@lib/utils';
import { parseInputAmountToBN } from '@lib/units';
import { TONIC_DECIMALS } from '@config/constants';

interface ValidateTonicStakeAmountAmountResult {
  reason: 'invalid' | 'belowLimit' | 'insufficientBalance' | null;
  valid: boolean;
}

const STAKING_MINIMUM_TONIC = utils.parseUnits('100', TONIC_DECIMALS);

function validateTonicStakeAmountAmount(
  mode: 'stake' | 'unstake',
  amount: string
): ValidateTonicStakeAmountAmountResult {
  try {
    const parsedAmount = parseInputAmountToBN(amount, TONIC_DECIMALS);
    const invalidInput =
      isAmountZeroEquivalent(amount) ||
      parsedAmount.isZero() ||
      parsedAmount.isNegative();

    if (invalidInput) {
      return { reason: 'invalid', valid: false };
    }

    if (mode === 'stake' && parsedAmount.lt(STAKING_MINIMUM_TONIC)) {
      return { reason: 'belowLimit', valid: false };
    }

    return { reason: null, valid: true };
  } catch (error) {
    return { reason: 'invalid', valid: false };
  }
}

export default validateTonicStakeAmountAmount;
