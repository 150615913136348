import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { PoolType } from '@config/base';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';

export default function usePartnerTokenList(poolType: PoolType) {
  const sdk = useTectonicSdk(poolType);

  const query = getQuery(QueryKey.TECTONIC_PARTNER_TOKEN_LIST)(sdk);

  const { data, isLoading, error } = useQuery(query.queryKey, query.queryFn, {
    enabled: poolType === 'DEFI',
  });

  if (poolType === 'MAIN') return { isLoading: false, data: [], error: null };

  return { data, isLoading, error };
}
