import ModeSwitch, { Modes } from '@components/ModeSwitch';

interface TonicStakingCardModeSwitchProps {
  mode: 'stake' | 'unstake';
  onModeChange(mode: 'stake' | 'unstake'): void;
}
const modes: Modes<'stake' | 'unstake'> = [
  { mode: 'stake' as const, text: 'Stake TONIC' },
  { mode: 'unstake' as const, text: 'Unstake' },
];
function TonicStakingCardModeSwitch({
  mode,
  onModeChange,
}: TonicStakingCardModeSwitchProps): JSX.Element {
  return (
    <ModeSwitch
      onModeChange={onModeChange}
      mode={mode}
      modes={modes}
      className="desktop:w-3/5 tablet:mt-0.5 tablet:w-1/2 mobile:mb-2"
    />
  );
}

export default TonicStakingCardModeSwitch;
