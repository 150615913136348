import { ReactNode } from 'react';
import clsx from 'clsx';

export interface CardProps {
  border?: boolean;
  children: ReactNode;
  className?: string;
  padding?: 'default' | 'small' | 'large' | 'none';
  variant?: 'default' | 'announcement' | 'no-background';
}

function Card({
  border = true,
  children,
  className,
  padding = 'default',
  variant = 'default',
  ...props
}: CardProps) {
  return (
    <div
      className={clsx(
        'mx-auto w-full',
        {
          'bg-darkPrimary': variant === 'default',
          'bg-gradient-to-r from-yellowPrimary to-pinkSecondary':
            variant === 'announcement',
        },
        {
          'p-2 desktop:p-2': padding === 'small',
          'p-2 desktop:p-3': padding === 'default',
          'p-2 desktop:p-4': padding === 'large',
        },
        {
          'border border-onSurface12': border,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export default Card;
