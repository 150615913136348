import { DropdownContext } from '@tectonicfi/tectonic-ui-kit';
import { useContext } from 'react';

import { displayValues as defaultDisplayValues } from './constants';

export default function DisplayValue({
  displayValues,
}: {
  displayValues?: Record<string, string>;
}) {
  const context = useContext(DropdownContext);
  return (
    <span className="text-[12px] font-semibold uppercase">
      {displayValues
        ? displayValues[context.selected]
        : defaultDisplayValues[context.selected]}
    </span>
  );
}
