export const sortValues = [
  'recently-locked',
  'ending-soon',
  'amount-asc',
  'amount-desc',
];

export type SortValue = typeof sortValues[number];

export const displayValues: Record<SortValue, string> = {
  ['recently-locked']: 'Recently locked',
  ['ending-soon']: 'Lock date ending soon',
  ['amount-asc']: 'xTONIC amount: low to high',
  ['amount-desc']: 'xTONIC amount: high to low',
};
