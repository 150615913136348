import { Modes } from '@components/ModeSwitch';

export enum Mode {
  tonic = 'tonic',
  vaults = 'vaults',
  earnings = 'earnings',
  bounty = 'bounty',
}

export const modes: Modes<Mode> = [
  { mode: Mode.tonic, text: 'TONIC' },
  { mode: Mode.vaults, text: 'Vaults' },
  { mode: Mode.earnings, text: 'Earnings' },
  // { mode: Mode.bounty, text: 'BOUNTY' },
];
