import React, { useMemo } from 'react';

import NFTImageCard from '../NFTImageCard';
import { NFT } from '../useNFTMeta';

type NFTGalleryProps = {
  stakedNfts: NFT[];
  userNfts: NFT[];
  selectedIds: string[];
  onSelect: (id: string) => void;
};
const NFTGallery: React.FC<NFTGalleryProps> = ({
  stakedNfts,
  userNfts,
  selectedIds,
  onSelect,
}) => {
  const rankSortedStakedNfts = useMemo(
    () => stakedNfts.sort((a, b) => a.rank - b.rank),
    [stakedNfts]
  );
  const rankSortedUserNfts = useMemo(
    () => userNfts.sort((a, b) => a.rank - b.rank),
    [userNfts]
  );

  return (
    <div className="grid grid-cols-4 gap-[20px] mobile:grid-cols-3">
      {rankSortedStakedNfts.map((meta) => (
        <NFTImageCard
          key={meta.id}
          meta={meta}
          onClick={onSelect}
          selectedIds={selectedIds}
        />
      ))}
      {rankSortedUserNfts.map((meta) => (
        <NFTImageCard
          key={meta.id}
          meta={meta}
          onClick={onSelect}
          selectedIds={selectedIds}
        />
      ))}
    </div>
  );
};

export default NFTGallery;
