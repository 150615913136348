import { Button, Icon, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';
import { ReactNode } from 'react';
import { BigNumber } from 'ethers';
import clsx from 'clsx';

import useUsdPrices from '@hooks/useUsdPrices';
import { formatAssetUsdPrice } from '@lib/units';
import { withHook } from '@hooks/withHook';
import useSupportedAsset from '@hooks/useSupportedAsset';
import Card from '@components/Card';

import TonicPageCardSection from './TonicPageCardSection';
function DataRow({
  label,
  value,
  loading,
  useMobileColumns,
}: {
  label: ReactNode;
  value: ReactNode;
  loading: boolean;
  useMobileColumns?: boolean;
}) {
  return (
    <div
      className={clsx('flex items-center justify-between', {
        'mobile:flex-col mobile:items-start': useMobileColumns,
      })}
    >
      {label}
      {loading ? (
        <Skeleton data-testid="skeleton" className="h-2 w-1/2 mobile:w-full" />
      ) : (
        value
      )}
    </div>
  );
}
export interface ConversionBountyCardProps {
  loading: boolean;
  protocolYieldInTonic: string;
  tonicPriceInUsd: string;
  claimableBountyInTonic: string;
  claimableBountyInUsd: string;
  onClaim: () => void;
}
export function ConversionBountyCardUi({
  loading,
  protocolYieldInTonic,
  tonicPriceInUsd,
  claimableBountyInTonic,
  claimableBountyInUsd,
  onClaim,
}: ConversionBountyCardProps) {
  return (
    <Card padding="none">
      <TonicPageCardSection borderBottom>
        <Text variant="semiBold">Protocol Revenue Conversion Bounty</Text>
      </TonicPageCardSection>
      <TonicPageCardSection borderBottom>
        <div className="flex items-center bg-testBlue p-2">
          <Icon.Announcement className="mr-2 h-4 w-4 flex-shrink-0 fill-rainbowYellow" />

          <Text className="text-mediumSmall leading-5">
            Claiming the conversion bounty helps trigger the conversion of
            protocol revenue to Tectonic and adding it to TONIC stakers.
          </Text>
        </div>
      </TonicPageCardSection>
      <TonicPageCardSection borderBottom className="space-y-2">
        <DataRow
          useMobileColumns={true}
          loading={loading}
          label={<Text>Pending Protocol Yield</Text>}
          value={<Text>{protocolYieldInTonic} TONIC</Text>}
        />
      </TonicPageCardSection>
      <TonicPageCardSection borderBottom className="space-y-2">
        <DataRow
          useMobileColumns={true}
          loading={loading}
          label={<Text>TONIC Exchange Rate</Text>}
          value={
            tonicPriceInUsd ? (
              <Text>1 TONIC = {tonicPriceInUsd}</Text>
            ) : (
              <Skeleton data-testid="skeleton" className="h-2 w-10" />
            )
          }
        />
        <DataRow
          useMobileColumns={true}
          loading={loading}
          label={
            <Text variant="semiBold" className="flex items-center">
              <Icon.Tonic className="mr-1 h-2.5 w-2.5" />
              Claimable Bounty
            </Text>
          }
          value={
            <div>
              <Text as="span" variant="semiBold">
                {claimableBountyInTonic} TONIC
              </Text>
              <Text
                as="span"
                variant="semiTransparent"
                className="text-small font-bold"
              >
                &nbsp; (~{claimableBountyInUsd})
              </Text>
            </div>
          }
        />
      </TonicPageCardSection>
      <TonicPageCardSection>
        <Button
          onClick={onClaim}
          className="w-full"
          disabled={loading || claimableBountyInTonic === '0'}
        >
          Claim Bounty
        </Button>
        <div className="mt-2 flex flex-row">
          <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2 flex-shrink-0 text-yellowPrimary" />
          <Text variant="small">
            Additional gas fees are required to facilitate this reward
            transaction. The conversion bounty will not be transferred to you
            until they are claimed.
          </Text>
        </div>
      </TonicPageCardSection>
    </Card>
  );
}
const ConversionBountyCard = withHook(() => {
  const tonicAsset = useSupportedAsset('TONIC');
  const { usdPrices } = useUsdPrices();
  const rawTonicUsdPrice = usdPrices['TONIC'];
  const tonicUsdPrice = rawTonicUsdPrice && BigNumber.from(rawTonicUsdPrice);
  const tonicPriceInUsd =
    tonicAsset && tonicUsdPrice
      ? formatAssetUsdPrice(tonicAsset, tonicUsdPrice)
      : '';
  return {
    tonicPriceInUsd,
  };
})(ConversionBountyCardUi);
export default ConversionBountyCard;
