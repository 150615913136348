import { TectonicSDK } from '@tectonicfi/sdk';
import { TectonicVaultStakeInfo } from '@tectonicfi/sdk/dist/types';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { addSeconds } from 'date-fns';
import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import LockingPeriodCard from '@components/LockDepositModal/LockingPeriodCard';
import { periodInSeconds } from '@components/LockDepositModal/periods';
import {
  LockingPeriod,
  LockingPeriodDetails,
} from '@components/LockDepositModal/types';
import Spinner from '@components/Spinner';
import useIsMobile from '@hooks/useIsMobile';
import useSupportedAsset from '@hooks/useSupportedAsset';
import useUsdPrices from '@hooks/useUsdPrices';
import { withHook } from '@hooks/withHook';
import { formatLockEndDate } from '@lib/dates';
import { convertXTonicToTonic, getUnderlyingUsdValue } from '@lib/math';
import { formatUserUnderlyingAmount, formatXTonic } from '@lib/units';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { TxStatus } from '@types';

interface UpgradeDeposits {
  stakeId: number;
  xTonicAmount: string;
  usdAmount: string;
  currentLockEndDate: Date;
  lockStartDate: Date;
}

export interface UpgradeDepositsModalProps extends BaseTransactionModalProps {
  availablePeriods: LockingPeriodDetails[];
  selectedPeriod?: LockingPeriod;
  totalXTonic: string;
  deposits: UpgradeDeposits[];
  loaded: boolean;
  onPeriodChange: (period: LockingPeriod) => void;
  onUpgrade: (stakeIds: number[], poolId: number) => void;
}

export function getLockEndDate(date: Date, period?: LockingPeriod) {
  if (!period) {
    return '-';
  }
  return formatLockEndDate(addSeconds(date, periodInSeconds[period]));
}

export function UpgradeDepositsModalUi({
  selectedPeriod,
  availablePeriods,
  totalXTonic,
  deposits,
  onPeriodChange,
  onUpgrade,
  onClose,
  transactionErrorMessage,
  transactionExplorerHref,
  loaded,
  ...props
}: UpgradeDepositsModalProps) {
  const singlePeriod = availablePeriods.length === 1;
  const isMobile = useIsMobile();

  return (
    <BaseTransactionModal
      transactionErrorMessage={transactionErrorMessage}
      transactionExplorerHref={transactionExplorerHref}
      onClose={onClose}
      {...props}
      variant="vault"
      renderTitle={(status) => {
        switch (status) {
          case TxStatus.awaiting_confirmation:
          case TxStatus.pending:
            return 'Upgrade';
          case TxStatus.confirmed:
            return 'Vault upgrade Completed';
          case TxStatus.failed:
            return 'Vault upgrade failed';
          default: {
            if (singlePeriod) {
              return 'Confirm Upgrade';
            }
            return 'Upgrade locking period';
          }
        }
      }}
      renderTransactionStatus={(txStatus) => {
        const isTransactionAwaitingConfirmation =
          txStatus === TxStatus.awaiting_confirmation;
        const isTransactionPending = txStatus === TxStatus.pending;
        const isTransactionConfirmed = txStatus === TxStatus.confirmed;
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;
        const showViewInExplorerButton = Boolean(
          (isTransactionPending || isTransactionConfirmed) &&
            transactionExplorerHref
        );

        return (
          <div className="text-center">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionPending && (
              <Text className="mt-4">Upgrading Vault, please wait...</Text>
            )}
            {isTransactionConfirmed && (
              <>
                <Icon.Check className="inline-block h-8 w-8" />
                <Text className="mt-4">
                  {totalXTonic} xTONIC have been upgraded in vault tier.
                </Text>
              </>
            )}
            {txStatus === 'failed' && (
              <>
                <Icon.Info className="inline-block h-8 w-8 text-rainbowRed" />
                <Text className="mt-4">
                  {transactionErrorMessage || 'Unknown error'}
                </Text>
              </>
            )}
            {showViewInExplorerButton && (
              <Button
                as="a"
                className="mt-3 w-full"
                href={transactionExplorerHref as string}
                rel="noopener noreferrer"
                target="_blank"
              >
                View on Cronos Chain
              </Button>
            )}
          </div>
        );
      }}
    >
      {!loaded && (
        <div className="flex h-[300px] items-center justify-center">
          <Spinner />
        </div>
      )}
      {loaded && (
        <div className="max-h-[568px] overflow-scroll mobile:max-h-[545px]">
          <Text>Upgrade to</Text>
          <div
            className={clsx(
              'my-3',
              !singlePeriod && 'grid gap-1',
              (availablePeriods.length === 2 || (!singlePeriod && isMobile)) &&
                'grid-cols-2',
              availablePeriods.length === 3 && !isMobile && 'grid-cols-3'
            )}
          >
            {availablePeriods.map((p, i) => (
              <LockingPeriodCard
                key={p.period}
                {...p}
                className={clsx(
                  singlePeriod && 'mx-auto max-w-[300px]',
                  i === 2 &&
                    availablePeriods.length === 3 &&
                    isMobile &&
                    'col-span-2'
                )}
                selected={p.period === selectedPeriod}
                onClick={() => onPeriodChange(p.period)}
              />
            ))}
          </div>
          <Text className="mb-2 text-mediumSmall">You&apos;ll lock</Text>
          <div className="mb-2 flex flex-col divide-y divide-greyBackground">
            {deposits.map((dep) => (
              <div
                className="flex flex-col space-y-0.5 py-2 "
                key={dep.stakeId}
              >
                <div className="flex flex-row justify-between">
                  <Text className="text-mediumSmall">
                    {dep.xTonicAmount} xTONIC
                  </Text>
                  <Text className="text-mediumSmall">{dep.usdAmount} USD</Text>
                </div>
                <div className="flex flex-row justify-between">
                  <Text className="text-mediumSmall">Lock end date</Text>
                  <Text className="text-mediumSmall">
                    {getLockEndDate(dep.lockStartDate, selectedPeriod)}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className="my-2 rounded-xl bg-greyBackground p-2">
            <Text variant="semiTransparent">
              Upgrading your locking period is irreversible. By upgrading your
              locking period, the new period end date will be the full term of
              the selected locking period.
            </Text>
          </div>
          <div className="h-[90px]"></div>
          <div className="absolute bottom-0 left-0 right-0 z-20 rounded-md bg-semiTransparentTestBlue p-3 backdrop-blur">
            <Button
              className="w-full"
              disabled={!selectedPeriod}
              onClick={() => {
                if (!selectedPeriod) {
                  return;
                }
                const periodDetails = availablePeriods.find(
                  (p) => p.period === selectedPeriod
                );
                if (!periodDetails) {
                  return;
                }
                onUpgrade(
                  deposits.map((d) => d.stakeId),
                  periodDetails.poolId
                );
              }}
            >
              Confirm Upgrade
            </Button>
          </div>
        </div>
      )}
    </BaseTransactionModal>
  );
}
const getData = async (sdk: TectonicSDK) => {
  const [xTonicExchangeRate] = await Promise.all([
    sdk.Staking.getExchangeRate(),
  ]);
  return { xTonicExchangeRate };
};

const useUpgradeDepositsData = () => {
  const sdk = useTectonicSdk();
  const [data, setData] = useState<Awaited<ReturnType<typeof getData>>>();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!sdk) {
      return;
    }
    getData(sdk).then((d) => {
      setLoaded(true);
      setData(d);
    });
  }, [sdk]);
  return { loaded, data };
};
const UpgradeDepositsModal = withHook(
  ({
    availablePeriods,
    deposits: _deposits,
  }: {
    availablePeriods: LockingPeriodDetails[];
    deposits: TectonicVaultStakeInfo[];
  }) => {
    const isSinglePeriod = availablePeriods.length === 1;
    const [_period, setPeriod] = useState<LockingPeriod>();
    const period = isSinglePeriod ? availablePeriods[0].period : _period;
    const { loaded: pricesLoaded, usdPrices } = useUsdPrices();
    const { loaded: dataLoaded, data } = useUpgradeDepositsData();
    const tonicAsset = useSupportedAsset('TONIC');
    const totalXTonic = formatXTonic(
      _deposits.reduce((sum, d) => sum.add(d.amount), BigNumber.from(0))
    );
    const deposits: UpgradeDeposits[] = useMemo(
      () =>
        _deposits.map((d) => ({
          stakeId: d.stakeId,
          currentLockEndDate: d.lockEndDate,
          lockStartDate: d.lockStartDate,
          xTonicAmount: formatXTonic(d.amount),
          usdAmount:
            (tonicAsset &&
              formatUserUnderlyingAmount(
                tonicAsset,
                getUnderlyingUsdValue(
                  tonicAsset,
                  convertXTonicToTonic(
                    d.amount,
                    data?.xTonicExchangeRate || BigNumber.from(0)
                  ),
                  BigNumber.from(usdPrices.TONIC || 0)
                )
              )) ||
            '-',
        })),
      [_deposits, tonicAsset, usdPrices.TONIC, data?.xTonicExchangeRate]
    );
    return {
      selectedPeriod: period,
      onPeriodChange: setPeriod,
      totalXTonic,
      availablePeriods,
      loaded: pricesLoaded && dataLoaded,
      deposits,
    };
  }
)(UpgradeDepositsModalUi);

export default UpgradeDepositsModal;
