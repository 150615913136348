import { useEffect, useMemo } from 'react';
import { useQueries } from 'react-query';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import { notify } from '@lib/bugsnag';
import { PoolType } from '@config/base';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { getQuery } from '@queries/queries';
import { QueryKey } from '@config/queryKey';
import { getNonTonicApyRate, getTonicApyRate } from '@lib/math';

import useTonicUsdPrice from './useTonicUsdPrice';
import useAssetTokenUsdPrice from './useAssetTokenUsdPrice';
import usePartnerTokenList from './usePartnerTokenList';
import usePartnerTokenUsdPrices from './usePartnerTokenUsdPrices';

export type UseBorrowApyResult = {
  netBorrowApy: Undefined<number>;
  borrowApy: Undefined<number>;
  tonicBorrowApy: number;
  netPartnerTokenBorrowApy: number;
  partnerTokenBorrowApy: Record<string, number>;
  hasError: boolean;
  isLoading: boolean;
};

interface UseBorrowApyOptions {
  skip?: boolean;
}

function useBorrowApy(
  poolType: PoolType,
  asset: Null<TectonicAsset>,
  options?: UseBorrowApyOptions
): UseBorrowApyResult {
  const sdk = useTectonicSdk(poolType);

  const { data: assetUsdPrice, isLoading: isAssetUsdPriceLoading } =
    useAssetTokenUsdPrice(poolType, asset?.tTokenAddress);

  const borrowAmountQuery = getQuery(QueryKey.TECTONIC_BORROW_AMOUNT)(
    sdk,
    asset?.tTokenAddress ?? ''
  );

  const borrowApyQuery = getQuery(QueryKey.TECTONIC_BORROW_APY)(
    sdk,
    asset?.tTokenAddress ?? ''
  );
  const tonicDailyDistributionRateForBorrowQuery = getQuery(
    QueryKey.TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_BORROW
  )(sdk, asset?.tTokenAddress ?? '');
  const nonTonicDailyDistributionRatesForBorrowQuery = getQuery(
    QueryKey.TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_BORROW
  )(sdk, asset?.tTokenAddress ?? '');

  // get borrow amount, borrow apy, daily distribution amount of tonic and non-tonic
  const [
    {
      data: borrowAmount,
      isLoading: isBorrowAmountLoading,
      error: borrowAmountError,
    },
    { data: borrowApy, isLoading: isBorrowApyLoading, error: borrowApyError },
    {
      data: dailyDistributionAmount,
      isLoading: isDailyDistributionAmountLoading,
      error: dailyDistributionAmountError,
    },
    {
      data: nonTonicDailyDistributionAmounts,
      isLoading: isNonTonicDailyDistributionAmountsLoading,
      error: nonTonicDailyDistributionAmountsError,
    },
  ] = useQueries([
    {
      ...borrowAmountQuery,
      enabled: !options?.skip && !!asset,
    },
    {
      ...borrowApyQuery,
      enabled: !options?.skip && !!asset,
    },
    {
      ...tonicDailyDistributionRateForBorrowQuery,
      enabled: !options?.skip && !!asset,
    },
    {
      ...nonTonicDailyDistributionRatesForBorrowQuery,
      enabled: !options?.skip && !!asset && poolType === 'DEFI',
    },
  ]);

  const { tonicUsdPrice } = useTonicUsdPrice();

  const tonicBorrowApy =
    asset &&
    assetUsdPrice &&
    dailyDistributionAmount &&
    tonicUsdPrice &&
    borrowAmount
      ? getTonicApyRate(
          dailyDistributionAmount,
          tonicUsdPrice,
          asset,
          borrowAmount,
          assetUsdPrice
        )
      : 0;

  const {
    data: partnerTokenList,
    isLoading: isPartnerTokenListLoading,
    error: partnerTokenListError,
  } = usePartnerTokenList(poolType);

  const partnerTokenUsdPrices = usePartnerTokenUsdPrices(
    poolType,
    partnerTokenList
  );

  const [partnerTokenBorrowApy, netPartnerTokenBorrowApy] = useMemo<
    [Record<string, number>, number]
  >(() => {
    if (!asset || !assetUsdPrice || !borrowAmount || !partnerTokenList) {
      return [{}, 0];
    }
    let netApy = 0;
    const apyObject = partnerTokenList.reduce((acc, token, index) => {
      const tokenUsdPrice = partnerTokenUsdPrices[index].data;
      const nonTonicDailyDistributionRate =
        nonTonicDailyDistributionAmounts?.find(
          (e) => e.partnerToken === token.address
        )?.rate;

      const apy =
        nonTonicDailyDistributionRate && tokenUsdPrice
          ? getNonTonicApyRate(
              nonTonicDailyDistributionRate,
              tokenUsdPrice,
              asset,
              borrowAmount,
              assetUsdPrice
            )
          : 0;
      netApy += apy;
      return {
        ...acc,
        [token.symbol]: apy,
      };
    }, {});

    return [apyObject, netApy];
  }, [
    asset,
    assetUsdPrice,
    borrowAmount,
    partnerTokenList,
    partnerTokenUsdPrices,
    nonTonicDailyDistributionAmounts,
  ]);

  const isLoading =
    isAssetUsdPriceLoading ||
    isBorrowAmountLoading ||
    isBorrowApyLoading ||
    isDailyDistributionAmountLoading ||
    isNonTonicDailyDistributionAmountsLoading ||
    isPartnerTokenListLoading;

  const hasError = Boolean(
    borrowApyError ||
      dailyDistributionAmountError ||
      partnerTokenListError ||
      borrowAmountError ||
      nonTonicDailyDistributionAmountsError
  );

  const netBorrowApy =
    borrowApy === undefined
      ? undefined
      : borrowApy - tonicBorrowApy * 100 - netPartnerTokenBorrowApy * 100;
  useEffect(() => {
    if (borrowAmountError) notify(borrowAmountError);
    if (borrowApyError) notify(borrowApyError);
    if (dailyDistributionAmountError) notify(dailyDistributionAmountError);
    if (nonTonicDailyDistributionAmountsError)
      notify(nonTonicDailyDistributionAmountsError);
    if (partnerTokenListError) notify(partnerTokenListError);
  }, [
    borrowAmountError,
    borrowApyError,
    dailyDistributionAmountError,
    nonTonicDailyDistributionAmountsError,
    partnerTokenListError,
  ]);

  return {
    netBorrowApy,
    borrowApy,
    tonicBorrowApy,
    hasError,
    partnerTokenBorrowApy,
    netPartnerTokenBorrowApy,
    isLoading,
  };
}

export default useBorrowApy;
