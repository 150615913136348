import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import Image from 'next/image';

import { NFT } from '../useNFTMeta';

type Size = 'sm' | 'lg';

interface Props {
  disabled?: boolean;
  hasStakedNFT?: boolean;
  onClick: VoidFunction;
  className?: string;
  meta?: NFT;
  size: Size;
}

export default function StakingNFTCard({
  disabled,
  hasStakedNFT,
  size,
  className,
  meta,
  onClick,
}: Props): JSX.Element {
  return (
    <div
      onClick={(e) => {
        if (disabled) return;
        e.stopPropagation();
        onClick();
      }}
      className={clsx(
        'rounded-[4px] border-1 border-semiTransparent',
        {
          'h-[45px] w-[45px]': size === 'sm',
          'h-[120px] w-[120px]': size === 'lg',
        },
        {
          'border-dashed': !meta,
        },
        className
      )}
    >
      {meta ? (
        <Image
          className="m-auto mt-1 rounded-[4px]"
          src={meta.image ?? ''}
          alt={`NFT #${meta.id}`}
          width="100%"
          height="100%"
          id={meta.id}
        />
      ) : (
        <div
          className={clsx('flex h-full flex-col items-center justify-center', {
            'cursor-pointer': !disabled,
          })}
        >
          {hasStakedNFT ? (
            <Icon.AddOrSubtract
              fill={disabled ? 'rgba(255, 255, 255, 0.3)' : '#F4F499'}
              width={size === 'lg' ? '60px' : '36px'}
              height={size === 'lg' ? '40px' : '24px'}
            />
          ) : (
            <Icon.Add
              fill={disabled ? 'rgba(255, 255, 255, 0.3)' : '#F4F499'}
              width={size === 'lg' ? '40px' : '24px'}
              height={size === 'lg' ? '40px' : '24px'}
            />
          )}
          {size === 'lg' && (
            <Text
              className={clsx('text-mediumSmall text-yellow', {
                'text-onSurface30': disabled,
              })}
            >
              {hasStakedNFT ? 'Stake/Unstake' : 'Stake'}
            </Text>
          )}
        </div>
      )}
    </div>
  );
}
