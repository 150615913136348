import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import { BigNumber } from 'ethers';
import { TectonicSDK } from '@tectonicfi/sdk';

import { VVS_AIRDROP_SNAPSHOT_BASE_URL } from '@config/base';
import { TectonicSdkContext } from '@providers/TectonicSdkProvider';
import { notify } from '@lib/bugsnag';

export interface VvsFinance2022FebTonicAirdropSnapshotFileBody {
  signature: string;
  tonicToClaim: string;
  vvsInLP: string;
  vvsInMine: string;
  vvsInWallet: string;
  vvsTotal: string;
}

export interface VvsFinance2022FebTonicAirdropSnapshot {
  signature: string;
  tonicToClaim: BigNumber;
  vvsInLP: BigNumber;
  vvsInMine: BigNumber;
  vvsInWallet: BigNumber;
  vvsTotal: BigNumber;
}

interface UseVvsFinance2022FebTonicAirdropDataState {
  hasClaimedAirdrop: boolean;
  hasError: boolean;
  hasNoSnapshot: boolean;
  loaded: boolean;
  loading: boolean;
  snapshot: VvsFinance2022FebTonicAirdropSnapshot | null;
}

const initialState: UseVvsFinance2022FebTonicAirdropDataState = {
  hasClaimedAirdrop: false,
  hasError: false,
  hasNoSnapshot: false,
  loaded: false,
  loading: false,
  snapshot: null,
};

const GET_PENDING = 'GET_PENDING';

const GET_FULFILLED = 'GET_FULFILLED';

const GET_FULFILLED_NO_SNAPSHOT = 'GET_FULFILLED_NO_SNAPSHOT';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  hasClaimedAirdrop: boolean;
  snapshot: VvsFinance2022FebTonicAirdropSnapshot;
  type: typeof GET_FULFILLED;
}

interface GetFulfilledNoSnapshotAction {
  type: typeof GET_FULFILLED_NO_SNAPSHOT;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type UseVvsFinance2022FebTonicAirdropDataAction =
  | GetFulfilledAction
  | GetFulfilledNoSnapshotAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: UseVvsFinance2022FebTonicAirdropDataState,
  action: UseVvsFinance2022FebTonicAirdropDataAction
): UseVvsFinance2022FebTonicAirdropDataState {
  switch (action.type) {
    case GET_PENDING:
      return { ...initialState, loading: true };
    case GET_FULFILLED:
      return {
        ...state,
        hasClaimedAirdrop: action.hasClaimedAirdrop,
        loaded: true,
        loading: false,
        snapshot: action.snapshot,
      };
    case GET_FULFILLED_NO_SNAPSHOT:
      return {
        ...state,
        hasNoSnapshot: true,
        loaded: true,
        loading: false,
      };
    case GET_REJECTED:
      return { ...state, loading: false, loaded: true, hasError: true };
    case RESET:
      return { ...initialState };
    default:
      return { ...state };
  }
}

function getEndpoint(walletAddress: string): string {
  return `${VVS_AIRDROP_SNAPSHOT_BASE_URL}/${walletAddress.toLowerCase()}.json`;
}

export interface UseVvsFinance2022FebTonicAirdropDataResult
  extends UseVvsFinance2022FebTonicAirdropDataState {
  refetch(): Promise<void>;
}

function useVvsFinance2022FebTonicAirdropData(
  walletAddress: string | null
): UseVvsFinance2022FebTonicAirdropDataResult {
  const sdk = useContext(TectonicSdkContext) as TectonicSDK;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSnapshotData = useCallback(
    async (
      address: string
    ): Promise<{
      hasClaimedAirdrop: boolean;
      snapshot: VvsFinance2022FebTonicAirdropSnapshot | null;
    }> => {
      const [{ data }, hasClaimedAirdrop] = await Promise.all([
        axios.get<VvsFinance2022FebTonicAirdropSnapshotFileBody>(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getEndpoint(address)
        ),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sdk.Tonic.airDropClaimed(address),
      ]);

      return {
        hasClaimedAirdrop,
        snapshot:
          // If the request responds with something other than a JSON object, we should also consider there to be no snapshot for this wallet
          typeof data === 'string'
            ? null
            : {
                signature: data.signature,
                // TONIC and VVS token amounts are already scaled to 18 decimals
                tonicToClaim: BigNumber.from(data.tonicToClaim),
                vvsInLP: BigNumber.from(data.vvsInLP),
                vvsInMine: BigNumber.from(data.vvsInMine),
                vvsInWallet: BigNumber.from(data.vvsInWallet),
                vvsTotal: BigNumber.from(data.vvsTotal),
              },
      };
    },
    [sdk]
  );

  useEffect(() => {
    let canceled = false;

    async function getSnapshot(): Promise<void> {
      dispatch({ type: GET_PENDING });

      try {
        const { snapshot, hasClaimedAirdrop } = await getSnapshotData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          walletAddress!
        );

        if (!snapshot) {
          dispatch({ type: GET_FULFILLED_NO_SNAPSHOT });
          return;
        }

        if (!canceled) {
          dispatch({ hasClaimedAirdrop, snapshot, type: GET_FULFILLED });
        }
      } catch (error) {
        if (!canceled) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.response?.status === 404) {
            dispatch({ type: GET_FULFILLED_NO_SNAPSHOT });
            return;
          }

          notify(error);
          dispatch({ type: GET_REJECTED });
        }
      }
    }

    if (walletAddress) {
      getSnapshot();
    } else {
      dispatch({ type: RESET });
    }

    return (): void => {
      canceled = true;
    };
  }, [getSnapshotData, walletAddress]);

  return useMemo(
    () => ({
      ...state,
      refetch: async (): Promise<void> => {
        if (!state.loading && walletAddress) {
          dispatch({ type: GET_PENDING });

          try {
            const { snapshot, hasClaimedAirdrop } = await getSnapshotData(
              walletAddress
            );

            if (!snapshot) {
              dispatch({ type: GET_FULFILLED_NO_SNAPSHOT });
              return;
            }

            dispatch({ hasClaimedAirdrop, snapshot, type: GET_FULFILLED });
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (error.response?.status === 404) {
              dispatch({ type: GET_FULFILLED_NO_SNAPSHOT });
              return;
            }

            notify(error);
            dispatch({ type: GET_REJECTED });
          }
        }
      },
    }),
    [getSnapshotData, state, walletAddress]
  );
}

export default useVvsFinance2022FebTonicAirdropData;
