import { ReactNode } from 'react';
import clsx from 'clsx';
import { AmountInputProps, Text } from '@tectonicfi/tectonic-ui-kit';

interface Props {
  rightLabel?: ReactNode;
  displayBalance?: ReactNode;
}

type EnhancedAmountInputProps = AmountInputProps & Props;

function EnhancedAmountInputSymbol({
  onFocus,
  value,
  rightLabel,
  label,
  invalid,
  onBlur,
  onChange,
  disabled,
  onButtonClick,
  displayBalance,
  buttonText = 'Max',
}: EnhancedAmountInputProps): JSX.Element {
  return (
    <div>
      {typeof label === 'string' ? (
        <Text className="mb-1 text-body font-medium">{label}</Text>
      ) : (
        label
      )}
      <div
        className={clsx(
          'w-auto border px-1.5 py-0.5',
          invalid ? 'border-red' : 'border-white border-opacity-30'
        )}
      >
        {displayBalance}
        <div className="flex items-center">
          <input
            className="webkit-none box-border h-full w-full bg-transparent py-1.5 font-medium text-white outline-none"
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            placeholder="0"
            type="text"
            value={value}
            disabled={disabled}
          />
          {rightLabel}
        </div>
        <div>
          <button
            className={clsx('text-yellowPrimary', {
              'cursor-not-allowed': disabled,
            })}
            disabled={disabled}
            onClick={onButtonClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EnhancedAmountInputSymbol;
