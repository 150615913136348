import { useQuery } from 'react-query';
import { useMemo } from 'react';

import { QueryKey } from '@config/queryKey';
import { getQuery, XtonicAprRangeType } from '@queries/queries';

import { XtonicAprsType } from './types';

const FIRST = 30;

interface XtonicAprsPerDayData {
  xtonicAprs?: XtonicAprsType[];
}

interface UseXtonicAprsPerDayDataResult extends XtonicAprsPerDayData {
  isLoading: boolean;
}

const useXtonicAprsData = (
  type: XtonicAprRangeType,
  first?: number
): UseXtonicAprsPerDayDataResult => {
  const defaultFirst = first ?? FIRST;

  const xtonicAprsPerDayQuery = getQuery(QueryKey.GRAPH_XTONIC_APRS)(
    type,
    defaultFirst
  );

  const { data, isLoading } = useQuery(
    [xtonicAprsPerDayQuery.queryKey, type, defaultFirst],
    xtonicAprsPerDayQuery.queryFn
  );

  return useMemo(
    () =>
      isLoading || !data
        ? { isLoading }
        : {
            isLoading,
            xtonicAprs: data
              .map(({ date, apr7d }) => ({
                date,
                apr: parseFloat(apr7d),
              }))
              .reverse(),
          },
    [isLoading, data]
  );
};

export default useXtonicAprsData;
