import { AnyAction } from 'redux';
import {
  Action,
  ConfigureStoreOptions,
  Store,
  configureStore,
} from '@reduxjs/toolkit';
import { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';

import { AppEnvironment } from '@types';

import rootReducer, { RootReducerState } from './rootReducer';

const buildEnvironment: AppEnvironment =
  process.env.NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Local;

export function makeStore(
  options?: Omit<
    ConfigureStoreOptions<
      RootReducerState,
      AnyAction,
      [ThunkMiddleware<RootReducerState, AnyAction>]
    >,
    'reducer'
  >
) {
  return configureStore<RootReducerState>({
    reducer: rootReducer,
    ...(options ?? {}),
  });
}

export type AppStore = ReturnType<typeof makeStore>;

export type AppDispatch = ReturnType<typeof makeStore>['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootReducerState,
  unknown,
  Action<string>
>;

const wrapper = createWrapper<Store<RootReducerState>>(() => makeStore(), {
  debug: buildEnvironment === AppEnvironment.Local,
});

export default wrapper;
