import { useCallback, useContext, useEffect, useRef } from 'react';
import { Button, Icon, PopoverButton, Text } from '@tectonicfi/tectonic-ui-kit';
import { useQuery } from 'react-query';

import { WalletContext } from '@providers/WalletProvider';
import useWalletBalance from '@hooks/useWalletBalance';
import { formatUserUnderlyingAmountWithAbbreviation } from '@lib/units';
import TransactionModalsProvider, {
  TransactionModalsContext,
} from '@providers/TransactionModalsProvider';
import useSupportedAsset from '@hooks/useSupportedAsset';
import { TOKEN_LISTS_ADDRESS } from '@config/constants';
import { EnsProviderContext, formatCronosId } from '@providers/EnsProvider';
import { getQuery } from '@queries/queries';
import { QueryKey } from '@config/queryKey';
import { isFeatureEnabled } from '@hooks/useIsFeatureEnabled';
import features from '@config/features';
import useEstimateTonicInBatch from '@hooks/useEstimateTonicInBatch';
import useUnclaimBoostTonic from '@hooks/useUnclaimBoostTonic';
// 15 seconds
const INTERVAL_MS = 1000 * 15;

function WalletInfo() {
  const tonicAsset = useSupportedAsset('TONIC');
  const intervalRef = useRef<number | null>(null);
  const { currentAccount, onShowConnectWalletModal, disconnectWallet } =
    useContext(WalletContext);
  const ensProvider = useContext(EnsProviderContext);

  const croIdQuery = getQuery(QueryKey.ENS_PROVIDER_ADDRESS)(
    ensProvider,
    currentAccount || ''
  );

  const queryResult = useQuery({
    ...croIdQuery,
    enabled: !!currentAccount && isFeatureEnabled(features.CronosId),
  });
  const walletAddress = currentAccount || '';
  const shortAddress = `${walletAddress.slice(0, 4)}...${walletAddress.slice(
    walletAddress.length - 4
  )}`;

  const {
    data: estimateTonicInBatchData,
    refetch: refetchEstimateTonicInBatch,
  } = useEstimateTonicInBatch();

  const { data: unclaimBoostTonicBalance, refetch: refetchBoostTonic } =
    useUnclaimBoostTonic();
  const { data: walletBalanceData, refetch: refetchWalletBalance } =
    useWalletBalance('MAIN', tonicAsset);

  const refetchTonicBalances = useCallback(() => {
    refetchEstimateTonicInBatch();
    refetchBoostTonic();
    refetchWalletBalance();
  }, [refetchEstimateTonicInBatch, refetchWalletBalance, refetchBoostTonic]);

  useEffect(() => {
    if (walletAddress) {
      intervalRef.current = window.setInterval(
        () => refetchTonicBalances(),
        INTERVAL_MS
      );
    }

    return (): void => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [walletAddress, refetchTonicBalances]);

  function getTonicBalance() {
    if (
      tonicAsset &&
      walletBalanceData.balance &&
      estimateTonicInBatchData &&
      unclaimBoostTonicBalance
    ) {
      const tonicRewardsBalance = estimateTonicInBatchData.add(
        unclaimBoostTonicBalance
      );
      return formatUserUnderlyingAmountWithAbbreviation(
        tonicAsset,
        walletBalanceData.balance?.add(tonicRewardsBalance)
      );
    }

    return '-';
  }

  return (
    <TransactionModalsProvider
      onTxSuccess={(): void => {
        refetchTonicBalances();
      }}
      TonicBalanceModalProps={{
        estimateTonicInBatchData,
        unclaimBoostTonicBalance,
      }}
    >
      <TransactionModalsContext.Consumer>
        {({ onOpenModal }) =>
          currentAccount ? (
            <div className="flex min-w-[262px] mobile:flex-col-reverse">
              <div className="flex mobile:mt-1">
                <Button
                  theme="info"
                  size="small"
                  className="mr-2"
                  onClick={(): void => {
                    if (tonicAsset) {
                      onOpenModal(tonicAsset, 'tonicBalance');
                      refetchTonicBalances();
                    }
                  }}
                >
                  <Icon.Tonic className="mr-1 text-body" />
                  <span>{getTonicBalance()}</span>
                </Button>

                <Button
                  theme="yellow"
                  variant="outlined"
                  size="small"
                  className="mr-2 flex items-center"
                  target="_blank"
                  href={`https://vvs.finance/swap?disableCurrencySelect&inputCurrency=${TOKEN_LISTS_ADDRESS.USDC}&outputCurrency=${TOKEN_LISTS_ADDRESS.TONIC}`}
                >
                  Buy
                </Button>
              </div>

              <PopoverButton
                buttonProps={{
                  children:
                    !queryResult.isLoading && queryResult.data
                      ? formatCronosId(queryResult.data)
                      : `Cronos ${shortAddress}`,
                  size: 'small',
                  theme: 'info',
                  className: 'h-full',
                }}
              >
                <div className="w-[250px] border border-onSurface12">
                  <div className="mx-2 mt-2 mb-1 text-onSurface">
                    <Text variant="semiBold">Cronos Network</Text>
                  </div>
                  <div className="mx-2 mt-1 mb-2 text-onSurface">
                    <Text variant="semiBold" className="break-words">
                      {(!queryResult.isLoading && queryResult.data) ??
                        currentAccount}
                    </Text>
                  </div>
                </div>
                <div className="border border-onSurface12">
                  <button
                    onClick={() => disconnectWallet()}
                    className="h-full w-full px-2 pt-2 pb-2"
                  >
                    <div className="flex justify-between">
                      <Text variant="semiBold">Disconnect</Text>
                      <Icon.Logout className="h-[24px] w-[24px]" />
                    </div>
                  </button>
                </div>
              </PopoverButton>
            </div>
          ) : (
            <Button onClick={onShowConnectWalletModal} size="small">
              Connect Wallet
            </Button>
          )
        }
      </TransactionModalsContext.Consumer>
    </TransactionModalsProvider>
  );
}

export default WalletInfo;
