import { ReactNode } from 'react';

import DataRow from '@components/DataRow';
import Spinner from '@components/Spinner';

interface TransactionModalDataRowProps {
  label: ReactNode;
  labelWrapperClass?: string;
  loading: boolean;
  value?: ReactNode;
  tooltip?: string;
}

function TransactionModalDataRow({
  label,
  labelWrapperClass,
  loading,
  value,
  tooltip,
}: TransactionModalDataRowProps): JSX.Element {
  return (
    <DataRow
      className="mb-2"
      label={label}
      labelWrapperClass={labelWrapperClass}
      tooltip={tooltip}
      value={
        loading ? (
          <Spinner heightClassName="h-2.5" widthClassName="w-2.5" />
        ) : (
          value
        )
      }
    />
  );
}

export default TransactionModalDataRow;
