import { TectonicVaultStakeInfo } from '@tectonicfi/sdk/dist/types';
import { Icon } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import { formatXTonic } from '@lib/units';

interface DepositInfoRowProps {
  deposit: TectonicVaultStakeInfo;
  selected: boolean;
  remainingTime: string;
  onClick: () => void;
}

function DepositInfoRow({
  deposit,
  onClick,
  selected,
  remainingTime,
}: DepositInfoRowProps) {
  return (
    <div
      className={clsx(
        selected ? 'border-green text-green' : 'border-white text-white',
        'flex w-full cursor-pointer items-center justify-between rounded-md border-1 p-1.5 mobile:px-2 mobile:py-1'
      )}
      onClick={onClick}
    >
      <div className="flex grow flex-row justify-between text-[14px] leading-[21px] mobile:flex-col">
        <div>{formatXTonic(deposit.amount)} xTONIC</div>
        <div>{remainingTime}</div>
      </div>
      <div className="ml-2 flex shrink flex-row items-center">
        {!selected ? (
          <span className="m-[2px] h-[16px] w-[16px] rounded-[50%] border-1 border-white bg-transparent" />
        ) : (
          <Icon.Check height={20} width={20} />
        )}
      </div>
    </div>
  );
}

export default DepositInfoRow;
