import { useContext, useCallback, useMemo, useEffect, useReducer } from 'react';
import { BigNumber } from 'ethers';
import { useQuery } from 'react-query';

import { notify } from '@lib/bugsnag';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import useXtonicAprsData from '@components/AnalyticsPageView/XTonicAnalytics/useXtonicAprsData';

interface TonicStakingCardDataState {
  apy: Null<number>;
  countdownInSeconds: Null<number>;
  hasError: boolean;
  loading: boolean;
  lockedExchangeRate: Null<BigNumber>;
  releasableXTonicAmount: Null<BigNumber>;
  unstakedXTonicAmount: Null<BigNumber>;
  xTonicBalance: Null<BigNumber>;
  xTonicToTonicExchangeRate: Null<BigNumber>;
  stakingRewards: Null<BigNumber>;
}

const initState: TonicStakingCardDataState = {
  apy: null,
  countdownInSeconds: null,
  hasError: false,
  loading: false,
  lockedExchangeRate: null,
  releasableXTonicAmount: null,
  unstakedXTonicAmount: null,
  xTonicBalance: null,
  xTonicToTonicExchangeRate: null,
  stakingRewards: null,
};

const GET_FULFILLED = 'GET_FULFILLED';

const GET_PENDING = 'GET_PENDING';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  type: typeof GET_FULFILLED;
  apy: number;
  countdownInSeconds: number;
  lockedExchangeRate: BigNumber;
  releasableXTonicAmount: BigNumber;
  unstakedXTonicAmount: BigNumber;
  xTonicBalance: BigNumber;
  xTonicToTonicExchangeRate: BigNumber;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type TonicStakingCardDataAction =
  | GetFulfilledAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: TonicStakingCardDataState,
  action: TonicStakingCardDataAction
): TonicStakingCardDataState {
  switch (action.type) {
    case GET_PENDING:
      return { ...initState, loading: true };
    case GET_FULFILLED:
      return {
        ...state,
        apy: action.apy,
        countdownInSeconds: action.countdownInSeconds,
        loading: false,
        lockedExchangeRate: action.lockedExchangeRate,
        releasableXTonicAmount: action.releasableXTonicAmount,
        unstakedXTonicAmount: action.unstakedXTonicAmount,
        xTonicBalance: action.xTonicBalance,
        xTonicToTonicExchangeRate: action.xTonicToTonicExchangeRate,
      };
    case GET_REJECTED:
      return { ...state, loading: false, hasError: true };
    case RESET:
      return { ...initState };
    default:
      return { ...state };
  }
}

export interface UseTonicStakingCardDataResult
  extends TonicStakingCardDataState {
  stakingRewardsFetched: boolean;
  totalXTonicLockedInVaultsFetched: boolean;
  totalXTonicLockedInVaults: BigNumber | null;
  refetch(): Promise<void>;
}

interface Data {
  countdownInSeconds: number;
  lockedExchangeRate: BigNumber;
  releasableXTonicAmount: BigNumber;
  unstakedXTonicAmount: BigNumber;
  xTonicBalance: BigNumber;
  xTonicToTonicExchangeRate: BigNumber;
}

function useStakingExchangeRate() {
  const sdk = useTectonicSdk();
  const query = getQuery(QueryKey.TECTONIC_STAKING_EXCHANGE_RATE)(sdk);
  return useQuery(query.queryKey, query.queryFn, {
    refetchInterval: 10000,
    enabled: !!sdk,
  });
}

function useStakingRewards(walletAddress: string | null) {
  const sdk = useTectonicSdk();

  const accountQuery = getQuery(QueryKey.GRAPH_ACCOUNT)(walletAddress);
  const { data: account, isFetched: accountFetched } = useQuery(
    accountQuery.queryKey,
    accountQuery.queryFn,
    {
      refetchInterval: 10000,
      enabled: !!walletAddress,
    }
  );

  const stakeEventsQuery = getQuery(QueryKey.GRAPH_STAKE_EVENTS)(walletAddress);
  const { data: stakeEvents } = useQuery(
    stakeEventsQuery.queryKey,
    stakeEventsQuery.queryFn,
    {
      refetchInterval: 10000,
      enabled: !!walletAddress,
    }
  );
  const vaultDepositQuery = getQuery(QueryKey.TECTONIC_VAULT_DEPOSITS)(
    sdk,
    walletAddress ?? ''
  );
  const { data: vaultDeposits, isFetched: vaultDepositsFetched } = useQuery(
    vaultDepositQuery.queryKey,
    vaultDepositQuery.queryFn,
    {
      refetchInterval: 10000,
      enabled: !!walletAddress && !!sdk,
    }
  );
  const releaseEventsQuery = getQuery(QueryKey.GRAPH_RELEASE_EVENTS)(
    walletAddress
  );
  const { data: releaseEvents } = useQuery(
    releaseEventsQuery.queryKey,
    releaseEventsQuery.queryFn,
    {
      refetchInterval: 10000,
      enabled: !!walletAddress,
    }
  );

  const { data: exchangeRate } = useStakingExchangeRate();

  if (!releaseEvents || !accountFetched || !stakeEvents || !exchangeRate) {
    return { isFetched: false };
  }

  let rewardsAmount = BigNumber.from(0);
  for (let i = 0; i < stakeEvents.length; i++) {
    rewardsAmount = rewardsAmount.sub(
      BigNumber.from(stakeEvents[i].tonicStaked)
    );
  }
  for (let i = 0; i < releaseEvents.length; i++) {
    rewardsAmount = rewardsAmount.add(
      BigNumber.from(releaseEvents[i].tonicReleased)
    );
  }
  const totalXTonicLockedInVaults =
    vaultDeposits?.reduce((sum, d) => {
      return sum.add(d.amount);
    }, BigNumber.from(0)) || BigNumber.from(0);
  const totalTonicLockedInVaults = convertXTonicToTonic(
    totalXTonicLockedInVaults,
    exchangeRate
  );
  const currentXTonic = BigNumber.from(account?.stakedAmount || 0);
  const currentTonic = convertXTonicToTonic(currentXTonic, exchangeRate);
  rewardsAmount = rewardsAmount.add(currentTonic).add(totalTonicLockedInVaults);
  return {
    isFetched: vaultDepositsFetched,
    stakingRewards: rewardsAmount.abs(),
    totalXTonicLockedInVaults,
  };
}

function useTonicStakingCardData(
  walletAddress: string | null
): UseTonicStakingCardDataResult {
  const sdk = useTectonicSdk();
  const {
    stakingRewards,
    isFetched: stakingRewardsFetched,
    totalXTonicLockedInVaults,
  } = useStakingRewards(walletAddress);
  const [state, dispatch] = useReducer(reducer, initState);

  const { xtonicAprs: dDayXtonicAPR } = useXtonicAprsData('ONE_MONTH', 1);
  const apy = dDayXtonicAPR?.[0]?.apr;

  const getData = useCallback(
    async (address: string): Promise<null | Data> => {
      if (sdk && address) {
        const xTonicAddress = sdk.Staking.getXTonic().address;
        try {
          const [
            xTonicToTonicExchangeRate,
            unstakedXTonicAmount,
            releasableXTonicAmount,
            countdownInSeconds,
            xTonicBalance,
            lockedExchangeRate,
          ] = await Promise.all([
            sdk.Staking.getExchangeRate(),
            sdk.Staking.getUnstakedToken(address),
            sdk.Staking.getReleasableToken(address),
            sdk.Staking.getCountDownSeconds(address),
            sdk.ERC20.balanceOf(xTonicAddress, address),
            sdk.Staking.getLockedExchangeRate(address),
          ]);

          return {
            countdownInSeconds,
            lockedExchangeRate,
            releasableXTonicAmount,
            unstakedXTonicAmount,
            xTonicBalance,
            xTonicToTonicExchangeRate,
          };
        } catch (e) {
          console.error(e);
        }
      }

      return null;
    },
    [sdk]
  );

  useEffect(() => {
    let canceled = false;

    async function getCardData(address: string): Promise<void> {
      try {
        dispatch({ type: GET_PENDING });
        const result = await getData(address);
        if (!canceled && result && apy) {
          dispatch({ ...result, apy, type: GET_FULFILLED });
        }
      } catch (error) {
        notify(error);
        dispatch({ type: GET_REJECTED });
      }
    }

    if (walletAddress) {
      getCardData(walletAddress);
    } else {
      dispatch({ type: RESET });
    }

    return () => {
      canceled = true;
    };
  }, [getData, walletAddress, apy]);
  return useMemo(
    () => ({
      ...state,
      totalXTonicLockedInVaults: totalXTonicLockedInVaults ?? BigNumber.from(0),
      stakingRewards: stakingRewards ?? BigNumber.from(0),
      stakingRewardsFetched: stakingRewardsFetched,
      // uses same queries
      totalXTonicLockedInVaultsFetched: stakingRewardsFetched,
      refetch: async (): Promise<void> => {
        if (!state.loading && walletAddress) {
          dispatch({ type: GET_PENDING });

          try {
            const result = await getData(walletAddress);

            if (result && apy) {
              dispatch({ ...result, apy, type: GET_FULFILLED });
            } else {
              dispatch({ type: GET_REJECTED });
            }
          } catch (error) {
            notify(error);
            dispatch({ type: GET_REJECTED });
          }
        }
      },
    }),
    [
      state,
      getData,
      walletAddress,
      stakingRewards,
      stakingRewardsFetched,
      totalXTonicLockedInVaults,
      apy,
    ]
  );
}

function convertXTonicToTonic(
  xTonicAmount: BigNumber,
  xTonicToTonicExchangeRate: BigNumber
): BigNumber {
  return xTonicAmount
    .mul(xTonicToTonicExchangeRate)
    .div(BigNumber.from(10).pow(18));
}

export default useTonicStakingCardData;
