import { BigNumber } from 'ethers';
export type SortFunction<T> = (a: T, b: T) => number;
export const byLowToHigh =
  <T>(propSelector: (element: T) => number) =>
  (_a: T, _b: T) => {
    const [a, b] = [propSelector(_a), propSelector(_b)];
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
export const byHighToLow =
  <T>(propSelector: (element: T) => number) =>
  (_a: T, _b: T) => {
    const [a, b] = [propSelector(_a), propSelector(_b)];
    if (a < b) {
      return 1;
    }
    if (b < a) {
      return -1;
    }
    return 0;
  };
export const byLowToHighBN =
  <T>(propSelector: (element: T) => BigNumber) =>
  (_a: T, _b: T) => {
    const [a, b] = [propSelector(_a), propSelector(_b)];
    if (a.gt(b)) {
      return 1;
    }
    if (b.gt(a)) {
      return -1;
    }
    return 0;
  };
export const byHighToLowBN =
  <T>(propSelector: (element: T) => BigNumber) =>
  (_a: T, _b: T) => {
    const [a, b] = [propSelector(_a), propSelector(_b)];
    if (a.lt(b)) {
      return 1;
    }
    if (b.lt(a)) {
      return -1;
    }
    return 0;
  };
