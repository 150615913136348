import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { useWallet } from '@providers/WalletProvider';

import { getQuery } from './queries';

export function useVaultPendingTonic() {
  const { currentAccount } = useWallet();
  const sdk = useTectonicSdk();
  const query = getQuery(QueryKey.TECTONIC_VAULT_PENDING_TONIC)(
    sdk,
    currentAccount as string
  );

  return useQuery(query.queryKey, query.queryFn, {
    enabled: !!currentAccount && !!sdk,
    refetchInterval: 3000, // update ever 5.5 seconds
    cacheTime: 0, // NOTE: need fetch the latest pending tonic data
  });
}
