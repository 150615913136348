import { useQuery } from 'react-query';
import { BigNumber } from 'ethers';
import { TectonicSDK } from '@tectonicfi/sdk';

import { QueryKey } from '@config/queryKey';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { getQuery } from '@queries/queries';
import useWallets from '@hooks/useWallets';
import { NFT_BOOSTING_NFT_ADDRESS } from '@config/base';
import { useWallet } from '@providers/WalletProvider';

export function useUserStakedCollections() {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.TECTONIC_VAULT_GET_USER_STAKED_COLLECTIONS)(
    sdk,
    currentAccount ?? ''
  );

  return useQuery(query.queryKey, query.queryFn);
}

export function useStakedNFTTokenIds(poolId: number) {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.TECTONIC_VAULT_GET_STAKED_NFT_TOKEN_IDS)(
    sdk,
    currentAccount ?? '',
    poolId,
    NFT_BOOSTING_NFT_ADDRESS[0] // TODO: When multiple nft collections are supported to staking , need to optimize
  );

  return useQuery(query.queryKey, query.queryFn);
}

export function useGetUserNftBoostMultiplier(poolId: number) {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.TECTONIC_VAULT_GET_USER_NFT_BOOST_MULTIPLIER)(
    sdk,
    currentAccount ?? '',
    poolId
  );

  return useQuery(query.queryKey, query.queryFn);
}

export const useEstimatedNftBoostMultiplier = (
  sdk: TectonicSDK,
  stakeNftIds: BigNumber[],
  unstakeNftIds: BigNumber[],
  poolId?: number
) => {
  const { currentAccount } = useWallet();
  const getEstimatedUserNftBoostMultiplierQuery = getQuery(
    QueryKey.TECTONIC_VAULT_GET_ESTIMATED_USER_NFT_BOOST_MULTIPLIER
  )(sdk, currentAccount ?? '', poolId ?? 0, stakeNftIds, unstakeNftIds);
  return useQuery({
    ...getEstimatedUserNftBoostMultiplierQuery,
    enabled: poolId !== undefined && !!currentAccount,
  });
};

export const useMaxEstimatedNftBoostMultiplier = (tokenIds: BigNumber[]) => {
  const sdk = useTectonicSdk();
  const getMaxEstimatedUserNftBoostMultiplierQuery = getQuery(
    QueryKey.TECTONIC_VAULT_GET_MAX_ESTIMATED_USER_NFT_BOOST_MULTIPLIER
  )(sdk, tokenIds);
  return useQuery(getMaxEstimatedUserNftBoostMultiplierQuery);
};

export const useSingeNFTMultiplier = (tokenId: Undefined<string>) => {
  const sdk = useTectonicSdk();

  const getNFTmultiplier = getQuery(QueryKey.TECTONIC_VAULT_GET_NFT_MULTIPLIER)(
    sdk,
    BigNumber.from(tokenId ?? '-1')
  );

  return useQuery(getNFTmultiplier.queryKey, getNFTmultiplier.queryFn, {
    enabled: !!tokenId,
  });
};

export const useStakeNFTInfos = (tokenId: Undefined<string>) => {
  const getStakeNFTInfosQuery = getQuery(QueryKey.GRAPH_STAKE_NFT_INFOS)(
    tokenId
  );

  return useQuery(
    getStakeNFTInfosQuery.queryKey,
    getStakeNFTInfosQuery.queryFn,
    {
      enabled: !!tokenId,
    }
  );
};
