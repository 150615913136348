import hoistNonReactStatics from 'hoist-non-react-statics';
import { createElement, memo } from 'react';
type AnyObject = Record<string, unknown>;
// types are taken from @types/react-redux
export type Matching<InjectedProps, DecorationTargetProps> = {
  [P in keyof DecorationTargetProps]: P extends keyof InjectedProps
    ? InjectedProps[P] extends DecorationTargetProps[P]
      ? DecorationTargetProps[P]
      : InjectedProps[P]
    : DecorationTargetProps[P];
};
type GetProps<C> = C extends React.ComponentType<infer P> ? P : never;
/** @descriptions Run a hook and inject its return value into the component as props */
export const withHook =
  <THookProps = AnyObject, TOwnProps = AnyObject>(
    useHook: (props: TOwnProps) => THookProps
  ) =>
  <C extends React.ComponentType<Matching<THookProps, GetProps<C>>>>(
    Component: C
  ) => {
    const WithHookComponent = hoistNonReactStatics(
      // eslint-disable-next-line react/display-name
      memo((props: TOwnProps & Omit<GetProps<C>, keyof THookProps>) => {
        const hookProps = useHook(props);
        const allProps = {
          ...props,
          ...hookProps,
        } as GetProps<C>;
        return createElement(Component, allProps);
      }),
      Component
    );
    WithHookComponent.displayName = `withHook(${Component.displayName})`;
    return WithHookComponent;
  };
export type Hook<TOwnProps, THookProps> = (ownProps: TOwnProps) => THookProps;
export type Hooks1<TOwnProps, THookProps> = [Hook<TOwnProps, THookProps>];
export type Hooks2<TOwnProps, THookProps1, THookProps2> = [
  Hook<TOwnProps, THookProps1>,
  Hook<TOwnProps & THookProps1, THookProps2>
];
export type Hooks3<TOwnProps, THookProps1, THookProps2, THookProps3> = [
  Hook<TOwnProps, THookProps1>,
  Hook<TOwnProps & THookProps1, THookProps2>,
  Hook<TOwnProps & THookProps1 & THookProps2, THookProps3>
];
export type Hooks<
  TOwnProps,
  THookProps1,
  THookProps2 = AnyObject,
  THookProps3 = AnyObject
> =
  | Hooks1<TOwnProps, THookProps1>
  | Hooks2<TOwnProps, THookProps1, THookProps2>
  | Hooks3<TOwnProps, THookProps1, THookProps2, THookProps3>;
/** @descriptions Run multiple hooks and inject the output of the previous hook as arguments into the next hook together with the original props,
 * the original props will be merge with the output of all hooks in the order they were specific and applied to the given component */
export const withHooks =
  <
    TOwnProps = AnyObject,
    THookProps1 = AnyObject,
    THookProps2 = AnyObject,
    THookProps3 = AnyObject
  >(
    ...hooks: Hooks<TOwnProps, THookProps1, THookProps2, THookProps3>
  ) =>
  <
    C extends React.ComponentType<
      Matching<TOwnProps & THookProps1 & THookProps2 & THookProps3, GetProps<C>>
    >
  >(
    Component: C
  ) => {
    const WithHooksComponent = hoistNonReactStatics(
      // eslint-disable-next-line react/display-name
      memo(
        (
          props: TOwnProps &
            Omit<
              GetProps<C>,
              keyof THookProps1 | keyof THookProps2 | keyof THookProps3
            >
        ) => {
          let allProps = { ...props } as GetProps<C>;
          hooks.forEach((h) => {
            allProps = { ...allProps, ...h(allProps) };
          });
          return createElement(Component, allProps);
        }
      ),
      Component
    );
    WithHooksComponent.displayName = `withHooks(${Component.displayName})`;
    return WithHooksComponent;
  };
