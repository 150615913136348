import { ReactNode, memo, useContext } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Icon, NavBar, Text } from '@tectonicfi/tectonic-ui-kit';

import NetworkErrorCard from '@components/NetworkErrorCard';
import { WalletContext } from '@providers/WalletProvider';
import Features from '@components/Features';
import { MobileNavigationDrawerContext } from '@providers/MobileNavigationDrawerProvider';

import AppMobileDrawer from './AppMobileDrawer';
import NavBarTab from './NavBarTab';
import WalletInfo from './WalletInfo';
import DesktopFooter from './DesktopFooter';
import { PATHNAME, getNavBarLabel } from './utils';

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps): JSX.Element {
  const router = useRouter();
  const { showDrawer, setShowDrawer } = useContext(
    MobileNavigationDrawerContext
  );
  const { onSwitchNetwork, showNetworkError } = useContext(WalletContext);

  return (
    <div className="flex min-h-screen flex-col">
      <NavBar className="z-10 mb-3 h-[74px] justify-center mobile:fixed mobile:inset-x-0 mobile:mb-2">
        <div className="flex w-full max-w-desktopContainer flex-row">
          <div className="flex flex-1 flex-row items-center">
            <Link href={PATHNAME.home} passHref>
              <Icon.Tectonic className="h-3 w-[169px]" cursor="pointer" />
            </Link>
          </div>
          <div className="flex flex-1 flex-row items-center justify-center space-x-3 mobile:hidden">
            <NavBarTab
              href={PATHNAME.markets}
              label={getNavBarLabel(PATHNAME.markets)}
              selected={router.pathname.includes(PATHNAME.markets)}
            />
            <NavBarTab
              href={PATHNAME.dashboard}
              label={getNavBarLabel(PATHNAME.dashboard)}
              selected={
                router.pathname === PATHNAME.dashboard ||
                router.pathname === PATHNAME.dashboardVENO ||
                router.pathname === PATHNAME.dashboardDEFI
              }
            />
            <Features.Earn>
              <NavBarTab
                href={PATHNAME.tonic}
                label="Earn"
                selected={getNavBarLabel(router.pathname) === 'Earn'}
              />
            </Features.Earn>
            <Features.Analytics>
              <NavBarTab
                href={PATHNAME.analytics}
                label={getNavBarLabel(PATHNAME.analytics)}
                selected={router.pathname === PATHNAME.analytics}
              />
            </Features.Analytics>
          </div>
          <div className="flex flex-1 items-center justify-end mobile:hidden">
            <WalletInfo />
          </div>
          <div className="flex flex-1 flex-row items-center justify-end gap-1 desktop:hidden tablet:hidden">
            <Text>{getNavBarLabel(router.asPath)}</Text>
            <button onClick={(): void => setShowDrawer((v) => !v)}>
              <Icon.Menu className="h-3 w-3" />
            </button>
          </div>
        </div>
      </NavBar>

      <AppMobileDrawer
        onClose={(): void => setShowDrawer(false)}
        open={showDrawer}
      />
      <div className="desktop:hidden tablet:hidden mobile:h-[74px]" />
      {showNetworkError && (
        <NetworkErrorCard
          className="mt-2 max-w-desktopContainer"
          onSwitchNetwork={onSwitchNetwork}
        />
      )}
      <div className="flex-1">{children}</div>
      <span className="mobile:hidden">
        <DesktopFooter />
      </span>
    </div>
  );
}

export default memo(AppLayout);
