import { TectonicVaultStakeInfo } from '@tectonicfi/sdk/dist/types';
import { useMemo } from 'react';

import { sortDeposits, SortValue } from '../DepositDropdown';

export default function useSortedDeposits(
  deposits: TectonicVaultStakeInfo[],
  sortBy: SortValue
) {
  return useMemo(() => {
    const now = new Date().getTime();
    const depositsList = sortDeposits(deposits, sortBy);

    const endDates = depositsList.map((v) => v.lockEndDate);
    const unlockedVaultExists = !!depositsList.find(
      (v) => v.lockEndDate.getTime() < now
    );
    return {
      sortedDeposits: depositsList,
      lockEndTimes: endDates,
      hasUnlockedVault: unlockedVaultExists,
    };
  }, [deposits, sortBy]);
}
