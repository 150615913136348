import { useEffect, useReducer } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import useAppDispatch from '@hooks/useAppDispatch';
import useTectonicAssetPricer from '@hooks/useTectonicAssetPricer';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { getAssetUsdPrice } from '@redux/usdPrices';
import { PoolType } from '@config/base';
import { TOKEN_INTEREST_INFOS } from '@config/TokenInterestInfo';

interface MarketDetailsCardDataState {
  borrowCap: BigNumber | null;
  borrowCapLoading: boolean;
  collateralFactor: number | null;
  exchangeRate: BigNumber | null;
  exchangeRateLoading: boolean;
  priceLoading: boolean;
  reserveFactor: number | null;
  reserves: BigNumber | null;
  reservesLoading: boolean;
  supplyRatePerBlock: BigNumber | null;
  supplyRatePerBlockLoading: boolean;
  tTokenMinted: BigNumber | null;
  tTokenMintedLoading: boolean;
  liquidationPenalty: number | null;
  supplyCap: number | null;
}

const initState: MarketDetailsCardDataState = {
  priceLoading: true,
  borrowCap: null,
  borrowCapLoading: true,
  collateralFactor: null,
  exchangeRate: null,
  exchangeRateLoading: true,
  reserveFactor: null,
  reserves: null,
  reservesLoading: true,
  supplyRatePerBlock: null,
  supplyRatePerBlockLoading: true,
  tTokenMinted: null,
  tTokenMintedLoading: true,
  liquidationPenalty: null,
  supplyCap: null,
};

const GET = 'GET';

const GET_FULFILLED = 'GET_FULFILLED';

const GET_REJECTED = 'GET_REJECTED';

const GET_PRICE_FULFILLED = 'GET_PRICE_FULFILLED';

const GET_PRICE_REJECTED = 'GET_PRICE_REJECTED';

type MarketDetailsCardDataField = keyof Pick<
  MarketDetailsCardDataState,
  | 'borrowCap'
  | 'collateralFactor'
  | 'exchangeRate'
  | 'reserveFactor'
  | 'liquidationPenalty'
  | 'reserves'
  | 'supplyRatePerBlock'
  | 'tTokenMinted'
  | 'supplyCap'
>;

interface GetAction {
  field: MarketDetailsCardDataField;
  value: MarketDetailsCardDataState[MarketDetailsCardDataField];
  type: typeof GET;
}

interface GetFulfilledAction {
  field: MarketDetailsCardDataField;
  type: typeof GET_FULFILLED;
  value: MarketDetailsCardDataState[MarketDetailsCardDataField];
}

interface GetPriceFulfilledAction {
  type: typeof GET_PRICE_FULFILLED;
}

interface GetPriceRejectedAction {
  type: typeof GET_PRICE_REJECTED;
}

interface GetRejectedAction {
  field: MarketDetailsCardDataField;
  type: typeof GET_REJECTED;
}

type MarketDetailsCardDataAction =
  | GetAction
  | GetFulfilledAction
  | GetRejectedAction
  | GetPriceFulfilledAction
  | GetPriceRejectedAction;

function reducer(
  state: MarketDetailsCardDataState,
  action: MarketDetailsCardDataAction
): MarketDetailsCardDataState {
  switch (action.type) {
    case GET_PRICE_FULFILLED:
    case GET_PRICE_REJECTED:
      return {
        ...state,
        priceLoading: false,
      };
    case GET:
      return {
        ...state,
        [action.field]: action.value,
      };
    case GET_FULFILLED:
      return {
        ...state,
        [action.field]: action.value,
        [`${action.field}Loading`]: false,
      };
    case GET_REJECTED:
      return { ...state, [`${action.field}Loading`]: false };
    default:
      return { ...state };
  }
}

// * use mock info, see https://cronoslabs.atlassian.net/browse/TEC-332
const useMarketDetailsCardDataWithTokenInterestInfo = (
  asset: Null<TectonicAsset>,
  mode: PoolType
): MarketDetailsCardDataState | null => {
  const info = TOKEN_INTEREST_INFOS[mode][asset?.symbol.toUpperCase() ?? ''];
  const appDispatch = useAppDispatch();

  const sdk = useTectonicSdk(mode);
  const tectonicAssetPricer = useTectonicAssetPricer(sdk);
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    function dispatchFulfilled(
      field: MarketDetailsCardDataField,
      value: MarketDetailsCardDataState[MarketDetailsCardDataField]
    ): void {
      dispatch({ field, type: GET_FULFILLED, value });
    }

    function dispatchRejected(field: MarketDetailsCardDataField): void {
      dispatch({ field, type: GET_REJECTED });
    }

    async function getPrice(): Promise<void> {
      if (sdk && asset) {
        try {
          await appDispatch(getAssetUsdPrice(tectonicAssetPricer, asset));
          dispatch({ type: GET_PRICE_FULFILLED });
        } catch (error) {
          dispatch({ type: GET_PRICE_REJECTED });
        }
      }
    }

    async function getBorrowCap(): Promise<void> {
      if (sdk && asset) {
        try {
          const value = await sdk.Borrow.borrowCap(asset.tTokenAddress);

          dispatchFulfilled('borrowCap', value);
        } catch (error) {
          dispatchRejected('borrowCap');
        }
      }
    }

    async function getReserves(): Promise<void> {
      if (sdk && asset) {
        try {
          const value = await sdk.Supply.reserves(asset.tTokenAddress);
          dispatchFulfilled('reserves', value);
        } catch (error) {
          dispatchRejected('reserves');
        }
      }
    }

    function getReserveFactor(): void {
      dispatch({
        type: GET,
        field: 'reserveFactor',
        value: info?.reserveFactor * 100,
      });
    }

    function getCollateralFactor(): void {
      dispatch({
        type: GET,
        field: 'collateralFactor',
        value: info?.collateralFactor * 100,
      });
    }

    function getLiquidationPenalty(): void {
      dispatch({
        type: GET,
        field: 'liquidationPenalty',
        value: info?.liquidationPenalty * 100,
      });
    }

    async function getTTokenMinted(): Promise<void> {
      if (sdk && asset) {
        try {
          const value = await sdk.Supply.totalSuppliedAmountInMarket(
            asset.tTokenAddress
          );
          dispatchFulfilled('tTokenMinted', value);
        } catch (error) {
          dispatchRejected('tTokenMinted');
        }
      }
    }

    async function getExchangeRate(): Promise<void> {
      if (sdk && asset) {
        try {
          const value = await sdk.Supply.exchangeRate(asset.tTokenAddress);
          dispatchFulfilled('exchangeRate', value);
        } catch (error) {
          dispatchRejected('exchangeRate');
        }
      }
    }

    async function getSupplyRatePerBlock(): Promise<void> {
      if (sdk && asset) {
        try {
          const value = await sdk.Supply.supplyRatePerBlock(
            asset.tTokenAddress
          );
          dispatchFulfilled('supplyRatePerBlock', value);
        } catch (error) {
          dispatchRejected('supplyRatePerBlock');
        }
      }
    }

    function getSupplyCap(): void {
      dispatch({
        type: GET,
        field: 'supplyCap',
        value: info?.supplyCap,
      });
    }

    getPrice();
    getBorrowCap();
    getReserves();
    getReserveFactor();
    getCollateralFactor();
    getLiquidationPenalty();
    getTTokenMinted();
    getExchangeRate();
    getSupplyRatePerBlock();
    getSupplyCap();
  }, [appDispatch, asset, sdk, info, tectonicAssetPricer]);

  if (!info) return null;

  return state;
};

export default useMarketDetailsCardDataWithTokenInterestInfo;
