import { combineReducers } from '@reduxjs/toolkit';

import mainMarkets from './markets';
import venoMarkets from './venoMarkets';
import defiMarkets from './defiMarkets';
import usdPrices from './usdPrices';

const rootReducer = combineReducers({
  mainMarkets,
  usdPrices,
  defiMarkets,
  venoMarkets,
});

export type RootReducerState = ReturnType<typeof rootReducer>;

export default rootReducer;
