import { useQueries } from 'react-query';
import { PartnerToken } from '@tectonicfi/sdk/dist/types';
import { utils } from 'ethers';

import { TokenCurrency } from '@hooks/useVVSSwapTrade';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { PoolType } from '@config/base';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { ChainId } from '@config/baseNetworkConfig';

const USDT: TokenCurrency = {
  address: '0x66e428c3f67a68878562e79A0234c1F83c208770',
  decimals: 6,
  chainId: ChainId.Mainnet,
  symbol: 'USDT',
};

const ARGO = {
  address: '0x47A9D630dc5b28F75d3AF3be3AAa982512Cd89Aa',
  decimals: 18,
  chainId: ChainId.Mainnet,
  symbol: 'ARGO',
};

const GOLD = {
  address: '0x62437579887178bea81a182db627427bb8e71766',
  decimals: 18,
  chainId: ChainId.Mainnet,
  symbol: 'GOLD',
};

const BLACK_PARTNER_TOKEN_LIST = ['XARGO', 'STARDUST'];

export default function usePartnerTokenUsdPrices(
  poolType: PoolType,
  partnerTokenList: Undefined<PartnerToken[]>
) {
  const sdk = useTectonicSdk(poolType);

  const outputCurrency =
    partnerTokenList?.map((partnerToken) => {
      // NOTE: XARGO: ARGO = 1:1, so use ARGO price instead.
      // NOTE: STARDUST: GOLD = 1:1, so use GOLD price instead.
      switch (true) {
        case partnerToken.symbol.toUpperCase() === 'XARGO':
          return ARGO;
        case partnerToken.symbol.toUpperCase() === 'STARDUST':
          return GOLD;

        default:
          return {
            address: partnerToken.address,
            decimals: partnerToken.decimals,
            symbol: partnerToken.symbol,
            chainId: ChainId.Mainnet, //TODO: when add other reward token to update
          };
      }
    }) ?? [];

  const vvsData = usePriceFromVVS(
    '1',
    USDT,
    outputCurrency,
    0,
    partnerTokenList ?? []
  );

  const partnerTokenPriceQueries = (partnerTokenList ?? []).map((e) => ({
    ...getQuery(QueryKey.TECTONIC_PARTNER_TOKEN_PRICE_IN_USD)(sdk, e.address),
    enabled:
      (poolType === 'MAIN' || poolType === 'VENO') &&
      !BLACK_PARTNER_TOKEN_LIST.includes(e.symbol.toUpperCase()),
  }));

  const oraclePriceData = useQueries(partnerTokenPriceQueries);

  return poolType === 'DEFI'
    ? vvsData.map((arr, index) => ({
        data: utils.parseUnits(
          arr?.data ?? '0',
          outputCurrency?.[index].decimals ?? 18
        ),
      }))
    : oraclePriceData;
}

export const usePriceFromVVS = (
  amount: string,
  inputCurrency: TokenCurrency,
  outputCurrency: TokenCurrency[],
  slippageTolerance: number,
  partnerTokenList: PartnerToken[]
) => {
  const queries = partnerTokenList.map((e, index) => ({
    ...getQuery(QueryKey.VVS_SWAP_TRADE_DATA)(
      amount,
      inputCurrency,
      slippageTolerance,
      outputCurrency[index]
    ),
    enabled: !!e.address,
  }));

  return useQueries(queries).map((data) => ({
    data: data.data?.price,
  }));
};
