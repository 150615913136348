import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { Icon, RangSlider, Text } from '@tectonicfi/tectonic-ui-kit';

import CollapsibleShortSteps from '@components/CollapsibleShortSteps/CollapsibleShortSteps';

interface selectBorrowLevelProps {
  selectStep: {
    selectedLongAsset: boolean;
    selectedBorrowLevel: boolean;
    selectedDexes: boolean;
  };
  setSelectStep: Dispatch<
    SetStateAction<{
      selectedLongAsset: boolean;
      selectedBorrowLevel: boolean;
      selectedDexes: boolean;
    }>
  >;
  borrowLevel: number;
  setBorrowLevel: Dispatch<SetStateAction<number>>;
  shortTokenAmount: string;
}
const SelectBorrowLevel = ({
  selectStep,
  setSelectStep,
  borrowLevel,
  setBorrowLevel,
  shortTokenAmount,
}: selectBorrowLevelProps) => {
  return (
    <CollapsibleShortSteps
      step={'Step 2'}
      isSelected={selectStep.selectedBorrowLevel}
      leftLabel={'Choose your desired borrowing level'}
      onStepSelected={() =>
        setSelectStep({
          selectedLongAsset: false,
          selectedBorrowLevel: !selectStep.selectedBorrowLevel,
          selectedDexes: false,
        })
      }
      chooseValue={
        <div className="mt-5 pb-3">
          <RangSlider
            min={0}
            max={90}
            value={borrowLevel}
            onChange={(value) => setBorrowLevel(value)}
            labelOffset={7}
            hideMaxButton={true}
            className={clsx(
              borrowLevel >= 90
                ? '!bg-gradient-to-r from-[red] to-[red]'
                : borrowLevel >= 50
                ? '!bg-gradient-to-r from-[#FF7C00] to-[#FF7C00]'
                : ''
            )}
            labelColor={
              borrowLevel >= 90
                ? '#FFA1A1'
                : borrowLevel >= 50
                ? '#FF7C00'
                : '#F4F499'
            }
          />
          {borrowLevel >= 90 ? (
            <div className="mt-2 flex ">
              <Icon.Warn width={30} height={30} />
              <div className="text-vaultChart24Month">
                Please note that the amount you wish to borrow will bring your
                account close to liquidation. This means your account may be
                liquidated more easily if the market drops. In general, it is
                recommended to keep your account borrowing level to below 50%.
              </div>
            </div>
          ) : borrowLevel >= 50 ? (
            <div className="mt-2 flex ">
              <Icon.Warn width={30} height={30} />
              <div className="text-vaultChart24Month">
                It is recommended to keep your account borrowing level to below
                50%
              </div>
            </div>
          ) : (
            ''
          )}
          {Number(shortTokenAmount) < 0 && (
            <div className="mt-2 text-red">
              Increase borrowing level to enable this transaction, but do note
              higher leverage increases your risk.
            </div>
          )}
        </div>
      }
      label={
        borrowLevel && (
          <Text className="py-2 text-yellowPrimary">{borrowLevel + '%'}</Text>
        )
      }
    />
  );
};

export default SelectBorrowLevel;
