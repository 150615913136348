import { BigNumber } from 'ethers';
import { Text } from '@tectonicfi/tectonic-ui-kit';

import { formatXTonicToTonicExchangeRate } from '@lib/units';

interface XTonicToTonicExchangeRateTagProps {
  tonicEquivalent: BigNumber;
}

function XTonicToTonicExchangeRateTag({
  tonicEquivalent,
}: XTonicToTonicExchangeRateTagProps): JSX.Element {
  const formatted = formatXTonicToTonicExchangeRate(tonicEquivalent);

  return (
    <div className="inline-block rounded-[8px] bg-gradient-to-r from-rainbowYellow via-rainbowBlue via-rainbowPink to-rainbowPinkSecondary p-[1px]">
      <div className="inline-block items-center justify-center rounded-[8px] bg-testBlue py-0.5 px-1.5">
        <Text>{`1 xTONIC = ${formatted} TONIC`}</Text>
      </div>
    </div>
  );
}

export default XTonicToTonicExchangeRateTag;
