import { ethers } from 'ethers';
import { ReactNode, createContext, memo, useCallback } from 'react';

import { getNetworkConfig } from '@config/baseNetworkConfig';

export interface EnsProviderContextValue {
  lookupAddress: (address: string) => Promise<string | null>;
}

export const formatCronosId = (cronosId: string) => {
  const maxLength = 25;
  if (cronosId.length <= maxLength) {
    return cronosId;
  }
  return cronosId.substring(0, 21) + '…' + '.cro';
};

interface EnsProviderProps {
  children: ReactNode;
}

const config = getNetworkConfig();
export const jsonRpc = config.rpcUrls.length
  ? new ethers.providers.JsonRpcProvider(config.rpcUrls[0], {
      chainId: config.chainId,
      name: config.chainName,
      ensAddress: config.ensAddress,
    })
  : null;

function EnsProvider({ children }: EnsProviderProps) {
  const lookupAddress = useCallback(async (address: string) => {
    const domain = !jsonRpc ? null : await jsonRpc.lookupAddress(address);
    return domain;
  }, []);

  return (
    <EnsProviderContext.Provider value={{ lookupAddress }}>
      {children}
    </EnsProviderContext.Provider>
  );
}

export const EnsProviderContext = createContext({} as EnsProviderContextValue);

export default memo(EnsProvider);
