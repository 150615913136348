import { TectonicVaultStakeInfo } from '@tectonicfi/sdk/dist/types';

import {
  byHighToLow,
  byHighToLowBN,
  byLowToHigh,
  byLowToHighBN,
  SortFunction,
} from '@lib/sort';

import { SortValue } from './constants';

const sortMapping: Record<SortValue, SortFunction<TectonicVaultStakeInfo>> = {
  'ending-soon': byLowToHigh((d) => d.lockEndDate.getTime()),
  'recently-locked': byHighToLow((d) => d.lockEndDate.getTime()),
  'amount-asc': byLowToHighBN((d) => d.amount),
  'amount-desc': byHighToLowBN((d) => d.amount),
};

export default function sortDeposits(
  deposits: TectonicVaultStakeInfo[],
  sortBy: SortValue
) {
  return deposits.sort(sortMapping[sortBy]);
}
