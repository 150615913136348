import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber, utils } from 'ethers';
import { addSeconds } from 'date-fns';

import {
  NORMALIZED_USD_PRICE_DECIMALS,
  TONIC_SUPPLY_AMOUNT_DECIMALS,
} from '@config/constants';
import { getNormalizedUsdPrice, getUnderlyingUsdValue } from '@lib/math';
import { parseInputAmountToBN } from '@lib/units';

export function isAmountZeroEquivalent(amount: string): boolean {
  return amount.replace(/0/g, '').replace('.', '') === '';
}

export function isNativeToken(asset: TectonicAsset): boolean {
  return asset.type === 'ether';
}

export function isLiquidated(
  borrowBalanceNormalizedUsd: BigNumber,
  borrowLimitNormalizedUsd: BigNumber
): boolean {
  if (borrowBalanceNormalizedUsd.isZero()) {
    return false;
  }

  return borrowBalanceNormalizedUsd.gte(borrowLimitNormalizedUsd);
}

export function isAmountLessThanAllowance(
  allowance: BigNumber,
  amount: string,
  asset: TectonicAsset | null
): boolean {
  if (!amount) return true; // if amount value is undefined

  if (amount && asset) {
    const inputAmount = parseInputAmountToBN(amount, asset.decimals);
    return inputAmount.lte(allowance);
  }
  return false;
}

// Expects path with leading slash
export function makeRelativeUrl(path: string): string {
  const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH || '';
  return `${BASE_PATH}${path}`;
}

// Expects path with leading string
export function makeAbsoluteUrl(path: string): string {
  const BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL || '';

  return `${BASE_URL}${makeRelativeUrl(path)}`;
}

interface OnlySuppliedAssetParams {
  asset: TectonicAsset;
  assetUsdPrice: BigNumber;
  borrowAmount: BigNumber;
  supplyAmount: BigNumber;
}

export function onlySuppliedAsset({
  asset,
  assetUsdPrice,
  borrowAmount,
  supplyAmount,
}: OnlySuppliedAssetParams): boolean {
  return (
    supplyAmount.gt(BigNumber.from(0)) &&
    // Only consider the user as having supplied if their supply amount is greater than or equal to $0.01 in value
    // This is so that we don't block the user from borrowing when they only have dust amounts supplied.
    getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, supplyAmount, assetUsdPrice)
    ).gte(utils.parseUnits('0.01', NORMALIZED_USD_PRICE_DECIMALS)) &&
    borrowAmount.isZero()
  );
}

interface OnlyBorrowedAssetParams {
  asset: TectonicAsset;
  assetUsdPrice: BigNumber;
  borrowAmount: BigNumber;
  supplyAmount: BigNumber;
}

export function onlyBorrowedAsset({
  asset,
  assetUsdPrice,
  borrowAmount,
  supplyAmount,
}: OnlyBorrowedAssetParams): boolean {
  return (
    borrowAmount.gt(BigNumber.from(0)) &&
    // Only consider the user as having borrowed if their borrow amount is greater than or equal to $0.01 in value.
    // This is so that we don't block the user from supplying when they only have dust amounts borrowed.
    getNormalizedUsdPrice(
      asset,
      getUnderlyingUsdValue(asset, borrowAmount, assetUsdPrice)
    ).gte(utils.parseUnits('0.01', NORMALIZED_USD_PRICE_DECIMALS)) &&
    supplyAmount.isZero()
  );
}

// TODO - Strengthen and make this bullet proof
// derivedUSD fractional decimals can be longer than 18 decimals, so we need to truncate
export function getTruncatedDerivedUsd(derivedUsd: string): string {
  if (derivedUsd.indexOf('.') === -1) {
    return derivedUsd;
  }

  const [left, right] = derivedUsd.split('.');
  return `${left}.${right.slice(0, 18)}`;
}

export function parseCirculatingSupplyToBigNumber(
  rawCirculatingSupply: string
): BigNumber {
  return utils.parseUnits(rawCirculatingSupply, TONIC_SUPPLY_AMOUNT_DECIMALS);
}

export function restrictInput(value: string, precision: number): string {
  let input = value;

  // restriction 1: allow only digits and decimal character
  input = input.replace(/[^0-9.]/g, '');

  if (input === '') {
    return '';
  }

  if (input.split('.').length > 2) {
    const parts = input.split('.');
    input = `${parts.shift()}.${parts.join('')}`;
  }

  // case when user enter .1 it should be 0.1
  if (input[0] === '.') {
    input = 0 + input;
  }

  // restriction 2: user cannot enter more decimal places than set precision rule
  if (input.indexOf('.') > -1) {
    const integer = input.split('.')[0];
    let decimal = input.split('.')[1];

    if (decimal.length > precision) {
      decimal = decimal.substring(0, precision);
      input = integer + '.' + decimal;
    }
  }

  return input;
}

export function countDownSecondsToDate(remainingSeconds: number): Date {
  return addSeconds(new Date(), remainingSeconds);
}

export const getApy = (apr: number, nPeriods = 365): number => {
  return Math.pow(1 + apr / nPeriods, nPeriods) - 1;
};
