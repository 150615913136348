import { useReducer, useCallback } from 'react';
import { TectonicTransaction } from '@tectonicfi/sdk/dist/types';

import { notify } from '@lib/bugsnag';
import { TxStatus } from '@types';
import { TransactionModalsState } from '@providers/TransactionModalsProvider/useTransactionModalsState';
import { getTxErrorReadableMessage, ErrorMessage } from '@lib/txErrors';

export type TxState =
  | { status: TxStatus.awaiting_confirmation }
  | { status: TxStatus.confirmed; txHash: string }
  | { status: TxStatus.failed; txHash: string | null; errorMessage: string }
  | { status: TxStatus.pending; txHash: string };

interface UseGetTxStatusState {
  txState: TxState | null;
}

const initState: UseGetTxStatusState = {
  txState: null,
};

const UPDATE = 'UPDATE';

const RESET = 'RESET';

interface ResetAction {
  type: typeof RESET;
}

interface UpdateAction {
  type: typeof UPDATE;
  value: TxState;
}

type UseGetTxStatusAction = ResetAction | UpdateAction;

interface UseGetTxStatusStateResult {
  getTxStatus(
    method: Promise<TectonicTransaction>,
    onTxSuccess?: () => void
  ): Promise<string | undefined>;
  resetTxStatus(): void;
  txState: UseGetTxStatusState['txState'];
}

function useGetTxStatus({
  activeModal,
}: TransactionModalsState): UseGetTxStatusStateResult {
  function reducer(
    state: UseGetTxStatusState,
    action: UseGetTxStatusAction
  ): UseGetTxStatusState {
    switch (action.type) {
      case RESET:
        return { ...initState };
      case UPDATE:
        if (activeModal) {
          return { ...state, txState: action.value };
        }

        return { ...state };
      default:
        return { ...state };
    }
  }

  const [{ txState }, dispatch] = useReducer(reducer, initState);

  const getTxStatus = useCallback(
    async (method: Promise<TectonicTransaction>, onTxSuccess?: () => void) => {
      try {
        dispatch({
          type: UPDATE,
          value: { status: TxStatus.awaiting_confirmation },
        });
        const { txHash, txReceiptPromise } = await method;
        dispatch({
          type: UPDATE,
          value: { status: TxStatus.pending, txHash },
        });
        const receipt = await txReceiptPromise;
        const { status } = receipt;

        if (status !== 0) {
          dispatch({
            type: UPDATE,
            value: { status: TxStatus.confirmed, txHash },
          });

          if (onTxSuccess) {
            onTxSuccess();
          }
        } else {
          // TODO - Handle error message
          console.error(receipt);
          dispatch({
            type: UPDATE,
            value: {
              errorMessage: 'Something went wrong...',
              status: TxStatus.failed,
              txHash,
            },
          });
        }

        return txHash;
      } catch (error) {
        dispatch({
          type: UPDATE,
          value: {
            errorMessage: getTxErrorReadableMessage(error as ErrorMessage),
            status: TxStatus.failed,
            txHash: null,
          },
        });

        notify(error);
      }
    },
    []
  );

  return {
    getTxStatus,
    resetTxStatus: (): void => dispatch({ type: RESET }),
    txState,
  };
}

export default useGetTxStatus;
