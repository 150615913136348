import { useCallback, useEffect, useReducer } from 'react';

import { jsonRpc } from '@providers/EnsProvider';

// Upgrade block height is 13184000
// We want to show the warning between min and max block height (-100 blocks and +100 blocks)
const MIN_BLOCK_HEIGHT = parseInt(
  process.env.NEXT_PUBLIC_MIN_BLOCK_HEIGHT_MAINTENANCE ?? '0'
);

const MAX_BLOCK_HEIGHT = parseInt(
  process.env.NEXT_PUBLIC_MAX_BLOCK_HEIGHT_MAINTENANCE ?? '0'
);

// Check upgrade status every 15 seconds
const INTERVAL_MS = 1000 * 15;

interface UseShowCronosUpgradeWarningState {
  loading: boolean;
  showWarning: boolean;
}

const initState: UseShowCronosUpgradeWarningState = {
  loading: true,
  showWarning: false,
};

const CHECK_FULFILLED = 'CHECK_FULFILLED';

const CHECK_REJECTED = 'CHECK_REJECTED';

interface CheckFulfilledAction {
  showWarning: boolean;
  type: typeof CHECK_FULFILLED;
}

interface CheckRejectedAction {
  type: typeof CHECK_REJECTED;
}

type UseShowCronosUpgradeWarningAction =
  | CheckFulfilledAction
  | CheckRejectedAction;

function reducer(
  state: UseShowCronosUpgradeWarningState,
  action: UseShowCronosUpgradeWarningAction
): UseShowCronosUpgradeWarningState {
  switch (action.type) {
    case CHECK_FULFILLED:
      return { ...state, loading: false, showWarning: action.showWarning };
    case CHECK_REJECTED:
      return { ...state, loading: false, showWarning: true };
    default:
      return { ...state };
  }
}

export type UseShowCronosUpgradeWarningResult =
  UseShowCronosUpgradeWarningState;

function shouldShowWarning(lastBlockHeight: number): boolean {
  if (
    lastBlockHeight > MIN_BLOCK_HEIGHT &&
    lastBlockHeight < MAX_BLOCK_HEIGHT
  ) {
    return true;
  }
  return false;
}

function useShowCronosUpgradeWarning(): UseShowCronosUpgradeWarningResult {
  const [state, dispatch] = useReducer(reducer, initState);

  const checkCurrentBlockHeight = useCallback(async (): Promise<void> => {
    try {
      const data = await jsonRpc?.getBlockNumber();

      if (data) {
        const showWarning = shouldShowWarning(data);
        dispatch({ type: CHECK_FULFILLED, showWarning });
        return;
      }

      dispatch({ type: CHECK_FULFILLED, showWarning: false });
    } catch (error) {
      dispatch({ type: CHECK_REJECTED });
    }
  }, []);

  useEffect(() => {
    checkCurrentBlockHeight();
  }, [checkCurrentBlockHeight]);

  useEffect(() => {
    let intervalRef: number | null = null;

    if (state.showWarning) {
      intervalRef = window.setInterval(() => {
        checkCurrentBlockHeight();
      }, INTERVAL_MS);
    }

    return (): void => {
      if (intervalRef) {
        window.clearInterval(intervalRef);
      }
    };
  }, [state.showWarning, checkCurrentBlockHeight]);

  return state;
}

export default useShowCronosUpgradeWarning;
