import { ReactElement, ReactNode } from 'react';

import useIsFeatureEnabled from '@hooks/useIsFeatureEnabled';
import { AppEnvironment } from '@types';

export interface FeatureProps {
  children?: ReactNode | ((isFeatureEnabled: boolean) => ReactNode | null);
  disabledEnvironments: AppEnvironment[];
}

function Feature({
  children,
  disabledEnvironments,
}: FeatureProps): ReactElement | null {
  const isFeatureEnabled = useIsFeatureEnabled();
  if (typeof children === 'function') {
    return children(isFeatureEnabled({ disabledEnvironments }));
  }

  if (!isFeatureEnabled({ disabledEnvironments })) {
    return null;
  }

  return <>{children || null}</>;
}

export default Feature;
