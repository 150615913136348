import { useEffect, useState } from 'react';

export type NFTRarity = 'Legendary' | 'Epic' | 'Rare' | 'Common';
export type NFT = {
  id: string;
  rank: number;
  name: string;
  image: string;
  rarity: NFTRarity;
};

type UseNFTMetaResult = {
  loading: boolean;
  nfts: NFT[];
};
const useNFTMeta = (uris?: string[], skip?: boolean): UseNFTMetaResult => {
  const [loading, setLoading] = useState(false);
  const [nfts, setNfts] = useState<NFT[]>([]);
  useEffect(() => {
    if (!skip && !!uris?.length) {
      setLoading(true);
      Promise.all(
        uris.map((uri) => fetch(uri).then((response) => response.json()))
      )
        .then((values) => {
          setNfts(values);
        })
        .catch((err) => {
          setNfts([]);
          console.error(err);
        })
        .finally(() => setLoading(false));
    } else {
      setNfts([]);
    }
  }, [skip, uris]);

  return {
    loading,
    nfts,
  };
};

export default useNFTMeta;
