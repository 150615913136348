import { TectonicAsset } from '@tectonicfi/sdk';
import { BigNumber } from 'ethers';
import get from 'lodash/get';

import { useMarketDetailsCardDataWithTokenInterestInfo } from '@components/MarketDetailsPageView/useMarketDetailsCardData';
import { parseInputAmountToBN } from '@lib/units';
import { PoolType } from '@config/base';

import useAppSelector from './useAppSelector';

const useDisableBorrow = (
  poolType: PoolType,
  asset: Null<TectonicAsset>,
  increment?: string
): boolean => {
  const { borrowAmounts } = useAppSelector((state) => ({
    borrowAmounts: get(state, `${poolType}Markets.borrowAmounts`, {}),
  }));

  const state = useMarketDetailsCardDataWithTokenInterestInfo(asset, poolType);

  if (!asset || state?.borrowCapLoading) return false;

  const assetBorrowAmount = borrowAmounts[asset.symbol]
    ? BigNumber.from(borrowAmounts[asset.symbol])
    : null;

  const borrowCap = state?.borrowCap ?? BigNumber.from(0);

  return (
    borrowCap.gt(0) &&
    !!assetBorrowAmount
      ?.add(parseInputAmountToBN(increment || '0', asset.decimals))
      .gte(borrowCap)
  );
};

export default useDisableBorrow;
