// inject helper information to window.h
// it's intended to be used only in devtool

interface WindowWithHelper extends Window {
  h: {
    COMMIT_HASH: unknown;
    VERSION_TAG: unknown;
  };
}

declare const window: WindowWithHelper;

/**
 * variables that will be expose to all non-production envs
 */
if (typeof window !== 'undefined' && process.env.APP_ENV !== 'prod') {
  window.h = window.h || {
    APP_DOMAIN: process.env.APP_DOMAIN,
    COMMIT_HASH: process.env.COMMIT_HASH,
  };
}

/**
 * variables that will be expose to all envs
 */
if (typeof window !== 'undefined') {
  window.h = window.h || {
    COMMIT_HASH: process.env.COMMIT_HASH,
    VERSION_TAG: process.env.COMMIT_TAG,
  };
}

// get around typescript isolatedModules error
export {};
