import { Dispatch, ReactNode, SetStateAction } from 'react';
import clsx from 'clsx';
import { AssetIcon, Text } from '@tectonicfi/tectonic-ui-kit';

import getAssetLogoUri from '@components/SupportedAssetIcon/getAssetLogoUri';

import ArrowUpSVG from '../../public/assets/images/arrow-up.svg';
import ArrowDownSVG from '../../public/assets/images/arrow-down.svg';

interface Props {
  rightLabel?: ReactNode;
  selectOptions: string[] | [];
  leftLabel: string;
  isCollapse: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
  onButtonClick: (data: string) => void;
  collateralTokenSymbol?: string;
  leftLabelClass?: string;
  label: string;
}

function EnhancedAmountSelect({
  rightLabel,
  label,
  onButtonClick,
  selectOptions,
  leftLabel,
  isCollapse,
  setCollapse,
  collateralTokenSymbol,
  leftLabelClass,
}: Props): JSX.Element {
  return (
    <div>
      {typeof label === 'string' ? (
        <Text className="mb-1 text-body font-medium">{label}</Text>
      ) : (
        label
      )}
      <div
        className={clsx('w-auto border border-white border-opacity-30 p-0.5')}
      >
        <div className="flex items-center p-1.5">
          <div className="w-full">
            <Text className={leftLabelClass}>{leftLabel}</Text>
          </div>
          {rightLabel}
          <div
            onClick={() => setCollapse((prev) => !prev)}
            className="cursor-pointer"
          >
            {isCollapse ? (
              <ArrowDownSVG fill={'yellow'} stroke={'yellow'} />
            ) : (
              <ArrowUpSVG fill={'yellow'} stroke={'yellow'} />
            )}
          </div>
        </div>
        {!isCollapse && (
          <div className="grid grid-cols-4 gap-1 p-1.5">
            {selectOptions &&
              selectOptions.map((value, key) => {
                return (
                  <button
                    key={key}
                    className={clsx(
                      'flex items-center justify-center rounded-lg border px-1 text-white mobile:mr-1 mobile:w-fit mobile:text-small',
                      collateralTokenSymbol === value
                        ? 'bg-black border-yellow'
                        : 'border-white/[0.3] bg-white/[0.12]'
                    )}
                    onClick={() => onButtonClick(value)}
                  >
                    {getAssetLogoUri(value) && (
                      <AssetIcon
                        name={value}
                        src={getAssetLogoUri(value) ?? ''}
                        className="mr-1 h-[12px] w-[12px]"
                      />
                    )}
                    {value}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default EnhancedAmountSelect;
