import {
  ReactNode,
  createContext,
  memo,
  useEffect,
  useReducer,
  useRef,
  useContext,
  useMemo,
} from 'react';
import { TectonicSDK } from '@tectonicfi/sdk';
import { Text } from '@tectonicfi/tectonic-ui-kit';

import { notify } from '@lib/bugsnag';
import { useWallet, WalletContext } from '@providers/WalletProvider';
import { getAbi, PoolType } from '@config/base';
import { getNetworkConfig } from '@config/baseNetworkConfig';

import {
  TectonicSdkState,
  initState,
  INITIALIZE_FULFILLED,
  INITIALIZE_REJECTED,
  INITIALIZE_FULFILLED_OF_DEFI_POOL,
  INITIALIZE_REJECTED_OF_DEFI_POOL,
  INITIALIZE_FULFILLED_OF_LCRO_POOL,
  INITIALIZE_REJECTED_OF_LCRO_POOL,
  TectonicSdkAction,
} from './types';

function reducer(
  state: TectonicSdkState,
  action: TectonicSdkAction
): TectonicSdkState {
  switch (action.type) {
    case INITIALIZE_FULFILLED:
      return { ...state, hasError: false };
    case INITIALIZE_REJECTED:
      return { ...state, hasError: true };
    case INITIALIZE_FULFILLED_OF_DEFI_POOL:
      return { ...state, hasErrorOfDeFiPool: false };
    case INITIALIZE_REJECTED_OF_DEFI_POOL:
      return { ...state, hasErrorOfDeFiPool: true };
    case INITIALIZE_FULFILLED_OF_LCRO_POOL:
      return { ...state, hasErrorOfLCROPool: false };
    case INITIALIZE_REJECTED_OF_LCRO_POOL:
      return { ...state, hasErrorOfLCROPool: true };
    default:
      return { ...state };
  }
}
export const TectonicSdkContext = createContext<TectonicSDK>(
  // we ensure this is always set in the provider
  null as unknown as TectonicSDK
);

export const TectonicSdkDeFiPoolContext = createContext<TectonicSDK>(
  // we ensure this is always set in the provider
  null as unknown as TectonicSDK
);

export const TectonicSdkLCROPoolContext = createContext<TectonicSDK>(
  // we ensure this is always set in the provider
  null as unknown as TectonicSDK
);

interface TectonicSdkProviderProps {
  children: ReactNode;
  poolType?: PoolType;
}

function TectonicSdkProvider({
  children,
  poolType,
}: TectonicSdkProviderProps): JSX.Element {
  // re-initialize SDK on currentAccount change in order to update sdk.walletAddress
  const { currentAccount } = useWallet();

  const ABI = getAbi();
  const initABI = ABI[poolType ?? 'MAIN'];

  const getFulfilledType = useMemo(() => {
    switch (poolType) {
      case 'DEFI':
        return INITIALIZE_FULFILLED_OF_DEFI_POOL;
      case 'VENO':
        return INITIALIZE_FULFILLED_OF_LCRO_POOL;
      default:
        return INITIALIZE_FULFILLED;
    }
  }, [poolType]);

  const getRejectedType = useMemo(() => {
    switch (poolType) {
      case 'DEFI':
        return INITIALIZE_REJECTED_OF_DEFI_POOL;
      case 'VENO':
        return INITIALIZE_REJECTED_OF_LCRO_POOL;
      default:
        return INITIALIZE_REJECTED;
    }
  }, [poolType]);

  const ref = useRef<TectonicSDK>(
    (() => {
      const _sdk = new TectonicSDK(initABI);
      _sdk.initWithRpcSync(getNetworkConfig().rpcUrls[0], false);
      return _sdk;
    })()
  );

  const [state, dispatch] = useReducer(reducer, initState);
  const { web3Provider } = useContext(WalletContext);
  const hasProvider = !!web3Provider;

  useEffect(() => {
    async function initSdk() {
      try {
        const sdk = new TectonicSDK(initABI);
        if (hasProvider) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await sdk.initWithProvider(web3Provider, { gasFeeMultiplier: 1.2 });
        } else {
          const config = getNetworkConfig();
          await sdk.initWithRpc(config.rpcUrls[0], false);
        }

        ref.current = sdk;
        dispatch({
          type: getFulfilledType,
        });
      } catch (error) {
        notify(error);
        dispatch({
          type: getRejectedType,
        });
      }
    }

    initSdk();
  }, [
    hasProvider,
    web3Provider,
    currentAccount,
    initABI,
    poolType,
    getFulfilledType,
    getRejectedType,
  ]);

  if (state.hasError) {
    return (
      <div className="mt-[40vh] flex w-full flex-col items-center justify-center">
        <Text className="mt-4">Something went wrong...</Text>
      </div>
    );
  }

  const SdkContext = getSDKContext(poolType);

  return (
    <SdkContext.Provider value={ref.current}>{children}</SdkContext.Provider>
  );
}

export function getSDKContext(poolType: Undefined<PoolType>) {
  switch (poolType) {
    case 'MAIN':
      return TectonicSdkContext;
    case 'DEFI':
      return TectonicSdkDeFiPoolContext;
    case 'VENO':
      return TectonicSdkLCROPoolContext;
    default:
      return TectonicSdkContext;
  }
}

export function useTectonicSdk(poolType?: PoolType) {
  return useContext(getSDKContext(poolType));
}

export default memo(TectonicSdkProvider);
