import { useState, useCallback, memo } from 'react';
import { Button, Icon, Select, Skeleton } from '@tectonicfi/tectonic-ui-kit';
import partial from 'lodash/partial';

import { formatUserUnderlyingAmountWithDust } from '@lib/units';
import usePartnerTokensData from '@hooks/usePartnerTokensData';

export interface PartnerTokensTabContentProps {
  onClaimPartnerTokens: (partnerAddresses: string[]) => void;
}

function PartnerTokensTabContent({
  onClaimPartnerTokens,
}: PartnerTokensTabContentProps): JSX.Element {
  const [selectedPartnerTokens, setSelectedPartnerTokens] = useState<string[]>(
    []
  );

  const { partnerTokensData, isLoading: isPartnerTokensLoading } =
    usePartnerTokensData('DEFI');

  const handleSelectPartnerToken = useCallback(
    (token: string) => {
      const idx = selectedPartnerTokens.indexOf(token);

      if (idx >= 0) {
        const updated = [...selectedPartnerTokens];
        updated.splice(idx, 1);
        setSelectedPartnerTokens(updated);
      } else {
        setSelectedPartnerTokens((prev) => [...prev, token]);
      }
    },
    [selectedPartnerTokens]
  );

  return (
    <>
      <div className="flex flex-col gap-2">
        {!isPartnerTokensLoading ? (
          partnerTokensData.map((token) => {
            const { symbol, amount, balance, underlyingAddress } = token;
            const title = `${symbol} unclaimed ${formatUserUnderlyingAmountWithDust(
              token,
              amount
            )}`;
            const desc = `${symbol} Wallet balance ${formatUserUnderlyingAmountWithDust(
              token,
              balance
            )}`;

            return (
              <Select
                key={symbol}
                Icon={Icon[symbol as keyof typeof Icon] ?? Icon.Question}
                title={title}
                description={desc}
                onSelect={() => handleSelectPartnerToken(underlyingAddress)}
                isSelected={selectedPartnerTokens.includes(underlyingAddress)}
              />
            );
          })
        ) : (
          <div className="flex flex-col gap-1">
            <Skeleton className="h-2 w-full !bg-darkerBlue" />
            <Skeleton className="h-2 w-full !bg-darkerBlue" />
            <Skeleton className="h-2 w-full !bg-darkerBlue" />
          </div>
        )}
      </div>
      <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurface p-3">
        <Button
          className="w-full"
          disabled={selectedPartnerTokens.length === 0}
          onClick={partial(onClaimPartnerTokens, selectedPartnerTokens)}
        >
          Claim
        </Button>
      </div>
    </>
  );
}

export default memo(PartnerTokensTabContent);
