import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { useSupportedAssets } from '@providers/SupportedAssetsProvider';
import { Mode } from '@components/MarketsPageView/types';

function useSdkAndSupportedAssets(mode: Undefined<Mode>) {
  const sdk = useTectonicSdk(mode);
  const { list } = useSupportedAssets(mode);

  return {
    sdk,
    list,
  };
}

export default useSdkAndSupportedAssets;
