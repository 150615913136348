import { useMemo } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';
import get from 'lodash/get';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar';
import EnableErc20Content from '@components/EnableErc20Content';
import Spinner from '@components/Spinner';
import StartEndText from '@components/StartEndText';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import EnhancedAmountInput from '@components/EnhancedAmountInput';
import useWalletBalance from '@hooks/useWalletBalance';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import { isAmountLessThanAllowance } from '@lib/utils';
import {
  formatPercent,
  formatRateToPercent,
  formatUserTotalUsdValue,
  formatUserUnderlyingAmount,
} from '@lib/units';
import {
  getBorrowBalanceRate,
  getLiquidationLtvRate,
  getLoanToValueRate,
  getPostSupplyBorrowLimit,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
  getUserPostSupplyCollateralBalance,
} from '@lib/math';
import { determineSupplyMaxAmount } from '@lib/maxLogic';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useDisableSupply from '@hooks/useDisableSupply';
import { Mode } from '@components/MarketsPageView/types';
import useSupplyApy from '@hooks/useSupplyApy';
import { PARTNER_TOKEN_ICONS } from '@config/constants';

import validateSupplyAmount from './validateSupplyAmount';

export interface SupplyModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  mode: Mode;
  allowance: BigNumber;
  amount: string;
  asset: Null<TectonicAsset>;
  isAllowanceLoaded: boolean;
  isAllowanceLoading: boolean;
  onAmountChange(value: string): void;
  onEnable(): void;
  onSupply(amount: string): void;
}

function SupplyModal({
  mode,
  allowance,
  amount,
  asset,
  isAllowanceLoaded,
  isAllowanceLoading,
  onAmountChange,
  onEnable,
  onSupply,
  transactionStatus,
  ...props
}: SupplyModalProps): JSX.Element {
  const { list: assets } = useSdkAndSupportedAssets(mode);

  const symbol = asset?.symbol || '';
  const options = useMemo(() => ({ skip: !props.isOpen }), [props.isOpen]);

  const erc20IsEnabled = isAmountLessThanAllowance(allowance, amount, asset);
  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);

  // TODO - If we can't load userMetricsData, then we should not proceed
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
  } = useUserMetrics(mode);
  const { data: walletData } = useWalletBalance(mode, asset, options);

  const {
    netSupplyApy,
    tonicSupplyApy,
    partnerTokenSupplyApy,
    isLoading: isSupplyApyLoading,
  } = useSupplyApy(mode, asset, options);

  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;
  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;
  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;

            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const loadingInfo =
    isSupplyApyLoading ||
    loadingUserMetrics ||
    walletData.loading ||
    !loadedUsdPrices;

  const { valid, reason } = validateSupplyAmount({
    asset,
    currentAssetSupplyAmount: asset
      ? userMetricsData.supplyAmounts[asset.symbol]
      : null,
    supplyAmount: amount,
    walletBalance: walletData.balance,
  });

  const disableSupply = useDisableSupply(
    mode,
    asset,
    valid ? amount : undefined
  );

  const onMaxClick = () => {
    if (asset && walletData.balance) {
      onAmountChange(
        determineSupplyMaxAmount(asset, { walletBalance: walletData.balance })
      );
    } else {
      onAmountChange('0');
    }
  };

  function renderCtaButton() {
    if (!erc20IsEnabled) {
      return (
        <Button className="mb-2 w-full" onClick={onEnable}>
          Enable
        </Button>
      );
    }

    function getButtonLabel(): string {
      switch (reason) {
        case 'insufficientBalance':
          return 'No funds available';
        default:
          return 'Supply';
      }
    }

    return (
      <>
        <Button
          className="w-full"
          disabled={
            !valid || !walletData.balance || walletData.loading || disableSupply
          }
          onClick={() => onSupply(amount)}
        >
          {getButtonLabel()}
        </Button>
        {disableSupply && (
          <Text className="text-small">
            You are unable to deposit your tokens as the the maximum amount that
            can be supplied to this market has been reached.
          </Text>
        )}
      </>
    );
  }

  function getNewBorrowLimit(): Null<BigNumber> {
    if (asset && borrowLimit) {
      const newBorrowLimit = getPostSupplyBorrowLimit(
        asset,
        borrowLimit,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.collateralFactors[symbol],
        userMetricsData.isCollateral[symbol]
      );

      if (!newBorrowLimit) {
        return null;
      }

      return newBorrowLimit;
    }

    return null;
  }

  const newBorrowLimit = getNewBorrowLimit();

  function getNewLiquidationLtv(): Null<string> {
    if (asset && newBorrowLimit && collateralBalance) {
      const newCollateralBalance = getUserPostSupplyCollateralBalance(
        asset,
        collateralBalance,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.isCollateral[symbol]
      );

      if (newCollateralBalance) {
        const newLiquidationLtv = formatPercent(
          formatRateToPercent(
            getLiquidationLtvRate(newBorrowLimit, newCollateralBalance)
          )
        );

        return newLiquidationLtv;
      }
    }

    return null;
  }

  function getNewLtv(): Null<string> {
    if (asset && borrowBalance && collateralBalance) {
      const newCollateralBalance = getUserPostSupplyCollateralBalance(
        asset,
        collateralBalance,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.isCollateral[symbol]
      );

      if (newCollateralBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(borrowBalance, newCollateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getLavaBarPercent(): number {
    if (borrowBalance) {
      if (newBorrowLimit) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, newBorrowLimit)
        );
      }

      if (borrowLimit) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, borrowLimit)
        );
      }
    }

    return 0;
  }

  return (
    <BaseTransactionModal
      className="desktop:!w-[510px]"
      title={
        isAllowanceLoaded && !allowance.isZero()
          ? `Supply ${symbol}`
          : `Enable ${symbol} Market`
      }
      {...props}
      transactionStatus={transactionStatus}
    >
      {(!isAllowanceLoaded || isAllowanceLoading) && (
        <div className="flex flex-col items-center justify-center">
          <Spinner />
          <Text className="mt-4">Loading data, please wait...</Text>
        </div>
      )}

      {isAllowanceLoaded && allowance.isZero() && (
        <EnableErc20Content
          onEnable={onEnable}
          symbol={symbol}
          walletData={walletData}
        />
      )}

      {isAllowanceLoaded && !allowance.isZero() && (
        <>
          <div className="mb-4">
            {erc20IsEnabled ? (
              <EnhancedAmountInput
                label="Supply amount"
                onBlur={(): void => {
                  if (amount === '') {
                    onAmountChange('0');
                  }
                }}
                onButtonClick={onMaxClick}
                onChange={(e): void => onAmountChange(e.target.value)}
                value={amount}
              />
            ) : (
              <Text>
                {`To supply ${symbol} to the Tectonic Protocol, you need to enable it first.`}
              </Text>
            )}
          </div>

          <TransactionModalDataRow
            label={
              <div className="flex">
                {asset && <SupportedAssetIcon className="mr-1" asset={asset} />}
                <Text variant="default">Net Supply APY</Text>
              </div>
            }
            loading={loadingInfo}
            value={
              netSupplyApy !== undefined && (
                <Text>{formatPercent(netSupplyApy)}</Text>
              )
            }
          />
          <TransactionModalDataRow
            label={
              <div className="ml-2.5 flex whitespace-nowrap">
                <Icon.Tonic className="mr-1 h-3 w-3" />
                <Text variant="default">Distribution APY</Text>
              </div>
            }
            loading={loadingInfo}
            value={<Text>{formatPercent(tonicSupplyApy * 100)}</Text>}
          />
          {Object.entries(partnerTokenSupplyApy).map(([tokenSymbol, apy]) => {
            const PartnerIcon = get(PARTNER_TOKEN_ICONS, tokenSymbol, null);

            if (!PartnerIcon || apy === 0) return null;

            return (
              <TransactionModalDataRow
                key={tokenSymbol}
                label={
                  <div className="ml-2.5 flex whitespace-nowrap">
                    <PartnerIcon className="mr-1 h-3 w-3" />
                    <Text variant="default">Distribution APY</Text>
                  </div>
                }
                loading={loadingInfo}
                value={<Text>{formatPercent(apy * 100)}</Text>}
              />
            );
          })}
          <TransactionModalDataRow
            label={<Text variant="default">Borrow limit</Text>}
            loading={loadingInfo}
            value={
              borrowLimit && (
                <StartEndText
                  startValue={formatUserTotalUsdValue(borrowLimit)}
                  endValue={
                    newBorrowLimit
                      ? formatUserTotalUsdValue(newBorrowLimit)
                      : null
                  }
                />
              )
            }
          />
          <TransactionModalDataRow
            label={<Text variant="default">Current Loan to Value (LTV)</Text>}
            loading={loadingInfo}
            value={
              !!(borrowBalance && collateralBalance) && (
                <StartEndText
                  startValue={formatPercent(
                    formatRateToPercent(
                      getLoanToValueRate(borrowBalance, collateralBalance)
                    )
                  )}
                  endValue={getNewLtv()}
                />
              )
            }
          />
          <TransactionModalDataRow
            label={<Text variant="default">Liquidation Threshold</Text>}
            loading={loadingInfo}
            value={
              !!(borrowLimit && collateralBalance) && (
                <StartEndText
                  startValue={formatPercent(
                    formatRateToPercent(
                      getLiquidationLtvRate(borrowLimit, collateralBalance)
                    )
                  )}
                  endValue={getNewLiquidationLtv()}
                />
              )
            }
          />
          <div className="mb-10">
            <BorrowLimitLavaBar value={getLavaBarPercent()} />
          </div>
          <TransactionModalDataRow
            label={<Text>Wallet balance</Text>}
            loading={walletData.loading}
            value={
              !!(walletData.asset && walletData.balance) && (
                <Text>
                  {`${formatUserUnderlyingAmount(
                    walletData.asset,
                    walletData.balance
                  )} ${symbol}`}
                </Text>
              )
            }
          />
          {renderCtaButton()}
        </>
      )}
    </BaseTransactionModal>
  );
}

export default SupplyModal;
