import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { differenceInMinutes, isAfter } from 'date-fns';
import { Text, StackView } from '@tectonicfi/tectonic-ui-kit';

export interface CountdownTimerProps {
  className?: string;
  endDateTime: Date;
}

const MINUTES_PER_HOUR = 60;

const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;

function CountdownTimer({
  className,
  endDateTime,
}: CountdownTimerProps): JSX.Element {
  const [nowDateTime, setNowDateTime] = useState(new Date());
  const { days, hours, minutes } = useMemo<{
    days: number;
    hours: number;
    minutes: number;
  }>(() => {
    const result: { days: number; hours: number; minutes: number } = {
      days: 0,
      hours: 0,
      minutes: 0,
    };
    let remainingMinutes = differenceInMinutes(endDateTime, nowDateTime);
    const numberOfDays = remainingMinutes / MINUTES_PER_DAY;

    if (numberOfDays > 1) {
      result.days = Math.floor(numberOfDays);
      remainingMinutes -= result.days * MINUTES_PER_DAY;
    }

    const numberOfHours = remainingMinutes / MINUTES_PER_HOUR;

    if (numberOfHours > 1) {
      result.hours = Math.floor(numberOfHours);
      remainingMinutes -= result.hours * MINUTES_PER_HOUR;
    }

    if (remainingMinutes > 0) {
      result.minutes = remainingMinutes;
    }

    return result;
  }, [endDateTime, nowDateTime]);

  useEffect(() => {
    let intervalRef: number | null = null;

    if (isAfter(endDateTime, new Date())) {
      intervalRef = window.setInterval(() => {
        setNowDateTime(new Date());
      }, 1000);
    }

    return (): void => {
      if (intervalRef) {
        window.clearInterval(intervalRef);
      }
    };
  }, [endDateTime]);

  return (
    <StackView
      className={clsx('items-center text-center', className)}
      direction="horizontal"
    >
      <StackView direction="vertical">
        <Text className="font-semibold">{days}</Text>
        <Text className="uppercase" variant="small">
          Days
        </Text>
      </StackView>
      <Text className="text-large">:</Text>
      <StackView direction="vertical">
        <Text className="font-semibold">{hours}</Text>
        <Text className="uppercase" variant="small">
          Hours
        </Text>
      </StackView>
      <Text className="text-large">:</Text>
      <StackView direction="vertical">
        <Text className="font-semibold">{minutes}</Text>
        <Text className="uppercase" variant="small">
          Mins
        </Text>
      </StackView>
    </StackView>
  );
}

export default CountdownTimer;
