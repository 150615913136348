import { BigNumber, utils } from 'ethers';
import {
  Button,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk';

import Card from '@components/Card';
import CountdownTimer from '@components/CountdownTimer';
import EnhancedAmountInput from '@components/EnhancedAmountInput';
import { X_TONIC_DECIMALS } from '@config/constants';
import { convertXTonicToTonic } from '@lib/math';
import { countDownSecondsToDate } from '@lib/utils';
import { formatXTonicToTonicExchangeRate } from '@lib/units';
import useIsMobile from '@hooks/useIsMobile';

import TonicPageCardSection from './TonicPageCardSection';
import TonicStakingCardModeSwitch from './TonicStakingCardModeSwitch';
import TonicStakingCardWithdrawStats from './TonicStakingCardWithdrawStats';
import TonicStakingYesterdayApy from './TonicStakingYesterdayApy';
import XTonicToTonicExchangeRateTag from './XTonicToTonicExchangeRateTag';
import useTonicStakingCardState from './useTonicStakingCardState';
import validateTonicStakeAmount from './validateTonicStakeAmount';
export interface TonicStakingCardProps {
  apy: number | null;
  countdownInSeconds: number | null;
  hasError: boolean;
  loading: boolean;
  lockedXTonicToTonicExchangeRate: BigNumber | null;
  onStakeTonic(tonicAmount: string): void;
  onUnstakeTonic(
    tonicAmount: string,
    hasCooldown: boolean,
    xTonicToTonicExchangeRate: BigNumber
  ): void;
  onWithdraw(amount: string): void;
  releasableXTonicAmount: BigNumber | null;
  tonicAsset: TectonicAsset | null;
  tonicBalance: BigNumber | null;
  unstakedXTonicAmount: BigNumber | null;
  xTonicBalance: BigNumber | null;
  xTonicToTonicExchangeRate: BigNumber | null;
  stakingRewards: BigNumber | null;
  stakingRewardsFetched: boolean;
  totalXTonicLockedInVaults: BigNumber | null;
  totalXTonicLockedInVaultsFetched: boolean;
}

function TonicStakingCard({
  apy,
  countdownInSeconds,
  hasError,
  loading,
  lockedXTonicToTonicExchangeRate,
  onStakeTonic,
  onUnstakeTonic,
  onWithdraw,
  releasableXTonicAmount,
  tonicAsset,
  tonicBalance,
  unstakedXTonicAmount,
  xTonicBalance,
  xTonicToTonicExchangeRate,
  stakingRewards,
  stakingRewardsFetched,
  totalXTonicLockedInVaults,
  totalXTonicLockedInVaultsFetched,
}: TonicStakingCardProps): JSX.Element {
  const {
    state: { mode, tonicAmount },
    onModeChange,
    onTonicAmountChange,
  } = useTonicStakingCardState();
  const isMobile = useIsMobile();
  const { valid, reason } = validateTonicStakeAmount(mode, tonicAmount);
  // If there is any releasableXTonicAmount, then the user must withdraw
  // before they can continue staking or unstaking.
  const mustWithdraw = !!(
    releasableXTonicAmount && releasableXTonicAmount.gt(BigNumber.from(0))
  );
  const hasCooldown = !!(countdownInSeconds && countdownInSeconds > 0);
  const showLockedExchangeRate = !!(
    (mustWithdraw || hasCooldown) &&
    lockedXTonicToTonicExchangeRate
  );
  const isUnstakingMore = !!(
    !mustWithdraw &&
    unstakedXTonicAmount &&
    unstakedXTonicAmount.gt(BigNumber.from(0)) &&
    mode === 'unstake'
  );

  function getButtonLabel() {
    if (mode === 'stake') {
      return 'Stake';
    }

    if (isUnstakingMore) {
      return 'Unstake More';
    }

    return 'Unstake';
  }

  function getValidationMessage(): string | null {
    if (reason === 'insufficientBalance') {
      return 'Insufficient amount/balance';
    }

    if (reason === 'belowLimit') {
      return 'You must stake at least 100 TONIC';
    }

    return null;
  }

  function renderMessage(): JSX.Element | null {
    if (mustWithdraw) {
      return (
        <div className="mt-2 flex flex-row">
          <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2  flex-shrink-0 text-lavaPrimary" />
          <Text>
            {`Your TONIC is ready to be withdrawn. You can ${mode} after you
                  withdraw.`}
          </Text>
        </div>
      );
    }

    if (isUnstakingMore) {
      return (
        <div className="mt-2 flex flex-row">
          <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2  flex-shrink-0 text-lavaPrimary" />
          <Text>
            Reminder: Unstaking more will reset your cooldown period to 10 days.
          </Text>
        </div>
      );
    }

    return null;
  }

  // When staking, max should be equal to the balance of Tonic in the user's wallet
  function onStakeMaxClick(): void {
    if (tonicAsset && tonicBalance) {
      if (tonicBalance.isZero() || tonicBalance.isNegative()) {
        onTonicAmountChange('0');
        return;
      }

      onTonicAmountChange(utils.formatUnits(tonicBalance, tonicAsset.decimals));
    }
  }

  // When unstaking, max should be equal to the balance of xTonic in the user's wallet
  function onUnstakeMaxClick(): void {
    if (tonicAsset && xTonicBalance && xTonicToTonicExchangeRate) {
      if (xTonicBalance.isZero() || xTonicBalance.isNegative()) {
        onTonicAmountChange('0');
        return;
      }

      onTonicAmountChange(
        utils.formatUnits(
          convertXTonicToTonic(xTonicBalance, xTonicToTonicExchangeRate),
          tonicAsset.decimals
        )
      );
    }
  }

  function getTonicEquivalent(exchangeRate: BigNumber): BigNumber {
    return convertXTonicToTonic(
      utils.parseUnits('1', X_TONIC_DECIMALS),
      exchangeRate
    );
  }

  const validationMessage = getValidationMessage();

  return (
    <Card padding="none">
      <TonicPageCardSection borderBottom>
        <Text variant="semiBold">TONIC Staking</Text>
      </TonicPageCardSection>
      {hasError ? (
        <div className="my-8 flex flex-row justify-center">
          <Text>Something went wrong...</Text>
        </div>
      ) : (
        <TonicPageCardSection className="space-y-2">
          <div className="text-body text-white">
            Stake TONIC to earn a share of Tectonics revenue
          </div>
          {apy && (
            <TonicStakingYesterdayApy
              // APY is a BigNumber scaled by 18 decimal places
              // Example: 100% (1.0) will be 1 * 10^18
              rate={apy}
            />
          )}
          <Card border={false} className="bg-testBlue">
            <TonicStakingCardModeSwitch
              mode={mode}
              onModeChange={onModeChange}
            />
            <EnhancedAmountInput
              data-testid="amount-input"
              invalid={Boolean(validationMessage)}
              label={
                <div className="mb-1 flex flex-row items-center justify-between mobile:flex-col-reverse mobile:items-start mobile:space-y-2 mobile:space-y-reverse">
                  <Text>
                    {mode === 'stake' ? 'Stake TONIC' : 'Unstake TONIC'}
                  </Text>
                  {loading || !xTonicToTonicExchangeRate ? (
                    <Skeleton className="h-4 w-1/3 !bg-onSurface12" />
                  ) : (
                    <XTonicToTonicExchangeRateTag
                      tonicEquivalent={getTonicEquivalent(
                        xTonicToTonicExchangeRate
                      )}
                    />
                  )}
                </div>
              }
              onBlur={(): void => {
                if (tonicAmount === '') {
                  onTonicAmountChange('0');
                }
              }}
              onButtonClick={
                mode === 'stake' ? onStakeMaxClick : onUnstakeMaxClick
              }
              onChange={(e): void => onTonicAmountChange(e.target.value)}
              value={tonicAmount}
            />
            {validationMessage && (
              <Text className="mt-1 text-red">{validationMessage}</Text>
            )}
            <Button
              className="mt-3 w-full mobile:py-1 mobile:px-2 mobile:text-small"
              disabled={!valid || loading || mustWithdraw}
              onClick={(): void => {
                if (tonicAsset) {
                  if (mode === 'stake') {
                    onStakeTonic(
                      utils
                        .parseUnits(tonicAmount, tonicAsset.decimals)
                        .toString()
                    );
                  } else if (xTonicToTonicExchangeRate) {
                    onUnstakeTonic(
                      utils
                        .parseUnits(tonicAmount, tonicAsset.decimals)
                        .toString(),
                      hasCooldown,
                      xTonicToTonicExchangeRate
                    );
                  }
                }
              }}
            >
              {getButtonLabel()}
            </Button>
            {renderMessage()}
          </Card>
          <Card
            variant={isMobile ? 'no-background' : 'default'}
            border={false}
            className="bg-testBlue mobile:bg-transparent"
            padding={isMobile ? 'none' : 'default'}
          >
            <TonicStakingCardWithdrawStats
              loading={loading}
              onWithdraw={(): void => {
                if (mustWithdraw && lockedXTonicToTonicExchangeRate) {
                  onWithdraw(
                    convertXTonicToTonic(
                      releasableXTonicAmount,
                      lockedXTonicToTonicExchangeRate
                    ).toString()
                  );
                }
              }}
              releasableXTonicAmount={releasableXTonicAmount}
              showWithdrawButton={mustWithdraw}
              tonicAsset={tonicAsset}
              // From a UI level, there is never a situation where unstaked X Tonic Amount / Pending Cooldown
              // should be non-zero when ready to withdraw amount is non zero
              unstakedXTonicAmount={
                mustWithdraw ? BigNumber.from(0) : unstakedXTonicAmount
              }
              xTonicBalance={xTonicBalance}
              xTonicToTonicExchangeRate={
                showLockedExchangeRate
                  ? lockedXTonicToTonicExchangeRate
                  : xTonicToTonicExchangeRate
              }
              currentXTonicToTonicExchangeRate={xTonicToTonicExchangeRate}
              stakingRewardsTonic={stakingRewards}
              stakingRewardsFetched={stakingRewardsFetched}
              totalXTonicLockedInVaults={totalXTonicLockedInVaults}
              totalXTonicLockedInVaultsFetched={
                totalXTonicLockedInVaultsFetched
              }
            />
            {hasCooldown && (
              <div className="mt-4 bg-onSurface12 py-1">
                <Tooltip message="You will be able to withdraw your TONIC after this cooldown period.">
                  <CountdownTimer
                    className="mx-auto w-1/2 "
                    endDateTime={countDownSecondsToDate(countdownInSeconds)}
                  />
                </Tooltip>
              </div>
            )}
            {showLockedExchangeRate && (
              <div className="mt-2 flex flex-row">
                <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2 flex-shrink-0 text-yellowPrimary" />
                <Text>
                  {`Unstaking locked rate: 1 xTONIC = ${formatXTonicToTonicExchangeRate(
                    getTonicEquivalent(lockedXTonicToTonicExchangeRate)
                  )} TONIC`}
                </Text>
              </div>
            )}
            {hasCooldown && (
              <div className="mt-2 flex flex-row">
                <Icon.Info className="mr-1.5 mt-0.5 h-2 w-2 flex-shrink-0 text-yellowPrimary" />
                <Text>
                  Estimated timer might be adjusted subject to real time
                  blockchain traffic
                </Text>
              </div>
            )}
          </Card>
        </TonicPageCardSection>
      )}
    </Card>
  );
}

export default TonicStakingCard;
