import { ReactNode } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';

import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';
import { secondsToPeriod } from '@components/LockDepositModal/periods';
import { PoolOfUser } from '@components/VaultsPageView/VaultsBlock/types';
import { TxStatus } from '@types';

export const getCtaType = (
  nftToStakeIds: string[],
  nftToUnstakeIds: string[]
): CtaType => {
  if (nftToStakeIds.length > 0 && nftToUnstakeIds.length > 0) {
    return 'REPLACE';
  } else if (nftToStakeIds.length > 0) {
    return 'STAKE';
  } else {
    return 'UNSTAKE';
  }
};

export type StakingFlow = 'SELECT' | 'STAKE';
export type CtaType = 'STAKE' | 'UNSTAKE' | 'REPLACE';
export function getRenderTitle(stakeFlow: StakingFlow, hasStaked: boolean) {
  return (txStatus: TxStatus, ctaType: Null<CtaType>): string => {
    const ctaWording = ctaType
      ? ctaType[0] + ctaType.slice(1).toLowerCase()
      : 'Stake';
    if (!txStatus || txStatus === TxStatus.pending) {
      switch (stakeFlow) {
        case 'SELECT':
          return hasStaked ? 'Stake / Unstake NFT' : 'Stake NFT';
        case 'STAKE':
        default:
          return `${ctaWording} NFT`;
      }
    }

    if (txStatus === TxStatus.awaiting_confirmation) {
      return 'Confirm transaction';
    }

    if (txStatus === TxStatus.confirmed) {
      return `${ctaWording} NFT`;
    }

    return 'Failed';
  };
}

const getCtaWordingMap = (lockPeriodName: string): Record<CtaType, string> => ({
  STAKE: `You have staked your Cronos Cruisers in ${lockPeriodName} Vault`,
  UNSTAKE: `You have unstaked your Cronos Cruisers from ${lockPeriodName} Vault`,
  REPLACE: `You have changed your Cronos Cruisers in ${lockPeriodName} Vault`,
});
export function getRenderTransactionStatus(
  transactionErrorMessage: string | null | undefined,
  onClose: () => void,
  pool?: PoolOfUser
) {
  const renderTransactionStatus = (
    txStatus: TxStatus,
    ctaType: Null<CtaType>
  ): ReactNode => {
    const isTransactionAwaitingConfirmation =
      txStatus === TxStatus.awaiting_confirmation;
    const isTransactionPending = txStatus === TxStatus.pending;
    const isTransactionConfirmed = txStatus === TxStatus.confirmed;
    const showSpinner =
      isTransactionAwaitingConfirmation || isTransactionPending;
    const ctaWording = getCtaWordingMap(
      pool ? getLockPeriodName(secondsToPeriod[pool.lockPeriod]) : ''
    )[ctaType ?? 'STAKE'];

    return (
      <div className="flex flex-col items-center gap-4 desktop:h-[574px]">
        {showSpinner && (
          <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
        )}
        {isTransactionAwaitingConfirmation && (
          <Text className="mt-4">Confirm the transaction in your wallet</Text>
        )}
        {isTransactionConfirmed && (
          <>
            <Icon.Check className="h-8 w-8" />
            <Text>{ctaWording}</Text>
            <Button
              className="mt-auto w-full mobile:absolute mobile:bottom-2 mobile:w-[340px]"
              onClick={onClose}
            >
              Done
            </Button>
          </>
        )}
        {txStatus === 'failed' && (
          <Text>{transactionErrorMessage || 'Unknown error'}</Text>
        )}
      </div>
    );
  };
  return renderTransactionStatus;
}
