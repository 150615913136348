import clsx from 'clsx';
import { Text } from '@tectonicfi/tectonic-ui-kit';

import Card from '@components/Card';

export interface NetworkErrorCardProps {
  className?: string;
  onSwitchNetwork?(): void;
}

function NetworkErrorCard({
  className,
  onSwitchNetwork,
}: NetworkErrorCardProps): JSX.Element {
  return (
    <Card
      padding="small"
      className={clsx('mb-5 bg-red mobile:mb-2', className)}
    >
      <div className="flex justify-between">
        <Text className="text-small font-medium text-surface">
          Tectonic is not supported on this network. Please switch to Cronos.
        </Text>
        <button onClick={onSwitchNetwork}>
          <Text className="text-right text-small font-semibold text-surface">
            Switch network
          </Text>
        </button>
      </div>
    </Card>
  );
}

export default NetworkErrorCard;
