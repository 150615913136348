import { DropdownContext } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { useContext } from 'react';

import { displayValues as defaultDisplayValues } from './constants';

export default function SortOptions({
  displayValues,
}: {
  displayValues?: Record<string, string>;
}) {
  const context = useContext(DropdownContext);

  return (
    <>
      {Object.keys(displayValues ?? defaultDisplayValues).map(
        (currentValue, i) => (
          <div
            className={clsx(
              i > 0 && 'border-t-[1px] border-greyBorder',
              'cursor-pointer p-2 text-left text-mediumSmall text-white'
            )}
            key={currentValue}
            onClick={() => context.onChange(currentValue)}
          >
            {displayValues
              ? displayValues[currentValue]
              : defaultDisplayValues[currentValue]}
          </div>
        )
      )}
    </>
  );
}
