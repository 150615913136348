import { BigNumber, utils } from 'ethers';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { AssetIcon, Button, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import {
  EnhancedAmountSelect,
  EnhancedSlippageToleranceSelect,
} from '@components/EnhancedAmountSelect';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar/BorrowLimitLavaBar';
import StartEndText from '@components/StartEndText/StartEndText';
import TransactionModalDataRow from '@components/TransactionModalDataRow/TransactionModalDataRow';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import useWalletBalance from '@hooks/useWalletBalance';
import {
  getBorrowBalanceRate,
  getLiquidationLtvRate,
  getLoanToValueRate,
  getPostRepayBorrowBalance,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
  getUnderlyingUsdValue,
} from '@lib/math';
import {
  formatPercent,
  formatRateToPercent,
  formatUserTotalUsdValue,
  formatUserUnderlyingAmount,
  formatUserUnderlyingUsdValue,
  formatUnits,
} from '@lib/units';
import useUserAssetBorrowAmount from '@hooks/useUserAssetBorrowAmount';
import useVvsSwapTrade from '@hooks/useVVSSwapTrade';
import SupportedAssetIcon from '@components/SupportedAssetIcon/SupportedAssetIcon';
import EnhancedAmountInputSymbol from '@components/EnhancedAmountInputSymbol/EnhancedAmountInputSymbol';
import getAssetLogoUri from '@components/SupportedAssetIcon/getAssetLogoUri';
import { Mode } from '@components/MarketsPageView/types';
import { BaseTransactionModalProps } from '@components/BaseTransactionModal';
import { parseInputAmountToBN, formatTotalUnderlyingAmount } from '@lib/units';
import {
  CRO_GAS_BUFFER,
  SLIPPAGE_TOLERANCE,
  SWAP_FEE,
} from '@config/constants';
import useSocketAddress from '@hooks/useSocketAddress';
import useWallets from '@hooks/useWallets';

import VVSSVG from '../../public/images/vvs.svg';

import { checkPriceImpact, getSwapPath } from './utils';

export interface RepayCollateralProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  allowance: BigNumber;
  amount: string;
  asset: TectonicAsset | null;
  isAllowanceLoaded: boolean;
  onAmountChange(value: string): void;
  onEnable(): void;
  onRepayAllWithCollateral: (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => void;
  onRepaySomeWithCollateral: (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => void;
  onGetTTokenAmountIn: (
    tectonicSocketAddress: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ) => Promise<[BigNumber, BigNumber]>;
  mode: Mode;
  erc20IsEnabled: boolean;
}

const RepayWithCollateral = ({
  erc20IsEnabled,
  amount,
  onAmountChange,
  mode,
  asset,
  onEnable,
  onRepayAllWithCollateral,
  onRepaySomeWithCollateral,
  onGetTTokenAmountIn,
  ...props
}: RepayCollateralProps) => {
  const [isCollapse, setCollapse] = useState<boolean>(true);
  const [isCollapseTolerance, setCollapseTolerance] = useState<boolean>(true);
  const [collateralTokenSymbol, setCollateralData] = useState<string>();
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.005);
  const [isMaxRepay, setIsMaxRepay] = useState(false);
  const [collateralRepayAmount, setCollateralRepayAmount] =
    useState<Null<BigNumber>>(null);

  const { list: assets } = useSdkAndSupportedAssets(mode);

  const symbol = asset?.symbol || '';

  const options = useMemo(
    () => ({ skip: !props.isOpen, mode }),
    [props.isOpen, mode]
  );

  const tectonicSocketAddress = useSocketAddress(mode);
  const { currentAccount } = useWallets();

  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);
  // TODO - If we can't load userMetricsData, then we should not proceed
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
  } = useUserMetrics(mode, options);
  const { data: walletData } = useWalletBalance(mode, asset, options);
  const { amount: currentBorrowAmount } = useUserAssetBorrowAmount(
    asset,
    mode,
    options
  );

  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;

  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;
  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol];
            //   console.log(a,supplyAmount,usdPrice, 'collateralBalance');
            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const loadingInfo =
    loadingUserMetrics || walletData.loading || !loadedUsdPrices;

  const decideRepayMethod = () => {
    const repayWithCollateralParams = getRepayWithCollateralParams();

    if (repayWithCollateralParams.path && currentAccount) {
      const { repayAmount, slip, path, tTokenAddressOfCollateral } =
        repayWithCollateralParams;

      if (isMaxRepay) {
        onRepayAllWithCollateral(
          tectonicSocketAddress,
          currentAccount,
          repayAmount,
          slip,
          path,
          tTokenAddressOfCollateral
        );
      } else {
        onRepaySomeWithCollateral(
          tectonicSocketAddress,
          currentAccount,
          repayAmount,
          slip,
          path,
          tTokenAddressOfCollateral
        );
      }
    }
    return null;
  };

  const onMaxClick = () => {
    if (asset && currentBorrowAmount) {
      if (asset.symbol === 'CRO' && walletData.balance) {
        const gasBuffer = utils.parseUnits(CRO_GAS_BUFFER, asset.decimals);

        if (walletData.balance.gte(gasBuffer)) {
          setIsMaxRepay(true);
        }
      } else {
        setIsMaxRepay(true);
      }
      onAmountChange(formatUnits(currentBorrowAmount, asset.decimals));
    } else {
      onAmountChange('0');
    }
  };

  const onSelectCollateralToken = (tokenName: string) => {
    setCollateralData(tokenName);
  };

  function getNewBorrowBalance(): BigNumber | null {
    if (asset && borrowBalance) {
      const newBorrowBalance = getPostRepayBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (!newBorrowBalance) {
        return null;
      }

      return newBorrowBalance;
    }

    return null;
  }

  const endBorrowBalance = getNewBorrowBalance();
  function getNewLtv(): string | null {
    if (asset && borrowBalance && collateralBalance) {
      const newBorrowBalance = getPostRepayBorrowBalance(
        asset,
        borrowBalance,
        amount,
        BigNumber.from(usdPrices[symbol])
      );

      if (newBorrowBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(newBorrowBalance, collateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getLavaBarPercent(): number {
    if (borrowLimit) {
      if (endBorrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(endBorrowBalance, borrowLimit)
        );
      }

      if (borrowBalance) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, borrowLimit)
        );
      }
    }

    return 0;
  }

  const assetOfCollateral = useMemo(() => {
    if (collateralTokenSymbol) {
      return assets.find((a) => a.symbol === collateralTokenSymbol);
    }
    return null;
  }, [collateralTokenSymbol, assets]);

  const vvsData = useVvsSwapTrade(
    amount,
    asset,
    slippageTolerance,
    assetOfCollateral
  );

  const selectOptionsOfCollateral = useMemo(
    () =>
      Object.keys(userMetricsData.isCollateral).filter(function (key) {
        return (
          userMetricsData.isCollateral[key] &&
          userMetricsData.supplyAmounts[key].gt(0)
        );
      }),
    [userMetricsData]
  );

  const supplyAmountOfCollateral = useMemo(() => {
    if (assetOfCollateral) {
      return userMetricsData.supplyAmounts[assetOfCollateral.symbol];
    }
    return BigNumber.from(0);
  }, [assetOfCollateral, userMetricsData]);

  const getNewCollateralBalance = useCallback(() => {
    if (supplyAmountOfCollateral && collateralRepayAmount) {
      return supplyAmountOfCollateral.sub(collateralRepayAmount);
    }

    return BigNumber.from(0);
  }, [supplyAmountOfCollateral, collateralRepayAmount]);

  const getRepayWithCollateralParams = useCallback(() => {
    if (asset && assetOfCollateral && slippageTolerance && amount) {
      const repayAmount = parseInputAmountToBN(amount, asset.decimals);

      const slip = utils.parseUnits(slippageTolerance.toString(), 18);

      const path = getSwapPath(asset, assetOfCollateral);

      return {
        repayAmount,
        slip,
        path,
        tTokenAddressOfCollateral: assetOfCollateral.tTokenAddress,
      };
    }

    return {};
  }, [asset, assetOfCollateral, amount, slippageTolerance]);

  const getTokenAmountInMax = useCallback(async () => {
    const repayWithCollateralParams = getRepayWithCollateralParams();

    if (repayWithCollateralParams.path) {
      const { repayAmount, slip, path } = repayWithCollateralParams;

      const tTokenAmountIn = await onGetTTokenAmountIn(
        tectonicSocketAddress,
        repayAmount,
        slip,
        path
      );

      return tTokenAmountIn[1];
    }

    return '';
  }, [
    onGetTTokenAmountIn,
    tectonicSocketAddress,
    getRepayWithCollateralParams,
  ]);

  useEffect(() => {
    if (asset && assetOfCollateral && slippageTolerance) {
      getTokenAmountInMax().then((tokenAmountMax) => {
        if (tokenAmountMax) {
          setCollateralRepayAmount(tokenAmountMax);
        }
      });
    }
  }, [asset, assetOfCollateral, slippageTolerance, getTokenAmountInMax]);

  const displayCtaButton = () => {
    if (!erc20IsEnabled) {
      return (
        <Button className="w-full" onClick={onEnable}>
          Enable
        </Button>
      );
    }
    const repayText = isMaxRepay ? 'Repay all' : 'Repay';

    const newCollateralBalance = getNewCollateralBalance();

    const isValidNewCollateralBalance = newCollateralBalance.gte(0);

    return (
      <Button
        className="w-full"
        disabled={
          !isValidNewCollateralBalance ||
          checkPriceImpact(Number(vvsData?.data?.priceImpact))
        }
        onClick={decideRepayMethod}
        theme="pink"
      >
        {!isValidNewCollateralBalance
          ? 'Insufficient Balance'
          : checkPriceImpact(Number(vvsData?.data?.priceImpact))
          ? 'Price Impact Too High'
          : repayText}
      </Button>
    );
  };

  const getUserTotalCollateralBalance = useCallback(
    (a: TectonicAsset, supplyAmount: BigNumber) => {
      if (a) {
        return formatUserUnderlyingUsdValue(
          a,
          getUnderlyingUsdValue(
            a,
            supplyAmount,
            BigNumber.from(usdPrices[a.symbol])
          )
        );
      }

      return '';
    },
    [usdPrices]
  );

  return (
    <div>
      <div>
        {erc20IsEnabled && (
          <EnhancedAmountInputSymbol
            label="Repay amount"
            rightLabel={
              asset && (
                <>
                  <SupportedAssetIcon className="h-2" asset={asset} />
                  <Text className="mx-1">{symbol}</Text>
                </>
              )
            }
            onBlur={(): void => {
              if (amount === '') {
                onAmountChange('0');
              }
            }}
            onButtonClick={onMaxClick}
            onChange={(e): void => {
              setIsMaxRepay(false);
              onAmountChange(e.target.value);
            }}
            value={amount}
          />
        )}
        <div className="my-2">
          <EnhancedAmountSelect
            label="Collateral to repay with"
            rightLabel={
              collateralTokenSymbol ? (
                <>
                  {getAssetLogoUri(collateralTokenSymbol) && (
                    <AssetIcon
                      name={collateralTokenSymbol}
                      src={getAssetLogoUri(collateralTokenSymbol) ?? ''}
                    />
                  )}
                  <Text className="mx-1">{collateralTokenSymbol}</Text>
                </>
              ) : (
                <Text className="mr-1">Select</Text>
              )
            }
            onButtonClick={(tokenName) => onSelectCollateralToken(tokenName)}
            selectOptions={selectOptionsOfCollateral}
            leftLabel={
              assetOfCollateral && collateralRepayAmount
                ? formatTotalUnderlyingAmount(
                    assetOfCollateral,
                    collateralRepayAmount
                  )
                : 'Collateral'
            }
            leftLabelClass={'text-white/50'}
            isCollapse={isCollapse}
            setCollapse={setCollapse}
            collateralTokenSymbol={collateralTokenSymbol}
          />
        </div>

        <TransactionModalDataRow
          label={<Text variant="default">Total borrow balance</Text>}
          loading={loadingInfo}
          value={
            borrowBalance && (
              <StartEndText
                startValue={formatUserTotalUsdValue(borrowBalance)}
                endValue={
                  endBorrowBalance
                    ? formatUserTotalUsdValue(endBorrowBalance)
                    : null
                }
              />
            )
          }
        />

        <TransactionModalDataRow
          label={<Text variant="default">Collateral balance</Text>}
          loading={loadingInfo}
          value={
            borrowBalance && (
              <StartEndText
                startValue={
                  assetOfCollateral
                    ? getUserTotalCollateralBalance(
                        assetOfCollateral,
                        supplyAmountOfCollateral
                      )
                    : ''
                }
                endValue={
                  assetOfCollateral
                    ? getUserTotalCollateralBalance(
                        assetOfCollateral,
                        getNewCollateralBalance()
                      )
                    : ''
                }
              />
            )
          }
        />
        <TransactionModalDataRow
          label={<Text variant="default">Current Loan to Value (LTV)</Text>}
          loading={loadingInfo}
          value={
            !!(borrowBalance && collateralBalance) && (
              <StartEndText
                startValue={formatPercent(
                  formatRateToPercent(
                    getLoanToValueRate(borrowBalance, collateralBalance)
                  )
                )}
                endValue={getNewLtv()}
              />
            )
          }
        />
        <TransactionModalDataRow
          label={<Text variant="default">Liquidation Threshold</Text>}
          loading={false}
          value={
            !!(borrowLimit && collateralBalance) && (
              <Text>
                {formatPercent(
                  formatRateToPercent(
                    getLiquidationLtvRate(borrowLimit, collateralBalance)
                  )
                )}
              </Text>
            )
          }
        />
        <div className="mb-2">
          <BorrowLimitLavaBar value={getLavaBarPercent()} />
        </div>

        <div className="mb-2 border border-white border-opacity-30 bg-darkerBlue p-1">
          <div className="mt-2 flex">
            <Text className="mr-1 text-white/60">Powered by</Text>
            <VVSSVG width="25" height="25" />
            <Text className="ml-1 font-bold">VVS Finance</Text>
          </div>

          <div className="my-1">
            <EnhancedSlippageToleranceSelect
              label=""
              onButtonClick={(st: number) => setSlippageTolerance(st)}
              selectOptions={SLIPPAGE_TOLERANCE}
              leftLabel="Slippage tolerance"
              isCollapse={isCollapseTolerance}
              setCollapse={setCollapseTolerance}
              slippageTolerance={slippageTolerance}
            />
          </div>
          <TransactionModalDataRow
            label={<Text variant="default">Minimum Received</Text>}
            loading={vvsData?.isLoading ?? false}
            value={
              <StartEndText
                startValue={vvsData?.data?.minReceived}
                endValue={null}
              />
            }
            tooltip="Amount received after accounting for slippage and fees. The transaction may revert if there is a large, unfavorable price movement before it is confirmed."
          />

          <TransactionModalDataRow
            label={<Text variant="default">Price</Text>}
            loading={vvsData?.isLoading ?? false}
            value={
              <StartEndText
                startValue={
                  vvsData?.data?.price
                    ? `${vvsData?.data?.price} ${asset?.symbol} per ${assetOfCollateral?.symbol}`
                    : ''
                }
                endValue={null}
              />
            }
          />

          <TransactionModalDataRow
            label={<Text variant="default">Price impact</Text>}
            loading={vvsData?.isLoading ?? false}
            value={
              <StartEndText
                startValue={
                  vvsData?.data?.priceImpact
                    ? `${vvsData?.data?.priceImpact} %`
                    : ''
                }
                endValue={null}
              />
            }
            tooltip="Difference between the market price and estimated price due to discrepancies between the trade size relative to liquidity available."
          />

          <TransactionModalDataRow
            label={<Text variant="default">Swap fee</Text>}
            loading={false}
            value={
              <StartEndText
                startValue={formatPercent(SWAP_FEE * 100)}
                endValue={null}
              />
            }
            tooltip="If using an aggregator, this amount represents commission fees paid to use their services, but does not account for swap fees incurred from routing liquidity to various trading venues"
          />
        </div>

        <TransactionModalDataRow
          label={<Text>Wallet balance</Text>}
          loading={walletData.loading}
          value={
            !!(walletData.asset && walletData.balance) && (
              <Text>
                {`${formatUserUnderlyingAmount(
                  walletData.asset,
                  walletData.balance
                )} ${symbol}`}
              </Text>
            )
          }
        />
        {displayCtaButton()}
      </div>
    </div>
  );
};

export default RepayWithCollateral;
