import { ReactNode } from 'react';
import clsx from 'clsx';
import { Icon, Tooltip } from '@tectonicfi/tectonic-ui-kit';

export interface DataRowProps {
  className?: string;
  label: ReactNode;
  labelWrapperClass?: string;
  value?: ReactNode;
  tooltip?: string;
}

function DataRow({
  className,
  label,
  labelWrapperClass,
  value,
  tooltip,
}: DataRowProps): JSX.Element {
  return (
    <div className={clsx('flex flex-row', className)}>
      <div className={clsx('flex', labelWrapperClass)}>
        {label}
        {tooltip && (
          <Tooltip message={<div className="w-[250px]">{tooltip}</div>}>
            <Icon.Question className="h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-1 items-center justify-end text-right">
        {value}
      </div>
    </div>
  );
}

export default DataRow;
