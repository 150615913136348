import { Icon, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';

type BoostedValueProps = {
  value: number | string;
  boostedValue?: number | string;
  loading?: boolean;
};
const BoostedValue = ({
  value,
  boostedValue,
  loading,
}: BoostedValueProps): JSX.Element => {
  return (
    <div className="flex items-center gap-0.5">
      <Text className="font-semibold">{value}</Text>
      {(loading || boostedValue) && (
        <>
          <Icon.Arrow
            className="h-[15px] w-[15px] rotate-180"
            color="#6CD1A9"
          />
          {!loading && boostedValue ? (
            <Text className="font-semibold text-green">{boostedValue}</Text>
          ) : (
            <Skeleton className="w-6" />
          )}
        </>
      )}
    </div>
  );
};

export default BoostedValue;
