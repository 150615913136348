import { useMemo } from 'react';
import { useQuery, useQueries } from 'react-query';
import { BigNumber } from 'ethers';
import { useCallback } from 'react';

import { useWallet } from '@providers/WalletProvider';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { NFT_BOOSTING_NFT_ADDRESS } from '@config/base';
import { delay } from '@lib/delay';

import useNFTMeta, { NFT } from './useNFTMeta';

// should not query when pool id is undefined
const DEFAULT_POOL_ID = 9;

type UseNFTResult = {
  loading: boolean;
  userNfts: NFT[];
  stakedNfts: NFT[];
  refetchNfts: () => Promise<void>;
};
const useNFT = (poolId?: number): UseNFTResult => {
  const { currentAccount } = useWallet();
  const sdk = useTectonicSdk();

  const erc721balanceQuery = getQuery(QueryKey.TECTONIC_ERC721_BALANCE_OF)(
    sdk,
    NFT_BOOSTING_NFT_ADDRESS[0],
    currentAccount ?? ''
  );

  const stakedNFTIdsQuery = getQuery(
    QueryKey.TECTONIC_VAULT_GET_STAKED_NFT_TOKEN_IDS
  )(
    sdk,
    currentAccount ?? '',
    poolId ?? DEFAULT_POOL_ID,
    NFT_BOOSTING_NFT_ADDRESS[0]
  );

  const [
    {
      data: erc721balance,
      isLoading: isErc721balanceLoading,
      refetch: refetchErc721balance,
    },
    {
      data: stakedNFTIds,
      isLoading: isStakedNFTIdsLoading,
      refetch: refetchStakedNFTIds,
    },
  ] = useQueries([
    { ...erc721balanceQuery, enabled: !!currentAccount, cacheTime: 0 },
    { ...stakedNFTIdsQuery, enabled: poolId !== undefined && !!currentAccount },
  ]);

  const indices = useMemo(
    () =>
      new Array(erc721balance?.toNumber() ?? 0)
        .fill(null)
        .map((_, i) => BigNumber.from(i)),
    [erc721balance]
  );

  const tokensOfOwnerByIndicesQuery = getQuery(
    QueryKey.TECTONIC_ERC721_TOKENS_OF_OWNER_BY_INDICES
  )(sdk, NFT_BOOSTING_NFT_ADDRESS[0], currentAccount ?? '', indices);

  const {
    data: tokenIdsOfOwner,
    isLoading: isTokenIdsOfOwnerLoading,
    refetch: refetchTokenIdsOfOwner,
  } = useQuery({
    ...tokensOfOwnerByIndicesQuery,
    enabled: !!currentAccount && indices.length > 0 && !isErc721balanceLoading,
  });

  const userNFTsURIQuery = getQuery(QueryKey.TECTONIC_ERC721_TOKEN_URI)(
    sdk,
    NFT_BOOSTING_NFT_ADDRESS[0],
    tokenIdsOfOwner ?? []
  );

  const stakedNFTsURIQuery = getQuery(QueryKey.TECTONIC_ERC721_TOKEN_URI)(
    sdk,
    NFT_BOOSTING_NFT_ADDRESS[0],
    stakedNFTIds ?? []
  );

  const [
    {
      data: userNFTsURIData,
      isLoading: isUserNFTsURIDataLoading,
      refetch: refetchUserNFTsURIData,
    },
    { data: stakedNFTsURIData, isLoading: isStakedNFTsURILoading },
  ] = useQueries([
    {
      ...userNFTsURIQuery,
      enabled: !isTokenIdsOfOwnerLoading && !!tokenIdsOfOwner?.length,
    },
    {
      ...stakedNFTsURIQuery,
      enabled: !isStakedNFTIdsLoading && !!stakedNFTIds?.length,
    },
  ]);

  const { loading: isUserNftsLoading, nfts: userNfts } = useNFTMeta(
    userNFTsURIData,
    isUserNFTsURIDataLoading
  );
  const { loading: isStakedNftsLoading, nfts: stakedNfts } = useNFTMeta(
    stakedNFTsURIData,
    isStakedNFTIdsLoading
  );

  const refetchNfts = useCallback(async () => {
    await refetchStakedNFTIds();
    await delay();

    await refetchErc721balance();
    await refetchTokenIdsOfOwner();
    await refetchUserNFTsURIData();
  }, [
    refetchErc721balance,
    refetchStakedNFTIds,
    refetchTokenIdsOfOwner,
    refetchUserNFTsURIData,
  ]);

  const loading =
    isErc721balanceLoading ||
    isTokenIdsOfOwnerLoading ||
    isStakedNFTIdsLoading ||
    isUserNFTsURIDataLoading ||
    isStakedNFTsURILoading ||
    isUserNftsLoading ||
    isStakedNftsLoading;

  return {
    loading,
    userNfts,
    stakedNfts,
    refetchNfts,
  };
};

export default useNFT;
