import { BigNumber } from 'ethers';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { useMemo } from 'react';
import clsx from 'clsx';
import { formatEther } from 'ethers/lib/utils';
import { useRouter } from 'next/router';

import {
  formatPercent,
  formatRateToPercent,
  formatTotalTonicAmount,
  formatMantissa,
} from '@lib/units';

export interface PickAmountScreenProps {
  xTonicBalance: BigNumber;
  apr: number;
  multiplier: string;
  lockEndDate: string;
  amount: string;
  amountValid: boolean;
  isConfirm: boolean;
  onAmountChange: (inputAmount: string) => void;
  onAmountPick: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

function AmountButton({
  label,
  onClick,
  active,
}: {
  label: string;
  onClick: () => void;
  active: boolean;
}) {
  return (
    <button
      className={clsx(
        'cursor-pointer rounded-3xl border border-greyBorder p-1 text-center',
        active ? 'bg-rainbowYellow' : 'bg-greyBackground'
      )}
      onClick={onClick}
    >
      <Text variant="semiBold" className={clsx(active && 'text-[#000]')}>
        {label}
      </Text>
    </button>
  );
}
function DataRow({ label, value }: { label: string; value: string }) {
  return (
    <div className="flex items-center justify-between">
      <Text variant="default" className="text-[14px]">
        {label}
      </Text>
      <Text variant="semiBold" className="text-[14px]">
        {value}
      </Text>
    </div>
  );
}
export default function PickAmountScreen({
  xTonicBalance,
  amount,
  amountValid,
  apr,
  multiplier,
  lockEndDate,
  isConfirm,
  onAmountPick,
  onAmountChange,
  onConfirm,
  onClose,
}: PickAmountScreenProps) {
  const router = useRouter();
  const ratios = useMemo(() => {
    const formatPartOfBalance = (ratio: number) => {
      if (ratio === 1) {
        return formatEther(xTonicBalance);
      }
      const mantissa = BigNumber.from(1_000_000_000);
      return formatEther(
        xTonicBalance
          .mul(mantissa)
          .div(mantissa.mul(100).div(Math.floor(ratio * 100)))
      );
    };
    return {
      [0.25]: formatPartOfBalance(0.25),
      [0.5]: formatPartOfBalance(0.5),
      [0.75]: formatPartOfBalance(0.75),
      [1]: formatPartOfBalance(1),
    };
  }, [xTonicBalance]);

  if (xTonicBalance.lte(0)) {
    return (
      <div className="text-center">
        <Icon.Info className="inline-block h-8 w-8 text-red" />
        <div className="mx-auto max-w-[350px] pt-4">
          <Text>
            To start using the vault feature you need xTONIC. Stake your tokens
            on the TONIC Staking Page to earn xTONIC
          </Text>
        </div>
        <Button
          className="mt-3 w-full"
          onClick={() => {
            router.push('/tonic');
            onClose();
          }}
        >
          Get xTONIC
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {!isConfirm ? (
        <div className="mb-3 bg-darkerBlue p-2 pb-3">
          <Text
            variant="semiTransparent"
            className="flex justify-between text-mediumSmall"
          >
            <span>Deposit Amount</span>
            <span>Balance: {formatTotalTonicAmount(xTonicBalance)}</span>
          </Text>
          <div className="flex items-center">
            <Text variant="semiBold" className="grow">
              <input
                className="webkit-none h-full w-full bg-transparent pt-1 pb-3 text-white outline-none"
                onChange={(e) => onAmountChange(e.target.value)}
                placeholder="0"
                type="text"
                value={formatMantissa(amount, 8)}
              />
            </Text>
            <div className="pt-1 pb-4">
              <Text variant="semiBold">xTONIC</Text>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mobile:grid-cols-2 mobile:gap-1 ">
            <AmountButton
              label="25%"
              active={ratios[0.25] === amount}
              onClick={() => onAmountChange(ratios[0.25])}
            />
            <AmountButton
              label="50%"
              active={ratios[0.5] === amount}
              onClick={() => onAmountChange(ratios[0.5])}
            />
            <AmountButton
              label="75%"
              active={ratios[0.75] === amount}
              onClick={() => onAmountChange(ratios[0.75])}
            />
            <AmountButton
              label="MAX"
              active={ratios[1] === amount}
              onClick={() => onAmountChange(ratios[1])}
            />
          </div>
        </div>
      ) : (
        <div className="mt-[28px] mb-3 flex flex-col items-center">
          <Text variant="large">{formatMantissa(amount, 8)} xTONIC</Text>
          <Text variant="semiTransparent">Deposit Amount</Text>
        </div>
      )}
      <div className="spacy-y-1">
        <DataRow label="APR" value={formatPercent(formatRateToPercent(apr))} />
        <DataRow label="Multiplier" value={multiplier} />
        <DataRow label="Lock end date" value={lockEndDate} />
      </div>
      <Text variant="semiTransparent" className="my-3" as="span">
        <ul className="ml-2 list-outside list-disc">
          <li className="text-[10px]">
            <Text
              variant="semiTransparent"
              className="text-mediumSmall leading-5"
            >
              You will not be able to withdraw your tokens before the period end
              date.
            </Text>
          </li>
          <li className="text-[10px]">
            <Text
              variant="semiTransparent"
              className="text-mediumSmall leading-5"
            >
              APR figures are estimates provided for your convenience only, and
              by no means represent guaranteed returns.
            </Text>
          </li>
        </ul>
      </Text>
      <div className="mobile:fixed mobile:bottom-0 mobile:left-0 mobile:w-full mobile:p-3">
        {!isConfirm ? (
          <Button
            className="w-full mobile:h-5"
            disabled={!amountValid}
            onClick={onAmountPick}
          >
            Deposit in Vault
          </Button>
        ) : (
          <Button className="w-full mobile:h-5" onClick={onConfirm}>
            Confirm
          </Button>
        )}
      </div>
    </div>
  );
}
