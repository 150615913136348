import { BigNumber } from 'ethers';
import { Button, Icon, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import useUsdPrices from '@hooks/useUsdPrices';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import {
  formatTonicAirdropClaimAmount,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import { getUnderlyingUsdValue } from '@lib/math';
import { TxStatus } from '@types';

export interface ClaimTonicAirdropModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  // TONIC Amount scaled to 18 decimal places
  amount: string;
  asset: TectonicAsset | null;
  onClaimTonicAirdrop(amount: string, signature: string): void;
  signature?: string;
}

function ClaimTonicAirdropModal({
  amount,
  asset,
  onClaimTonicAirdrop,
  onClose,
  signature,
  transactionErrorMessage,
  transactionExplorerHref,
  ...props
}: ClaimTonicAirdropModalProps): JSX.Element {
  const { usdPrices } = useUsdPrices();
  const tonicUsdPrice = usdPrices['TONIC']
    ? BigNumber.from(usdPrices['TONIC'])
    : null;

  function getParsedAmount(): BigNumber | null {
    // amount can be a decimal for other modals
    try {
      return BigNumber.from(amount);
    } catch (error) {
      return null;
    }
  }

  function renderAmount() {
    if (asset && amount) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text variant="large">
          {formatTonicAirdropClaimAmount(asset, parsedAmount)}
        </Text>
      );
    }

    return <Skeleton />;
  }

  function renderUsdValue() {
    if (asset && amount && tonicUsdPrice) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text className="text-onSurface60" variant="default">
          {formatUserUnderlyingUsdValue(
            asset,
            getUnderlyingUsdValue(asset, parsedAmount, tonicUsdPrice)
          )}
        </Text>
      );
    }

    return <Skeleton />;
  }

  return (
    <BaseTransactionModal
      onClose={onClose}
      renderTitle={(txStatus): string => {
        if (!txStatus || txStatus === TxStatus.pending) {
          return 'Claim TONIC airdrop';
        }

        if (txStatus === TxStatus.awaiting_confirmation) {
          return 'Confirm transaction';
        }

        if (txStatus === TxStatus.confirmed) {
          return 'Success';
        }

        return 'Failed';
      }}
      renderTransactionStatus={(txStatus) => {
        const isTransactionAwaitingConfirmation =
          txStatus === TxStatus.awaiting_confirmation;
        const isTransactionPending = txStatus === TxStatus.pending;
        const isTransactionConfirmed = txStatus === TxStatus.confirmed;
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;
        const showViewInExplorerButton = Boolean(
          (isTransactionPending || isTransactionConfirmed) &&
            transactionExplorerHref
        );

        return (
          <div className="text-center">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionConfirmed && (
              <>
                <Icon.Check className="inline-block h-8 w-8" />
                <Text className="mt-4">
                  Your TONIC airdrop has been deposited to your wallet.
                </Text>
                <Button className="mt-3 w-full" onClick={onClose}>
                  Done
                </Button>
              </>
            )}
            {txStatus === 'failed' && (
              <Text>{transactionErrorMessage || 'Unknown error'}</Text>
            )}
            {showViewInExplorerButton && (
              <div className="pt-1">
                <a
                  className="text-yellowPrimary"
                  href={transactionExplorerHref as string}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View on explorer
                </a>
                <Button className="mt-3 w-full" onClick={onClose}>
                  Done
                </Button>
              </div>
            )}
          </div>
        );
      }}
      transactionErrorMessage={transactionErrorMessage}
      transactionExplorerHref={transactionExplorerHref}
      {...props}
    >
      <div className="my-3 flex flex-col items-center">
        {asset && <SupportedAssetIcon asset={asset} className="mb-3 h-8 w-8" />}
        {renderAmount()}
        {renderUsdValue()}
      </div>
      <Button
        className="w-full"
        onClick={(): void => {
          if (amount && signature) {
            onClaimTonicAirdrop(amount, signature);
          }
        }}
      >
        Confirm
      </Button>
    </BaseTransactionModal>
  );
}

export default ClaimTonicAirdropModal;
