import { BigNumber } from 'ethers';
import { Button, Skeleton, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import useUsdPrices from '@hooks/useUsdPrices';
import {
  formatUserUnderlyingAmount,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import { getUnderlyingUsdValue } from '@lib/math';
import { TxStatus } from '@types';

export interface TonicStakingWithdrawModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  // TONIC Amount scaled up to 18 decimals
  amount: string;
  asset: TectonicAsset | null;
  onWithdraw(): void;
}

function TonicStakingWithdrawModal({
  amount,
  asset,
  onClose,
  onWithdraw,
  transactionErrorMessage,
  ...props
}: TonicStakingWithdrawModalProps): JSX.Element {
  const { usdPrices } = useUsdPrices();
  const tonicUsdPrice = usdPrices['TONIC']
    ? BigNumber.from(usdPrices['TONIC'])
    : null;

  function getParsedAmount(): BigNumber | null {
    // amount can be a decimal for other modals
    try {
      return BigNumber.from(amount);
    } catch (error) {
      return null;
    }
  }

  function renderAmount() {
    if (asset && amount) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text variant="large">
          {formatUserUnderlyingAmount(asset, parsedAmount)}
        </Text>
      );
    }

    return <Skeleton />;
  }

  function renderUsdValue() {
    if (asset && amount && tonicUsdPrice) {
      const parsedAmount = getParsedAmount();

      if (!parsedAmount) {
        return <Skeleton />;
      }

      return (
        <Text className="text-onSurface60" variant="default">
          {formatUserUnderlyingUsdValue(
            asset,
            getUnderlyingUsdValue(asset, parsedAmount, tonicUsdPrice)
          )}
        </Text>
      );
    }

    return <Skeleton />;
  }

  return (
    <BaseTransactionModal
      onClose={onClose}
      renderTitle={(txStatus): string => {
        if (!txStatus || txStatus === TxStatus.pending) {
          return 'Withdraw Unstaked TONIC';
        }

        if (txStatus === TxStatus.awaiting_confirmation) {
          return 'Confirm transaction';
        }

        if (txStatus === TxStatus.confirmed) {
          return 'Success';
        }

        return 'Failed';
      }}
      transactionErrorMessage={transactionErrorMessage}
      {...props}
    >
      <Text variant="default">
        You are about to withdraw the following amount.
      </Text>
      <div className="my-3 flex flex-col items-center">
        {asset && <SupportedAssetIcon asset={asset} className="mb-3 h-8 w-8" />}
        {renderAmount()}
        {renderUsdValue()}
      </div>
      <Button className="w-full" onClick={onWithdraw}>
        Confirm
      </Button>
    </BaseTransactionModal>
  );
}

export default TonicStakingWithdrawModal;
