import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { TectonicAsset, TectonicSDK } from '@tectonicfi/sdk';

export interface TectonicAssetPricer {
  getUsdPrice(asset: TectonicAsset): Promise<BigNumber | null>;
}

function useTectonicAssetPricer(sdk: TectonicSDK): TectonicAssetPricer {
  return useMemo<TectonicAssetPricer>(() => {
    async function getUsdPrice(
      asset: TectonicAsset
    ): Promise<BigNumber | null> {
      return sdk.Oracle.tokenPriceInUsd(asset.tTokenAddress);
    }

    return { getUsdPrice };
  }, [sdk]);
}

export default useTectonicAssetPricer;
