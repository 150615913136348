import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';

import CommunityLists from './CommunityLists';

export default function DesktopFooter() {
  return (
    <div className="mx-auto w-full bg-darkerBlue pb-2  text-center">
      <CommunityLists />
      <div className="border-bottom-1 mx-auto mt-2 w-5/6 border-b-1 border-greyBackground pb-2">
        <a
          href="https://tectonic.gitbook.io/docs/introduction/what-is-tectonic"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Text className="text-vaultChart6Months">Whitepaper</Text>
        </a>
      </div>
      <Icon.PoweredByCronos className="mx-auto mt-3 h-4 w-[114px]" />
    </div>
  );
}
