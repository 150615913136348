import { ReactNode } from 'react';
import clsx from 'clsx';

interface TonicPageCardSectionProps {
  borderBottom?: boolean;
  borderTop?: boolean;
  children: ReactNode;
  className?: string;
}

function TonicPageCardSection({
  borderBottom,
  borderTop,
  children,
  className,
}: TonicPageCardSectionProps): JSX.Element {
  return (
    <div
      className={clsx(
        'py-3 px-2',
        {
          'border-t-1': borderTop,
          'border-b-1': borderBottom,
          'border-onSurface12': borderTop || borderBottom,
          'border-transparent': !borderTop && !borderBottom,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export default TonicPageCardSection;
