import { useMemo } from 'react';

import { NFT } from '../useNFTMeta';

type UseNftSelectionParams = {
  selectedIds: string[];
  userNfts: NFT[];
  stakedNfts: NFT[];
};
export type UseNftSelectionResult = {
  nftToStakeIds: string[];
  nftToUnstakeIds: string[];
  selectedNfts: NFT[];
};
const useNftSelection = ({
  selectedIds,
  userNfts,
  stakedNfts,
}: UseNftSelectionParams): UseNftSelectionResult =>
  useMemo(() => {
    const nftToStakeIds: string[] = [];
    const nftToUnstakeIds: string[] = [];
    const selectedNfts: NFT[] = [];

    let selectedNft: Undefined<NFT>;
    for (const selectedId of selectedIds) {
      if ((selectedNft = userNfts.find(({ id }) => id === selectedId))) {
        // select from wallet
        selectedNfts.push(selectedNft);
        nftToStakeIds.push(selectedId);
      } else if (
        (selectedNft = stakedNfts.find(({ id }) => id === selectedId))
      ) {
        // staked unchanged
        selectedNfts.push(selectedNft);
      }
    }
    // unstake
    for (const { id } of stakedNfts) {
      if (!selectedIds.includes(id)) {
        nftToUnstakeIds.push(id);
      }
    }
    return { nftToStakeIds, nftToUnstakeIds, selectedNfts };
  }, [selectedIds, userNfts, stakedNfts]);

export default useNftSelection;
