import useSdkAndSupportedAssets from './useSdkAndSupportedAssets';
import useAssetTokenUsdPrice from './useAssetTokenUsdPrice';

// TONIC asset only exist in MAIN pool
export default function useTonicUsdPrice() {
  const { list } = useSdkAndSupportedAssets('MAIN');
  const tonicAsset = list.find((asset) => asset.symbol === 'TONIC');
  const { data, isLoading } = useAssetTokenUsdPrice(
    'MAIN',
    tonicAsset?.tTokenAddress
  );

  return { tonicUsdPrice: data, isLoading };
}
