import { Icon } from '@tectonicfi/tectonic-ui-kit';

import X from './Twitter.svg';

function SocialMediaLinks() {
  return (
    <div className="flex flex-row items-center">
      <a
        className="pr-3"
        href="https://twitter.com/TectonicFi"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon.X />
      </a>
      <a
        className="pr-3"
        href="https://t.me/TectonicOfficial"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon.Telegram />
      </a>
      <a
        className="pr-3"
        href="https://discord.gg/jqaxqK7KBR"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon.Discord />
      </a>
      <a
        className="pr-3"
        href="https://medium.com/@0xTectonic"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon.Medium />
      </a>
    </div>
  );
}

export default SocialMediaLinks;
