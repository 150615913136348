import { FC, SVGAttributes } from 'react';
import { Icon } from '@tectonicfi/tectonic-ui-kit';
import { ethers } from 'ethers';

import { RewardTokens } from '@components/MarketsPageView/types';

export const UNLIMITED_ALLOWANCE_AMOUNT =
  ethers.constants.MaxUint256.toString();

export const LIQUIDATION_PENALTY_PERCENT = 10;

export const NORMALIZED_USD_PRICE_DECIMALS = 18;

export const TONIC_DECIMALS = 18;

export const VVS_DECIMALS = 18;

export const X_TONIC_DECIMALS = 18;

export const X_TONIC_TO_TONIC_EXCHANGE_RATE_DECIMALS = 18;

export const LIQUIDITY_CUSHION = 0.9; // 90%

export const INTEREST_RATE_SCALED_DECIMALS = 20;
// Since `INTEREST_RATE_SCALED_DECIMALS` is 20 decimals,
// de-scaling by 18 decimals will yield us percentage format.
export const INTEREST_RATE_DISPLAY_DECIMALS = 18;

export const TOKEN_DISPLAY_DECIMALS: Record<string, number> = {
  USDC: 3,
  USDT: 3,
  DAI: 3,
  VVS: 2,
  CRO: 3,
  WETH: 4,
  WBTC: 4,
  TONIC: 2,
};

export const PARTNER_TOKEN_ICONS: Record<
  RewardTokens,
  FC<SVGAttributes<SVGElement>>
> = {
  MTD: Icon.MTD,
  VVS: Icon.VVS,
  FER: Icon.FER,
  TONIC: Icon.Tonic,
  LCRO: Icon.LCRO,
  LQTY: Icon.VVS_LIGHT,
  ORB: Icon.ORB,
  XARGO: Icon.ARGO,
  STARDUST: Icon.STARDUST,
};

export const CRO_GAS_BUFFER = '2'; // 2 CRO

export const TONIC_SUPPLY_AMOUNT_DECIMALS = 18;

export const PRODUCTION_TONIC_STAKING_WHITELIST_ADDRESS = [];

export const TOKEN_LISTS_ADDRESS: Record<string, string> = {
  TONIC: '0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2',
  USDC: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
};

// NOTE: // this fee is fixed value
export const SWAP_FEE = 0.003;

export const SLIPPAGE_TOLERANCE = [0.001, 0.005, 0.01];

export const BLACKLIST_COLLATERAL_SWAP_TOKENS = ['LATOM'];

export const BLACKLIST_LONG_TOKENS = ['TUSD', 'LATOM'];

export const BLACKLIST_SHORT_TOKENS = ['TUSD', 'CRO', 'LATOM'];

export const VVS_DEX_CONTRACT_ADDRESS = [
  '0x145863Eb42Cf62847A6Ca784e6416C1682b1b2Ae',
];
