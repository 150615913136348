import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import { formatPercent, formatRateToPercent } from '@lib/units';

import { LockingPeriod, LockingPeriodDetails } from './types';
const borderColorSelected = 'border-yellowBorderActive';
const borderColorDefault = 'border-greyBorder';
const names: Record<LockingPeriod, string> = {
  '30s': '30 Seconds',
  '60s': '60 Seconds',
  '120s': '120 Seconds',
  '240s': '240 Seconds',
  '1month': '1 Month',
  '6months': '6 Months',
  '12months': '12 Months',
  '24months': '24 Months',
  '48months': '48 Months',
};
export function getLockPeriodName(period: LockingPeriod) {
  return names[period];
}

export default function LockingPeriodCard({
  multiplier,
  selected,
  apr,
  period,
  disabled,
  className,
  onClick,
}: LockingPeriodDetails & {
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (period: LockingPeriod) => void;
}) {
  const yellowColor = disabled ? 'text-semiTransparent' : 'text-rainbowYellow';
  const whiteColor = disabled ? 'text-semiTransparent' : 'text-white';
  const clickable = !disabled && !selected;
  const handleClick = !clickable ? undefined : () => onClick(period);
  return (
    <div
      onClick={handleClick}
      className={clsx(
        'flex flex-col items-center rounded-2xl border-1 py-2 px-1 ',
        selected ? borderColorSelected : borderColorDefault,
        selected && 'bg-darkerBlue',
        clickable && 'cursor-pointer hover:bg-white hover:bg-opacity-12',
        !clickable && 'cursor-default',
        className
      )}
    >
      <Icon.Vault className={clsx('h-4 w-6', yellowColor)} />
      <Text variant="semiBold" className={clsx('mt-1', yellowColor)}>
        {getLockPeriodName(period)}
      </Text>
      <Text className="mt-1 text-mediumSmall" variant="semiBold">
        <Text variant="semiTransparent" className="mr-[6px]" as="span">
          APR
        </Text>{' '}
        <Text className={clsx('text-mediumSmall', whiteColor)} as="span">
          {formatPercent(formatRateToPercent(apr))}
        </Text>
      </Text>
      <Text className="text-mediumSmall" variant="semiBold">
        <Text variant="semiTransparent" className="mr-1" as="span">
          Multiplier
        </Text>
        <Text className={clsx('text-mediumSmall', whiteColor)} as="span">
          {multiplier}
        </Text>
      </Text>
    </div>
  );
}
