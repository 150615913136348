import { useQueries, useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { PoolType } from '@config/base';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';

export default function useAssetTokenUsdPrice(
  poolType?: PoolType,
  tokenAddress?: string
) {
  const sdk = useTectonicSdk(poolType);

  const query = getQuery(QueryKey.TECTONIC_ASSET_PRICE_IN_USD)(
    sdk,
    tokenAddress ?? ''
  );

  return useQuery(query.queryKey, query.queryFn, {
    enabled: !!tokenAddress && !!sdk,
  });
}

export function useAssetTokenUsdPrices(
  poolType: PoolType,
  tokenAddresses: string[]
) {
  const sdk = useTectonicSdk(poolType);

  const queries = tokenAddresses.map((ta) =>
    getQuery(QueryKey.TECTONIC_ASSET_PRICE_IN_USD)(sdk, ta)
  );

  return useQueries(queries);
}
