import { Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { BigNumber } from 'ethers';
import map from 'lodash/map';
import forIn from 'lodash/forIn';

import TonicPageCardSection from '@components/TonicPageView/TonicPageCardSection';
import useSupportedAsset from '@hooks/useSupportedAsset';
import useUsdPrices from '@hooks/useUsdPrices';
import { getUnderlyingUsdValue } from '@lib/math';
import {
  formatPercent,
  formatRateToPercent,
  formatTotalTonicAmount,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import { UseTransactionModalsStateResult } from '@providers/TransactionModalsProvider/useTransactionModalsState';
import { useVaultPendingTonic } from '@queries/useVaultPendingTonic';
import { LockingPeriod } from '@components/LockDepositModal/types';
import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';

import HarvestVaultEarnings from '../HarvestVaultEarnings';

import VaultsPieChart, { Vault } from './VaultsPieChart';

export interface EarningsBlockProps {
  avgApr: string;
  xTonicStaked: string;
  xTonicUnlocked: string;
  allocations: Undefined<Record<LockingPeriod, number>>;
  hasDeposits: boolean;
  className?: string;
  onOpenModal: UseTransactionModalsStateResult['onOpenModal'];
}
function Ellipse({ className }: { className?: string }) {
  return <span className={clsx('h-[10px] w-[10px] rounded-full', className)} />;
}
export default function EarningsBlock({
  avgApr,
  xTonicStaked,
  xTonicUnlocked,
  hasDeposits,
  allocations,
  className,
  onOpenModal,
}: EarningsBlockProps) {
  const tonicAsset = useSupportedAsset('TONIC');
  const { data: pendingTonic, isLoading } = useVaultPendingTonic();
  const tonicPriceInUsd = useUsdPrices().usdPrices['TONIC'];
  const tonicRewardInUsd =
    !isLoading &&
    pendingTonic &&
    tonicPriceInUsd &&
    tonicAsset &&
    formatUserUnderlyingUsdValue(
      tonicAsset,
      getUnderlyingUsdValue(
        tonicAsset,
        pendingTonic,
        BigNumber.from(tonicPriceInUsd)
      )
    );

  const vaults: Vault[] = [];

  forIn(allocations, (value: number, period: string) => {
    if (value > 0) {
      vaults.push({ period, value });
    }
  });

  return (
    <div
      className={clsx(
        'flex flex-col justify-between border-1 border-yellowBorder bg-darkPrimary mobile:border-none mobile:bg-transparent ',
        className
      )}
    >
      <TonicPageCardSection borderBottom className="mobile:hidden">
        <Text variant="semiBold">Earnings</Text>
      </TonicPageCardSection>
      <div className="px-2 py-4 mobile:px-0 mobile:pt-0">
        <div className="flex items-center justify-between">
          <Text className="text-mediumSmall">AVG Earning APR</Text>
          <Text className="text-mediumSmall" variant="semiBold">
            {avgApr}
          </Text>
        </div>
        <div className="my-1 flex items-center justify-between">
          <Text className="text-mediumSmall">xTONIC locked in Vaults</Text>
          <Text className="text-mediumSmall" variant="semiBold">
            {xTonicStaked}
          </Text>
        </div>
        <div className="flex items-center justify-between">
          <Text className="text-mediumSmall">xTONIC Unlocked</Text>
          <Text className="text-mediumSmall" variant="semiBold">
            {xTonicUnlocked}
          </Text>
        </div>
        {hasDeposits && (
          <div className="mt-9 flex items-start justify-between">
            <div className="space-y-2">
              <Text className="mb-1 text-mediumSmall">Allocation:</Text>
              {map(allocations, (value: number, period: LockingPeriod) => {
                if (!value) return null;

                return (
                  <div key={period} className="flex items-center">
                    <Ellipse
                      className={clsx('mr-1', {
                        'bg-vaultChart6Months':
                          period === '6months' || period === '30s',
                        'bg-vaultChart12Months':
                          period === '12months' || period === '60s',
                        'bg-vaultChart24Month':
                          period === '24months' || period === '120s',
                        'bg-vaultChart48Months':
                          period === '48months' || period === '240s',
                      })}
                    />
                    <Text className="text-mediumSmall" as="span">
                      {getLockPeriodName(period)}&nbsp;
                    </Text>
                    <Text
                      className="text-mediumSmall"
                      as="span"
                      variant="semiBold"
                    >
                      {formatPercent(formatRateToPercent(value), 0)}
                    </Text>
                  </div>
                );
              })}
            </div>
            <div className="ml-3 max-w-[280px] grow">
              <VaultsPieChart vaults={vaults} />
            </div>
          </div>
        )}
      </div>
      <div className="hidden mobile:flex mobile:min-h-[200px]"></div>
      <div className="mt-auto border-t-1 border-yellowBorder mobile:fixed mobile:bottom-0 mobile:right-0 mobile:left-0 mobile:h-[200px] mobile:w-full mobile:bg-background mobile:p-0">
        <HarvestVaultEarnings
          tonicRewards={
            (!isLoading &&
              pendingTonic &&
              formatTotalTonicAmount(pendingTonic)) ||
            '-'
          }
          tonicRewardsInUsd={tonicRewardInUsd || '-'}
          hasRewards={!!pendingTonic && pendingTonic.gt(0)}
          onHarvest={() =>
            tonicAsset &&
            pendingTonic &&
            onOpenModal(tonicAsset, 'harvestVaultTonic', {
              meta: {
                type: 'harvestVaultTonic',
                tonicAmount: pendingTonic,
              },
            })
          }
        />
      </div>
    </div>
  );
}
