import { useQuery } from 'react-query';

import { ChainId } from '@config/baseNetworkConfig';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';

const wrappedCurrency = (currency: string) => {
  return currency === 'BTC' || currency === 'ETH' ? `W${currency}` : currency;
};

const useWowmaxSwapTrade = (
  inputValue: string,
  slippageAmount: string,
  chainId: ChainId,
  toCurrency: string,
  fromCurrency: string
) => {
  const query = getQuery(QueryKey.WOWMAX_SWAP_TRADE_DATA)(
    inputValue,
    wrappedCurrency(fromCurrency),
    slippageAmount,
    chainId,
    wrappedCurrency(toCurrency)
  );

  const data = useQuery(
    [
      query.queryKey,
      inputValue,
      wrappedCurrency(fromCurrency),
      slippageAmount,
      chainId,
      wrappedCurrency(toCurrency),
    ],
    query.queryFn,
    {
      enabled: Boolean(
        fromCurrency && Number(inputValue) > 0 && slippageAmount && toCurrency
      ),
    }
  );
  if (!inputValue || !fromCurrency || !slippageAmount || !toCurrency) {
    return null;
  }
  return data;
};

export default useWowmaxSwapTrade;
