import { ReactNode } from 'react';
import {
  Icon,
  Button,
  Modal,
  ModalProps,
  Text,
} from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import { TxStatus } from '@types';

function getDefaultTitle(title?: string, txStatus?: TxStatus | null): string {
  if (txStatus) {
    if (txStatus === TxStatus.failed) {
      return 'Transaction failed';
    }

    if (txStatus === TxStatus.confirmed) {
      return 'Transaction confirmed';
    }

    if (txStatus === TxStatus.awaiting_confirmation) {
      return 'Confirm transaction';
    }

    return 'Transaction pending';
  }

  return title || '';
}

export interface BaseTransactionModalProps extends Omit<ModalProps, 'title'> {
  renderTitle?(transactionStatus?: TxStatus | null): string;
  renderTransactionStatus?(transactionStatus: TxStatus): ReactNode | null;
  title?: string;
  transactionErrorMessage?: string | null;
  transactionExplorerHref?: string | null;
  transactionStatus?: TxStatus | null;
  className?: string;
  isDrawer?: boolean;
}

function BaseTransactionModal({
  children,
  isOpen,
  onClose,
  renderTitle: renderTitleProps,
  renderTransactionStatus,
  title,
  transactionErrorMessage,
  transactionExplorerHref,
  transactionStatus,
  className,
  isDrawer,
  ...props
}: BaseTransactionModalProps): JSX.Element {
  const isTransactionAwaitingConfirmation =
    transactionStatus === 'awaiting_confirmation';
  const isTransactionPending = transactionStatus === 'pending';
  const isTransactionConfirmed = transactionStatus === 'confirmed';
  const showSpinner = isTransactionAwaitingConfirmation || isTransactionPending;
  const showViewInExplorerButton = Boolean(
    (isTransactionPending || isTransactionConfirmed) && transactionExplorerHref
  );

  function renderChildren(): ReactNode | null {
    if (!transactionStatus) {
      return children;
    }

    if (renderTransactionStatus) {
      return renderTransactionStatus(transactionStatus);
    }

    return (
      <div className="text-center text-onSurface">
        {showSpinner && (
          <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
        )}
        {isTransactionAwaitingConfirmation && (
          <Text className="mt-4">Confirm the transaction in your wallet</Text>
        )}
        {isTransactionPending && (
          <Text className="mt-4">Transaction broadcast</Text>
        )}
        {isTransactionConfirmed && (
          <Icon.Check className="inline-block h-8 w-8" />
        )}
        {transactionStatus === 'failed' && (
          <Text>{transactionErrorMessage || 'Unknown error'}</Text>
        )}
        {showViewInExplorerButton && (
          <div className="pt-1">
            <a
              className="text-yellowPrimary"
              href={transactionExplorerHref as string}
              rel="noopener noreferrer"
              target="_blank"
            >
              View on explorer
            </a>
            <Button className="mt-3 w-full" onClick={onClose}>
              Done
            </Button>
          </div>
        )}
      </div>
    );
  }

  function renderTitle(): string {
    if (renderTitleProps) {
      return renderTitleProps(transactionStatus);
    }

    return getDefaultTitle(title, transactionStatus);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={renderTitle()}
      className={clsx(
        {
          'mobile:fixed mobile:left-0 mobile:right-0 mobile:top-0 mobile:bottom-0 mobile:h-screen mobile:!max-h-screen mobile:w-screen':
            isDrawer,
        },
        className
      )}
      {...props}
    >
      {renderChildren()}
    </Modal>
  );
}

export default BaseTransactionModal;
