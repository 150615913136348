import { Icon, Text } from '@tectonicfi/tectonic-ui-kit';

interface Props {
  unclaimValueAmount: string;
  totalBalanceToUsdValue: string;
  unclaimBoostValueAmount: string;
  boostTonicToUsdValue: string;
}

export default function TonicSummary({
  unclaimValueAmount,
  totalBalanceToUsdValue,
  unclaimBoostValueAmount,
  boostTonicToUsdValue,
}: Props) {
  return (
    <div className="text-center">
      <Icon.Tonic className="mx-auto mb-3 h-8 w-8" />

      <div className="flex justify-evenly">
        <div>
          <Text className="text-yellowPrimary">TONIC Rewards</Text>
          <div className="font-normal text-white">{unclaimValueAmount}</div>
          <div className="mt-[4px] mb-3 text-white60 mobile:mb-4">
            {totalBalanceToUsdValue} USD
          </div>
        </div>
        <div>
          <Text className="text-yellowPrimary">Boost Rewards</Text>
          <div className="font-normal text-white">
            {unclaimBoostValueAmount}
          </div>
          <div className="mt-[4px] mb-3 text-white60 mobile:mb-4">
            {boostTonicToUsdValue} USD
          </div>
        </div>
      </div>
    </div>
  );
}
