import { LavaBar, Tooltip, Text } from '@tectonicfi/tectonic-ui-kit';

interface BorrowLimitLavaBarProps {
  showMessage?: boolean;
  value: number;
}

function BorrowLimitLavaBar({
  showMessage = true,
  value,
}: BorrowLimitLavaBarProps): JSX.Element {
  const isDangerous = value > 90;
  const showLiquidationMessage = showMessage && isDangerous;

  return (
    <>
      <Tooltip message="This lava bar tells you your account health. If it's full, you're at risk of being liquidated. Repay your loans or add more collateral to lower it.">
        <LavaBar
          theme={isDangerous ? 'liquidation' : 'lava'}
          showValue={true}
          value={value}
        />
      </Tooltip>
      {showLiquidationMessage && (
        <Text className="text-onSurface60" variant="small">
          To prevent instant liquidation, the maximum amount that you can borrow
          or withdraw is further reduced by 10% from the collateral factor. Find
          out more{' '}
          <a
            className="underline"
            href="https://tectonic.gitbook.io/docs/guides/liquidations"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Text>
      )}
    </>
  );
}

export default BorrowLimitLavaBar;
