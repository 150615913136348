import { Icon, RangSlider } from '@tectonicfi/tectonic-ui-kit';

import TonicSummary from './TonicSummary';

interface Props {
  lockedPercent: number;
  lockedToValueAmount: string;
  walletValueAmount: string;
  unclaimValueAmount: string;
  totalBalanceToUsdValue: string;
  unclaimBoostValueAmount: string;
  boostTonicToUsdValue: string;
  lockedToUsdValue: string;
  walletToUsdValue: string;
  setLockedPercent: (lockedPercent: number) => void;
}

export const MIN_LOCKED = 60;

export default function SelectLocked({
  lockedToValueAmount,
  walletValueAmount,
  unclaimValueAmount,
  lockedPercent,
  setLockedPercent,
  totalBalanceToUsdValue,
  lockedToUsdValue,
  walletToUsdValue,
  unclaimBoostValueAmount,
  boostTonicToUsdValue,
}: Props) {
  return (
    <div>
      <TonicSummary
        totalBalanceToUsdValue={totalBalanceToUsdValue}
        unclaimValueAmount={unclaimValueAmount}
        unclaimBoostValueAmount={unclaimBoostValueAmount}
        boostTonicToUsdValue={boostTonicToUsdValue}
      />

      <div className="mb-2 text-left text-mediumSmall font-normal leading-[21px] text-white mobile:mb-3">
        NOTE: When you initiate this claim, {MIN_LOCKED}% of your TONIC will be
        automatically locked in a vault of your choice. You can redeem the rest
        or lock it up for a higher APR.{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://tectonic.gitbook.io/docs/guides/claiming-tonic-rewards"
        >
          <span className="font-semibold text-yellowPrimary">Learn More</span>
        </a>
      </div>

      <div className="mb-4 border-1 border-solid border-greyBackground bg-darkerBlue p-2 text-small font-medium text-white">
        <div>How much do you want to lock? (Minimum is {MIN_LOCKED}%.)</div>

        <div className="pt-4">
          <RangSlider
            min={MIN_LOCKED}
            step={1}
            value={lockedPercent}
            onChange={setLockedPercent}
            labelOffset={2.5}
          />
        </div>

        <div className="text-body font-medium mobile:text-mediumSmall mobile:font-normal">
          <div className="mt-[28px] flex w-full justify-between border-b-1 border-solid border-greyBackground pb-2">
            <div className="flex flex-col gap-y-[4px]">
              <span>Lock in vault</span>
              <span>{lockedPercent}%</span>
            </div>
            <span className="flex flex-col items-end gap-[6px]">
              <div className="flex gap-1">
                <span className="font-semibold">{lockedToValueAmount}</span>
                <Icon.Tonic className="h-[18px] w-[18px]" />
              </div>

              <div className="text-mediumSmall font-normal text-white60">
                {lockedToUsdValue}
                USD
              </div>
            </span>
          </div>

          <div className="mt-[28px] flex  w-full justify-between">
            <div className="flex flex-col gap-y-[4px]">
              <span>Claimed immediately</span>
              <span>{100 - lockedPercent}%</span>
            </div>
            <span className="flex flex-col items-end gap-[6px]">
              <div className="flex gap-1">
                <span className="font-semibold">{walletValueAmount}</span>
                <Icon.Tonic className="h-[18px] w-[18px]" />
              </div>

              <div className="text-mediumSmall font-normal text-white60">
                {walletToUsdValue} USD
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
