import { useContext } from 'react';
import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { TectonicSdkContext } from '@providers/TectonicSdkProvider';
import { getQuery } from '@queries/queries';

import useWallets from './useWallets';

export default function useUnclaimBoostTonic() {
  const { currentAccount } = useWallets();
  const sdk = useContext(TectonicSdkContext);

  const query = getQuery(QueryKey.GET_UNCLAIM_TONIC_REWARDS)(
    sdk,
    currentAccount ?? ''
  );

  return useQuery(query.queryKey, query.queryFn, {
    enabled: !!sdk && !!currentAccount,
    cacheTime: 0,
  });
}
