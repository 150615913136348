import { PoolType, getTectonicSocketAddress } from '@config/base';

export default function useSocketAddress(mode: PoolType): string {
  const socketAddress = getTectonicSocketAddress();

  switch (mode) {
    case 'MAIN':
      return socketAddress[0];

    case 'DEFI':
      return socketAddress[1];

    case 'VENO':
      return socketAddress[2];

    default:
      return socketAddress[0];
  }
}
