import { TectonicAsset } from '@tectonicfi/sdk';

export function reduceToMap<T>(
  values: T[],
  assets: TectonicAsset[]
): { [key: string]: T } {
  return values.reduce((previous, v, index) => {
    const asset = assets[index];
    return { ...previous, [asset.symbol]: v };
  }, {});
}
