import { Dropdown, Icon, Modal, Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { isAfter } from 'date-fns';
import { BigNumber } from 'ethers';
import { useMemo, useState } from 'react';

import DropdownOption from '@components/DropdownOption';
import useIsMobile from '@hooks/useIsMobile';
import { withHook } from '@hooks/withHook';
import { formatLockCountdown } from '@lib/dates';
import {
  byHighToLow,
  byHighToLowBN,
  byLowToHigh,
  byLowToHighBN,
  SortFunction,
} from '@lib/sort';
import { formatXTonic } from '@lib/units';
export type DepositsSort =
  | 'date-asc'
  | 'date-desc'
  | 'xtonic-asc'
  | 'xtonic-desc';
const sortTitles: Record<DepositsSort, string> = {
  'date-asc': 'Lock date ending soon',
  'date-desc': 'Recently locked',
  'xtonic-asc': 'xTONIC amount: low to high',
  'xtonic-desc': 'xTONIC amount: high to low',
};
export interface YourDeposit {
  stakeId: number;
  xTonicAmount: string;
  unlocked: boolean;
  lockCountdown: string;
}
export interface YourDepositsModalProps {
  /** @description List of sorted vaults for given page. */
  deposits: YourDeposit[];
  sort: DepositsSort;
  /** @description Index starting from 1 */
  page: number;
  totalPages: number;
  totalLocked: string;
  totalDeposits: number;
  onSortChange: (sort: DepositsSort) => void;
  onPageChange: (page: number) => void;
  onClose: () => void;
}

export function YourDepositsModalUi({
  deposits,
  sort,
  page,
  totalPages,
  totalLocked,
  totalDeposits,
  onSortChange,
  onPageChange,
  onClose,
}: YourDepositsModalProps) {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Your deposits"
      variant="vault"
    >
      <div className="min-h-[593px] overflow-hidden pb-1 mobile:min-h-[549px]">
        <div className="mb-2 flex justify-between">
          <Text className="text-mediumSmall">Total locked</Text>
          <Text variant="semiBold" className="text-mediumSmall">
            {totalLocked}
          </Text>
        </div>
        <div className="mb-3 flex justify-between">
          <Text className="text-mediumSmall">Number of deposits</Text>
          <Text variant="semiBold" className="text-mediumSmall">
            {totalDeposits}
          </Text>
        </div>
        <div className="mb-3 flex items-center">
          <div className="mr-[6px] text-[16px] font-medium text-white mobile:text-[14px] mobile:font-normal">
            Sort by:
          </div>
          <Dropdown
            value={sort}
            displayValue={
              <span className="text-[12px] font-semibold">
                {sortTitles[sort]}
              </span>
            }
            onChange={onSortChange as (x: string) => void}
          >
            {Object.entries(sortTitles).map(([sortKey, title], i) => (
              <DropdownOption
                key={sortKey}
                value={sortKey}
                className={clsx(i > 0 && 'border-t-[1px] border-greyBorder')}
              >
                {title}
              </DropdownOption>
            ))}
          </Dropdown>
        </div>
        <div className="grid grid-cols-4 gap-1 mobile:grid-cols-2">
          {deposits.map((d) => (
            <YourDepositCard key={d.stakeId} {...d} />
          ))}
        </div>
        {totalPages > 1 && (
          <div className="mt-4 flex items-center justify-center">
            <button
              disabled={page <= 1}
              title="Previous page"
              onClick={() => {
                if (page <= 1) {
                  return;
                }
                onPageChange(page - 1);
              }}
            >
              <Icon.Arrow
                className={clsx(page <= 1 ? 'text-greyBorder' : 'text-white')}
                width={24}
                height={24}
              />
            </button>
            <Text className="mx-1">
              Page {page} of {totalPages}
            </Text>
            <button
              disabled={page >= totalPages}
              title="Next page"
              onClick={() => {
                if (page >= totalPages) {
                  return;
                }
                onPageChange(page + 1);
              }}
            >
              <Icon.Arrow
                className={clsx(
                  'rotate-180',
                  page >= totalPages ? 'text-greyBorder' : 'text-white'
                )}
                width={24}
                height={24}
              />
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
export function YourDepositCard({
  xTonicAmount,
  unlocked,
  lockCountdown,
}: YourDeposit) {
  return (
    <div
      className={clsx(
        'relative flex flex-col items-center rounded-[15px] border-1 border-solid border-transparent bg-blueElevatedSurface bg-clip-padding py-2',
        "before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0 before:z-[-1] before:m-[-1px] before:rounded-[15px]  before:content-['']",
        unlocked
          ? 'before:bg-gradient-to-r before:from-highlightBorderColorFrom before:to-highlightBorderColorTo'
          : 'before:bg-greyBorder'
      )}
    >
      <Text className="text-small font-semibold">{xTonicAmount}</Text>
      <Text className="text-small text-white60">xTONIC</Text>
      <Text className="mt-1 text-small font-semibold">
        {unlocked ? 'Unlocked' : lockCountdown}
      </Text>
    </div>
  );
}

export interface Deposit {
  id: number;
  unlockDate: Date;
  xTonicAmount: BigNumber;
}

function mapToYourDeposit(deposit: Deposit): YourDeposit {
  return {
    stakeId: deposit.id,
    lockCountdown: formatLockCountdown(deposit.unlockDate),
    xTonicAmount: formatXTonic(deposit.xTonicAmount),
    unlocked: isAfter(new Date(), deposit.unlockDate),
  };
}
export function getYourDeposits(
  deposits: Deposit[],
  sort: DepositsSort,
  // starting from 1
  page: number,
  pageSize = 16
) {
  // sort
  const sorted = deposits.sort(sortMapping[sort]);
  // slice
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  const sliced = sorted.slice(start, end);
  // map
  const mapped = sliced.map(mapToYourDeposit);
  return mapped;
}

const sortMapping: Record<DepositsSort, SortFunction<Deposit>> = {
  'date-asc': byLowToHigh((d) => d.unlockDate.getTime()),
  'date-desc': byHighToLow((d) => d.unlockDate.getTime()),
  'xtonic-asc': byLowToHighBN((d) => d.xTonicAmount),
  'xtonic-desc': byHighToLowBN((d) => d.xTonicAmount),
};

export default withHook(({ deposits: _deposits }: { deposits: Deposit[] }) => {
  const [sort, setSort] = useState<DepositsSort>('date-asc');
  const [page, setPage] = useState(1);
  const isMobile = useIsMobile();
  const totalPages = 1 + Math.floor(_deposits.length / (isMobile ? 6 : 16));
  const deposits = useMemo(() => {
    return getYourDeposits(_deposits, sort, page, isMobile ? 6 : 16);
  }, [_deposits, sort, page, isMobile]);
  const totalDeposits = _deposits.length;
  const totalLocked = useMemo(
    () =>
      formatXTonic(
        _deposits.reduce((sum, d) => sum.add(d.xTonicAmount), BigNumber.from(0))
      ),
    [_deposits]
  );
  return {
    deposits,
    totalDeposits,
    totalLocked,
    page,
    sort,
    totalPages,
    // Note that we do not do any validation here, caller is responsible to ensure no invalid page is set
    onPageChange: setPage,
    onSortChange: setSort,
  };
})(YourDepositsModalUi);
