import { Dispatch, SetStateAction, useMemo } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { BigNumber } from 'ethers';
import { AssetIcon, Icon, Text } from '@tectonicfi/tectonic-ui-kit';

import {
  formatPercent,
  formatUserUnderlyingAmountWithDust,
  formatUserUnderlyingUsdValue,
} from '@lib/units';
import { getUnderlyingUsdValue } from '@lib/math';
import useGetXTonicBoostDailyDistributeRate, {
  BoostType,
} from '@hooks/useGetXTonicBoostDailyDistributeRate';
import useSupplyApy from '@hooks/useSupplyApy';
import useBorrowApy from '@hooks/useBorrowApy';
import getAssetLogoUri from '@components/SupportedAssetIcon/getAssetLogoUri';

import { MarketsData } from './useAllMarketsData';
import { SelectedMarketProps } from './BoostModal';
import { useXTonicBoostAPY } from './XTonicBoostingHooks';

interface AssetDetailsProps extends MarketsData {
  setSelectedMarket?: Dispatch<
    SetStateAction<Undefined<SelectedMarketProps[]>>
  >;
  selectedMarket?: SelectedMarketProps[];
  boostType: BoostType;
  isOpen: boolean;
  isSelectionHidden?: boolean;
  apyColor?: boolean;
}

const AssetDetails = ({
  pool,
  asset,
  amount,
  usdPrice,
  setSelectedMarket,
  selectedMarket,
  boostType,
  isSelectionHidden,
  apyColor,
}: AssetDetailsProps) => {
  const { data } = useGetXTonicBoostDailyDistributeRate(
    asset?.tTokenAddress,
    boostType
  );

  const { tonicSupplyApy } = useSupplyApy(pool, asset, {
    skip: boostType !== BoostType.SUPPLY,
  });
  const { tonicBorrowApy } = useBorrowApy(pool, asset, {
    skip: boostType !== BoostType.BORROW,
  });

  const boostAPY = useXTonicBoostAPY(asset.tTokenAddress, boostType, pool);

  const SelectedAsset = useMemo(() => {
    return selectedMarket?.some((item) => isEqual(item.marketDetails, asset));
  }, [selectedMarket, asset]);

  const handleClick = () => {
    if (selectedMarket && setSelectedMarket) {
      if (SelectedAsset) {
        const duplicateSelectedMarket = [...selectedMarket];
        const index = selectedMarket.findIndex((item) =>
          isEqual(item.marketDetails, asset)
        );
        duplicateSelectedMarket.splice(index, 1);
        setSelectedMarket(duplicateSelectedMarket);
      } else {
        setSelectedMarket([
          ...selectedMarket,
          {
            marketDetails: asset,
            boostType: boostType,
          },
        ]);
      }
    } else {
      setSelectedMarket &&
        setSelectedMarket([{ marketDetails: asset, boostType: boostType }]);
    }
  };

  const getLeftBoostedAPY = (apy: number) => {
    return apyColor
      ? formatPercent((apy + boostAPY) * 100)
      : formatPercent(apy * 100);
  };

  const getRightBoostedAPY = (apy: number) => {
    return apyColor
      ? formatPercent(apy * 100)
      : formatPercent((apy + boostAPY) * 100);
  };

  return (
    <div className="p-1.5">
      <div className="flex items-center justify-between">
        <div>
          <div className="flex p-0.5">
            <AssetIcon
              name={asset?.name}
              src={getAssetLogoUri(asset?.symbol) ?? ''}
            />
            <Text className="mx-1">{asset?.symbol}</Text>
          </div>
          <Text className="p-0.5">
            {boostType === BoostType.BORROW
              ? 'Borrow balance'
              : 'Supply balance'}
          </Text>
          <Text className="px-0.5 pt-1.5">Final estimate</Text>
        </div>
        <div className="flex items-center">
          <div>
            <Text className="py-0.5 text-right capitalize">
              {pool.toLowerCase()} Pool
            </Text>
            <Text className="text-right">
              {formatUserUnderlyingAmountWithDust(asset, amount)}
            </Text>
            <Text className="text-right text-small text-onSurface60">
              {formatUserUnderlyingUsdValue(
                asset,
                getUnderlyingUsdValue(asset, amount, BigNumber.from(usdPrice))
              )}
            </Text>
            <div className="mt-1 flex justify-end">
              <Icon.Tonic width={16} height={16} />
              <Text className="ml-1 text-small">
                {boostType === BoostType.BORROW
                  ? getLeftBoostedAPY(tonicBorrowApy)
                  : getLeftBoostedAPY(tonicSupplyApy)}
              </Text>

              {!data?.isZero() && (
                <>
                  <Icon.Arrow
                    width={16}
                    height={16}
                    className={clsx('rotate-180 text-vaultChart12Months', {
                      'text-vaultChart24Month': apyColor,
                    })}
                  />
                  <Text
                    className={clsx('text-small text-vaultChart12Months', {
                      'text-vaultChart24Month': apyColor,
                    })}
                  >
                    {boostType === BoostType.BORROW
                      ? getRightBoostedAPY(tonicBorrowApy)
                      : getRightBoostedAPY(tonicSupplyApy)}
                  </Text>
                </>
              )}
            </div>
          </div>
          {!isSelectionHidden && (
            <>
              {SelectedAsset ? (
                <Icon.Checked
                  width={18}
                  height={18}
                  className={clsx('mx-2 cursor-pointer', {
                    invisible: data?.isZero() || amount.isZero(),
                  })}
                  onClick={handleClick}
                />
              ) : (
                <Icon.UnChecked
                  width={18}
                  height={18}
                  className={clsx('mx-2 cursor-pointer', {
                    invisible: data?.isZero() || amount.isZero(),
                  })}
                  onClick={handleClick}
                />
              )}
            </>
          )}
        </div>
      </div>
      {(data?.isZero() || amount.isZero()) && (
        <Text variant="small" className="p-0.5 text-rainbowRed">
          This market is not being incentivised with TONIC. Apply your boost to
          another market to make full use of it.
        </Text>
      )}
    </div>
  );
};

export default AssetDetails;
