import { Icon, Skeleton, Text, Tooltip } from '@tectonicfi/tectonic-ui-kit';

import { PoolOfUser } from '@components/VaultsPageView/VaultsBlock/types';
import { formatPercent, formatRateToPercent } from '@lib/units';

import NFTImageCard, { MAX_STAKING_NFT } from '../NFTImageCard';
import { NFT } from '../useNFTMeta';
import BoostedValue from '../BoostedValue';
import { CtaType } from '../StakingNFTModal/util';

type Props = {
  selectedNfts: NFT[];
  pool?: PoolOfUser;
  estimatedNftBoostMultiplier?: number;
  isEstimatedNftBoostMultiplierLoading?: boolean;
  ctaType: Null<CtaType>;
};
export default function StakeNow({
  selectedNfts,
  pool,
  isEstimatedNftBoostMultiplierLoading,
  estimatedNftBoostMultiplier,
  ctaType,
}: Props) {
  const selectedIds = selectedNfts.map(({ id }) => id);
  return (
    <>
      <Text variant="default" className="mb-3 font-medium">
        NFTs selected
      </Text>
      <div className="flex flex-row flex-wrap justify-center gap-[20px] px-6">
        {selectedNfts.map((nft) => (
          <NFTImageCard key={nft.id} meta={nft} selectedIds={selectedIds} />
        ))}
      </div>

      <div className="mt-3 mb-1 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div>No. of NFT(s) selected</div>

        <span className="font-semibold">
          {selectedNfts.length} / {MAX_STAKING_NFT}
        </span>
      </div>

      <div className="mb-1 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">
          Boosted APR{' '}
          <Tooltip message="Determined by the final multiplier, which is the product of the vault's base and the selected NFTs' multipliers">
            <Icon.Question className="ml-0.5 inline h-3 w-3 align-top" />
          </Tooltip>
        </div>

        {pool ? (
          <BoostedValue
            value={formatPercent(formatRateToPercent(pool.apy))}
            loading={
              selectedIds.length > 0 && isEstimatedNftBoostMultiplierLoading
            }
            boostedValue={
              selectedIds.length > 0 &&
              !isEstimatedNftBoostMultiplierLoading &&
              estimatedNftBoostMultiplier
                ? formatPercent(
                    formatRateToPercent(estimatedNftBoostMultiplier * pool.apy)
                  )
                : undefined
            }
          />
        ) : (
          <Skeleton className="w-6" />
        )}
      </div>

      <div className="mb-2 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">
          Multiplier{' '}
          <Tooltip message="The product of the vault's base and the selected NFTs' multipliers">
            <Icon.Question className="ml-0.5 inline h-3 w-3 align-top" />
          </Tooltip>
        </div>

        {pool ? (
          <BoostedValue
            value={`${pool.multiplier}x`}
            loading={
              selectedIds.length > 0 && isEstimatedNftBoostMultiplierLoading
            }
            boostedValue={
              selectedIds.length > 0 &&
              !isEstimatedNftBoostMultiplierLoading &&
              estimatedNftBoostMultiplier
                ? `${estimatedNftBoostMultiplier * pool.multiplier}x`
                : undefined
            }
          />
        ) : (
          <Skeleton className="w-6" />
        )}
      </div>
      {(ctaType === 'STAKE' || ctaType === 'REPLACE') && (
        <Text className="text-[11px]">
          While your NFT is staked, you will not be able to sell or utilise the
          same NFT in any other features or platforms. You can, however, unstake
          your NFT at your convenience, including before the vault&apos;s lock
          end date. Unstaking your NFT will result in the immediate termination
          of your multiplier benefit.
        </Text>
      )}
    </>
  );
}
