import {
  useState,
  memo,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  ReactNode,
} from 'react';
import {
  Button,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '@tectonicfi/tectonic-ui-kit';
import { BigNumber } from 'ethers';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import { useVaultsData } from '@components/VaultsPageView/VaultsAndEarningsBlock';
import useIsMobile from '@hooks/useIsMobile';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { formatPercent, formatRateToPercent } from '@lib/units';
import { useEstimatedNftBoostMultiplier } from '@hooks/useStakeNFT';
import { TxStatus } from '@types';

import { SelectStakingNFT, StakeNow } from '../index';
import useNFT from '../useNFT';
import BoostedValue from '../BoostedValue';

import useNftSelection from './useNftSelection';
import {
  getCtaType,
  CtaType,
  getRenderTitle,
  getRenderTransactionStatus,
  StakingFlow,
} from './util';

type Props = BaseTransactionModalProps & {
  poolId?: number;
  onStakeUnstakeNFTs: (
    nftToStakeIds: BigNumber[],
    nftToUnstakeIds: BigNumber[],
    poolId: number
  ) => Promise<string | undefined>;
  onEnableNFTs: () => Promise<void>;
};

function StakingNFTModal({
  poolId,
  onClose,
  onStakeUnstakeNFTs,
  onEnableNFTs,
  isOpen,
  transactionErrorMessage,
  transactionStatus,
  afterLeave,
  ...props
}: Props): JSX.Element {
  const sdk = useTectonicSdk();
  const { data, loaded } = useVaultsData();
  const pool = data?.poolsOfUser.find((p) => p.poolId === poolId);
  const [stakeFlow, setStakeFlow] = useState<StakingFlow>('SELECT');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isMobile = useIsMobile();

  const { loading, userNfts, stakedNfts, refetchNfts } = useNFT(poolId);

  // initialization
  useEffect(() => {
    if (isOpen) setSelectedIds(stakedNfts.map(({ id }) => id));
  }, [isOpen, stakedNfts]);

  useEffect(() => {
    if (transactionStatus === TxStatus.confirmed) {
      refetchNfts();
    }
  }, [transactionStatus, refetchNfts]);

  const hasStakedNFTs = stakedNfts.length > 0;
  const hasBoostNFTs = userNfts.length > 0 || hasStakedNFTs;

  const { nftToStakeIds, nftToUnstakeIds, selectedNfts } = useNftSelection({
    selectedIds,
    userNfts,
    stakedNfts,
  });

  const {
    data: estimatedNftBoostMultiplier,
    isLoading: isEstimatedNftBoostMultiplierLoading,
  } = useEstimatedNftBoostMultiplier(
    sdk,
    nftToStakeIds.map((id) => BigNumber.from(id)),
    nftToUnstakeIds.map((id) => BigNumber.from(id)),
    poolId
  );

  const handleClose = useCallback(() => {
    setStakeFlow('SELECT');
    setSelectedIds([]);
    onClose();
  }, [onClose]);

  const ctaTypeRef = useRef<Null<CtaType>>(null);

  const handleStake = useCallback(async () => {
    if (stakeFlow === 'SELECT') {
      ctaTypeRef.current = getCtaType(nftToStakeIds, nftToUnstakeIds);
      return setStakeFlow('STAKE');
    } else {
      if (poolId !== undefined) {
        if (nftToStakeIds.length === 0 && nftToUnstakeIds.length === 0) {
          return;
        }
        await onEnableNFTs();

        onStakeUnstakeNFTs(
          nftToStakeIds.map((id) => BigNumber.from(id)),
          nftToUnstakeIds.map((id) => BigNumber.from(id)),
          poolId
        );
      }
    }
  }, [
    stakeFlow,
    onStakeUnstakeNFTs,
    poolId,
    nftToStakeIds,
    nftToUnstakeIds,
    onEnableNFTs,
  ]);

  const handleAfterLeave = useCallback(() => {
    if (afterLeave) afterLeave();
    ctaTypeRef.current = null;
  }, [afterLeave]);

  const renderTitle = useMemo(
    () => getRenderTitle(stakeFlow, hasStakedNFTs),
    [stakeFlow, hasStakedNFTs]
  );

  const renderTransactionStatus = useMemo(
    () =>
      getRenderTransactionStatus(transactionErrorMessage, handleClose, pool),
    [pool, transactionErrorMessage, handleClose]
  );

  return (
    <BaseTransactionModal
      className="h-[666px] w-[510px] overflow-hidden p-3 pr-1.5 desktop:relative"
      isDrawer={hasBoostNFTs}
      variant="wallet"
      isOpen={isOpen}
      isHideCloseIcon={isMobile}
      isShowMobileSliderClose={isMobile}
      onClose={handleClose}
      onBack={
        stakeFlow === 'STAKE' && transactionStatus !== TxStatus.confirmed
          ? () => setStakeFlow('SELECT')
          : undefined
      }
      transactionStatus={transactionStatus}
      renderTitle={(txStatus: TxStatus): string =>
        renderTitle(txStatus, ctaTypeRef.current)
      }
      renderTransactionStatus={(txStatus: TxStatus): ReactNode =>
        renderTransactionStatus(txStatus, ctaTypeRef.current)
      }
      afterLeave={handleAfterLeave}
      {...props}
    >
      <div className="h-[450px] overflow-y-auto pr-1.5 mobile:min-h-screen mobile:pb-[230px]">
        {stakeFlow === 'SELECT' && !loading && (
          <SelectStakingNFT
            userNfts={userNfts}
            stakedNfts={stakedNfts}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            pool={pool}
          />
        )}
        {stakeFlow === 'STAKE' && (
          <StakeNow
            selectedNfts={selectedNfts}
            pool={pool}
            isEstimatedNftBoostMultiplierLoading={
              isEstimatedNftBoostMultiplierLoading
            }
            estimatedNftBoostMultiplier={estimatedNftBoostMultiplier}
            ctaType={ctaTypeRef.current}
          />
        )}
      </div>

      <div className="absolute bottom-0 left-0 z-50 w-full bg-blueElevatedSurfaceTransparent p-3">
        {stakeFlow === 'SELECT' && (
          <div className="mb-[12px] flex w-full flex-row justify-between text-mediumSmall font-normal">
            <div className="flex flex-row items-center justify-center">
              Boosted APR{' '}
              <Tooltip
                message={
                  <Text className="w-[330px] text-small">
                    Determined by the final multiplier, which is the product of
                    the vault&apos;s base and the selected NFTs&apos;
                    multipliers
                  </Text>
                }
              >
                <Icon.Question className="ml-0.5 inline h-3 w-3 align-top" />
              </Tooltip>
            </div>
            {loaded && pool ? (
              <BoostedValue
                value={formatPercent(formatRateToPercent(pool.apy))}
                loading={
                  selectedIds.length > 0 && isEstimatedNftBoostMultiplierLoading
                }
                boostedValue={
                  selectedIds.length > 0 && estimatedNftBoostMultiplier
                    ? formatPercent(
                        formatRateToPercent(
                          estimatedNftBoostMultiplier * pool.apy
                        )
                      )
                    : undefined
                }
              />
            ) : (
              <Skeleton className="w-6" />
            )}
          </div>
        )}

        <Button
          className="bottom-0 w-full"
          onClick={handleStake}
          disabled={nftToStakeIds.length === 0 && nftToUnstakeIds.length === 0}
        >
          {stakeFlow === 'SELECT'
            ? 'Confirm'
            : `${
                ctaTypeRef.current
                  ? ctaTypeRef.current[0] +
                    ctaTypeRef.current.slice(1).toLowerCase()
                  : 'Stake'
              } now`}
        </Button>
      </div>
    </BaseTransactionModal>
  );
}

export default memo(StakingNFTModal);
