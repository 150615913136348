import { BraveWallet } from '@web3-wallet/brave-wallet';
import { DeFiWallet } from '@web3-wallet/defiwallet';
import { MetaMask } from '@web3-wallet/metamask';
import type { CurrentWallet, Wallet, WalletName } from '@web3-wallet/react';
import { createCurrentWallet, createWallet } from '@web3-wallet/react';
import { WalletConnect } from '@web3-wallet/walletconnect';

import { ChainId } from '@config/baseNetworkConfig';
import { Network } from '@types';

import type { WalletConfig } from './walletConfigs';
import { getWalletConfig } from './walletConfigs';

export const SUPPORTED_CHAIN_IDS: ChainId[] = (() => {
  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos:
    case Network.cronos_uat:
    case Network.cronos_local:
      return [ChainId.Mainnet];

    case Network.cronos_testnet:
      return [ChainId.Testnet];

    default:
      throw new Error(
        'process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK is incorrect'
      );
  }
})();

export const metaMask = createWallet(
  getWalletConfig(MetaMask.walletName).connector
);

export const defiWallet = createWallet(
  getWalletConfig(DeFiWallet.walletName).connector
);

export const braveWallet = createWallet(
  getWalletConfig(BraveWallet.walletName).connector
);

export const walletConnect = createWallet(
  getWalletConfig(WalletConnect.walletName).connector
);

const walletMap: Record<string, Wallet> = {
  [metaMask.getWalletName()]: metaMask,
  [defiWallet.getWalletName()]: defiWallet,
  [walletConnect.getWalletName()]: walletConnect,
  [braveWallet.getWalletName()]: braveWallet,
};

export const getWallet = (name: WalletName): Wallet => {
  const wallet = walletMap[name];

  if (!wallet) throw new Error(`unknown wallet ${name}`);

  return wallet;
};

const _currentWallet = createCurrentWallet([
  metaMask,
  defiWallet,
  braveWallet,
  walletConnect,
]);

const { useWalletName, useChainId, useIsConnected } = _currentWallet;

const useWalletConfig = (): WalletConfig | undefined => {
  return getWalletConfig(useWalletName());
};

const useIsConnectedToSupportedChain = (): boolean => {
  const chainId = useChainId();
  const isConnected = useIsConnected();

  return isConnected && SUPPORTED_CHAIN_IDS.includes(chainId as number);
};

const useSupportedChainId = (): ChainId | undefined => {
  const chainId = useChainId();

  return chainId && SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : undefined;
};

const useIsWrongNetwork = (): boolean => {
  const chainId = useChainId();
  const isConnected = useIsConnected();

  return isConnected && !SUPPORTED_CHAIN_IDS.includes(chainId as number);
};

const useIsReadOnly = (): boolean => {
  return !useIsConnectedToSupportedChain();
};

type EnhancedCurrentWallet = Omit<
  CurrentWallet,
  'useBalance' | 'useBalances'
> & {
  useWalletConfig: typeof useWalletConfig;
  useIsConnectedToSupportedChain: typeof useIsConnectedToSupportedChain;
  useSupportedChainId: typeof useSupportedChainId;
  useIsReadOnly: typeof useIsReadOnly;
  useIsWrongNetwork: typeof useIsWrongNetwork;
};

export const currentWallet: EnhancedCurrentWallet = {
  ..._currentWallet,
  useWalletConfig,
  useIsConnectedToSupportedChain,
  useSupportedChainId,
  useIsReadOnly,
  useIsWrongNetwork,
};
