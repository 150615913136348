import { useQuery } from 'react-query';
import { TectonicSDK } from '@tectonicfi/sdk';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';

export default function useTTokenExchangeRate(
  tTokenAddress: string,
  sdk: TectonicSDK
) {
  const query = getQuery(QueryKey.TECTONIC_SUPPLY_EXCHANGE_RATE)(
    sdk,
    tTokenAddress
  );
  return useQuery(query.queryKey, query.queryFn, {
    // refetchInterval: 10000,
    enabled: !!sdk,
  });
}
