import { GraphQLClient } from 'graphql-request';

export const mainGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/tectonic-main`,
  {
    headers: {},
  }
);

export const tonicAnalyticsGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/analytics-tonic`,
  {
    headers: {},
  }
);

export const xtonicAnalyticsGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/analytics-xtonic`,
  {
    headers: {},
  }
);

export const tonicVenoAnalyticsGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/analytics-tonic-lcro`,
  {
    headers: {},
  }
);

export const tonicDefiAnalyticsGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/analytics-tonic-defi`,
  {
    headers: {},
  }
);

export const stakeNFTInfosGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/vault`,
  {
    headers: {},
  }
);

export const tonicSupplyGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/tonic-circulating-supply`,
  {
    headers: {},
  }
);

export const commonAnalyticsGraphQLClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_SUBGRAPH_URL}/analytics-common`,
  {
    headers: {},
  }
);
