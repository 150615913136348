import { useRouter } from 'next/router';

import { PoolType } from '@config/base';

enum NonTonicPoolPathName {
  DeFiPool = 'DEFI',
  VenoPool = 'VENO',
}

export default function usePoolMode(): PoolType {
  const { pathname } = useRouter();

  switch (!!pathname) {
    case pathname.toUpperCase().includes(NonTonicPoolPathName.DeFiPool):
      return 'DEFI';
    case pathname.toUpperCase().includes(NonTonicPoolPathName.VenoPool):
      return 'VENO';

    default:
      return 'MAIN';
  }
}
