import { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { Text } from '@tectonicfi/tectonic-ui-kit';

import { formatRateToPercent } from '@lib/units';

import ArrowUpSVG from '../../public/assets/images/arrow-up.svg';
import ArrowDownSVG from '../../public/assets/images/arrow-down.svg';

interface Props {
  selectOptions: number[];
  leftLabel: string;
  isCollapse: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
  onButtonClick: (data: number) => void;
  slippageTolerance: number;
  leftLabelClass?: string;
  label: string;
}

function EnhancedSlippageToleranceSelect({
  label,
  onButtonClick,
  selectOptions,
  leftLabel,
  isCollapse,
  setCollapse,
  slippageTolerance,
  leftLabelClass,
}: Props): JSX.Element {
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [stValue, setSTValue] = useState<number | 'Custom'>('Custom');
  return (
    <div>
      {typeof label === 'string' ? (
        <Text className="mb-1 text-body font-medium">{label}</Text>
      ) : (
        label
      )}
      <div
        className={clsx('w-auto border border-white border-opacity-30 p-0.5')}
      >
        <div className="flex items-center p-1.5">
          <div className="w-full">
            <Text className={leftLabelClass}>{leftLabel}</Text>
          </div>
          <Text className="mx-1">
            {slippageTolerance
              ? `${formatRateToPercent(slippageTolerance).toFixed(1)}%`
              : 'Select'}
          </Text>
          <div
            onClick={() => setCollapse((prev) => !prev)}
            className="cursor-pointer"
          >
            {isCollapse ? (
              <ArrowDownSVG fill={'yellow'} stroke={'yellow'} />
            ) : (
              <ArrowUpSVG fill={'yellow'} stroke={'yellow'} />
            )}
          </div>
        </div>
        {!isCollapse && (
          <div className="grid grid-cols-4 gap-1 p-1.5">
            {selectOptions &&
              selectOptions.map((value, key) => {
                return (
                  <button
                    key={key}
                    className={clsx(
                      'rounded-lg border px-1 text-white mobile:text-small',
                      {
                        'bg-black border-yellow':
                          !isFocusInput && slippageTolerance === value,
                        'border-white/[0.3] bg-white/[0.12]':
                          slippageTolerance !== value,
                      }
                    )}
                    onClick={() => onButtonClick(value)}
                  >
                    {formatRateToPercent(value)}%
                  </button>
                );
              })}
            <input
              type="text"
              className={clsx(
                'rounded-lg border-1 border-white/[0.3] bg-white/[0.12] text-center outline-none focus:border-yellow mobile:text-small',
                {
                  'bg-black !border-yellow':
                    !selectOptions.includes(slippageTolerance) ||
                    stValue === slippageTolerance,
                }
              )}
              value={
                stValue === 'Custom' ? 'Custom' : Math.round(stValue * 100)
              }
              onFocus={() => {
                setIsFocusInput(true);
                setSTValue(0);
              }}
              onBlur={() => setIsFocusInput(false)}
              onChange={(e) => {
                const value = e.target.value;
                const isValid = /^0[0-9]*$|^[1-9][0-9]?$|100$/.test(value);

                if (isValid) {
                  onButtonClick(Number(value) / 100);
                  setSTValue(Number(value) / 100);
                } else if (value === '') {
                  onButtonClick(0);
                  setSTValue(0);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EnhancedSlippageToleranceSelect;
