import { AppDispatch, AppStore, AppThunk } from '@redux/wrapper';
import { RootReducerState } from '@redux/rootReducer';

export enum Network {
  cronos_local = 'cronos_local',
  cronos_testnet = 'cronos_testnet',
  cronos_uat = 'cronos_uat',
  cronos = 'cronos',
}

export enum TxStatus {
  awaiting_confirmation = 'awaiting_confirmation',
  confirmed = 'confirmed',
  failed = 'failed',
  pending = 'pending',
}

export enum Connector {
  injected = 'injected',
  defiwallet = 'defiwallet',
  walletconnect = 'walletconnect',
}

export enum AppEnvironment {
  Local = 'local',
  Dev = 'dev',
  UAT = 'uat',
  Production = 'production',
  Staging = 'sta',
  Staging1 = 'sta1',
}

export type { AppDispatch, AppStore, AppThunk, RootReducerState };
