import { TectonicVaultStakeInfo } from '@tectonicfi/sdk/dist/types';
import {
  BackButton,
  Button,
  Dropdown,
  Text,
  Toggle,
} from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

import { LockingPeriodDetails } from '@components/LockDepositModal/types';
import { unlockDateToString } from '@lib/dates';
import { secondsToPeriod } from '@components/LockDepositModal/periods';
import { useStakedNFTTokenIds } from '@hooks/useStakeNFT';

import { DisplayValue, SortOptions, SortValue } from '../DepositDropdown';
import useDepositSelectionState, {
  TOGGLE,
  TOGGLE_SELECT_ALL,
} from '../useDepositSelectionState';
import { PoolOfUser } from '../VaultsBlock/types';
import DepositInfoRow from '../DepositInfoRow';

import useSortedDeposits from './useSortedDeposits';

export interface UpgradeLockingPeriodProps {
  poolId: number;
  pools: PoolOfUser[];
  deposits: TectonicVaultStakeInfo[];
  onBackToVaultInfo: (id: number) => void;
  onConfirmSelection: (
    stakeInfos: TectonicVaultStakeInfo[],
    availablePeriods: LockingPeriodDetails[]
  ) => void;
}
function UpgradeLockingPeriod({
  deposits,
  poolId,
  pools,
  onBackToVaultInfo,
  onConfirmSelection,
}: UpgradeLockingPeriodProps): JSX.Element {
  const [sortBy, setSortBy] = useState<SortValue>('recently-locked');
  const [state, dispatch] = useDepositSelectionState();
  const { isLoading: isStakedNFTIdsLoading, data: stakedNftIds } =
    useStakedNFTTokenIds(poolId);
  const hasStakedNFTsError = useMemo(
    () =>
      deposits.length === state.selectedVaults.length && !!stakedNftIds?.length,
    [stakedNftIds, deposits, state]
  );

  const { sortedDeposits, lockEndTimes, hasUnlockedVault } = useSortedDeposits(
    deposits,
    sortBy
  );
  const remainingTimeStrings = useMemo(() => {
    return lockEndTimes.map(unlockDateToString);
  }, [lockEndTimes]);

  return (
    <div
      className={clsx(
        hasUnlockedVault
          ? 'border-solid border-transparent bg-clip-padding before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0 before:z-0 before:m-[-1px] before:rounded-md  before:bg-gradient-to-r before:from-highlightBorderColorFrom before:to-highlightBorderColorTo before:content-[""]'
          : 'border-greyBorder',
        'relative my-2 rounded-md  border-1 bg-testBlue mobile:my-3 mobile:mx-0'
      )}
    >
      <div
        className={clsx(
          'relative z-10 max-h-[610px] min-h-[589px] rounded-md bg-testBlue  text-white'
        )}
      >
        <style jsx>
          {`
            .no-scrollbar::-webkit-scrollbar {
              display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .no-scrollbar {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            }
          `}
        </style>
        <div className="no-scrollbar max-h-[610px] overflow-scroll p-3">
          <div className="absolute top-0 left-0 right-0 z-20 rounded-md bg-testBlue p-3">
            <BackButton onClick={() => onBackToVaultInfo(poolId)}>
              Back to vault info
            </BackButton>
          </div>
          <div className="h-[18px]"></div>
          <div className="mt-3 flex w-full flex-col justify-between gap-1 leading-5">
            <div>
              <Text variant="heading3" className="font-bold">
                Upgrade locking period
              </Text>
            </div>
            <div>
              <span className="text-mediumSmall">
                Select the deposits you wish to upgrade
              </span>
            </div>
          </div>
          <div className="mt-3 flex w-full flex-row items-center justify-start">
            <div className="text-[16px] mobile:text-[14px]">Sort by:</div>
            <Dropdown
              className="ml-[6px] leading-5"
              onChange={(value) => setSortBy(value)}
              value={sortBy}
              displayValue={<DisplayValue />}
            >
              <SortOptions />
            </Dropdown>
          </div>
          <div className="mt-2 flex w-full flex-row items-start justify-end gap-1">
            <div className="pt-[5px] text-mediumSmall">select all</div>
            <Toggle
              data-testid="select-all"
              checked={state.selectedAll}
              onClick={() =>
                dispatch({
                  type: TOGGLE_SELECT_ALL,
                  allIds: sortedDeposits.map((deposit) => deposit.stakeId),
                })
              }
            />
          </div>
          <div className="mt-2 flex flex-col gap-2 ">
            {sortedDeposits.map((deposit, i) => (
              <DepositInfoRow
                key={deposit.stakeId}
                deposit={deposit}
                remainingTime={remainingTimeStrings[i]}
                selected={state.selectedVaults.includes(deposit.stakeId)}
                onClick={() =>
                  dispatch({
                    type: TOGGLE,
                    id: deposit.stakeId,
                  })
                }
              />
            ))}
            <div className="h-[104px]"></div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-20 rounded-md bg-semiTransparentTestBlue p-2 backdrop-blur">
          <Button
            variant="contained"
            className="w-full"
            disabled={
              isStakedNFTIdsLoading ||
              !state.selectedVaults.length ||
              hasStakedNFTsError
            }
            onClick={() => {
              const activeDeposits = deposits.filter((d) =>
                state.selectedVaults.includes(d.stakeId)
              );
              const activePools = pools.filter((p) => p.poolId > poolId);
              onConfirmSelection(
                activeDeposits,
                activePools.map((p) => ({
                  apr: p.apy,
                  multiplier: `${p.multiplier}x`,
                  poolId: p.poolId,
                  period: secondsToPeriod[p.lockPeriod],
                }))
              );
            }}
          >
            Confirm Selection
          </Button>
          {hasStakedNFTsError && (
            <Text className="text-small text-red">
              In order to upgrade all your xTONIC from this vault, you need to
              first unstake all your NFTs
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpgradeLockingPeriod;
