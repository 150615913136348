import clsx from 'clsx';
interface ModeConfig<TMode extends string> {
  text: string;
  mode: TMode;
}
interface ModeSwitchProps<TMode extends string> {
  mode: TMode;
  modes: Modes<TMode>;
  onModeChange: (mode: TMode) => void;
  className?: string;
  variant?: 'default' | 'tabs';
}

export type Modes<TMode extends string> = ModeConfig<TMode>[];

function ModeSwitch<TMode extends string>({
  mode,
  onModeChange,
  modes,
  className,
  variant,
}: ModeSwitchProps<TMode>): JSX.Element {
  const modeVariant = variant ?? 'default';
  const isDefaultVariant = modeVariant === 'default';
  const isTabsVariant = modeVariant === 'tabs';

  return (
    <div
      className={clsx(
        {
          'mx-auto mb-4 flex w-full flex-row justify-around rounded-[28px] bg-onSurface12 p-0.5':
            modeVariant === 'default',
          'mx-auto flex w-full flex-row justify-around text-[16px] font-semibold mobile:mx-2 mobile:justify-between':
            modeVariant === 'tabs',
        },
        className
      )}
    >
      {modes.map((item) => (
        <div
          key={item.mode}
          className={clsx(
            {
              'flex flex-1 cursor-pointer justify-around rounded-[28px] py-1 font-semibold':
                isDefaultVariant,
            },
            {
              'bg-yellowPrimary text-surface':
                mode === item.mode && isDefaultVariant,
              'border-b-2 border-yellowBorderActive text-yellowBorderActive':
                mode === item.mode && isTabsVariant,
            },
            {
              'text-white': mode !== item.mode,
            },
            {
              'cursor-pointer pb-1': isTabsVariant,
            }
          )}
          onClick={(): void => onModeChange(item.mode)}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
}

export default ModeSwitch;
