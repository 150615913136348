export interface ErrorMessage {
  code: number;
  data: {
    code: number;
    message: string;
  };
  message: string;
  stack: string;
}

export function getTxErrorReadableMessage(error: ErrorMessage): string {
  if (error?.data || error?.message) {
    return error?.data?.message || error?.message;
  }

  return 'Something went wrong...';
}
