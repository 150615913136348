import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Icon, Text, Tooltip } from '@tectonicfi/tectonic-ui-kit';
import { BigNumber } from 'ethers';

import { PoolOfUser } from '@components/VaultsPageView/VaultsBlock/types';
import { useMaxEstimatedNftBoostMultiplier } from '@hooks/useStakeNFT';
import { formatPercent, formatRateToPercent } from '@lib/units';
import useIsMobile from '@hooks/useIsMobile';

import { MAX_STAKING_NFT } from '../NFTImageCard';
import { NFT } from '../useNFTMeta';
import BoostedValue from '../BoostedValue';

import NFTGallery from './NFTGallery';

interface Props {
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  userNfts: NFT[];
  stakedNfts: NFT[];
  pool?: PoolOfUser;
}

export default function SelectStakingNFT({
  selectedIds,
  setSelectedIds,
  userNfts,
  stakedNfts,
  pool,
}: Props): JSX.Element {
  const isMobile = useIsMobile();
  const handleSelect = useCallback(
    (id: string) => {
      setSelectedIds((prev) =>
        prev.includes(id)
          ? prev.filter((selectedId) => id !== selectedId)
          : [...prev, id]
      );
    },
    [setSelectedIds]
  );

  const rankSortedNftIds = useMemo(
    () =>
      [...userNfts, ...stakedNfts]
        .sort((a, b) => a.rank - b.rank)
        .slice(0, MAX_STAKING_NFT)
        .map(({ id }) => BigNumber.from(id)),
    [userNfts, stakedNfts]
  );

  const { isLoading: isNftBoostMultiplierLoading, data: NftBoostMultiplier } =
    useMaxEstimatedNftBoostMultiplier(rankSortedNftIds);

  return (
    <>
      <Text variant="default" className="mb-3 font-medium">
        Select up to {MAX_STAKING_NFT} NFTs you want to stake
      </Text>
      <div className="mb-1 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div>No. of NFT(s) selected</div>

        <span className="font-semibold">
          {selectedIds.length} / {MAX_STAKING_NFT}
        </span>
      </div>

      <div className="mb-2 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">
          <Text className="text-mediumSmall mobile:w-[110px]">
            Max estimated boosted APR
          </Text>
          <Tooltip
            message={
              <Text className="w-[330px] text-small">
                Based on the NFTs in your wallet with the highest multipliers,
                capped at 5 NFTs
              </Text>
            }
            leftOffset={isMobile ? 24 : 90}
          >
            <Icon.Question className="h-3 w-3 align-top" />
          </Tooltip>
        </div>

        {pool && (
          <BoostedValue
            value={formatPercent(formatRateToPercent(pool.apy))}
            loading={isNftBoostMultiplierLoading}
            boostedValue={
              !isNftBoostMultiplierLoading && NftBoostMultiplier
                ? formatPercent(
                    formatRateToPercent(pool.apy * NftBoostMultiplier)
                  )
                : undefined
            }
          />
        )}
      </div>

      <NFTGallery
        userNfts={userNfts}
        stakedNfts={stakedNfts}
        selectedIds={selectedIds}
        onSelect={handleSelect}
      />
    </>
  );
}
