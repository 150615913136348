import { AmountInput, AmountInputProps } from '@tectonicfi/tectonic-ui-kit';

import { isAmountZeroEquivalent } from '@lib/utils';

type EnhancedAmountInputProps = AmountInputProps;

function EnhancedAmountInput({
  onFocus,
  value,
  ...rest
}: EnhancedAmountInputProps): JSX.Element {
  return (
    <AmountInput
      onFocus={(e): void => {
        if (isAmountZeroEquivalent(value)) {
          e.target.select();
        }

        if (onFocus) {
          onFocus(e);
        }
      }}
      value={value}
      {...rest}
    />
  );
}

export default EnhancedAmountInput;
