import { useEffect } from 'react';

import { RootReducerState } from '@types';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useTectonicAssetPricer from '@hooks/useTectonicAssetPricer';
import { getAssetUsdPrice } from '@redux/usdPrices';
import { PoolType } from '@config/base';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';

interface UseUsdPricesResult {
  loaded: boolean;
  usdPrices: RootReducerState['usdPrices']['bySymbol'];
}

interface UseUsdPricesOptions {
  skip?: boolean;
}

function useUsdPrices(
  poolType?: PoolType,
  options?: UseUsdPricesOptions
): UseUsdPricesResult {
  const { sdk, list: supportedAssets } = useSdkAndSupportedAssets(
    poolType ?? 'MAIN'
  );
  const tectonicAssetPricer = useTectonicAssetPricer(sdk);
  const dispatch = useAppDispatch();
  const skip = options?.skip || false;
  const usdPrices = useAppSelector((store) => store.usdPrices.bySymbol);

  useEffect(() => {
    function getUsdPrices(): void {
      supportedAssets.forEach((asset) =>
        dispatch(getAssetUsdPrice(tectonicAssetPricer, asset))
      );
    }

    if (!skip) {
      getUsdPrices();
    }
  }, [dispatch, supportedAssets, tectonicAssetPricer, skip]);

  return {
    loaded: supportedAssets.every(({ symbol }) => !!usdPrices[symbol]),
    usdPrices,
  };
}

export default useUsdPrices;
