import { useMemo, useState, useEffect } from 'react';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';
import get from 'lodash/get';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar';
import StartEndText from '@components/StartEndText';
import SupportedAssetIcon from '@components/SupportedAssetIcon';
import EnhancedAmountInput from '@components/EnhancedAmountInput';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import {
  formatPercent,
  formatRateToPercent,
  formatUserTotalUsdValue,
  formatUserUnderlyingAmount,
} from '@lib/units';
import {
  getBorrowBalanceRate,
  getLiquidationLtvRate,
  getLoanToValueRate,
  getPostWithdrawBorrowLimit,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
  getUserMaxWithdrawAmount,
  getUserPostWithdrawCollateralBalance,
  getUserSupplyBalance,
} from '@lib/math';
import { determineWithdrawMaxAmount } from '@lib/maxLogic';
import useGetTotalMarketAvailableAmount from '@hooks/useGetTotalMarketAvailableAmount';
import { isAmountZeroEquivalent } from '@lib/utils';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { Mode } from '@components/MarketsPageView/types';
import useSupplyApy from '@hooks/useSupplyApy';
import { PARTNER_TOKEN_ICONS } from '@config/constants';

import WithdrawCautionMessage from './WithdrawCautionMessage';
import validateWithdrawAmount from './validateWithdrawAmount';

export interface WithdrawModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  mode: Mode;
  amount: string;
  asset: TectonicAsset | null;
  onAmountChange(value: string): void;
  onWithdraw(): void;
  onWithdrawSome(value: string): void;
}

function WithdrawModal({
  mode,
  amount,
  asset,
  onAmountChange,
  onWithdraw,
  onWithdrawSome,
  transactionStatus = null,
  ...props
}: WithdrawModalProps): JSX.Element {
  const { list: assets } = useSdkAndSupportedAssets(mode);
  const [isMaxWithdraw, setIsMaxWithdraw] = useState(false);
  const symbol = asset?.symbol || '';

  useEffect(() => {
    if (!props.isOpen) {
      setIsMaxWithdraw(false);
    }
  }, [props.isOpen]);

  const options = useMemo(
    () => ({ skip: !props.isOpen, mode }),
    [props.isOpen, mode]
  );

  const {
    netSupplyApy,
    tonicSupplyApy,
    partnerTokenSupplyApy,
    isLoading: isSupplyApyLoading,
  } = useSupplyApy(mode, asset, options);

  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);
  // TODO - If we can't load userMetricsData, then we should not proceed
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
  } = useUserMetrics(mode);

  const {
    balance: totalMarketAvailableAmount,
    loading: loadingTotalMarketAvailableAmount,
  } = useGetTotalMarketAvailableAmount(asset, mode, options);

  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;
  const supplyBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserSupplyBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);

            return { asset: a, supplyAmount, usdPrice };
          })
        )
      : null;
  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;
  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;

            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const userMaxWithdrawAmount =
    asset &&
    loadedUserMetrics &&
    borrowBalance &&
    supplyBalance &&
    borrowLimit &&
    loadedUsdPrices
      ? getUserMaxWithdrawAmount({
          asset,
          assetPrice: BigNumber.from(usdPrices[symbol]),
          collateralFactor: userMetricsData.collateralFactors[symbol],
          currentSupplyAmount: userMetricsData.supplyAmounts[symbol],
          borrowBalance,
          borrowLimit,
          isCollateral: userMetricsData.isCollateral[symbol],
        })
      : null;

  const loadingInfo =
    isSupplyApyLoading ||
    loadingUserMetrics ||
    loadingTotalMarketAvailableAmount ||
    !loadedUsdPrices;

  const shouldRenderCautionMessage = useMemo(() => {
    return (
      !isAmountZeroEquivalent(amount) &&
      borrowBalance &&
      !borrowBalance.isZero()
    );
  }, [amount, borrowBalance]);

  const { valid, reason } = validateWithdrawAmount({
    amount,
    asset,
    assetIsCollateral: userMetricsData.isCollateral[symbol],
    supplyAmount: userMetricsData.supplyAmounts[symbol],
    totalMarketAvailableAmount,
    userMaxWithdrawAmount,
  });

  const decideWithdrawMethod = () => {
    if (asset && userMaxWithdrawAmount) {
      if (isMaxWithdraw) {
        onWithdraw();
      } else {
        onWithdrawSome(amount);
      }
    }
    return null;
  };

  const onMaxClick = () => {
    if (asset && loadedUserMetrics && userMaxWithdrawAmount) {
      setIsMaxWithdraw(
        userMaxWithdrawAmount.eq(userMetricsData.supplyAmounts[symbol])
      );
      onAmountChange(
        determineWithdrawMaxAmount(asset, {
          userMaxWithdrawAmount,
        })
      );
    } else {
      onAmountChange('0');
    }
  };

  function getNewBorrowLimit(): BigNumber | null {
    if (asset && borrowLimit) {
      const newBorrowLimit = getPostWithdrawBorrowLimit(
        asset,
        borrowLimit,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.collateralFactors[symbol],
        userMetricsData.isCollateral[symbol]
      );

      if (!newBorrowLimit) {
        return null;
      }

      return newBorrowLimit;
    }

    return null;
  }
  const newBorrowLimit = getNewBorrowLimit();

  function getNewLiquidationLtv(): string | null {
    if (asset && newBorrowLimit && collateralBalance) {
      const newCollateralBalance = getUserPostWithdrawCollateralBalance(
        asset,
        collateralBalance,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.isCollateral[symbol]
      );

      if (newCollateralBalance) {
        const newLiquidationLtv = formatPercent(
          formatRateToPercent(
            getLiquidationLtvRate(newBorrowLimit, newCollateralBalance)
          )
        );

        return newLiquidationLtv;
      }
    }

    return null;
  }

  function getNewLtv(): string | null {
    if (asset && borrowBalance && collateralBalance) {
      const newCollateralBalance = getUserPostWithdrawCollateralBalance(
        asset,
        collateralBalance,
        amount,
        BigNumber.from(usdPrices[symbol]),
        userMetricsData.isCollateral[symbol]
      );

      if (newCollateralBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(borrowBalance, newCollateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getLavaBarPercent(): number {
    if (borrowBalance) {
      if (newBorrowLimit) {
        // If both are zero, then the lava bar should be empty
        if (borrowBalance.isZero() && newBorrowLimit.isZero()) {
          return 0;
        }

        // If borrowBalance is > 0 but the user enters an amount that would cause the new borrowLimit
        // to be 0, since we cannot divide by 0, the lava bar will empty (return 0 if denominator is 0).
        // However, the lava bar should still light up fully in this case since the user would
        // effectively be borrowing something against no collateral.
        if (borrowBalance.gt(BigNumber.from(0)) && newBorrowLimit.isZero()) {
          return 100;
        }

        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, newBorrowLimit)
        );
      }

      if (borrowLimit) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, borrowLimit)
        );
      }
    }

    return 0;
  }

  const lavaBarPercent = getLavaBarPercent();

  return (
    <BaseTransactionModal
      className="desktop:!w-[510px]"
      title={`Withdraw ${symbol}`}
      {...props}
      transactionStatus={transactionStatus}
    >
      <div className="mb-4">
        <EnhancedAmountInput
          label="Withdraw amount"
          onBlur={(): void => {
            setIsMaxWithdraw(false);
            if (amount === '') {
              onAmountChange('0');
            }
          }}
          onButtonClick={onMaxClick}
          onChange={(e): void => {
            setIsMaxWithdraw(false);
            onAmountChange(e.target.value);
          }}
          value={amount}
        />
      </div>
      <TransactionModalDataRow
        label={
          <div className="flex">
            {asset && <SupportedAssetIcon className="mr-1" asset={asset} />}
            <Text variant="default">Net Supply APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={
          netSupplyApy !== undefined && (
            <Text>{formatPercent(netSupplyApy)}</Text>
          )
        }
      />
      <TransactionModalDataRow
        label={
          <div className="ml-2.5 flex whitespace-nowrap">
            <Icon.Tonic className="mr-1 h-3 w-3" />
            <Text variant="default">Distribution APY</Text>
          </div>
        }
        loading={loadingInfo}
        value={<Text>{formatPercent(tonicSupplyApy * 100)}</Text>}
      />
      {Object.entries(partnerTokenSupplyApy).map(([tokenSymbol, apy]) => {
        const PartnerIcon = get(PARTNER_TOKEN_ICONS, tokenSymbol, null);

        if (!PartnerIcon || apy === 0) return null;

        return (
          <TransactionModalDataRow
            key={tokenSymbol}
            label={
              <div className="ml-2.5 flex whitespace-nowrap">
                <PartnerIcon className="mr-1 h-3 w-3" />
                <Text variant="default">Distribution APY</Text>
              </div>
            }
            loading={loadingInfo}
            value={<Text>{formatPercent(apy * 100)}</Text>}
          />
        );
      })}
      <TransactionModalDataRow
        label={<Text variant="default">Borrow limit</Text>}
        loading={loadingInfo}
        value={
          borrowLimit && (
            <StartEndText
              startValue={formatUserTotalUsdValue(borrowLimit)}
              endValue={
                newBorrowLimit ? formatUserTotalUsdValue(newBorrowLimit) : null
              }
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Current Loan to Value (LTV)</Text>}
        loading={loadingInfo}
        value={
          !!(borrowBalance && collateralBalance) && (
            <StartEndText
              startValue={formatPercent(
                formatRateToPercent(
                  getLoanToValueRate(borrowBalance, collateralBalance)
                )
              )}
              endValue={getNewLtv()}
            />
          )
        }
      />
      <TransactionModalDataRow
        label={<Text variant="default">Liquidation Threshold</Text>}
        loading={loadingInfo}
        value={
          !!(borrowLimit && collateralBalance) && (
            <StartEndText
              startValue={formatPercent(
                formatRateToPercent(
                  getLiquidationLtvRate(borrowLimit, collateralBalance)
                )
              )}
              endValue={getNewLiquidationLtv()}
            />
          )
        }
      />
      <div className="mb-10">
        <BorrowLimitLavaBar value={lavaBarPercent} />

        {shouldRenderCautionMessage && lavaBarPercent <= 90 ? (
          <WithdrawCautionMessage isSevere={lavaBarPercent >= 89} />
        ) : null}
      </div>
      <TransactionModalDataRow
        label={<Text>Currently supplying</Text>}
        loading={loadingInfo}
        value={
          !!(asset && userMetricsData.supplyAmounts[symbol]) && (
            <Text>
              {`${formatUserUnderlyingAmount(
                asset,
                userMetricsData.supplyAmounts[symbol]
              )} ${symbol}`}
            </Text>
          )
        }
      />
      <Button
        className="w-full"
        disabled={!valid}
        onClick={decideWithdrawMethod}
      >
        {reason === 'insufficientLiquidity'
          ? 'Insufficient Liquidity'
          : 'Withdraw'}
      </Button>
    </BaseTransactionModal>
  );
}

export default WithdrawModal;
