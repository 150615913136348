import { useRouter } from 'next/router';
import Head from 'next/head';

import { makeAbsoluteUrl } from '@lib/utils';

const Seo = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const router = useRouter();
  const canonical = makeAbsoluteUrl(router.asPath);

  return (
    <Head>
      {title && (
        <>
          <title key="title">{title}</title>
          <meta property="og:title" content={title} key="og:title" />
          <meta property="og:image:alt" content={title} key="og:image:alt" />
          <meta name="twitter:title" content={title} key="twitter:title" />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} key="description" />
          <meta
            property="og:description"
            content={description}
            key="og:description"
          />
          <meta
            name="twitter:description"
            content={description}
            key="twitter:description"
          />
        </>
      )}
      <link rel="canonical" href={canonical} key="canonical" />
      <meta property="og:url" content={canonical} key="og:url" />
      <meta name="robots" content="index, follow, max-image-preview:large" />
    </Head>
  );
};

export default Seo;
