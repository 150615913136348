import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';

import { isAmountZeroEquivalent } from '@lib/utils';
import { parseInputAmountToBN } from '@lib/units';

interface ValidateSupplyAmountParams {
  asset: TectonicAsset | null;
  // The amount the user is currently supplying for this asset.
  // Does not include `supplyAmount`.
  currentAssetSupplyAmount: BigNumber | null;
  // The amount the user is intending to supply for this asset
  supplyAmount: string;
  walletBalance: BigNumber | null;
}

interface ValidateSupplyAmountResult {
  reason: 'invalid' | 'insufficientBalance' | 'exceedsTvlLimit' | null;
  valid: boolean;
}

const invalidResult: ValidateSupplyAmountResult = {
  valid: false,
  reason: 'invalid',
};

function validateSupplyAmount({
  asset,
  currentAssetSupplyAmount,
  supplyAmount,
  walletBalance,
}: ValidateSupplyAmountParams): ValidateSupplyAmountResult {
  if (asset && currentAssetSupplyAmount && walletBalance) {
    try {
      const parsedAmount = parseInputAmountToBN(supplyAmount, asset.decimals);
      const invalidInput =
        isAmountZeroEquivalent(supplyAmount) ||
        parsedAmount.isZero() ||
        parsedAmount.isNegative();

      if (invalidInput) {
        return invalidResult;
      }

      const insufficientBalance = parsedAmount.gt(walletBalance);

      if (insufficientBalance) {
        return { valid: false, reason: 'insufficientBalance' };
      }

      return { valid: true, reason: null };
    } catch (error) {
      return invalidResult;
    }
  }
  return invalidResult;
}

export default validateSupplyAmount;
