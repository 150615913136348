import { PageSection } from '@tectonicfi/tectonic-ui-kit';

import NoConnectedWalletBlock from '@components/NoConnectedWalletBlock';
import useWallets from '@hooks/useWallets';
import { Mode } from '@components/EarnPageView/types';

import VaultsAndEarningsBlock from './VaultsAndEarningsBlock';

const pageClassName = `pt-3 -mt-3 mobile:mt-0 mobile:pt-0
bg-cover flex flex-col items-center`;

interface VaultsPageProps {
  mode: Mode;
}

export default function VaultsPageView({ mode }: VaultsPageProps) {
  const { currentAccount } = useWallets();

  if (!currentAccount) {
    return (
      <PageSection className={pageClassName} contentWrapperClassName="w-full">
        <NoConnectedWalletBlock prompt="Connect your Wallet to see your reward" />
      </PageSection>
    );
  }
  return (
    <PageSection
      className={pageClassName}
      contentWrapperClassName="h-full flex flex-col w-full"
    >
      <VaultsAndEarningsBlock mode={mode} />
    </PageSection>
  );
}
