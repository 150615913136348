import { Button, Text } from '@tectonicfi/tectonic-ui-kit';
import { useRouter } from 'next/router';

import LockingPeriodCard from './LockingPeriodCard';
import { LockingPeriod, LockingPeriodDetails } from './types';

export default function PickLockingPeriodScreen({
  lockingPeriods,
  selectedPeriod,
  hasXTonic,
  onPeriodChange,
  onPeriodSelect,
}: {
  hasXTonic: boolean;
  selectedPeriod?: LockingPeriod;
  lockingPeriods: LockingPeriodDetails[];
  onPeriodChange: (period: LockingPeriod) => void;
  onPeriodSelect: () => void;
}) {
  const pickDisabled = !hasXTonic && !selectedPeriod;
  const handlePeriodSelect = pickDisabled ? undefined : onPeriodSelect;
  const router = useRouter();
  return (
    <div className="space-y-3">
      {hasXTonic ? (
        <Text>
          Your deposit will be held for the duration of the locking period.
          After the period ends you&apos;ll be able to withdraw your funds.
        </Text>
      ) : (
        <Text className="text-rainbowRed">
          To start using the vault feature you need xTONIC. Stake your tokens on
          the TONIC Staking Page to earn xTONIC
        </Text>
      )}
      <div className="grid grid-cols-2 gap-1">
        {lockingPeriods.map((p) => (
          <LockingPeriodCard
            key={p.period}
            {...p}
            disabled={!hasXTonic}
            selected={p.period === selectedPeriod}
            onClick={onPeriodChange}
          />
        ))}
      </div>
      {hasXTonic ? (
        <Button
          disabled={!selectedPeriod}
          className="w-full"
          onClick={handlePeriodSelect}
        >
          Select
        </Button>
      ) : (
        <Button className="w-full" onClick={() => router.push('/tonic')}>
          Get xTONIC
        </Button>
      )}
    </div>
  );
}
