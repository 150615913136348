import { useQuery } from 'react-query';
import { useMemo } from 'react';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';

type XtonicStatsDataType = {
  xtonicStats?: {
    xTonicPriceInUsd: number;
    xTonicTotalSupply: number;
    xTonicMarketCap: number;
    xTonicExchangeRate: number;
    stakedTonic: number;
    avgLockTime: number;
    apr7d: number;
  };
};

interface UseXtonicStatsDataResult extends XtonicStatsDataType {
  isLoading: boolean;
}

const useXtonicStatsData = (): UseXtonicStatsDataResult => {
  const xtonicStatsQuery = getQuery(QueryKey.GRAPH_XTONIC_STATS);

  const { data, isLoading } = useQuery(
    xtonicStatsQuery.queryKey,
    xtonicStatsQuery.queryFn
  );

  return useMemo(
    () =>
      isLoading || !data
        ? { isLoading }
        : {
            isLoading,
            xtonicStats: {
              xTonicPriceInUsd: parseFloat(data.xTonicPriceInUsd),
              xTonicTotalSupply: parseFloat(data.xTonicTotalSupply),
              xTonicExchangeRate: parseFloat(data.xTonicExchangeRate),
              xTonicMarketCap: parseFloat(data.xTonicMarketCap),
              stakedTonic: parseFloat(data.stakedTonic),
              avgLockTime: parseFloat(data.avgLockTime),
              apr7d: parseFloat(data.apr7d),
            },
          },
    [isLoading, data]
  );
};

export default useXtonicStatsData;
