import { Text } from '@tectonicfi/tectonic-ui-kit';

const MESSAGE = {
  severe:
    'Please note that this withdrawal will severely decrease your account liquidity. This means your account may be liquidated if the market drops. In general, it is recommended to keep your account LTV to below 50%.',
  normal:
    'Please note that withdrawing will decrease your account liquidity. This means your account may be liquidated more easily if the market drops.',
};

interface WithdrawCautionMessageProps {
  isSevere?: boolean;
}

function WithdrawCautionMessage({
  isSevere = false,
}: WithdrawCautionMessageProps): JSX.Element {
  return (
    <Text className="text-onSurface60" variant="small">
      {isSevere ? MESSAGE.severe : MESSAGE.normal}
    </Text>
  );
}

export default WithdrawCautionMessage;
