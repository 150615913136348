import { Icon } from '@tectonicfi/tectonic-ui-kit';

import { LockingPeriodDetails } from '@components/LockDepositModal/types';
import { getLockEndDate } from '@components/UpgradeDepositsModal/UpgradeDepositsModal';
import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';
import { LockingPeriod } from '@components/LockDepositModal/types';
import { formatPercent } from '@lib/units';

import TonicSummary from './TonicSummary';

interface Props {
  lockedPercent: number;
  unclaimValueAmount: string;
  totalBalanceToUsdValue: string;
  lockedToValueAmount: string;
  walletValueAmount: string;
  lockedToUsdValue: string;
  walletToUsdValue: string;
  lockedXTonicToValueAmount: string;
  selectedVault: LockingPeriodDetails | null;
  period: LockingPeriod | undefined;
  unclaimBoostValueAmount: string;
  boostTonicToUsdValue: string;
}

export default function ConfirmClaimCard({
  lockedPercent,
  unclaimValueAmount,
  totalBalanceToUsdValue,
  lockedToValueAmount,
  walletValueAmount,
  lockedToUsdValue,
  walletToUsdValue,
  selectedVault,
  lockedXTonicToValueAmount,
  period,
  boostTonicToUsdValue,
  unclaimBoostValueAmount,
}: Props) {
  return (
    <div className="mt-4 h-full text-body font-medium mobile:text-mediumSmall mobile:font-normal">
      <TonicSummary
        unclaimValueAmount={unclaimValueAmount}
        totalBalanceToUsdValue={totalBalanceToUsdValue}
        unclaimBoostValueAmount={unclaimBoostValueAmount}
        boostTonicToUsdValue={boostTonicToUsdValue}
      />

      <span className="flex justify-between border-b-1 border-white12 pb-2 text-white">
        <div className="flex flex-col gap-y-[4px]">
          <span>To be claimed immediately</span>
          <span>{100 - lockedPercent}%</span>
        </div>

        <div className="flex flex-col items-end">
          <span className="mb-[6px] flex items-center gap-x-1">
            <div className="font-semibold">{walletValueAmount}</div>
            <Icon.Tonic />
          </span>

          <span className="text-mediumSmall font-normal text-white60">
            {walletToUsdValue} USD
          </span>
        </div>
      </span>

      <span className="mt-2 flex justify-between border-b-1 border-white12 pb-2 text-mediumSmall text-white">
        <div className="flex flex-col gap-y-[4px]">
          <span>{period ? getLockPeriodName(period) : ''} vault</span>
          <span>{lockedPercent}%</span>
        </div>

        <div className="flex flex-col items-end">
          <span className="mb-[6px] flex items-center gap-x-1">
            <div className="font-semibold">{lockedToValueAmount}</div>
            <Icon.Tonic />
          </span>

          <span className="mb-[4px] text-mediumSmall font-normal text-white60">
            {lockedXTonicToValueAmount} xTONIC
          </span>
          <span className="text-mediumSmall font-normal text-white60">
            {lockedToUsdValue} USD
          </span>
        </div>
      </span>

      <span className="mt-2 flex justify-between text-mediumSmall text-white">
        <div>APR</div>
        <div className="mobile:font-semibold">
          {selectedVault?.apr ? formatPercent(selectedVault.apr * 100) : 0}
        </div>
      </span>

      <span className="mt-2 flex justify-between text-mediumSmall text-white">
        <div>Multiplier</div>
        <div className="mobile:font-semibold">{selectedVault?.multiplier}</div>
      </span>

      <span className="mt-2 flex justify-between pb-2 text-mediumSmall text-white">
        <div>Lock end date</div>
        <div className="mobile:font-semibold">
          {getLockEndDate(new Date(), selectedVault?.period)}
        </div>
      </span>
    </div>
  );
}
