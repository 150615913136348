import { differenceInDays, differenceInMonths, format } from 'date-fns';

export function formatLockEndDate(date: Date) {
  // Jun 22, 2022
  return date.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}
export function formatLockCountdown(date: Date) {
  const now = new Date();
  const days = differenceInDays(date, now);
  if (days < 2) {
    return 'tomorrow';
  }
  if (days < 30) {
    return `in ${days} days`;
  }
  const months = differenceInMonths(date, now);
  if (months === 1) {
    return 'in 1 month';
  }
  return `in ${months} months`;
}

export function unlockDateToString(unlockDate: Date): string {
  const now = new Date();
  if (unlockDate.getTime() <= now.getTime()) return 'Unlocked';
  const days = differenceInDays(unlockDate, now);
  if (days < 2) {
    return '1 day left';
  }
  if (days < 30) {
    return `${days} days left`;
  }
  const months = differenceInMonths(unlockDate, now);
  if (months === 1) {
    return '1 month left';
  }
  return `${months} months left`;
}

export function passedUnlockDateToString(unlockDate: Date): string {
  const now = new Date();
  const days = differenceInDays(now, unlockDate);
  if (days < 2) {
    return '1 day ago';
  }
  if (days < 30) {
    return `${days} days ago`;
  }
  const months = differenceInMonths(now, unlockDate);
  if (months === 1) {
    return '1 month ago';
  }
  return `${months} months ago`;
}

export const formatTime = (date: number | Date, formats = 'MM/dd/yyyy') =>
  format(date, formats);

export const formatTimeDistance = (date: number | Date) => {
  const now = new Date();
  const diff = now.getTime() - new Date(date).getTime();
  const day = 1000 * 60 * 60 * 24;
  const hour = 1000 * 60 * 60;
  const minute = 1000 * 60;
  const second = 1000;

  switch (true) {
    case diff < 0:
      return '';
    case diff / day >= 1:
      return `${Math.floor(diff / day)} days ago`;
    case diff / hour >= 1:
      return `${Math.floor(diff / hour)} hours ago`;
    case diff / minute >= 1:
      return `${Math.floor(diff / minute)} minutes ago`;
    case diff / second >= 1:
      return `${Math.floor(diff / second)} seconds ago`;
    default:
      return '';
  }
};
