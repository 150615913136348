import get from 'lodash/get';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import mainnetAssets from '@config/tectonic-build-cache/mainnet/assets.json';
import mainnetDeFiPoolAssets from '@config/tectonic-build-cache/mainnet/deFiPoolAssets.json';
import venoPoolAssets from '@config/tectonic-build-cache/mainnet/venoPoolAssets.json';
import testnetDeFiPoolAssets from '@config/tectonic-build-cache/testnet/deFiPoolAssets.json';
import testnetAssets from '@config/tectonic-build-cache/testnet/assets.json';
import testnetVenoAssets from '@config/tectonic-build-cache/testnet/venoPoolAssets.json';
import { PoolType } from '@config/base';
import { Network } from '@types';

const getAsset = () => {
  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos_testnet:
      return {
        MAIN: testnetAssets,
        DEFI: testnetDeFiPoolAssets,
        VENO: testnetVenoAssets,
      };
    case Network.cronos:
      return {
        MAIN: mainnetAssets,
        DEFI: mainnetDeFiPoolAssets,
        VENO: venoPoolAssets,
      };
    case Network.cronos_uat:
      return {
        MAIN: mainnetAssets,
        DEFI: mainnetDeFiPoolAssets,
        VENO: venoPoolAssets,
      };
    default:
      return {
        MAIN: testnetAssets,
        DEFI: testnetDeFiPoolAssets,
        VENO: testnetVenoAssets,
      };
  }
};

const ASSETS_MAPPING = getAsset();

type AssetsType = typeof ASSETS_MAPPING.MAIN;

const supportedAssets = (a: AssetsType) =>
  a.assets.map((asset) => {
    // CRO market name has been renamed to Cronos
    if (asset.symbol === 'CRO') {
      return { ...asset, name: 'Cronos' };
    }
    return asset;
  });

const supportedAssetsMap = (poolType: PoolType) =>
  supportedAssets(get(ASSETS_MAPPING, poolType)).reduce(
    (previous, current) => ({
      ...previous,
      // Uppercase symbols to be safe
      [current.symbol.toUpperCase()]: current,
    }),
    {} as Record<string, TectonicAsset>
  );

export const contextValue = {
  list: supportedAssets(ASSETS_MAPPING.MAIN),
  map: supportedAssetsMap('MAIN'),
  loaded: true,
};

export const contextDeFiPoolValue = {
  list: supportedAssets(ASSETS_MAPPING.DEFI),
  map: supportedAssetsMap('DEFI'),
  loaded: true,
};

export const contextVenoPoolValue = {
  list: supportedAssets(ASSETS_MAPPING.VENO),
  map: supportedAssetsMap('VENO'),
  loaded: true,
};
