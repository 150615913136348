import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../tailwind.config.js';

import { useMediaQuery } from './useMediaQuery';

const fullConfig = resolveConfig(tailwindConfig);
export default function useIsMobile() {
  const result = useMediaQuery(
    `(max-width: ${
      // the default config typing is inaccurate compared to the actual config provided by the UI kit
      (fullConfig?.theme?.screens as unknown as { mobile: { max: string } })
        .mobile?.max
    })`
  );
  return result;
}
