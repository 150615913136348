import { ReactNode, memo } from 'react';
import { Text, PageSection } from '@tectonicfi/tectonic-ui-kit';

import FullScreenLoader from '@components/FullScreenLoader';

import useShowCronosUpgradeWarning from './useShowCronosUpgradeWarning';

interface CronosUpgradeWarningProps {
  children: ReactNode;
}

function CronosUpgradeWarning({
  children,
}: CronosUpgradeWarningProps): JSX.Element {
  const { loading, showWarning } = useShowCronosUpgradeWarning();

  if (loading) {
    return <FullScreenLoader />;
  }

  if (!showWarning) {
    return <>{children}</>;
  }

  return (
    <PageSection>
      <div className="mt-3 space-y-2 text-center">
        <Text variant="heading1">Mainnet upgrade in progress</Text>

        <Text variant="large">
          Tectonic is currently unavailable as Cronos mainnet is undergoing a
          scheduled upgrade. Come back again later today.
        </Text>
      </div>
    </PageSection>
  );
}

export default memo(CronosUpgradeWarning);
