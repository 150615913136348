import { useMemo } from 'react';
import { Text, Button } from '@tectonicfi/tectonic-ui-kit';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import BorrowLimitLavaBar from '@components/BorrowLimitLavaBar';
import Spinner from '@components/Spinner';
import StartEndText from '@components/StartEndText';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import useUsdPrices from '@hooks/useUsdPrices';
import useUserMetrics from '@hooks/useUserMetrics';
import {
  formatUserTotalUsdValue,
  formatPercent,
  formatRateToPercent,
} from '@lib/units';
import {
  getBorrowBalanceRate,
  getLoanToValueRate,
  getPostDisableAsCollateralBorrowLimit,
  getPostDisableAsCollateralCollateralBalance,
  getPostEnableAsCollateralBorrowLimit,
  getPostEnableAsCollateralCollateralBalance,
  getTotalBorrowLimit,
  getUserBorrowBalance,
  getUserCollateralBalance,
} from '@lib/math';
import { isLiquidated } from '@lib/utils';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { Mode } from '@components/MarketsPageView/types';

import validateCollateralToggle from './validateCollateralToggle';

const STATIC_ELEMENTS = {
  enable: {
    title: 'Enable as collateral',
    description:
      'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.',
    getButtonLabel: (assetSymbol: string) => `Use ${assetSymbol} as collateral`,
  },
  disable: {
    title: 'Disable as collateral',
    description:
      'This asset will no longer be used towards your borrowing limit, and can’t be seized in liquidation.',
    getButtonLabel: (assetSymbol: string) => `Disable ${assetSymbol}`,
  },
  dismiss: {
    title: 'Collateral required',
    description:
      'This asset is required to support your borrowed assets. Either repay borrowed assets, or supply another asset as collateral.',
    getButtonLabel: () => 'Dismiss',
  },
};

export interface EnableOrDisableAsCollateralModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  asset: TectonicAsset | null;
  onDisableAsCollateral(): void;
  onEnableAsCollateral(): void;
  mode: Mode;
}

interface GetNewBorrowLimitParams {
  asset: TectonicAsset;
  borrowLimit: BigNumber;
  collateralFactor: number;
  isEnabledAsCollateral: boolean;
  supplyAmount: BigNumber;
  usdPrice: BigNumber;
  mode?: Mode;
}
function getNewBorrowLimit({
  asset,
  borrowLimit,
  collateralFactor,
  isEnabledAsCollateral,
  supplyAmount,
  usdPrice,
}: GetNewBorrowLimitParams): BigNumber {
  const mathFunction = isEnabledAsCollateral
    ? getPostDisableAsCollateralBorrowLimit
    : getPostEnableAsCollateralBorrowLimit;

  return mathFunction(
    asset,
    borrowLimit,
    supplyAmount,
    usdPrice,
    collateralFactor
  );
}

function EnableOrDisableAsCollateralModal({
  asset,
  isOpen,
  onClose,
  onDisableAsCollateral,
  onEnableAsCollateral,
  transactionStatus = null,
  mode,
  ...props
}: EnableOrDisableAsCollateralModalProps): JSX.Element {
  const { list: assets } = useSdkAndSupportedAssets(mode);
  const options = useMemo(() => ({ skip: !isOpen }), [isOpen]);
  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode, options);
  const {
    data: userMetricsData,
    loading: loadingUserMetrics,
    loaded: loadedUserMetrics,
    refetch,
  } = useUserMetrics(mode, options);

  const onDismiss = () => {
    refetch();
    onClose();
  };

  const symbol = asset?.symbol || '';
  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol] ?? 0);

            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;
  const borrowLimit =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((a) => {
            const collateralFactor =
              userMetricsData.collateralFactors[a.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol] ?? 0);

            return {
              asset: a,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;

  const newBorrowLimit =
    asset && loadedUserMetrics && borrowLimit && loadedUsdPrices
      ? getNewBorrowLimit({
          asset,
          borrowLimit,
          collateralFactor: userMetricsData.collateralFactors[asset.symbol],
          isEnabledAsCollateral: userMetricsData.isCollateral[asset.symbol],
          supplyAmount:
            userMetricsData.supplyAmounts[asset.symbol] ?? BigNumber.from(0),
          usdPrice: BigNumber.from(usdPrices[asset.symbol] ?? 0),
        })
      : null;
  const collateralBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserCollateralBalance(
          assets.map((a) => {
            const supplyAmount =
              userMetricsData.supplyAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[a.symbol] ?? false;

            return {
              asset: a,
              collateralAmount: supplyAmount,
              isCollateral: isEnabledAsCollateral,
              usdPrice,
            };
          })
        )
      : null;

  const action = useMemo(() => {
    if (!asset || !loadedUserMetrics || !borrowBalance || !newBorrowLimit) {
      return null;
    }

    if (userMetricsData.isCollateral[asset.symbol]) {
      if (isLiquidated(borrowBalance, newBorrowLimit)) {
        return 'dismiss';
      }

      return 'disable';
    }

    return 'enable';
  }, [
    asset,
    borrowBalance,
    newBorrowLimit,
    loadedUserMetrics,
    userMetricsData,
  ]);

  const { valid, reason } = validateCollateralToggle({
    action,
    asset,
    assetBorrowAmount: userMetricsData.borrowAmounts[symbol],
  });

  const { description, title, getButtonLabel } = useMemo(() => {
    if (action) {
      return STATIC_ELEMENTS[action];
    }

    return { description: '', title: '', getButtonLabel: (): string => '' };
  }, [action]);

  function getNewLtv(): string | null {
    if (action === 'dismiss') {
      return 'Liquidated';
    }

    if (
      asset &&
      borrowBalance &&
      collateralBalance &&
      (action === 'enable' || action === 'disable')
    ) {
      const mathFunction =
        action === 'enable'
          ? getPostEnableAsCollateralCollateralBalance
          : getPostDisableAsCollateralCollateralBalance;

      const newCollateralBalance = mathFunction(
        asset,
        collateralBalance,
        userMetricsData.supplyAmounts[asset.symbol] ?? BigNumber.from(0),
        BigNumber.from(usdPrices[asset.symbol] ?? 0)
      );

      if (newCollateralBalance) {
        const newLtv = formatPercent(
          formatRateToPercent(
            getLoanToValueRate(borrowBalance, newCollateralBalance)
          )
        );

        return newLtv;
      }
    }

    return null;
  }

  function getLavaBarPercent(): number {
    if (action === 'dismiss') {
      return 100;
    }

    if (borrowBalance) {
      if (newBorrowLimit) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, newBorrowLimit)
        );
      }

      if (borrowLimit) {
        return formatRateToPercent(
          getBorrowBalanceRate(borrowBalance, borrowLimit)
        );
      }
    }

    return 0;
  }

  return (
    <BaseTransactionModal
      isOpen={isOpen}
      onClose={onDismiss}
      title={title}
      {...props}
      transactionStatus={transactionStatus}
    >
      {action ? (
        <>
          <div className="mb-4">
            <Text className="text-body">{description}</Text>
          </div>
          <TransactionModalDataRow
            label={<Text variant="default">Borrow limit</Text>}
            loading={loadingUserMetrics || !loadedUsdPrices}
            value={
              borrowLimit && (
                <StartEndText
                  startValue={formatUserTotalUsdValue(borrowLimit)}
                  endValue={
                    newBorrowLimit
                      ? formatUserTotalUsdValue(newBorrowLimit)
                      : null
                  }
                />
              )
            }
          />
          <TransactionModalDataRow
            label={<Text variant="default">Current Loan to Value (LTV)</Text>}
            loading={loadingUserMetrics || !loadedUsdPrices}
            value={
              !!(borrowBalance && collateralBalance) && (
                <StartEndText
                  startValue={formatPercent(
                    formatRateToPercent(
                      getLoanToValueRate(borrowBalance, collateralBalance)
                    )
                  )}
                  endValue={getNewLtv()}
                />
              )
            }
          />
          <div className="mb-2">
            <BorrowLimitLavaBar value={getLavaBarPercent()} />
          </div>
          <Button
            className="mb-2 w-full"
            disabled={
              action === null ||
              loadingUserMetrics ||
              !loadedUsdPrices ||
              !valid
            }
            onClick={
              action === null
                ? undefined
                : (): void => {
                    if (action === 'enable') {
                      onEnableAsCollateral();
                    } else if (action === 'disable') {
                      onDisableAsCollateral();
                    } else {
                      onClose();
                    }
                  }
            }
          >
            {reason === 'insufficientLiquidity'
              ? 'Insufficient Liquidity'
              : getButtonLabel(symbol)}
          </Button>
        </>
      ) : (
        <div className="flex min-h-[150px] items-center justify-center">
          <Spinner />
        </div>
      )}
    </BaseTransactionModal>
  );
}

export default EnableOrDisableAsCollateralModal;
