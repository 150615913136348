import { useContext, useEffect, useRef } from 'react';

import useAppDispatch from '@hooks/useAppDispatch';
import useTectonicAssetPricer from '@hooks/useTectonicAssetPricer';
import { SupportedAssetsContext } from '@providers/SupportedAssetsProvider';
import { TectonicSdkContext } from '@providers/TectonicSdkProvider';
import { refreshAllAssetsUsdPrice } from '@redux/usdPrices';

// 1 minute
const INTERVAL_MS = 1000 * 60;

function useRefreshUsdPrices() {
  const sdk = useContext(TectonicSdkContext);
  const tectonicAssetPricer = useTectonicAssetPricer(sdk);
  const { list: assets } = useContext(SupportedAssetsContext);
  const intervalRef = useRef<number>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (assets.length) {
      intervalRef.current = window.setInterval((): void => {
        dispatch(refreshAllAssetsUsdPrice(tectonicAssetPricer, assets));
      }, INTERVAL_MS);
    }

    return (): void => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [dispatch, tectonicAssetPricer, assets]);
}

export default useRefreshUsdPrices;
