import { BigNumber } from 'ethers';
import { useContext } from 'react';
import { TectonicSDK } from '@tectonicfi/sdk';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';

import { Mode } from '@components/MarketsPageView/types';
import useWallets from '@hooks/useWallets';
import { SupportedAssetsContext } from '@providers/SupportedAssetsProvider';
import { TectonicSdkContext } from '@providers/TectonicSdkProvider';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { PoolType } from '@config/base';

import useSdkAndSupportedAssets from './useSdkAndSupportedAssets';

interface UseUserMetricsState {
  borrowAmounts: { [key: string]: BigNumber };
  borrowAmountsLoaded: boolean;
  collateralFactors: { [key: string]: number };
  collateralFactorsLoaded: boolean;
  isCollateral: { [key: string]: boolean };
  isCollateralLoaded: boolean;
  loading: boolean;
  supplyAmounts: { [key: string]: BigNumber };
  supplyTTokenAmounts: { [key: string]: BigNumber };
  supplyAmountsLoaded: boolean;
  supplyTTokenAmountsLoaded: boolean;
}

export interface UseUserMetricsResult {
  data: {
    borrowAmounts: UseUserMetricsState['borrowAmounts'];
    collateralFactors: UseUserMetricsState['collateralFactors'];
    isCollateral: UseUserMetricsState['isCollateral'];
    supplyAmounts: UseUserMetricsState['supplyAmounts'];
    supplyTTokenAmounts: UseUserMetricsState['supplyTTokenAmounts'];
  };
  loaded: boolean;
  loading: boolean;
  refetch(): void;
}

interface UseUserMetricsOptions {
  mode?: Mode;
  skip?: boolean;
}

export function useCollateralFactors(
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
) {
  const sdk = useContext(TectonicSdkContext);
  const { list: supportedAssets } = useContext(SupportedAssetsContext);
  const query = getQuery(QueryKey.TECTONIC_COLLATERAL_FACTORS)(
    sdk,
    supportedAssets
  );
  return useQuery(query.queryKey, query.queryFn, queryOptions);
}

export function useIsCollateral(
  address: string,
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
) {
  const sdk = useContext(TectonicSdkContext);
  const { list: supportedAssets } = useContext(SupportedAssetsContext);
  const query = getQuery(QueryKey.TECTONIC_IS_COLLATERAL)(
    sdk,
    supportedAssets,
    address
  );
  return useQuery(query.queryKey, query.queryFn, queryOptions);
}

export function useSupplyTtokenAmounts(
  address: string,
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
) {
  const sdk = useContext(TectonicSdkContext);
  const { list: supportedAssets } = useContext(SupportedAssetsContext);
  const query = getQuery(QueryKey.TECTONIC_TTOKEN_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  return useQuery(query.queryKey, query.queryFn, queryOptions);
}

export function useSupplyAmounts(
  address: string,
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
) {
  const sdk = useContext(TectonicSdkContext);
  const { list: supportedAssets } = useContext(SupportedAssetsContext);
  const query = getQuery(QueryKey.TECTONIC_USER_SUPPLY_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  return useQuery(query.queryKey, query.queryFn, queryOptions);
}

export function useBorrowAmounts(
  address: string,
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
) {
  const sdk = useContext(TectonicSdkContext);
  const { list: supportedAssets } = useContext(SupportedAssetsContext);
  const query = getQuery(QueryKey.TECTONIC_USER_BORROW_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  return useQuery(query.queryKey, query.queryFn, queryOptions);
}

function shouldEnableHook(
  sdk: TectonicSDK | null,
  supportedAssets: TectonicAsset[],
  queryOptions?: Partial<Pick<UseQueryOptions, 'enabled'>>
): boolean | undefined {
  return (
    !!sdk &&
    !!supportedAssets &&
    !!supportedAssets.length &&
    queryOptions?.enabled !== false
  );
}

function useUserMetrics(
  poolType: PoolType,
  options?: UseUserMetricsOptions
): UseUserMetricsResult {
  const { currentAccount: walletAddress } = useWallets();

  const { sdk, list: supportedAssets } = useSdkAndSupportedAssets(poolType);

  const enabled =
    !options?.skip && shouldEnableHook(sdk, supportedAssets) && !!walletAddress;

  const address = walletAddress as string;

  const collateralFactorsQuery = getQuery(QueryKey.TECTONIC_COLLATERAL_FACTORS)(
    sdk,
    supportedAssets
  );
  const borrowAmountsQuery = getQuery(QueryKey.TECTONIC_USER_BORROW_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  const supplyAmountsQuery = getQuery(QueryKey.TECTONIC_USER_SUPPLY_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  const supplyTtokenAmountsQuery = getQuery(QueryKey.TECTONIC_TTOKEN_AMOUNTS)(
    sdk,
    supportedAssets,
    address
  );
  const isCollateralQuery = getQuery(QueryKey.TECTONIC_IS_COLLATERAL)(
    sdk,
    supportedAssets,
    address
  );

  const results = useQueries([
    {
      queryKey: collateralFactorsQuery.queryKey,
      queryFn: collateralFactorsQuery.queryFn,
      enabled,
    },
    {
      queryKey: borrowAmountsQuery.queryKey,
      queryFn: borrowAmountsQuery.queryFn,
      enabled,
    },
    {
      queryKey: supplyAmountsQuery.queryKey,
      queryFn: supplyAmountsQuery.queryFn,
      enabled,
    },
    {
      queryKey: supplyTtokenAmountsQuery.queryKey,
      queryFn: supplyTtokenAmountsQuery.queryFn,
      enabled,
    },
    {
      queryKey: isCollateralQuery.queryKey,
      queryFn: isCollateralQuery.queryFn,
      enabled,
    },
  ]);

  return {
    data: {
      collateralFactors: results[0].data ?? {},
      borrowAmounts: results[1].data ?? {},
      supplyAmounts: results[2].data ?? {},
      supplyTTokenAmounts: results[3].data ?? {},
      isCollateral: results[4].data ?? {},
    },
    loaded: results.every((r) => r.isFetched),
    loading: !!results.find((r) => r.isLoading),
    refetch: () => {
      return Promise.all(results.map((r) => r.refetch()));
    },
  };
}

export default useUserMetrics;
