import type { AddEthereumChainParameter } from '@web3-wallet/react';

import { Network } from '@types';

export const MAINNET_EXPLORER_URL = 'https://explorer.cronos.org';
export const TESTNET_EXPLORER_URL = 'https://explorer.cronos.org/testnet';

export enum ChainId {
  Mainnet = 25,
  Testnet = 338,
}

interface EthereumChainParameter extends AddEthereumChainParameter {
  ensAddress: string;
}

export const cronosChainBaseConfig: Record<Network, EthereumChainParameter> = {
  [Network.cronos_local]: {
    chainId: ChainId.Mainnet, // 25 for forked mainnet, 1337 for full custom local
    chainName: 'Cronos Local',
    nativeCurrency: {
      name: 'tCRO',
      symbol: 'tCRO',
      decimals: 18,
    },
    rpcUrls: ['http://localhost:8545'],
    blockExplorerUrls: [TESTNET_EXPLORER_URL],
    ensAddress: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
  },
  [Network.cronos_testnet]: {
    chainId: ChainId.Testnet,
    chainName: 'Cronos Testnet',
    nativeCurrency: {
      name: 'tCRO',
      symbol: 'tCRO',
      decimals: 18,
    },
    rpcUrls: [
      'https://testnet.cronoslabs.com/v1/89433fdc930781343d3465d593a76dfd',
    ],
    blockExplorerUrls: [TESTNET_EXPLORER_URL],
    ensAddress: '0x16a23bFBcE9c53998c90201629E4cDB40B81B127',
  },
  [Network.cronos_uat]: {
    chainId: ChainId.Mainnet,
    chainName: 'Cronos',
    nativeCurrency: {
      name: 'CRO',
      symbol: 'CRO',
      decimals: 18,
    },
    rpcUrls: ['https://psta-forkenv.crorc.co/endpoints/cae4311b127864cb'],
    blockExplorerUrls: [MAINNET_EXPLORER_URL],
    ensAddress: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
  },
  [Network.cronos]: {
    chainId: ChainId.Mainnet,
    chainName: 'Cronos',
    nativeCurrency: {
      name: 'CRO',
      symbol: 'CRO',
      decimals: 18,
    },
    rpcUrls: [
      'https://mainnet.cronoslabs.com/v1/89433fdc930781343d3465d593a76dfd',
    ],
    blockExplorerUrls: [MAINNET_EXPLORER_URL],
    ensAddress: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
  },
};

export const RPC_URLS: Record<ChainId, string> = {
  [ChainId.Mainnet]: cronosChainBaseConfig[Network.cronos].rpcUrls[0],
  [ChainId.Testnet]: cronosChainBaseConfig[Network.cronos_testnet].rpcUrls[0],
};

export const networkConfigs = Object.keys(cronosChainBaseConfig).reduce<{
  [key: string]: EthereumChainParameter;
}>((acc, value) => {
  acc[value] = cronosChainBaseConfig[
    value as Network
  ] as EthereumChainParameter;
  return acc;
}, {});

export function getNetworkConfig(): EthereumChainParameter {
  if (process.env.NODE_ENV === 'test') {
    return networkConfigs['cronos_local'];
  }

  const network = process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK;

  if (network) {
    return networkConfigs[network];
  }

  throw new Error(`Please setup default network in env variable.`);
}
