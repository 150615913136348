import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';

export enum BoostType {
  SUPPLY,
  BORROW,
}
export default function useGetXTonicBoostDailyDistributeRate(
  tokenAddress: string,
  boostType: BoostType
) {
  const sdk = useTectonicSdk();
  const query = getQuery(QueryKey.GET_XTONIC_BOOST_DAILY_DISTRIBUTE_RATE)(
    sdk,
    tokenAddress,
    boostType
  );

  return useQuery(query.queryKey, query.queryFn);
}
