import { Icon, Tooltip } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import partial from 'lodash/partial';
import { TectonicVaultPool } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';

import { formatPercent } from '@lib/units';
import { poolToPeriod } from '@components/LockDepositModal/periods';
import { LockingPeriodDetails } from '@components/LockDepositModal/types';
import { getLockEndDate } from '@components/UpgradeDepositsModal/UpgradeDepositsModal';
import { LockingPeriod } from '@components/LockDepositModal/types';
import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';

interface Props {
  onSelectVaultTier: (params: LockingPeriodDetails) => void;
  selectedVault: LockingPeriodDetails | null;
  isLoading: boolean;
  pools: TectonicVaultPool[];
  lockedToValueAmount: string;
  lockedToUsdValue: string;
  lockedXTonicToValueAmount: string;
  exchangeRate?: BigNumber;
}

export default function VaultTier({
  onSelectVaultTier,
  selectedVault,
  pools,
  lockedToValueAmount,
  lockedXTonicToValueAmount,
  lockedToUsdValue,
}: Props) {
  return (
    <div className="flex flex-col text-body font-medium desktop:pb-[350px]">
      <div className="mb-3 w-full text-left text-white">Select vault tier</div>
      <span className="flex w-full flex-1 basis-1/2 flex-wrap justify-center gap-2 mobile:gap-x-1 mobile:gap-y-2">
        {pools
          ?.map((pool) => poolToPeriod(pool) as LockingPeriodDetails)
          ?.filter((item) => !!item)
          ?.map(({ apr, multiplier, period, poolId }) => (
            <VaultCard
              key={poolId}
              isActive={selectedVault?.poolId === poolId}
              poolId={poolId}
              period={period}
              apr={apr}
              multiplier={multiplier}
              onSelectVaultTier={onSelectVaultTier}
            />
          ))}
      </span>

      <div className="mt-3 text-body font-medium mobile:text-mediumSmall mobile:font-normal">
        <span className="text-medium text-white">You’ll lock</span>

        <span className="text-medium l mt-2 flex justify-between text-white">
          <div>
            TONIC{' '}
            <Tooltip
              className="z-50 inline text-left"
              message="TONIC will first be converted to xTONIC according to the TONIC:xTONIC exchange rate"
            >
              <Icon.Question className="inline h-3 w-3 align-middle" />
            </Tooltip>
          </div>

          <span className="text-right">
            <div className="mobile:font-semibold">{lockedToValueAmount}</div>
            <div className="mt-[4px] text-mediumSmall font-normal text-white60">
              {lockedXTonicToValueAmount} xTONIC
            </div>
            <div className="mt-[4px] text-mediumSmall font-normal text-white60">
              {lockedToUsdValue} USD
            </div>
          </span>
        </span>

        <div className="my-2 h-[1px] w-full bg-white12"></div>

        <span className="text-medium flex justify-between  text-white">
          <div>Lock end date</div>
          <div className="mobile:font-semibold">
            {getLockEndDate(new Date(), selectedVault?.period)}
          </div>
        </span>
      </div>
    </div>
  );
}

interface VaultCardPros {
  isActive: boolean;
  poolId: number;
  period: LockingPeriod;
  apr: number;
  multiplier: string;
  onSelectVaultTier: (params: LockingPeriodDetails) => void;
}

function VaultCard({
  isActive,
  onSelectVaultTier,
  poolId,
  period,
  multiplier,
  apr,
}: VaultCardPros) {
  return (
    <div
      className={clsx(
        'flex h-fit w-[148px] cursor-pointer flex-col items-center justify-center rounded-[5px] border-1 border-greyBorder pt-[21px] pb-2',
        {
          '!border-yellow': isActive,
        }
      )}
      onClick={partial(onSelectVaultTier, {
        poolId,
        period,
        apr,
        multiplier,
      })}
    >
      <Icon.Vault className="h-4 w-5 text-yellow" />
      <div className="mt-[5px] text-yellow">{getLockPeriodName(period)}</div>

      <div className="mt-1 text-small font-semibold">
        <span className="mr-[5px] text-white60">APR</span>
        <span className="text-white">{formatPercent(apr * 100)}</span>
      </div>

      <div className="mt-[5px] text-small  font-semibold">
        <span className="mr-[5px] text-white60">Multiplier</span>
        <span className="text-white">{multiplier}</span>
      </div>
    </div>
  );
}
