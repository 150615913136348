import { BigNumber } from 'ethers';
import { useCallback, useMemo, useReducer } from 'react';

import { TONIC_DECIMALS } from '@config/constants';
import { restrictInput } from '@lib/utils';

interface TonicStakingCardState {
  mode: 'stake' | 'unstake';
  tonicAmount: string;
}

const initState: TonicStakingCardState = {
  mode: 'stake',
  tonicAmount: '0',
};

const ON_TONIC_AMOUNT_CHANGE = 'ON_TONIC_AMOUNT_CHANGE';

const ON_MODE_CHANGE = 'ON_MODE_CHANGE';

interface OnModeChangeAction {
  type: typeof ON_MODE_CHANGE;
  value: TonicStakingCardState['mode'];
}

interface OnTonicAmountChangeAction {
  type: typeof ON_TONIC_AMOUNT_CHANGE;
  value: string;
}

type TonicStakingCardAction = OnModeChangeAction | OnTonicAmountChangeAction;

function reducer(
  state: TonicStakingCardState,
  action: TonicStakingCardAction
): TonicStakingCardState {
  switch (action.type) {
    case ON_MODE_CHANGE:
      return { ...state, mode: action.value, tonicAmount: '0' };
    case ON_TONIC_AMOUNT_CHANGE:
      return {
        ...state,
        tonicAmount: restrictInput(action.value, TONIC_DECIMALS),
      };
    default:
      return { ...state };
  }
}

export interface TonicStakingCardProps {
  hasError: boolean;
  loading: boolean;
  xTonicToTonicExchangeRate: BigNumber | null;
}

interface UseTonicStakingCardStateResult {
  onModeChange(v: TonicStakingCardState['mode']): void;
  onTonicAmountChange(v: string): void;
  state: TonicStakingCardState;
}

function useTonicStakingCardState(): UseTonicStakingCardStateResult {
  const [state, dispatch] = useReducer(reducer, initState);

  const onModeChange = useCallback(
    (value: TonicStakingCardState['mode']): void =>
      dispatch({ type: ON_MODE_CHANGE, value }),
    []
  );

  const onTonicAmountChange = useCallback(
    (value: string): void => dispatch({ type: ON_TONIC_AMOUNT_CHANGE, value }),
    []
  );

  return useMemo(
    () => ({
      onModeChange,
      onTonicAmountChange,
      state,
    }),
    [onModeChange, onTonicAmountChange, state]
  );
}

export default useTonicStakingCardState;
