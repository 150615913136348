import {
  Button,
  Icon,
  Modal,
  ModalProps,
  Text,
} from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import useIsMobile from '@hooks/useIsMobile';
import { MINTED_NETWORK_URL, NFT_BOOSTING_NFT_ADDRESS } from '@config/base';

export type ErrorType = 'WITHDRAW' | 'UPGRADE';

export default function EmptyNFTModal({
  isOpen,
  onClose,
}: Pick<ModalProps, 'isOpen' | 'onClose'>): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Modal
      className={clsx('w-[510px] desktop:h-[362px]', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw]':
          isMobile,
      })}
      isShowMobileSliderClose={isMobile}
      title="Stake NFT"
      variant="wallet"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col items-center gap-4 desktop:h-[260px]">
        <Icon.Info className="h-[60px] w-[60px] text-lavaPrimary" />
        <Text className="text-left">
          No Cronos Cruisers found in your wallet. To start, you first need an
          NFT
        </Text>
        <Button
          size={isMobile ? 'small' : 'medium'}
          className="mt-auto w-full text-center mobile:absolute mobile:bottom-2 mobile:w-[340px]"
          rel="noopener noreferrer"
          target="_blank"
          href={`${MINTED_NETWORK_URL}/collections/cronos/${NFT_BOOSTING_NFT_ADDRESS[0]}`}
        >
          Take me to Minted
        </Button>
      </div>
    </Modal>
  );
}
