import { Modal } from '@tectonicfi/tectonic-ui-kit';

import {
  walletConfigs,
  WalletConfig,
} from '@providers/WalletProvider/walletConfigs';

export interface ConnectWalletModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onSelectWallet: (walletConfig: WalletConfig) => void;
}

function ConnectWalletModal({
  isOpen,
  onClose,
  onSelectWallet,
}: ConnectWalletModalProps) {
  return (
    <Modal
      title="Connect Wallet"
      variant="wallet"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="mt-6 grid grid-cols-2 gap-y-6">
        {walletConfigs.map((walletConfig) => (
          <button
            key={walletConfig.name}
            className="flex min-h-[90px] min-w-[180px] flex-col items-center justify-start"
            onClick={() => {
              onSelectWallet(walletConfig);
            }}
          >
            <walletConfig.icon className="text-iconLarge h-[30px] w-[30px]" />
            <p className="mt-1.5 break-words text-center text-body text-white">
              {walletConfig.title}
            </p>
          </button>
        ))}
      </div>
    </Modal>
  );
}

export default ConnectWalletModal;
