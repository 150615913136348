import { useQuery } from 'react-query';
import { TectonicAsset } from '@tectonicfi/sdk';

import {
  WCRO_ADDRESS_MAINNET,
  WCRO_ADDRESS_TESTNET,
} from '@components/RepayModal/utils';
import { ChainId } from '@config/baseNetworkConfig';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';

export interface TokenCurrency {
  chainId: ChainId;
  address: string;
  decimals: number;
  symbol: string;
}

const useVvsSwapTrade = (
  amount: string,
  asset: Null<TectonicAsset>,
  slippageTolerance: number,
  assetOfCollateral?: Null<TectonicAsset>
) => {
  const getChainId = () => {
    return process.env.NEXT_PUBLIC_API_URL ===
      'https://psta-tectonic-api.3ona.co'
      ? ChainId.Testnet
      : ChainId.Mainnet;
  };

  const inputCurrency = asset && {
    decimals: asset?.decimals,
    symbol: asset?.symbol,
    chainId: getChainId(),
    address:
      asset?.symbol === 'CRO'
        ? getChainId() === ChainId.Testnet
          ? WCRO_ADDRESS_TESTNET
          : WCRO_ADDRESS_MAINNET
        : asset?.underlyingAddress,
  };

  const outputCurrency = assetOfCollateral && {
    address:
      assetOfCollateral?.symbol === 'CRO'
        ? getChainId() === ChainId.Testnet
          ? WCRO_ADDRESS_TESTNET
          : WCRO_ADDRESS_MAINNET
        : assetOfCollateral?.underlyingAddress,
    decimals: assetOfCollateral?.decimals,
    symbol: assetOfCollateral?.symbol,
    chainId: getChainId(),
  };

  const query = getQuery(QueryKey.VVS_SWAP_TRADE_DATA)(
    amount,
    inputCurrency,
    slippageTolerance,
    outputCurrency
  );

  const data = useQuery(
    [query.queryKey, amount, inputCurrency, outputCurrency, slippageTolerance],
    query.queryFn,
    {
      enabled: Boolean(
        inputCurrency &&
          Number(amount) > 0 &&
          slippageTolerance &&
          outputCurrency
      ),
    }
  );
  if (!asset || !assetOfCollateral || !slippageTolerance || !amount) {
    return null;
  }
  return data;
};

export default useVvsSwapTrade;
