import { Text, Button } from '@tectonicfi/tectonic-ui-kit';

import { WalletBalanceState } from '@hooks/useWalletBalance';
import TransactionModalDataRow from '@components/TransactionModalDataRow';
import { formatUserUnderlyingAmount } from '@lib/units';

export interface EnableErc20ContentProps {
  symbol: string;
  walletData: WalletBalanceState;
  onEnable(): void;
}

function EnableErc20Content({
  symbol,
  walletData,
  onEnable,
}: EnableErc20ContentProps): JSX.Element {
  return (
    <>
      <div className="mb-4">
        <Text>
          {`To supply or repay ${symbol} to the Tectonic Protocol, you need to enable it first.`}
        </Text>
      </div>

      <Button className="mb-2 w-full" onClick={onEnable}>
        {`Enable ${symbol} Market`}
      </Button>
      <TransactionModalDataRow
        label={<Text>Wallet balance</Text>}
        loading={walletData.loading}
        value={
          !!(walletData.asset && walletData.balance) && (
            <Text>
              {`${formatUserUnderlyingAmount(
                walletData.asset,
                walletData.balance
              )} ${symbol}`}
            </Text>
          )
        }
      />
    </>
  );
}

export default EnableErc20Content;
