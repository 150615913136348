import { TectonicAsset } from '@tectonicfi/sdk';
import { BigNumber } from 'ethers';
import get from 'lodash/get';

import { parseInputAmountToBN } from '@lib/units';
import { PoolType } from '@config/base';
import { TOKEN_INTEREST_INFOS } from '@config/TokenInterestInfo';

import useAppSelector from './useAppSelector';

const useDisableSupply = (
  poolType: PoolType,
  asset: Null<TectonicAsset>,
  increment?: string
): boolean => {
  const { supplyAmounts } = useAppSelector((state) => ({
    supplyAmounts: get(state, `${poolType}Markets.supplyAmounts`, {}),
  }));

  if (!asset) return true;

  const assetSupplyAmount = supplyAmounts[asset.symbol]
    ? BigNumber.from(supplyAmounts[asset.symbol])
    : null;
  const info = TOKEN_INTEREST_INFOS[poolType][asset.symbol];

  return (
    info?.supplyCap > 0 &&
    !!assetSupplyAmount
      ?.add(parseInputAmountToBN(increment || '0', asset.decimals))
      .div(BigNumber.from(10).pow(asset.decimals))
      .gte(info.supplyCap)
  );
};

export default useDisableSupply;
