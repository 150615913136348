import get from 'lodash/get';

import { Network } from '@types';

import testnetAbi from '../abi/mainPool/testnet.json';
import mainnetAbi from '../abi/mainPool/mainnet.json';
import uatnetAbi from '../abi/mainPool/uatnet.json';
import localAbi from '../abi/mainPool/local.json';
import testnetDeFiPoolAbi from '../abi/deFiPool/testnet.json';
import mainnetDeFiPoolAbi from '../abi/deFiPool/mainnet.json';
import uatnetDeFiPoolAbi from '../abi/deFiPool/uatnet.json';
import localDeFiPoolAbi from '../abi/deFiPool/local.json';
import localVenoPoolAbi from '../abi/venoPool/local.json';
import testnetVenoPoolAbi from '../abi/venoPool/testnet.json';
import uatnetVenoPoolAbi from '../abi/venoPool/uatnet.json';
import mainnetVenoPoolAbi from '../abi/venoPool/mainnet.json';

export type PoolType = 'MAIN' | 'VENO' | 'DEFI';

export function getAbi(): Record<PoolType, object> {
  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos:
      return {
        MAIN: mainnetAbi,
        DEFI: mainnetDeFiPoolAbi,
        VENO: mainnetVenoPoolAbi,
      };
    case Network.cronos_uat:
      return {
        MAIN: uatnetAbi,
        DEFI: uatnetDeFiPoolAbi,
        VENO: uatnetVenoPoolAbi,
      };
    case Network.cronos_local:
      return {
        MAIN: localAbi,
        DEFI: localDeFiPoolAbi,
        VENO: localVenoPoolAbi,
      };
    default:
      return {
        MAIN: testnetAbi,
        DEFI: testnetDeFiPoolAbi,
        VENO: testnetVenoPoolAbi,
      };
  }
}

export const getDeferLiquidityCheckAdapterAddress = (): string =>
  get(getAbi().MAIN, 'contracts')?.find(
    (item: Record<string, string>) =>
      item?.contractName === 'DeferLiquidityCheckAdapter'
  )?.address ?? '0xb39B5c1233C2a4722e88C4694833b880AA3D87eb';

export const getTectonicVaultProxy = (): string =>
  get(getAbi().MAIN, 'contracts')?.find(
    (item: Record<string, string>) =>
      item?.contractName === 'TectonicVaultProxy'
  )?.address ?? '0x8c47f73F495e573B1CEDEB1fa4ea9ABFf00C5e6E';

export const getTectonicSocketAddress = () =>
  Object.values(getAbi())
    .map((val) => get(val, 'contracts'))
    .reduce((acc: string[], val) => {
      const address = val.find(
        (i: Record<string, string>) => i?.contractName === 'TectonicSocket'
      )?.address;

      return address ? [...acc, address] : acc;
    }, []);

export const baseConfig = {};

export function getMetaMaskWalletDownloadUrl() {
  return 'https://metamask.app.link/dapp/app.tectonic.finance';
}

export const VVS_AIRDROP_SNAPSHOT_BASE_URL =
  process.env.NEXT_PUBLIC_VVS_AIRDROP_SNAPSHOT_BASE_URL ?? '';

const NFT_BOOSTING_NFT_ADDRESSES: Record<Network, string[]> = {
  [Network.cronos_local]: ['0xA16413bA46AB8aD6BEd893c4979a3D8672Ad125E'],
  [Network.cronos_testnet]: ['0xA16413bA46AB8aD6BEd893c4979a3D8672Ad125E'],
  [Network.cronos_uat]: ['0xd25358e2cad3e1fd165887569892a99fffa674ac'],
  [Network.cronos]: ['0xd25358e2cad3e1fd165887569892a99fffa674ac'],
};
export const NFT_BOOSTING_NFT_ADDRESS: string[] =
  NFT_BOOSTING_NFT_ADDRESSES[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];

const MINTED_NETWORK_URLS: Record<Network, string> = {
  [Network.cronos_local]: 'https://msta-minted.crorc.co',
  [Network.cronos_testnet]: 'https://msta-minted.crorc.co',
  [Network.cronos_uat]: 'https://muat-minted.3ona.co',
  [Network.cronos]: 'https://minted.network',
};
export const MINTED_NETWORK_URL: string =
  MINTED_NETWORK_URLS[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];

const VENO_FINANCE_URLS: Record<Network, string> = {
  [Network.cronos_local]: 'https://psta1-veno.crorc.co',
  [Network.cronos_testnet]: 'https://psta1-veno.crorc.co',
  [Network.cronos_uat]: 'https://puat-veno.crolabs-int.co',
  [Network.cronos]: 'https://veno.finance',
};
export const VENO_FINANCE_URL: string =
  VENO_FINANCE_URLS[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];

const FULCROM_FINANCE_URLS: Record<Network, string> = {
  [Network.cronos_local]: 'https://pdev-fulcrom.crorc.co',
  [Network.cronos_testnet]: 'https://psta1-fulcrom.crorc.co',
  [Network.cronos_uat]: 'https://puat-fulcrom.crolabs-int.co',
  [Network.cronos]: 'https://fulcrom.finance',
};
export const FULCROM_FINANCE_URL: string =
  FULCROM_FINANCE_URLS[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];

const ORBY_FINANCE_URLS: Record<Network, string> = {
  [Network.cronos_local]: 'https://pdev-orbit-app.crorc.co/',
  [Network.cronos_testnet]: 'https://psta1-orbit-app.crorc.co/',
  [Network.cronos_uat]: 'https://puat-orbit-app.crolabs-int.co/',
  [Network.cronos]: 'https://orby.network/',
};
export const ORBY_FINANCE_URL: string =
  ORBY_FINANCE_URLS[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];

export const WHITEPAPER_URL =
  'https://tectonic.gitbook.io/docs/introduction/what-is-tectonic';
const FERRO_FINANCE_URLS: Record<Network, string> = {
  [Network.cronos_local]: 'https://psta1-ferro.crorc.co/',
  [Network.cronos_testnet]: 'https://psta1-ferro.crorc.co/',
  [Network.cronos_uat]: 'https://puat-ferro.crolabs-int.co/',
  [Network.cronos]: 'https://ferroprotocol.com/',
};
export const FERRO_FINANCE_URL: string =
  FERRO_FINANCE_URLS[
    (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK ??
      'cronos_testnet') as Network
  ];
