import { useEffect, useMemo, useState, useCallback } from 'react';
import clsx from 'clsx';
import { BigNumber, utils } from 'ethers';
import get from 'lodash/get';
import { TectonicAsset } from '@tectonicfi/sdk';
import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';

import TransactionModalsProvider, {
  TransactionModalsContext,
} from '@providers/TransactionModalsProvider/TransactionModalsProvider';
import { BaseTransactionModalProps } from '@components/BaseTransactionModal';
import BaseTransactionModal from '@components/BaseTransactionModal/BaseTransactionModal';
import TransactionModalDataRow from '@components/TransactionModalDataRow/TransactionModalDataRow';
import StartEndText from '@components/StartEndText/StartEndText';
import { Mode } from '@components/MarketsPageView/types';
import useUserMetrics from '@hooks/useUserMetrics';
import {
  BLACKLIST_LONG_TOKENS,
  VVS_DEX_CONTRACT_ADDRESS,
} from '@config/constants';
import {
  formatPercent,
  formatRateToPercent,
  formatTotalUnderlyingUsdValue,
  formatUnits,
  getUsdPriceDecimals,
  parseInputAmountToBN,
} from '@lib/units';
import useWalletBalance from '@hooks/useWalletBalance';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useSocketAddress from '@hooks/useSocketAddress';
import useUsdPrices from '@hooks/useUsdPrices';
import useVvsSwapTrade from '@hooks/useVVSSwapTrade';
import useWowmaxSwapTrade from '@hooks/useWowmaxSwapTrade';
import useSupplyApy from '@hooks/useSupplyApy';
import useBorrowApy from '@hooks/useBorrowApy';
import useAppSelector from '@hooks/useAppSelector';
import {
  getBorrowBalanceRate,
  getLoanToValueRate,
  getMarketLiquidity,
  getPostBorrowBorrowBalance,
  getPostEnableAsCollateralCollateralBalance,
  getPostSupplyBorrowLimit,
  getTotalBorrowLimit,
  getUnderlyingUsdValue,
  getUserBorrowBalance,
} from '@lib/math';
import { determineSupplyMaxAmount } from '@lib/maxLogic';
import {
  getSwapPath,
  getUnderlyingAddress,
} from '@components/RepayModal/utils';
import useTotalSupplyBorrow from '@components/DashboardPageView/useTotalSupplyBorrow';
import { ChainId } from '@config/baseNetworkConfig';

import SelectLongAsset from './SelectLongAsset';
import SelectBorrowLevel from './SelectBorrowLevel';
import SelectDexes from './SelectDexes';
import { Dexes, isMaxSupplyCap, isOverflowBalance } from './utils';

export interface ShortModalProps
  extends Omit<BaseTransactionModalProps, 'children' | 'title'> {
  asset: TectonicAsset | null;
  amount: string;
  onShortLongToken: (
    tectonicSocketAddress: string,
    longAmount: BigNumber,
    shortAmount: BigNumber,
    slippageTolerance: BigNumber,
    dexes: string[],
    paths: string[][],
    isLongCRO: boolean
  ) => void;
  onShortLongPositionByWowmax: (
    tectonicSocketAddress: string,
    longAmount: BigNumber,
    shortAmount: BigNumber,
    longToken: string,
    shortToken: string,
    swapData: string,
    isLongCRO: boolean
  ) => void;
  onGetTTokenAmountOut: (
    tectonicSocketAddress: string,
    fromAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ) => Promise<[BigNumber, BigNumber]>;
  onAmountChange(value: string): void;
  onEnableLongToken(
    value: TectonicAsset,
    amount: string
  ): Promise<string | undefined>;
  onEnableAsCollateral(data: TectonicAsset): void;
  mode: Mode;
}

const ShortModal = ({
  asset,
  amount,
  mode,
  onShortLongToken,
  onShortLongPositionByWowmax,
  onGetTTokenAmountOut,
  onAmountChange,
  onEnableLongToken,
  onEnableAsCollateral,
  transactionStatus,
  ...props
}: ShortModalProps) => {
  const symbol = asset?.symbol || '';
  const [swapToAmount, setSwapToAmount] = useState<Null<BigNumber>>(null);
  const [longTokenSymbol, setLongTokenData] = useState<string>('');
  const [borrowLevel, setBorrowLevel] = useState(0);
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.005);
  const [isCollapseTolerance, setCollapseTolerance] = useState<boolean>(true);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [dexes, setDexes] = useState<Dexes>(Dexes.vvs);
  const [selectStep, setSelectStep] = useState({
    selectedLongAsset: false,
    selectedBorrowLevel: false,
    selectedDexes: false,
  });

  const options = useMemo(
    () => ({ skip: !props.isOpen, mode }),
    [props.isOpen, mode]
  );
  const { list: assets } = useSdkAndSupportedAssets(mode);
  const { loaded: loadedUsdPrices, usdPrices } = useUsdPrices(mode);
  const tectonicSocketAddress = useSocketAddress(mode);

  const { borrowAmounts, supplyAmounts } = useAppSelector((state) => ({
    supplyAmounts: get(state, `${mode.toLowerCase()}Markets.supplyAmounts`),
    borrowAmounts: get(state, `${mode.toLowerCase()}Markets.borrowAmounts`),
  }));

  const { data: userMetricsData, loaded: loadedUserMetrics } =
    useUserMetrics(mode);

  const {
    supplyBalance: supplyBalanceMainPool,
    supplyBalanceLcroPool,
    supplyBalanceDeFiPool,
  } = useTotalSupplyBorrow();

  const borrowBalance =
    loadedUserMetrics && loadedUsdPrices
      ? getUserBorrowBalance(
          assets.map((a) => {
            const borrowAmount =
              userMetricsData.borrowAmounts[a.symbol] ?? BigNumber.from(0);
            const usdPrice = BigNumber.from(usdPrices[a.symbol]);
            return { asset: a, borrowAmount, usdPrice };
          })
        )
      : null;

  const borrowLimit: Null<BigNumber> =
    loadedUserMetrics && loadedUsdPrices
      ? getTotalBorrowLimit(
          assets.map((data) => {
            const collateralFactor =
              userMetricsData.collateralFactors[data.symbol] ?? 0;
            const isEnabledAsCollateral =
              userMetricsData.isCollateral[data.symbol] ?? false;
            const supplyAmount =
              userMetricsData.supplyAmounts[data.symbol] ?? BigNumber.from(0);
            const usdPrice =
              BigNumber.from(usdPrices[data.symbol]) ?? BigNumber.from(0);

            return {
              asset: data,
              collateralFactor,
              isEnabledAsCollateral,
              supplyAmount,
              usdPrice,
            };
          })
        )
      : null;

  useEffect(() => {
    if (borrowBalance && borrowLimit) {
      setBorrowLevel(
        Math.round(
          formatRateToPercent(
            getBorrowBalanceRate(borrowBalance, borrowLimit) + 0.01
          )
        )
      );
    }
  }, []);

  const selectOptionsOfLongToken = useMemo(
    () =>
      Object.keys(userMetricsData.isCollateral).filter(function (key) {
        return (
          key !== symbol &&
          !BLACKLIST_LONG_TOKENS.includes(key) &&
          userMetricsData.borrowAmounts[key]?.eq(0) &&
          !isMaxSupplyCap(mode, key, assets, supplyAmounts[key])
        );
      }),
    [userMetricsData, symbol, mode, assets, supplyAmounts]
  );

  const onMaxClick = () => {
    if (swapToLongToken && walletData?.balance) {
      onAmountChange(
        determineSupplyMaxAmount(swapToLongToken, {
          walletBalance: walletData.balance,
        })
      );
    } else {
      onAmountChange('0');
    }
  };

  const swapToLongToken = useMemo(() => {
    if (longTokenSymbol) {
      return assets.find((a) => a.symbol === longTokenSymbol) ?? null;
    }

    return null;
  }, [longTokenSymbol, assets]);

  const { data: walletData } = useWalletBalance(mode, swapToLongToken, options);

  const { netSupplyApy } = useSupplyApy(mode, swapToLongToken);

  const { netBorrowApy } = useBorrowApy(mode, asset, options);

  function getNewBorrowLimit(): Null<BigNumber> {
    if (swapToLongToken && borrowLimit && longTokenSymbol) {
      const newBorrowLimit = getPostSupplyBorrowLimit(
        swapToLongToken,
        borrowLimit,
        amount,
        BigNumber.from(usdPrices[longTokenSymbol]),
        userMetricsData.collateralFactors[longTokenSymbol],
        userMetricsData.isCollateral[longTokenSymbol]
      );

      if (!newBorrowLimit) {
        return null;
      }

      return newBorrowLimit;
    }

    return null;
  }

  function getNewBorrowBalance(): BigNumber | null {
    if (asset && borrowBalance) {
      const newBorrowBalance = getPostBorrowBorrowBalance(
        asset,
        borrowBalance,
        shortTokenAmount,
        BigNumber.from(usdPrices[symbol])
      );

      if (!newBorrowBalance) {
        return null;
      }

      return newBorrowBalance;
    }

    return null;
  }

  const isReachedMaxLiquidity = () => {
    const marketLiquidityAmount = asset
      ? getMarketLiquidity(
          BigNumber.from(supplyAmounts[asset.symbol]),
          BigNumber.from(borrowAmounts[asset.symbol])
        )
      : null;
    const marketLiquidityUSDAmount =
      asset &&
      marketLiquidityAmount &&
      getUnderlyingUsdValue(
        asset,
        marketLiquidityAmount,
        BigNumber.from(usdPrices[asset.symbol])
      );
    const shortTokenUSDAmount =
      asset &&
      getUnderlyingUsdValue(
        asset,
        parseInputAmountToBN(shortTokenAmount, asset?.decimals),
        BigNumber.from(usdPrices[asset.symbol])
      );

    return marketLiquidityUSDAmount
      ? shortTokenUSDAmount?.gt(marketLiquidityUSDAmount)
      : false;
  };

  const shortTokenAmount = useMemo(() => {
    const newBorrowLimit =
      Number(amount) > 0 ? getNewBorrowLimit() : borrowLimit;
    if (newBorrowLimit && asset && longTokenSymbol && borrowBalance) {
      const newBorrowLevel = borrowLevel / 100;

      const newBorrowLimitNumber = Number(
        utils.formatUnits(newBorrowLimit, 18)
      );
      const newBorrowBalanceNumber = Number(
        utils.formatUnits(borrowBalance, 18)
      );
      const usdPriceDecimals = getUsdPriceDecimals(asset);

      const data =
        newBorrowBalanceNumber - newBorrowLevel * newBorrowLimitNumber;

      const convertedUsdPrice = utils.formatUnits(
        BigNumber.from(usdPrices[asset.symbol]),
        usdPriceDecimals
      );
      const numerator = Number(data) / Number(convertedUsdPrice);

      const denominator =
        newBorrowLevel *
          (userMetricsData.collateralFactors[longTokenSymbol] / 100) -
        1;
      const result = (numerator / denominator).toFixed(4);
      return result;
    }
    return '0';
  }, [swapToLongToken, longTokenSymbol, amount, borrowLevel, userMetricsData]);

  const getShortDisplay = () => {
    if (asset && shortTokenAmount) {
      const ShortUsdValue = formatTotalUnderlyingUsdValue(
        asset,
        getUnderlyingUsdValue(
          asset,
          parseInputAmountToBN(shortTokenAmount, asset?.decimals),
          BigNumber.from(usdPrices[asset.symbol])
        )
      );
      return `${ShortUsdValue}  (${shortTokenAmount} ${asset.symbol})`;
    }
    return '';
  };

  const getLeverage = () => {
    if (shortTokenAmount && asset && swapToLongToken && FinalLongTokenAmount) {
      let supplyBalance: BigNumber;
      switch (mode) {
        case 'DEFI':
          supplyBalance = supplyBalanceDeFiPool;
          break;
        case 'VENO':
          supplyBalance = supplyBalanceLcroPool;
          break;
        case 'MAIN':
        default:
          supplyBalance = supplyBalanceMainPool;
      }
      const newBorrowLimit = getPostEnableAsCollateralCollateralBalance(
        swapToLongToken,
        supplyBalance,
        parseInputAmountToBN(FinalLongTokenAmount, swapToLongToken?.decimals),
        BigNumber.from(usdPrices[swapToLongToken.symbol] ?? 0)
      );
      const newBorrowBalance = getNewBorrowBalance();
      if (newBorrowBalance && newBorrowLimit) {
        const currentCollateralFactor = getLoanToValueRate(
          newBorrowBalance,
          newBorrowLimit
        );
        return `${(1 / (1 - currentCollateralFactor)).toFixed(2)}x`;
      }
    }
    return '';
  };

  const getNetAPY = () => {
    if (
      shortTokenAmount &&
      asset &&
      swapToLongToken &&
      FinalLongTokenAmount &&
      netSupplyApy &&
      netBorrowApy
    ) {
      const shortUsdValue = getUnderlyingUsdValue(
        asset,
        parseInputAmountToBN(shortTokenAmount, asset?.decimals),
        BigNumber.from(usdPrices[asset.symbol])
      );

      const longUsdValue = getUnderlyingUsdValue(
        swapToLongToken,
        parseInputAmountToBN(FinalLongTokenAmount, swapToLongToken?.decimals),
        BigNumber.from(usdPrices[swapToLongToken.symbol])
      );
      const supplyUsdApy =
        Number(
          formatUnits(longUsdValue, getUsdPriceDecimals(swapToLongToken))
        ) * netSupplyApy;
      const borrowUsdApy =
        Number(formatUnits(shortUsdValue, getUsdPriceDecimals(asset))) *
        netBorrowApy;
      const result =
        (supplyUsdApy - borrowUsdApy) /
        Number(formatUnits(longUsdValue, getUsdPriceDecimals(swapToLongToken)));
      return result;
    }
    return 0;
  };

  const getTokenAmountOut = useCallback(async () => {
    if (shortTokenAmount && asset && swapToLongToken) {
      const tTokenAmountOut = await onGetTTokenAmountOut(
        tectonicSocketAddress,
        parseInputAmountToBN(shortTokenAmount, asset?.decimals),
        utils.parseUnits(slippageTolerance.toString(), 18),
        getSwapPath(swapToLongToken, asset)
      );

      return {
        tTokenAmount: tTokenAmountOut[0],
        amountOutMin: tTokenAmountOut[1],
      };
    }

    return {
      tTokenAmount: BigNumber.from(0),
      amountOutMin: BigNumber.from(0),
    };
  }, [
    onGetTTokenAmountOut,
    tectonicSocketAddress,
    swapToLongToken,
    slippageTolerance,
    shortTokenAmount,
  ]);

  useEffect(() => {
    if (asset && swapToLongToken && slippageTolerance) {
      getTokenAmountOut().then((tokenAmountOut) => {
        if (tokenAmountOut) {
          setSwapToAmount(tokenAmountOut.amountOutMin);
        }
      });
    }
  }, [
    asset,
    swapToLongToken,
    slippageTolerance,
    getTokenAmountOut,
    borrowLevel,
  ]);

  const vvsData = useVvsSwapTrade(
    shortTokenAmount,
    asset,
    slippageTolerance,
    swapToLongToken
  );

  const wowmaxData = useWowmaxSwapTrade(
    shortTokenAmount,
    formatRateToPercent(slippageTolerance).toString(),
    ChainId.Mainnet,
    longTokenSymbol ?? '',
    symbol
  );
  const swapDetails = useMemo(() => {
    return dexes === Dexes.vvs ? vvsData : wowmaxData;
  }, [vvsData, wowmaxData, dexes]);

  const FinalLongTokenAmount = useMemo(() => {
    if (dexes === Dexes.wowmax) {
      if (swapToLongToken && amount && wowmaxData?.data) {
        const convertWowmaxAmountOut = formatUnits(
          wowmaxData?.data?.amountOut[0],
          swapToLongToken?.decimals
        );
        return (Number(amount) + Number(convertWowmaxAmountOut)).toFixed(4);
      }
    } else if (swapToLongToken && swapToAmount && amount) {
      const bnInputAmount = parseInputAmountToBN(
        amount,
        swapToLongToken?.decimals
      );
      const finalLongBalance = bnInputAmount.add(swapToAmount);
      return Number(
        utils.formatUnits(finalLongBalance, swapToLongToken.decimals)
      ).toFixed(4);
    }
  }, [dexes, swapToLongToken, wowmaxData?.data, amount, swapToAmount]);

  const getLongDisplay = () => {
    if (swapToLongToken && FinalLongTokenAmount) {
      const finalUsdValue = formatTotalUnderlyingUsdValue(
        swapToLongToken,
        getUnderlyingUsdValue(
          swapToLongToken,
          parseInputAmountToBN(FinalLongTokenAmount, swapToLongToken?.decimals),
          BigNumber.from(usdPrices[swapToLongToken.symbol])
        )
      );
      return `${finalUsdValue} (${FinalLongTokenAmount} ${swapToLongToken.symbol})`;
    }
    return '';
  };

  const isEmptyWalletAndCollateral = useMemo(() => {
    if (borrowLimit?.isZero() && walletData?.balance?.isZero()) {
      return true;
    }
    return false;
  }, [swapToLongToken, walletData?.balance, borrowLimit, amount]);

  const getWarningText = useCallback(() => {
    switch (true) {
      case isEmptyWalletAndCollateral:
        return 'Currently your existing collateral and wallet balance are 0. Please choose another token with balance';
      default:
        return '';
    }
  }, [isEmptyWalletAndCollateral]);

  const huddleShortCta = useCallback(async () => {
    if (shortTokenAmount && asset && swapToLongToken) {
      if (swapToLongToken.symbol !== 'CRO') {
        await onEnableLongToken(swapToLongToken, amount);
      }

      if (!userMetricsData.isCollateral[swapToLongToken.symbol]) {
        onEnableAsCollateral(swapToLongToken);
      }

      if (dexes === Dexes.vvs) {
        onShortLongToken(
          tectonicSocketAddress,
          parseInputAmountToBN(amount, swapToLongToken?.decimals),
          parseInputAmountToBN(shortTokenAmount, asset.decimals),
          utils.parseUnits(slippageTolerance.toString(), 18),
          VVS_DEX_CONTRACT_ADDRESS,
          [getSwapPath(swapToLongToken, asset)],
          swapToLongToken.symbol === 'CRO' ? true : false
        );
      } else {
        onShortLongPositionByWowmax(
          tectonicSocketAddress,
          parseInputAmountToBN(amount, swapToLongToken?.decimals),
          parseInputAmountToBN(shortTokenAmount, asset.decimals),
          getUnderlyingAddress(swapToLongToken),
          getUnderlyingAddress(asset),
          wowmaxData?.data?.data,
          swapToLongToken.symbol === 'CRO' ? true : false
        );
      }
    }
  }, [
    shortTokenAmount,
    swapToLongToken,
    wowmaxData,
    onEnableLongToken,
    onEnableAsCollateral,
  ]);

  const displayCtaButton = () => {
    if (
      swapToLongToken &&
      !userMetricsData.isCollateral[swapToLongToken.symbol]
    ) {
      return (
        <TransactionModalsProvider>
          <TransactionModalsContext.Consumer>
            {({ onOpenModal }) => (
              <Button
                className="w-full"
                onClick={() => {
                  onOpenModal(swapToLongToken, 'collateral');
                }}
              >
                Enable {swapToLongToken.symbol} Market
              </Button>
            )}
          </TransactionModalsContext.Consumer>
        </TransactionModalsProvider>
      );
    }

    return (
      <span className="flex flex-col gap-y-1.5">
        <div
          className={clsx('flex gap-x-1', {
            hidden: !getWarningText(),
          })}
        >
          <Icon.Warn width={18} height={18} />
          <div className="text-mediumSmall font-normal text-rainbowRed">
            {getWarningText()}
          </div>
        </div>
        <Button
          className="w-full"
          theme="pink"
          disabled={
            isOverflowBalance(walletData, amount) ||
            isEmptyWalletAndCollateral ||
            !swapToLongToken ||
            borrowLevel <= 0 ||
            !termsChecked ||
            Number(shortTokenAmount) <= 0 ||
            isReachedMaxLiquidity()
          }
          onClick={huddleShortCta}
        >
          Short
        </Button>
      </span>
    );
  };

  return (
    <BaseTransactionModal
      className="max-h-[calc(100%-1rem)] desktop:!w-[510px]"
      title={`Short ${symbol}`}
      {...props}
      transactionStatus={transactionStatus}
    >
      <SelectLongAsset
        selectStep={selectStep}
        setSelectStep={setSelectStep}
        walletData={walletData}
        amount={amount}
        onAmountChange={onAmountChange}
        longTokenSymbol={longTokenSymbol}
        selectOptionsOfAssets={selectOptionsOfLongToken}
        onMaxClick={onMaxClick}
        setLongTokenData={setLongTokenData}
        asset={asset}
        borrowLimit={borrowLimit}
      />

      <SelectBorrowLevel
        selectStep={selectStep}
        setSelectStep={setSelectStep}
        borrowLevel={borrowLevel}
        setBorrowLevel={setBorrowLevel}
        shortTokenAmount={shortTokenAmount}
      />

      <SelectDexes
        selectStep={selectStep}
        setSelectStep={setSelectStep}
        dexes={dexes}
        setDexes={setDexes}
        termsChecked={termsChecked}
        setTermsChecked={setTermsChecked}
        slippageTolerance={slippageTolerance}
        setSlippageTolerance={setSlippageTolerance}
        isCollapseTolerance={isCollapseTolerance}
        setCollapseTolerance={setCollapseTolerance}
        swapToLongToken={swapToLongToken}
        asset={asset}
        swapDetails={swapDetails}
      />

      <div className="mt-7" />

      <TransactionModalDataRow
        label={<Text variant="default">Leverage</Text>}
        loading={false}
        value={<StartEndText startValue={getLeverage()} endValue={''} />}
      />

      <TransactionModalDataRow
        label={<Text variant="default">Final long balance</Text>}
        loading={false}
        value={<StartEndText startValue={getLongDisplay()} endValue={''} />}
      />
      <TransactionModalDataRow
        label={<Text variant="default">Final short balance</Text>}
        loading={false}
        value={<StartEndText startValue={getShortDisplay()} endValue={''} />}
      />
      <TransactionModalDataRow
        label={<Text variant="default">Net APY</Text>}
        loading={false}
        value={
          <StartEndText startValue={formatPercent(getNetAPY())} endValue={''} />
        }
      />
      {displayCtaButton()}
    </BaseTransactionModal>
  );
};

export default ShortModal;
