import { useState } from 'react';
import clsx from 'clsx';

import ModeSwitch from '@components/ModeSwitch';
import TonicPageView from '@components/TonicPageView';
import VaultsPageView from '@components/VaultsPageView';
import { VaultsBlockState } from '@components/VaultsPageView/VaultsBlock/types';
import useIsMobile from '@hooks/useIsMobile';

import { Mode, modes } from './types';
import { INITIAL_VAULTS_BLOCK_STATE, VaultsBlockStateContext } from './utils';

interface TonicVaultsContainerProps {
  mode: Mode;
  onModeChange(mode: Mode): void;
  className?: string;
}

function TonicVaultsContainer({
  mode,
  onModeChange,
}: TonicVaultsContainerProps): JSX.Element {
  const isMobile = useIsMobile();
  const [vaultsBlockState, setVaultsBlockState] = useState<VaultsBlockState>(
    INITIAL_VAULTS_BLOCK_STATE
  );
  return (
    <div className="mobile:pb-3">
      <div
        className={clsx('mb-3 bg-darkerBlue pt-3 mobile:bg-transparent', {
          'mobile:hidden': vaultsBlockState.activeView !== 'PoolOverview',
        })}
      >
        <ModeSwitch
          mode={mode}
          onModeChange={onModeChange}
          className="max-w-[170px] mobile:max-w-[240px]"
          modes={modes.filter(
            (item) => isMobile || [Mode.vaults, Mode.tonic].includes(item.mode)
          )}
          variant="tabs"
        />
      </div>
      {mode === Mode.tonic ? (
        <TonicPageView />
      ) : (
        <VaultsBlockStateContext.Provider
          value={{ vaultsBlockState, setVaultsBlockState }}
        >
          <VaultsPageView mode={mode} />
        </VaultsBlockStateContext.Provider>
      )}
    </div>
  );
}

export default TonicVaultsContainer;
