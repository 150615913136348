import { Text } from '@tectonicfi/tectonic-ui-kit';

import Spinner from '@components/Spinner';

function FullScreenLoader(): JSX.Element {
  return (
    <div className="mt-[40vh] flex w-full flex-col items-center justify-center">
      <Spinner />
      <Text className="mt-4">Loading data, please wait...</Text>
    </div>
  );
}

export default FullScreenLoader;
