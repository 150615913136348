import clsx from 'clsx';

interface SpinnerProps {
  className?: string;
  heightClassName?: string;
  widthClassName?: string;
}

function Spinner({
  className,
  heightClassName = 'h-5',
  widthClassName = 'w-5',
}: SpinnerProps): JSX.Element {
  return (
    <svg
      className={clsx(
        'animate-spin rounded-full border-4 border-solid border-yellowPrimary',
        {
          [heightClassName]: !!heightClassName,
          [widthClassName]: !!widthClassName,
        },
        className
      )}
      style={{ borderTopColor: 'transparent' }}
      viewBox="0 0 24 24"
      data-testid="spinner"
    />
  );
}

export default Spinner;
