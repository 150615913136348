import React, {
  ReactEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Icon,
  Text,
  Skeleton,
  Tooltip,
} from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { utils } from 'ethers';

import { secondsToPeriod } from '@components/LockDepositModal/periods';
import { getLockPeriodName } from '@components/LockDepositModal/LockingPeriodCard';
import {
  useGetUserNftBoostMultiplier,
  useStakedNFTTokenIds,
} from '@hooks/useStakeNFT';
import useIsMobile from '@hooks/useIsMobile';
import {
  formatPercent,
  formatRateToPercent,
  formatXTonic,
  formatMantissa,
} from '@lib/units';
import useNFT from '@components/VaultsPageView/StakingNFT/useNFT';
import useXtonicStatsData from '@components/AnalyticsPageView/XTonicAnalytics/useXtonicStatsData';
import { TxStatus } from '@types';

import { StakingNFTCard, MAX_STAKING_NFT } from '../StakingNFT';
import ArrowUpSVG from '../../../public/assets/images/arrow-up.svg';
import ArrorDownSVG from '../../../public/assets/images/arrow-down.svg';

import { PoolOfUser } from './types';
import { PoolOverviewProps } from './PoolOverview';

const PoolInfo: React.FC<{
  pool: PoolOfUser;
  hasStakes: boolean;
  multiplierData: number;
  stakedNftAmount: number;
  className?: string;
  onDepositMore: ReactEventHandler;
}> = ({
  multiplierData,
  hasStakes,
  pool,
  className,
  onDepositMore,
  stakedNftAmount,
}) => {
  const yellowColor = hasStakes ? 'text-rainbowYellow' : 'text-semiTransparent';
  const textHighlightColor = clsx({
    'text-white': hasStakes,
    'text-semiTransparent': !hasStakes,
    'text-green': multiplierData > 1,
  });
  return (
    <div className={clsx('mb-2 flex items-center justify-between', className)}>
      <div className="text-small font-medium text-semiTransparent">
        <div className="flex gap-y-2 gap-x-4">
          <div>
            APR{' '}
            <span className={textHighlightColor}>
              {formatPercent(formatRateToPercent(pool.apy * multiplierData))}
            </span>
          </div>
          <div>
            Multiplier{' '}
            <span className={textHighlightColor}>
              {formatMantissa(pool.multiplier * multiplierData)}x
            </span>
          </div>
        </div>
        <div className="mt-3 flex gap-y-2 gap-x-4">
          <div>
            xTONIC{' '}
            <span className={textHighlightColor}>
              {formatXTonic(pool.totalStaked)}
            </span>
          </div>
          <div className="mobile:hidden">
            NFT Staked{' '}
            <span className={clsx({ 'text-white': hasStakes })}>
              {stakedNftAmount}
            </span>
          </div>
        </div>
      </div>

      <div className="mobile:hidden">
        {hasStakes ? (
          <Icon.ChevronDown
            height="24px"
            width="24px"
            className={clsx(yellowColor, '-rotate-90 mobile:hidden')}
          />
        ) : (
          <Button onClick={onDepositMore} size="small">
            Deposit
          </Button>
        )}
      </div>
    </div>
  );
};

const BoostedLabel: React.FC<{ hidden?: boolean; className?: string }> = ({
  hidden,
  className,
}) => (
  <div
    className={clsx(
      'rounded border-1 border-green bg-scrim px-2 py-[2px] text-small text-green mobile:px-0.5',
      { hidden },
      className
    )}
  >
    Boosted
  </div>
);

interface Props extends Omit<PoolOverviewProps, 'pools'> {
  pool: PoolOfUser;
  onOpenNFTStakingModal: (poolId: number, hasBoostNFTs: boolean) => void;
}
function PoolItem({
  pool,
  txState,
  onPoolClick,
  onDepositMoreClick,
  onOpenNFTStakingModal,
}: Props): JSX.Element {
  const [isCollapse, setCollapse] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const hasStakes = pool.numberOfStakes > 0;
  const yellowColor = hasStakes ? 'text-rainbowYellow' : 'text-semiTransparent';
  const lockPeriod = secondsToPeriod[pool.lockPeriod];

  const { data, refetch: refetchStakedNFTTokenIds } = useStakedNFTTokenIds(
    pool?.poolId
  );
  const { data: multiplierData = 1, refetch: refetchMultiplierData } =
    useGetUserNftBoostMultiplier(pool.poolId);

  const { xtonicStats } = useXtonicStatsData();

  const { loading, stakedNfts, userNfts, refetchNfts } = useNFT(pool.poolId);
  const stakedNftAmount = stakedNfts.length;

  const hasBoostNFTs = stakedNftAmount > 0 || userNfts.length > 0;

  const handleDepositMore = useCallback<ReactEventHandler>(
    (e) => {
      e.stopPropagation();
      onDepositMoreClick(lockPeriod);
    },
    [onDepositMoreClick, lockPeriod]
  );

  useEffect(() => {
    if (txState?.status === TxStatus.confirmed) {
      refetchNfts();
      refetchStakedNFTTokenIds();
      refetchMultiplierData();
    }
  }, [txState, refetchNfts, refetchStakedNFTTokenIds, refetchMultiplierData]);

  return (
    <div
      key={pool.poolId}
      className="mx-3 my-2 cursor-pointer rounded-md border-1 border-onSurface30 bg-blueElevatedSurface p-2 mobile:my-3 mobile:mx-0"
    >
      <div
        onClick={() => onPoolClick(pool.poolId)}
        className="flex shrink-0 grow flex-row mobile:flex-col mobile:items-center mobile:gap-2"
      >
        <div className="mobile:mt-2 mobile:flex mobile:gap-3">
          <div className="flex w-[110px] flex-col items-center gap-2 mobile:w-[94px]">
            <div className="flex flex-col gap-1">
              <div className={clsx('relative flex items-center')}>
                <Text
                  className={clsx(
                    'text-small font-semibold mobile:order-last mobile:text-[14px]',
                    yellowColor
                  )}
                >
                  {getLockPeriodName(lockPeriod)}
                </Text>
                <div>
                  {!isMobile && (
                    <Tooltip
                      message={
                        <div className="w-[250px] text-small">
                          <div className="flex flex-row flex-nowrap items-center">
                            <Text className="text-small">Staked xTonic:</Text>{' '}
                            <Text className="font-semibold">
                              {formatXTonic(pool.initialTotalStaked)}
                            </Text>
                          </div>

                          <div className="flex flex-row flex-nowrap items-center">
                            <Text className="text-small">TVL:</Text>
                            <Text className="font-semibold">
                              {xtonicStats?.xTonicPriceInUsd &&
                                (
                                  parseFloat(
                                    utils.formatUnits(
                                      pool.initialTotalStaked,
                                      18
                                    )
                                  ) * xtonicStats.xTonicPriceInUsd
                                ).toLocaleString()}{' '}
                              USD
                            </Text>
                          </div>
                        </div>
                      }
                    >
                      <Icon.Question className="h-3 w-3" />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div
                className={clsx('bg-no-repeat mix-blend-lighten', {
                  'h-[60px] w-[88px] bg-vault-card-1-month': pool.poolId === 0,
                  'h-[48px] w-[81px] bg-vault-card-6-month': pool.poolId === 1,
                  'h-[69px] w-[54px] bg-vault-card-12-month': pool.poolId === 2,
                  'h-[68px] w-[86px] bg-vault-card-48-month': pool.poolId === 3,
                })}
              />
            </div>
            <BoostedLabel className="mobile:hidden" hidden={!data?.length} />
          </div>
          <PoolInfo
            className="desktop:hidden tablet:hidden"
            pool={pool}
            stakedNftAmount={stakedNftAmount}
            onDepositMore={handleDepositMore}
            multiplierData={multiplierData}
            hasStakes={hasStakes}
          />
        </div>

        <div className="ml-4 mt-1 flex h-full w-full flex-1 flex-col justify-between mobile:ml-0 mobile:gap-1">
          <PoolInfo
            className="mobile:hidden"
            pool={pool}
            stakedNftAmount={stakedNftAmount}
            onDepositMore={handleDepositMore}
            multiplierData={multiplierData}
            hasStakes={hasStakes}
          />
          {stakedNftAmount > 0 && (
            <div className="flex w-full justify-between desktop:hidden tablet:hidden">
              <Text className="text-small text-semiTransparent">
                NFTs staked
              </Text>
              <BoostedLabel />
            </div>
          )}

          {hasStakes && (
            <div className="flex flex-1 gap-4 mobile:gap-2.5">
              {!loading ? (
                <>
                  {stakedNfts.map((meta) => (
                    <StakingNFTCard
                      key={meta.id}
                      size="sm"
                      meta={meta}
                      onClick={() => {
                        onOpenNFTStakingModal(pool.poolId, hasBoostNFTs);
                      }}
                    />
                  ))}
                  {Array(MAX_STAKING_NFT - stakedNftAmount)
                    .fill(0)
                    .map((_, index) => (
                      <StakingNFTCard
                        key={index}
                        size="sm"
                        onClick={() => {
                          onOpenNFTStakingModal(pool.poolId, hasBoostNFTs);
                        }}
                      />
                    ))}
                </>
              ) : (
                <div className="flex w-full flex-col">
                  <Skeleton className="h-[20px] w-full !bg-onSurface12" />
                  <Skeleton className="mt-[5px] h-[20px] w-full !bg-onSurface12" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <>
          <div
            className="mx-auto mt-[10px] flex w-4/12 items-center justify-between text-small font-medium"
            onClick={() => setCollapse((prev) => !prev)}
          >
            <Text>Vault info</Text>
            {isCollapse ? (
              <ArrorDownSVG fill={'yellow'} stroke={'yellow'} />
            ) : (
              <ArrowUpSVG fill={'yellow'} stroke={'yellow'} />
            )}
          </div>

          <div
            className={clsx('mobile:flex mobile:justify-between mobile:py-1', {
              'mobile:hidden': isCollapse,
            })}
          >
            <div>Total staked xTONIC</div>
            <div className="flex justify-end">
              {pool.initialTotalStaked ? (
                formatXTonic(pool.initialTotalStaked)
              ) : (
                <Skeleton className="mt-[2px] h-[20px] w-full !bg-onSurface12" />
              )}
            </div>
          </div>
          <div
            className={clsx(
              'w-full mobile:flex mobile:justify-between mobile:py-0.5',
              {
                'mobile:hidden': isCollapse,
              }
            )}
          >
            <div>TVL</div>
            <div className="flex w-full justify-end">
              {pool.initialTotalStaked && xtonicStats?.xTonicPriceInUsd ? (
                `${(
                  parseFloat(utils.formatUnits(pool.initialTotalStaked, 18)) *
                  xtonicStats.xTonicPriceInUsd
                ).toLocaleString()} USD`
              ) : (
                <Skeleton className="mt-[2px] h-[20px] w-full !bg-onSurface12" />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(PoolItem);
