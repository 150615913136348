import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getTectonicSocketAddress } from '@config/base';
import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';

import useWallets from './useWallets';

export default function useEstimateTonicInBatch() {
  const sdk = useTectonicSdk();
  const tectonicSocketAddresses = useMemo(() => getTectonicSocketAddress(), []);
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.TECTONIC_ESTIMATE_TONIC_IN_BATCH)(
    sdk,
    tectonicSocketAddresses,
    currentAccount ?? ''
  );

  const { data, isLoading, refetch } = useQuery(query.queryKey, query.queryFn, {
    enabled: !!currentAccount,
    cacheTime: 0,
  });

  return {
    data,
    refetch,
    isLoading,
  };
}
