import { Text, TextProps } from '@tectonicfi/tectonic-ui-kit';

interface StartEndTextProps extends Omit<TextProps, 'children'> {
  endValue?: string | null;
  startValue: string;
}

function StartEndText({
  endValue,
  startValue,
  ...rest
}: StartEndTextProps): JSX.Element {
  const showStartAndEnd = !!(startValue && endValue);
  const showStartValueOnly = !!(startValue && !endValue);

  return (
    <Text {...rest}>
      {showStartAndEnd && `${startValue} → ${endValue}`}
      {showStartValueOnly && startValue}
    </Text>
  );
}

export default StartEndText;
