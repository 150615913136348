import { useContext, useEffect, useCallback, useReducer } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import { notify } from '@lib/bugsnag';
import useWallets from '@hooks/useWallets';
import { WalletContext } from '@providers/WalletProvider';
import { isNativeToken } from '@lib/utils';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import { PoolType } from '@config/base';

export interface WalletBalanceState {
  asset: Null<TectonicAsset>;
  balance: BigNumber | null;
  hasError: boolean;
  loading: boolean;
}

const initState: WalletBalanceState = {
  asset: null,
  balance: null,
  hasError: false,
  loading: false,
};

const GET_FULFILLED = 'GET_FULFILLED';

const GET_PENDING = 'GET_PENDING';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  asset: TectonicAsset;
  balance: BigNumber;
  type: typeof GET_FULFILLED;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type WalletBalanceAction =
  | GetFulfilledAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: WalletBalanceState,
  action: WalletBalanceAction
): WalletBalanceState {
  switch (action.type) {
    case GET_PENDING:
      return { ...state, loading: true };
    case GET_FULFILLED:
      return {
        ...state,
        asset: action.asset,
        balance: action.balance,
        loading: false,
      };
    case GET_REJECTED:
      return { ...state, loading: false, hasError: true };
    case RESET:
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}

export interface UseWalletBalanceResult {
  data: WalletBalanceState;
  refetch: () => void;
}

interface UseWalletBalanceOptions {
  skip?: boolean;
}

function useWalletBalance(
  mode: PoolType,
  asset: TectonicAsset | null,
  options?: UseWalletBalanceOptions
): UseWalletBalanceResult {
  const skip = options?.skip || false;
  const { currentAccount: walletAddress } = useWallets();
  const { sdk } = useSdkAndSupportedAssets(mode);

  const { web3Provider } = useContext(WalletContext);
  const [data, dispatch] = useReducer(reducer, initState);

  const getBalance = useCallback(
    async (a: TectonicAsset, wa: string): Promise<void> => {
      try {
        if (sdk && web3Provider) {
          dispatch({ type: GET_PENDING });
          if (isNativeToken(a)) {
            const balance = await web3Provider.getBalance(wa);
            dispatch({
              asset: a,
              balance,
              type: GET_FULFILLED,
            });
          } else {
            const balance = await sdk.ERC20.balanceOf(a.underlyingAddress, wa);
            dispatch({ asset: a, balance, type: GET_FULFILLED });
          }
        }
      } catch (error) {
        // TODO handle error
        notify(error);
        dispatch({ type: GET_REJECTED });
      }
    },
    [sdk, web3Provider]
  );

  const refetch = useCallback(() => {
    if (asset && walletAddress) {
      getBalance(asset, walletAddress);
    }
  }, [asset, walletAddress, getBalance]);

  useEffect(() => {
    if (asset && walletAddress && !skip) {
      getBalance(asset, walletAddress);
    } else {
      dispatch({ type: RESET });
    }
  }, [asset, getBalance, sdk, walletAddress, skip]);

  return { data, refetch };
}

export default useWalletBalance;
