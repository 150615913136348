import { Button, Icon, Text } from '@tectonicfi/tectonic-ui-kit';

import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
import { TxStatus } from '@types';

export interface WithdrawLockedDepositModalProps
  extends BaseTransactionModalProps {
  apr: string;
  totalXTonicUnlocked: string;
  multiplier: string;
  onWithdraw: () => void;
}
function renderTitle(status: TxStatus | null | undefined) {
  if (status === undefined || status === null) {
    return 'Withdraw';
  }
  switch (status) {
    case TxStatus.awaiting_confirmation:
    case TxStatus.pending:
      return 'Withdraw';
    case TxStatus.confirmed:
      return 'Withdraw Transaction Completed';
    case TxStatus.failed:
      return 'Withdraw Transaction failed';
  }
}
export default function WithdrawLockedDepositModal({
  onWithdraw,
  onClose,
  apr,
  multiplier,
  totalXTonicUnlocked,
  transactionExplorerHref,
  transactionErrorMessage,
  ...props
}: WithdrawLockedDepositModalProps) {
  return (
    <BaseTransactionModal
      {...props}
      variant="vault"
      onClose={onClose}
      renderTitle={renderTitle}
      transactionExplorerHref={transactionExplorerHref}
      renderTransactionStatus={(txStatus) => {
        const isTransactionAwaitingConfirmation =
          txStatus === TxStatus.awaiting_confirmation;
        const isTransactionPending = txStatus === TxStatus.pending;
        const isTransactionConfirmed = txStatus === TxStatus.confirmed;
        const showSpinner =
          isTransactionAwaitingConfirmation || isTransactionPending;
        const showViewInExplorerButton = Boolean(
          (isTransactionPending || isTransactionConfirmed) &&
            transactionExplorerHref
        );

        return (
          <div className="text-center">
            {showSpinner && (
              <Icon.Spinner className="inline-block h-8 w-8 animate-spin" />
            )}
            {isTransactionAwaitingConfirmation && (
              <Text className="mt-4">
                Confirm the transaction in your wallet
              </Text>
            )}
            {isTransactionPending && (
              <Text className="mt-4">
                Removing xTONIC from the vault, please wait...
              </Text>
            )}
            {isTransactionConfirmed && (
              <>
                <Icon.Check className="inline-block h-8 w-8" />
                <Text className="mt-4">
                  {totalXTonicUnlocked} xTONIC have been removed from the vault
                </Text>
              </>
            )}
            {txStatus === 'failed' && (
              <>
                <Icon.Info className="inline-block h-8 w-8 text-red" />
                <Text className="mt-4">
                  {transactionErrorMessage ||
                    'xTONIC removal failed, please retry later'}
                </Text>
              </>
            )}
            {showViewInExplorerButton && (
              <a
                href={transactionExplorerHref as string}
                rel="noopener noreferrer"
                target="_blank"
                className="mx-auto mt-3 text-rainbowYellow"
              >
                View on Cronos Chain
              </a>
            )}
          </div>
        );
      }}
    >
      <div className="flex flex-col ">
        <div className="mb-3 flex flex-col items-center">
          <Text variant="large">{totalXTonicUnlocked} xTONIC</Text>
          <Text variant="semiTransparent" className="text-medium">
            Withdraw amount
          </Text>
        </div>
        <div className="mb-1 flex items-center justify-between">
          <Text className="text-mediumSmall">APR</Text>
          <Text variant="semiBold" className="text-mediumSmall">
            {apr}
          </Text>
        </div>
        <div className="flex items-center justify-between">
          <Text className="text-mediumSmall">Multiplier</Text>
          <Text variant="semiBold" className="text-mediumSmall">
            {multiplier}
          </Text>
        </div>
        <Text variant="semiTransparent" className="my-3 text-mediumSmall">
          You will stop earning the Vault TONIC rewards by removing your
          unlocked xTONIC.
        </Text>
        <Button onClick={onWithdraw}>Confirm Withdrawal</Button>
      </div>
    </BaseTransactionModal>
  );
}
