import { useCallback } from 'react';

import { AppEnvironment } from '@types';

interface IsFeatureEnabledParams {
  disabledEnvironments: AppEnvironment[];
}
export function isFeatureEnabled(
  disabledEnvironments: IsFeatureEnabledParams['disabledEnvironments']
) {
  const buildEnvironment: AppEnvironment =
    process.env.NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Local;
  return disabledEnvironments.indexOf(buildEnvironment) === -1;
}
function useIsFeatureEnabled(): (params: IsFeatureEnabledParams) => boolean {
  const buildEnvironment: AppEnvironment =
    process.env.NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Local;

  return useCallback(
    ({ disabledEnvironments }: IsFeatureEnabledParams): boolean =>
      disabledEnvironments.indexOf(buildEnvironment) === -1,
    [buildEnvironment]
  );
}

export default useIsFeatureEnabled;
