import { ReactNode } from 'react';
import clsx from 'clsx';
import { Text } from '@tectonicfi/tectonic-ui-kit';
import ArrowUpSVG from '@public/assets/images/arrow-up.svg';
import ArrowDownSVG from '@public/assets/images/arrow-down.svg';

interface CollapsibleShortStepsProps {
  step: string;
  label: ReactNode;
  isSelected: boolean;
  leftLabel: string;
  chooseValue: ReactNode;
  onStepSelected: VoidFunction;
}
const CollapsibleShortSteps = ({
  step,
  isSelected,
  label,
  leftLabel,
  chooseValue,
  onStepSelected,
}: CollapsibleShortStepsProps) => {
  return (
    <div
      className={clsx(
        'mb-2 bg-darkerBlue',
        isSelected ? 'shadow-pink' : 'border-1 border-white border-opacity-30'
      )}
    >
      <Text className="p-1 text-heading3 font-bold">{step}</Text>
      <div className="flex items-center p-1">
        <div className="w-full">
          <Text>{leftLabel}</Text>
        </div>
        <div onClick={onStepSelected} className="cursor-pointer">
          {isSelected ? <ArrowUpSVG /> : <ArrowDownSVG />}
        </div>
      </div>
      <div className="border-top-1 mx-auto w-[95%] border-t-1 border-greyBackground">
        {isSelected ? (
          chooseValue
        ) : label ? (
          label
        ) : (
          <Text className="py-1 text-onSurface60">{'Select'}</Text>
        )}
      </div>
    </div>
  );
};

export default CollapsibleShortSteps;
