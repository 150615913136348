import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';

import { NFT } from '../useNFTMeta';

export const MAX_STAKING_NFT = 5;

export interface Props {
  selectedIds: string[];
  meta: NFT;
  className?: string;
  onClick?: (id: string) => void;
}

function NFTImageCard({
  meta,
  onClick,
  className,
  selectedIds,
}: Props): JSX.Element {
  const selected = selectedIds.includes(meta.id);
  const isOverStakingNFTAmount = selectedIds.length >= MAX_STAKING_NFT;

  return (
    <div
      className={clsx(
        'relative flex cursor-pointer flex-col items-center justify-items-start rounded-[4px] border-1 border-semiTransparent p-1 pb-2',
        className,
        {
          'border-vaultChart6Months': selected,
          'opacity-50': isOverStakingNFTAmount && !selected,
        }
      )}
      onClick={() => {
        if (!isOverStakingNFTAmount || (isOverStakingNFTAmount && selected))
          onClick?.(meta.id);
      }}
    >
      <Image
        className="m-auto mt-1 rounded-[4px]"
        src={meta?.image ?? ''}
        alt={`NFT #${meta?.id}`}
        width="84px"
        height="84px"
        id={meta.id}
      />
      <div
        className={clsx('absolute right-[12px] top-[12px] h-[18px] w-[18px]', {
          'rounded-[50%] border-1 border-white': !selected,
          'bg-selected bg-no-repeat': selected,
        })}
      />

      <div
        className={clsx(
          'mt-[10px] w-fit rounded-[2px] bg-white60 p-[1px] text-center text-small font-medium',
          {
            'bg-gradient-to-r from-yellowBorderActive to-borderGradientTo':
              meta?.rarity === 'Legendary',
          }
        )}
      >
        <div className="w-fit bg-blueElevatedSurface py-[1px] px-[3px]">
          {meta?.rarity}
        </div>
      </div>
    </div>
  );
}

export default React.memo(NFTImageCard);
