import { useEffect, useCallback, useReducer } from 'react';
import { TectonicSDK } from '@tectonicfi/sdk';
import { BigNumber } from '@ethersproject/bignumber';
import { TectonicAsset } from '@tectonicfi/sdk/dist/types';

import { isNativeToken } from '@lib/utils';
import { UNLIMITED_ALLOWANCE_AMOUNT } from '@config/constants';
import { Mode } from '@components/MarketsPageView/types';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';

interface UseGetAllowanceState {
  allowance: BigNumber;
  asset: TectonicAsset | null;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
}

const initState: UseGetAllowanceState = {
  allowance: BigNumber.from(0),
  asset: null,
  hasError: false,
  loaded: false,
  loading: false,
};

const GET_PENDING = 'GET_PENDING';

const GET_FULFILLED = 'GET_FULFILLED';

const GET_REJECTED = 'GET_REJECTED';

const RESET = 'RESET';

interface GetPendingAction {
  type: typeof GET_PENDING;
}

interface GetFulfilledAction {
  allowance: BigNumber;
  asset: TectonicAsset;
  type: typeof GET_FULFILLED;
}

interface GetRejectedAction {
  type: typeof GET_REJECTED;
}

interface ResetAction {
  type: typeof RESET;
}

type UseGetAllowanceAction =
  | GetFulfilledAction
  | GetPendingAction
  | GetRejectedAction
  | ResetAction;

function reducer(
  state: UseGetAllowanceState,
  action: UseGetAllowanceAction
): UseGetAllowanceState {
  switch (action.type) {
    case GET_PENDING:
      return { ...state, loading: true, loaded: false };
    case GET_FULFILLED:
      return {
        ...state,
        allowance: action.allowance,
        asset: action.asset,
        loaded: true,
        loading: false,
      };
    case GET_REJECTED:
      return { ...state, loading: false, hasError: true };
    case RESET:
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}

export type useGetAllowanceResult = {
  allowance: BigNumber;
  asset: Null<TectonicAsset>;
  loaded: boolean;
  loading: boolean;
  refetch(): void;
};

function useGetAllowance(
  asset: Null<TectonicAsset>,
  currentAccount: Null<string>,
  mode: Mode
): useGetAllowanceResult {
  const { sdk } = useSdkAndSupportedAssets(mode);
  const [state, dispatch] = useReducer(reducer, initState);

  const getAllowance = useCallback(
    async (
      tectonicSdk: TectonicSDK,
      tectonicAsset: TectonicAsset,
      address: string
    ) => {
      try {
        const allowance = await tectonicSdk.ERC20.allowanceOf(
          tectonicAsset.underlyingAddress,
          address,
          tectonicAsset.tTokenAddress
        );
        dispatch({
          type: GET_FULFILLED,
          allowance,
          asset: tectonicAsset,
        });
      } catch (error) {
        dispatch({
          type: GET_REJECTED,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (!asset) {
      dispatch({ type: RESET });
      return;
    }

    if (asset && isNativeToken(asset)) {
      dispatch({
        type: GET_FULFILLED,
        allowance: BigNumber.from(UNLIMITED_ALLOWANCE_AMOUNT),
        asset,
      });
      return;
    }

    if (sdk && asset && currentAccount) {
      dispatch({ type: GET_PENDING });
      getAllowance(sdk, asset, currentAccount);
    }
  }, [asset, currentAccount, getAllowance, sdk]);

  return {
    allowance: state.allowance,
    asset: state.asset,
    loaded: state.loaded && !state.hasError,
    loading: state.loading,
    refetch: () => {
      if (sdk && asset && currentAccount) {
        dispatch({ type: GET_PENDING });
        getAllowance(sdk, asset, currentAccount);
      }
    },
  };
}

export default useGetAllowance;
