import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

interface MobileNavigationDrawerProviderProps {
  children: ReactNode;
}
interface MobileNavigationDrawerContextValue {
  showDrawer: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
}

export const MobileNavigationDrawerContext =
  createContext<MobileNavigationDrawerContextValue>({
    showDrawer: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShowDrawer: () => {},
  });

function MobileNavigationDrawerProvider({
  children,
}: MobileNavigationDrawerProviderProps): JSX.Element {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <MobileNavigationDrawerContext.Provider
      value={{ showDrawer, setShowDrawer }}
    >
      {children}
    </MobileNavigationDrawerContext.Provider>
  );
}

export default memo(MobileNavigationDrawerProvider);
