import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
} from 'react';
import { ethers } from 'ethers';
import partial from 'lodash/partial';

import { notify } from '@lib/bugsnag';
import ConnectWalletModal from '@components/ConnectWalletModal';
import { getNetworkConfig } from '@config/baseNetworkConfig';
import useIsMobile from '@hooks/useIsMobile';

import { WalletConfig } from './walletConfigs';
import { currentWallet, getWallet } from './wallets';

const {
  useAccount,
  disconnect,
  useProvider,
  connect,
  useIsWrongNetwork,
  autoConnect,
  connectAsCurrentWallet,
} = currentWallet;

export interface WalletContextValue {
  currentAccount: Null<string>;
  disconnectWallet: VoidFunction;
  onShowConnectWalletModal: VoidFunction;
  onSwitchNetwork: VoidFunction;
  showNetworkError: boolean;
  web3Provider: Null<ethers.providers.Web3Provider>;
}

export const WalletContext = createContext({} as WalletContextValue);

interface WalletProviderProps {
  children: ReactNode;
}

export function useWallet() {
  return useContext(WalletContext);
}

function WalletProvider({ children }: WalletProviderProps) {
  const [isShowWalletModal, setIsShowWalletModal] = useState(false);
  const account = useAccount();
  const web3Provider = useProvider();
  const isMobile = useIsMobile();

  useEffect(() => {
    autoConnect();
  }, []);

  const config = useMemo(() => getNetworkConfig(), []);

  const onSwitchNetwork = useCallback(
    async () => await connect(config),
    [config]
  );

  const disconnectWallet = useCallback(async () => await disconnect(), []);

  const onSelectWallet = useCallback(
    async (walletConfig: WalletConfig) => {
      const wallet = getWallet(walletConfig.name);

      try {
        await wallet.detectProvider();
      } catch (error) {
        console.error(error);

        const link = walletConfig.getDownloadLink({
          isMobile,
        });

        if (link) {
          window.open(link);
        }

        notify(error);

        return null;
      }

      try {
        await connectAsCurrentWallet(walletConfig.name, config);

        setIsShowWalletModal(false);
      } catch (error) {
        console.error(error);
      }
    },
    [isMobile, config]
  );

  const onShowConnectWalletModal = useCallback(
    () => setIsShowWalletModal(true),
    []
  );

  const showNetworkError = useIsWrongNetwork();

  const contextValue: WalletContextValue = useMemo(
    () => ({
      currentAccount: account || null,
      disconnectWallet,
      onShowConnectWalletModal,
      onSwitchNetwork,
      showNetworkError,
      web3Provider: web3Provider || null,
    }),
    [
      account,
      disconnectWallet,
      web3Provider,
      onSwitchNetwork,
      showNetworkError,
      onShowConnectWalletModal,
    ]
  );

  return (
    <WalletContext.Provider value={contextValue}>
      <ConnectWalletModal
        isOpen={isShowWalletModal}
        onClose={partial(setIsShowWalletModal, false)}
        onSelectWallet={onSelectWallet}
      />
      {children}
    </WalletContext.Provider>
  );
}

export default WalletProvider;
