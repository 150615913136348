import { Button, Icon } from '@tectonicfi/tectonic-ui-kit';
import { Text } from '@tectonicfi/tectonic-ui-kit';
import { BigNumber } from 'ethers';

import { formatTotalTonicAmount } from '@lib/units';
import BaseTransactionModal, {
  BaseTransactionModalProps,
} from '@components/BaseTransactionModal';
export interface HarvestTonicModalProps extends BaseTransactionModalProps {
  tonicAmount: BigNumber;
  onHarvest: () => void;
}

export default function HarvestTonicModal({
  onHarvest,
  tonicAmount,
  ...props
}: HarvestTonicModalProps) {
  return (
    <BaseTransactionModal {...props} title="Harvest Tonic">
      <div className="flex flex-col items-center">
        <Icon.Tonic width="100" height={100} />
        <Text className="mt-2">
          Pending Tonic: {formatTotalTonicAmount(tonicAmount)}
        </Text>
        <Button className="mt-2 w-full" onClick={onHarvest}>
          Harvest
        </Button>
      </div>
    </BaseTransactionModal>
  );
}
