import { useMemo } from 'react';
import { BigNumber } from 'ethers';
import { Icon, Skeleton, Text, Tooltip } from '@tectonicfi/tectonic-ui-kit';

import Card from '@components/Card';
import useTonicSupplyStats from '@hooks/useTonicSupplyStats';
import useUsdPrices from '@hooks/useUsdPrices';
import {
  formatAssetUsdPrice,
  formatNumber,
  formatTonicCirculatingSupply,
  formatTonicMarketCap,
} from '@lib/units';
import { getTonicMarketCap } from '@lib/math';
import { parseCirculatingSupplyToBigNumber } from '@lib/utils';
import useSupportedAsset from '@hooks/useSupportedAsset';
import useIsMobile from '@hooks/useIsMobile';
import useRevenuePaidToStakers from '@hooks/useRevenuePaidToStakers';

function TonicMarketStatsCards(): JSX.Element {
  const isMobile = useIsMobile();
  const { isLoading, data } = useTonicSupplyStats();
  const { data: revenuePaidToStaker } = useRevenuePaidToStakers();

  const rawCirculatingSupply = data?.circulatingSupply;
  const { usdPrices } = useUsdPrices();
  const tonicAsset = useSupportedAsset('TONIC');
  const rawTonicUsdPrice = usdPrices['TONIC'];
  const parsedStats = useMemo(() => {
    if (
      !tonicAsset ||
      !rawTonicUsdPrice ||
      isLoading ||
      !rawCirculatingSupply
    ) {
      return null;
    }

    const tonicUsdPrice = BigNumber.from(rawTonicUsdPrice);
    const circulatingSupply =
      parseCirculatingSupplyToBigNumber(rawCirculatingSupply);
    const tonicMarketCap = getTonicMarketCap(tonicUsdPrice, circulatingSupply);

    return {
      circulatingSupply: formatTonicCirculatingSupply(circulatingSupply),
      marketCap: formatTonicMarketCap(tonicAsset, tonicMarketCap),
      tonicUsdPrice: tonicUsdPrice,
    };
  }, [tonicAsset, rawTonicUsdPrice, isLoading, rawCirculatingSupply]);

  return (
    <Card className="flex flex-row justify-between tablet:flex-col tablet:space-y-4 mobile:flex-col mobile:space-y-4">
      <div className="flex-1">
        <Text variant="small">$TONIC Price</Text>
        <Text className="mt-1" variant="large">
          {tonicAsset && parsedStats?.tonicUsdPrice ? (
            formatAssetUsdPrice(tonicAsset, parsedStats.tonicUsdPrice)
          ) : (
            <Skeleton />
          )}
        </Text>
      </div>
      <div className="flex-1">
        <Text variant="small">Circulating Supply</Text>
        <Text className="mt-1" variant="large">
          {parsedStats?.circulatingSupply ?? <Skeleton />}
        </Text>
      </div>
      <div className="flex-1">
        <Text variant="small">Market Cap</Text>
        <Text className="mt-1" variant="large">
          {parsedStats?.marketCap ?? <Skeleton />}
        </Text>
      </div>
      <div className="flex-1">
        <div className="flex flex-row items-center">
          <Text variant="small">Protocol Revenue paid to stakers</Text>
          <Tooltip
            message={
              <Text className="w-[270px] text-small">
                Protocol revenue that has been distributed to TONIC stakers
              </Text>
            }
            leftOffset={isMobile ? 180 : 150}
          >
            <Icon.Question className="h-2.5 w-2.5" />
          </Tooltip>
        </div>
        <Text className="mt-1" variant="large">
          $
          {(revenuePaidToStaker &&
            revenuePaidToStaker?.length >= 0 &&
            formatNumber(
              revenuePaidToStaker?.reduce(
                (total, obj) => parseFloat(obj.totalPaidToStakerInUsd) + total,
                0
              )
            )) ?? <Skeleton />}
        </Text>
      </div>
    </Card>
  );
}

export default TonicMarketStatsCards;
