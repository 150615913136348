import { Text } from '@tectonicfi/tectonic-ui-kit';

import { formatXTonic } from '@lib/units';
import { PoolId } from '@components/VaultsPageView/types';
import { LockingPeriod } from '@components/LockDepositModal/types';
import useTonicStakingCardData from '@components/TonicPageView/useTonicStakingCardData';
import useWallets from '@hooks/useWallets';
import { TxState } from 'hooks/useGetTxStatus';

import PoolItem from './PoolItem';
import { PoolOfUser } from './types';

export interface PoolOverviewProps {
  pools: PoolOfUser[];
  txState: Null<TxState>;
  onPoolClick: (id: PoolId) => void;
  onDepositMoreClick: (period: LockingPeriod) => void;
  onOpenNFTStakingModal: (poolId: number, hasBoostNFTs: boolean) => void;
}

export default function PoolOverview({
  pools,
  txState,
  onPoolClick,
  onDepositMoreClick,
  onOpenNFTStakingModal,
}: PoolOverviewProps): JSX.Element {
  const { currentAccount } = useWallets();
  const { xTonicBalance } = useTonicStakingCardData(currentAccount);

  return (
    <>
      {xTonicBalance && !xTonicBalance.isZero() && (
        <div className="my-2 flex justify-between bg-yellowBorder px-2 py-1.5 mobile:mt-0">
          <Text>Wallet Balance</Text>
          <Text>{formatXTonic(xTonicBalance)} xTONIC</Text>
        </div>
      )}
      <div className="my-2 py-3 desktop:bg-testBlue tablet:bg-testBlue mobile:my-0 mobile:pt-0">
        {pools.map((pool) => (
          <PoolItem
            key={pool.poolId}
            pool={pool}
            txState={txState}
            onDepositMoreClick={onDepositMoreClick}
            onPoolClick={onPoolClick}
            onOpenNFTStakingModal={onOpenNFTStakingModal}
          />
        ))}
      </div>
    </>
  );
}
