import { useQuery } from 'react-query';

import { QueryKey } from '@config/queryKey';
import { getQuery } from '@queries/queries';

function useRevenuePaidToStakers() {
  const revenuePaidStakerQuery = getQuery(QueryKey.GRAPH_REVENUE_PAID_STAKER);
  return useQuery(revenuePaidStakerQuery);
}

export default useRevenuePaidToStakers;
