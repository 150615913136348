import { DropdownContext } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';
import { PropsWithChildren, useContext } from 'react';

export interface DropdownOptionsProps {
  value: string;
  className?: string;
}
export default function DropdownOption({
  value,
  className,
  children,
}: PropsWithChildren<DropdownOptionsProps>) {
  const { onChange } = useContext(DropdownContext);
  return (
    <div
      onClick={() => {
        onChange(value);
      }}
      className={clsx(
        'cursor-pointer p-2 text-left text-mediumSmall text-white',
        className
      )}
    >
      {children}
    </div>
  );
}
