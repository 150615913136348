import { ReactNode, useState } from 'react';
import {
  Button,
  Icon,
  MobileNavigationDrawer,
  NavBar,
  Text,
  Footer,
} from '@tectonicfi/tectonic-ui-kit';
import Link from 'next/link';

import { WHITEPAPER_URL } from '@config/base';

import SocialMediaLinks from '../SocialMediaLinks';

import { PATHNAME } from './utils';

interface MobileNavigationDrawerItemProps {
  children: ReactNode;
}

function MobileNavigationDrawerItem({
  children,
}: MobileNavigationDrawerItemProps): JSX.Element {
  return <li className="pb-4">{children}</li>;
}

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps): JSX.Element {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <NavBar className="z-11 justify-center mobile:fixed mobile:inset-x-0">
        <div className="flex w-full max-w-desktopContainer flex-row">
          <div className="flex flex-1 flex-col justify-center text-logo desktop:h-[34px] mobile:h-3">
            <Icon.Tectonic className="h-3 w-[169px]" />
          </div>
          <div className="flex flex-1 justify-end mobile:hidden">
            <Link href={PATHNAME.markets} passHref>
              <Button as="a" className="min-w-[110px] text-center" size="small">
                <span>Enter App</span>
              </Button>
            </Link>
          </div>
          <div className="flex flex-1 flex-row justify-end desktop:hidden tablet:hidden">
            <button
              onClick={(): void => setShowDrawer((v) => !v)}
              className="text-large"
            >
              <Icon.Menu />
            </button>
          </div>
        </div>
      </NavBar>
      <MobileNavigationDrawer
        className="top-[64px] h-[calc(100vh-64px)] desktop:hidden tablet:hidden"
        open={showDrawer}
      >
        <MobileNavigationDrawer.Content className="p-8">
          <ul>
            <MobileNavigationDrawerItem>
              <Text variant="heading3">Markets</Text>
            </MobileNavigationDrawerItem>
            <MobileNavigationDrawerItem>
              <Link href={PATHNAME.markets} passHref>
                <Button as="a" size="small" style={{ lineHeight: '12px' }}>
                  Enter app
                </Button>
              </Link>
            </MobileNavigationDrawerItem>
            <MobileNavigationDrawerItem>
              <Text className="pb-2 text-onSurface60">Join the Community</Text>
              <SocialMediaLinks />
            </MobileNavigationDrawerItem>
            <MobileNavigationDrawerItem>
              <div className="pb-2">
                <a
                  href={WHITEPAPER_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Text as="span" className="text-onSurface">
                    Whitepaper
                  </Text>
                </a>
              </div>
            </MobileNavigationDrawerItem>
            <MobileNavigationDrawerItem>
              <div className="flex h-4 flex-1 flex-col justify-center">
                <Icon.PoweredByCronos className="h-4 w-[114px]" />
              </div>
            </MobileNavigationDrawerItem>
          </ul>
        </MobileNavigationDrawer.Content>
      </MobileNavigationDrawer>
      <div className="desktop:hidden tablet:hidden mobile:h-8" />
      {children}
      <Footer>
        <Footer.Item url={WHITEPAPER_URL}>Whitepaper</Footer.Item>
      </Footer>
    </>
  );
}

export default AppLayout;
