import { Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import { formatPercent, formatRateToPercent } from '@lib/units';

interface TonicStakingYesterdayApyProps {
  className?: string;
  rate: number;
}

function TonicStakingYesterdayApy({
  className,
  rate,
}: TonicStakingYesterdayApyProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex flex-row justify-between bg-gradient-to-r from-tonicApyBackgroundGradientFrom to-tonicApyBackgroundGradientTo px-2 py-3',
        className
      )}
    >
      <Text>Last 7 Days APY</Text>
      <Text>{formatPercent(formatRateToPercent(rate))}</Text>
    </div>
  );
}

export default TonicStakingYesterdayApy;
