import { BigNumber } from 'ethers';
import { TectonicAsset } from '@tectonicfi/sdk';
import {
  Button,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '@tectonicfi/tectonic-ui-kit';
import Link from 'next/link';

import { convertXTonicToTonic, convertTonicToXTonic } from '@lib/math';
import { formatUserUnderlyingAmount } from '@lib/units';

interface TonicStakingCardWithdrawStatsProps {
  loading: boolean;
  onWithdraw(): void;
  releasableXTonicAmount: BigNumber | null;
  showWithdrawButton: boolean;
  tonicAsset: TectonicAsset | null;
  unstakedXTonicAmount: BigNumber | null;
  xTonicBalance: BigNumber | null;
  currentXTonicToTonicExchangeRate: BigNumber | null;
  xTonicToTonicExchangeRate: BigNumber | null;
  stakingRewardsTonic: BigNumber | null;
  stakingRewardsFetched: boolean;
  totalXTonicLockedInVaults: BigNumber | null;
  totalXTonicLockedInVaultsFetched: boolean;
}

function TonicStakingCardWithdrawStats({
  loading,
  onWithdraw,
  releasableXTonicAmount,
  showWithdrawButton,
  tonicAsset,
  unstakedXTonicAmount,
  xTonicBalance,
  currentXTonicToTonicExchangeRate,
  xTonicToTonicExchangeRate,
  stakingRewardsTonic,
  stakingRewardsFetched,
  totalXTonicLockedInVaults,
  totalXTonicLockedInVaultsFetched,
}: TonicStakingCardWithdrawStatsProps): JSX.Element {
  function getTonicAmount(
    xTonicAmount: BigNumber | null,
    exchangeRate: BigNumber | null
  ): string | null {
    if (loading || !xTonicAmount || !exchangeRate || !tonicAsset) {
      return null;
    }

    return formatUserUnderlyingAmount(
      tonicAsset,
      convertXTonicToTonic(xTonicAmount, exchangeRate)
    );
  }

  function getXTonicAmountFromTonicAmount(
    tonicAmount: BigNumber | null,
    exchangeRate: BigNumber | null
  ): string | null {
    if (loading || !tonicAmount || !exchangeRate || !tonicAsset) {
      return null;
    }

    return formatUserUnderlyingAmount(
      tonicAsset,
      convertTonicToXTonic(tonicAmount, exchangeRate)
    );
  }

  function getXTonicAmount(xTonicAmount: BigNumber | null): string | null {
    if (loading || !xTonicAmount || !tonicAsset) {
      return null;
    }

    // Use tonicAsset in place of xTonic - same decimals
    return formatUserUnderlyingAmount(tonicAsset, xTonicAmount);
  }

  const tonicEquivalentBalance = getTonicAmount(
    xTonicBalance,
    currentXTonicToTonicExchangeRate
  );
  const unstakedTonicAmount = getTonicAmount(
    unstakedXTonicAmount,
    xTonicToTonicExchangeRate
  );
  const releasableTonicAmount = getTonicAmount(
    releasableXTonicAmount,
    xTonicToTonicExchangeRate
  );
  const xTonicEquivalentRewards = getXTonicAmountFromTonicAmount(
    stakingRewardsTonic,
    currentXTonicToTonicExchangeRate
  );
  const tonicEquivalentLockedInVaults = getTonicAmount(
    totalXTonicLockedInVaults ?? BigNumber.from(0),
    currentXTonicToTonicExchangeRate
  );

  return (
    <>
      <div className="flex flex-col justify-start gap-2 text-[14px] font-normal leading-[21px] text-white">
        <div className="flex w-full flex-row mobile:flex-col">
          <div>Staked&nbsp;</div>
          <div className="flex grow flex-row justify-between">
            {xTonicBalance && tonicEquivalentBalance ? (
              <>
                <div>{`${getXTonicAmount(xTonicBalance)} xTONIC`}</div>
                <div>{`${tonicEquivalentBalance} TONIC`}</div>
              </>
            ) : (
              <>
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
              </>
            )}
          </div>
        </div>

        <Tooltip
          className="flex w-full flex-row mobile:flex-col"
          message="xTONIC locked in vaults will keep earning TONIC staking rewards."
        >
          <div>
            <Icon.Info className="mr-1 mb-[2px] hidden h-2 w-2 align-middle text-yellow mobile:inline" />
            Locked in{' '}
            <Link href="/vaults" passHref={true}>
              <span className="cursor-pointer text-yellowPrimary hover:underline">
                vault
              </span>
            </Link>
            &nbsp;
          </div>
          <div className="flex grow flex-row justify-between">
            {totalXTonicLockedInVaultsFetched &&
            tonicEquivalentLockedInVaults ? (
              <>
                <div>
                  {`${getXTonicAmount(totalXTonicLockedInVaults)} xTONIC`}
                  <Icon.Info className="ml-[6px] inline h-2 w-2 align-top text-yellow mobile:hidden" />
                </div>
                <div>{`${tonicEquivalentLockedInVaults} TONIC`}</div>
              </>
            ) : (
              <>
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
              </>
            )}
          </div>
        </Tooltip>

        <div className="flex w-full flex-row mobile:flex-col">
          <div>Pending Cooldown&nbsp;</div>
          <div className="flex grow flex-row justify-between">
            {unstakedTonicAmount ? (
              <>
                <div>{`${getXTonicAmount(unstakedXTonicAmount)} xTONIC`}</div>
                <div>{`${unstakedTonicAmount} TONIC`}</div>
              </>
            ) : (
              <>
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
              </>
            )}
          </div>
        </div>

        <div className="flex w-full flex-row mobile:flex-col">
          <div>Ready to withdraw&nbsp;</div>
          <div className="flex grow flex-row justify-between">
            {releasableTonicAmount ? (
              <>
                <div>{`${getXTonicAmount(releasableXTonicAmount)} xTONIC`}</div>
                <div>{`${releasableTonicAmount} TONIC`}</div>
              </>
            ) : (
              <>
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
              </>
            )}
          </div>
        </div>

        <div className="flex w-full flex-row mobile:flex-col">
          <div>Cumulative Rewards&nbsp;</div>
          <div className="flex grow flex-row justify-between">
            {stakingRewardsTonic &&
            stakingRewardsFetched &&
            !loading &&
            tonicAsset ? (
              <>
                <div>{`${xTonicEquivalentRewards} xTONIC`}</div>
                <div>{`${getXTonicAmount(stakingRewardsTonic)} TONIC`}</div>
              </>
            ) : (
              <>
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
                <Skeleton className="!mt-0.5 block h-2 w-1/2 !bg-onSurface12" />
              </>
            )}
          </div>
        </div>
      </div>
      {showWithdrawButton && (
        <Button className="mt-3 w-full" onClick={onWithdraw}>
          Withdraw
        </Button>
      )}
      <div className="mt-3 flex flex-row">
        <Text variant="small">
          Cumulative rewards are calculated under the assumption that no xTONIC
          was transferred from or to your wallet, and takes locked xTONIC in the
          Vault into account.
        </Text>
      </div>
    </>
  );
}

export default TonicStakingCardWithdrawStats;
