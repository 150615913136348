import features from '@config/features';
import { createFeatureComponent } from '@lib/features';

const createFeatures = () =>
  Object.entries(features).reduce((all, [name, disabledEnvironments]) => {
    all[name as keyof typeof features] = createFeatureComponent(
      name,
      disabledEnvironments
    );
    return all;
  }, {} as Record<keyof typeof features, ReturnType<typeof createFeatureComponent>>);
export default createFeatures();
