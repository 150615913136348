import { Dispatch, SetStateAction } from 'react';
import { UseQueryResult } from 'react-query';
import { TectonicAsset } from '@tectonicfi/sdk';
import { RadioButton, Text, Tooltip } from '@tectonicfi/tectonic-ui-kit';

import CollapsibleShortSteps from '@components/CollapsibleShortSteps/CollapsibleShortSteps';
import SwapModeSwitch from '@components/SwapModeSwitch/SwapModeSwitch';
import { EnhancedSlippageToleranceSelect } from '@components/EnhancedAmountSelect';
import { SLIPPAGE_TOLERANCE, SWAP_FEE } from '@config/constants';
import TransactionModalDataRow from '@components/TransactionModalDataRow/TransactionModalDataRow';
import { formatPercent, formatUnits } from '@lib/units';
import StartEndText from '@components/StartEndText/StartEndText';

import VVSSVG from '../../public/assets/images/swap-vvs.svg';
import WOWMAXSVG from '../../public/assets/images/wowmax.svg';

import { Dexes, checkTrailingZeros } from './utils';

interface selectDexesProps {
  selectStep: {
    selectedLongAsset: boolean;
    selectedBorrowLevel: boolean;
    selectedDexes: boolean;
  };
  setSelectStep: Dispatch<
    SetStateAction<{
      selectedLongAsset: boolean;
      selectedBorrowLevel: boolean;
      selectedDexes: boolean;
    }>
  >;
  dexes: string;
  setDexes: Dispatch<SetStateAction<Dexes>>;
  termsChecked: boolean;
  setTermsChecked: Dispatch<SetStateAction<boolean>>;
  slippageTolerance: number;
  setSlippageTolerance: Dispatch<SetStateAction<number>>;
  isCollapseTolerance: boolean;
  setCollapseTolerance: Dispatch<SetStateAction<boolean>>;
  swapToLongToken: Null<TectonicAsset>;
  asset: Null<TectonicAsset>;
  swapDetails: Null<UseQueryResult<any, unknown>>;
}

const SelectDexes = ({
  selectStep,
  setSelectStep,
  dexes,
  setDexes,
  termsChecked,
  setTermsChecked,
  slippageTolerance,
  setSlippageTolerance,
  isCollapseTolerance,
  setCollapseTolerance,
  swapToLongToken,
  asset,
  swapDetails,
}: selectDexesProps) => {
  const getWowmaxMinRevived = () => {
    if (swapDetails?.data?.amountOut[0] && swapToLongToken) {
      const convertWowmaxAmountOut = Number(
        formatUnits(swapDetails?.data?.amountOut[0], swapToLongToken?.decimals)
      );
      return (convertWowmaxAmountOut * (1 - slippageTolerance)).toFixed(4);
    }
    return '';
  };

  const getPriceDecimals = () => {
    const price =
      dexes === 'wowmax'
        ? Number(1 / swapDetails?.data?.price)
        : Number(swapDetails?.data?.price);

    return !Number.isInteger(price) ? checkTrailingZeros(price) : price;
  };

  return (
    <CollapsibleShortSteps
      step={'Step 3'}
      isSelected={selectStep.selectedDexes}
      leftLabel={'Check your swap details'}
      onStepSelected={() =>
        setSelectStep({
          selectedLongAsset: false,
          selectedBorrowLevel: false,
          selectedDexes: !selectStep.selectedDexes,
        })
      }
      chooseValue={
        <div className="py-2">
          <SwapModeSwitch
            onModeChange={(newMode) => {
              setDexes(newMode);
              setTermsChecked(false);
            }}
            mode={dexes}
          />
          <div className="mt-2 flex items-start">
            <RadioButton
              onClick={() => setTermsChecked(!termsChecked)}
              active={termsChecked}
              className="items-center"
            />
            <span className="ml-1 inline text-[10px]">
              You are about to access a third party service. Your use of the
              third party services will be at your own risk.
              <Tooltip
                message={
                  'You are about to access a third party service, which is available on an as-is and as-available basis. Your use of the third party services will be at your own risk. Any use of the third party service, and any information you provide, will be subject to and governed by the policies and terms of that third party, and may be subject to separate fees charged by that third party.'
                }
              >
                <Text className="text-[10px] text-yellowPrimary">
                  {' '}
                  Learn more.
                </Text>
              </Tooltip>
            </span>
          </div>
          <div className="my-1">
            <EnhancedSlippageToleranceSelect
              label=""
              onButtonClick={(st: number) => setSlippageTolerance(st)}
              selectOptions={SLIPPAGE_TOLERANCE}
              leftLabel="Slippage tolerance"
              isCollapse={isCollapseTolerance}
              setCollapse={setCollapseTolerance}
              slippageTolerance={slippageTolerance}
            />
          </div>
          <TransactionModalDataRow
            label={<Text variant="default">Minimum Received</Text>}
            loading={swapDetails?.isLoading ?? false}
            value={
              <StartEndText
                startValue={
                  swapDetails?.data
                    ? dexes === 'vvs'
                      ? swapDetails?.data?.minReceived
                      : getWowmaxMinRevived()
                    : ''
                }
                endValue={null}
              />
            }
            tooltip="Amount received after accounting for slippage and fees. The transaction may revert if there is a large, unfavorable price movement before it is confirmed."
          />

          <TransactionModalDataRow
            label={<Text variant="default">Price</Text>}
            loading={swapDetails?.isLoading ?? false}
            value={
              <StartEndText
                startValue={
                  swapDetails?.data
                    ? `${getPriceDecimals()} ${asset?.symbol} per ${
                        swapToLongToken?.symbol
                      }`
                    : ''
                }
                endValue={null}
              />
            }
          />

          <TransactionModalDataRow
            label={<Text variant="default">Price impact</Text>}
            loading={swapDetails?.isLoading ?? false}
            value={
              <StartEndText
                startValue={
                  swapDetails?.data?.priceImpact
                    ? `${swapDetails?.data?.priceImpact} %`
                    : ''
                }
                endValue={null}
              />
            }
            tooltip="Difference between the market price and estimated price due to discrepancies between the trade size relative to liquidity available."
          />

          <TransactionModalDataRow
            label={<Text variant="default">Swap fee</Text>}
            loading={false}
            value={
              <StartEndText
                startValue={formatPercent(dexes === 'vvs' ? SWAP_FEE * 100 : 0)}
                endValue={null}
              />
            }
            tooltip="If using an aggregator, this amount represents commission fees paid to use their services, but does not account for swap fees incurred from routing liquidity to various trading venues"
          />
        </div>
      }
      label={
        termsChecked && (
          <div className="py-2">
            {dexes === 'vvs' ? (
              <div className="flex">
                <VVSSVG width="25" height="25" />
                <Text className="ml-1 font-bold">{'VVS Finance'}</Text>
              </div>
            ) : (
              <WOWMAXSVG width="139" height="14" />
            )}
            {swapDetails?.data?.price && (
              <Text className="mt-1">
                {getPriceDecimals()} {asset?.symbol} per{' '}
                {swapToLongToken?.symbol}
              </Text>
            )}
          </div>
        )
      }
    />
  );
};

export default SelectDexes;
