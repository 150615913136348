import { AppEnvironment } from '@types';

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '';

export function trackPageView(url: string): void {
  if (window.dataLayer && GTM_ID) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
}

const buildEnvironment: AppEnvironment =
  process.env.NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Local;

export function getGtmHeadScriptContent(): string | null {
  if (!GTM_ID) {
    return null;
  }

  if (buildEnvironment === AppEnvironment.Production) {
    return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');`;
  }

  if (buildEnvironment === AppEnvironment.UAT) {
    return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=575ZMxWRSOOpIWrcvwdFYA&gtm_preview=env-10&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
    `;
  }

  return null;
}

export function getGtmIframeSource(): string | null {
  if (!GTM_ID) {
    return null;
  }

  if (buildEnvironment === AppEnvironment.Production) {
    return `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
  }

  if (buildEnvironment === AppEnvironment.UAT) {
    return `https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=575ZMxWRSOOpIWrcvwdFYA&gtm_preview=env-10&gtm_cookies_win=x`;
  }

  return null;
}
