import { TectonicAsset } from '@tectonicfi/sdk/dist/types';
import { BigNumber } from 'ethers';

import { isAmountZeroEquivalent } from '@lib/utils';
import { parseInputAmountToBN } from '@lib/units';

interface ValidateBorrowAmountParams {
  amount: string;
  asset: TectonicAsset | null;
  hasAnyCollateralEnabled: boolean;
  totalMarketAvailableAmount: BigNumber | null;
  userMaxBorrowAmount: BigNumber | null;
}

type InvalidReason =
  | 'exceedsBorrowLimitAmount'
  | 'insufficientCollateral'
  | 'insufficientLiquidity'
  | 'invalid'
  | null;
interface ValidateBorrowAmountResult {
  reason: InvalidReason;
  valid: boolean;
}

function validateBorrowAmount({
  amount,
  asset,
  hasAnyCollateralEnabled,
  totalMarketAvailableAmount,
  userMaxBorrowAmount,
}: ValidateBorrowAmountParams): ValidateBorrowAmountResult {
  const invalidResult: ValidateBorrowAmountResult = {
    valid: false,
    reason: 'invalid',
  };

  if (asset && amount && userMaxBorrowAmount && totalMarketAvailableAmount) {
    try {
      const parsedAmount = parseInputAmountToBN(amount, asset.decimals);
      const invalidInput =
        isAmountZeroEquivalent(amount) ||
        parsedAmount.isZero() ||
        parsedAmount.isNegative();

      if (invalidInput) {
        return invalidResult;
      }

      if (!hasAnyCollateralEnabled) {
        return { valid: false, reason: 'insufficientCollateral' };
      }

      const greaterThanTotalMarketAvailableAmount =
        parsedAmount.gt(totalMarketAvailableAmount) && hasAnyCollateralEnabled;
      if (greaterThanTotalMarketAvailableAmount) {
        return { valid: false, reason: 'insufficientLiquidity' };
      }

      const greaterThanUserMaxBorrowAmount =
        parsedAmount.gt(userMaxBorrowAmount) && hasAnyCollateralEnabled;
      if (greaterThanUserMaxBorrowAmount) {
        return { valid: false, reason: 'exceedsBorrowLimitAmount' };
      }

      return { valid: true, reason: null };
    } catch (error) {
      return invalidResult;
    }
  }
  return invalidResult;
}

export default validateBorrowAmount;
