export interface TectonicSdkState {
  hasError: boolean;
  hasErrorOfDeFiPool: boolean;
  hasErrorOfLCROPool: boolean;
}

export interface InitializeFulfilledAction {
  type:
    | typeof INITIALIZE_FULFILLED
    | typeof INITIALIZE_FULFILLED_OF_DEFI_POOL
    | typeof INITIALIZE_FULFILLED_OF_LCRO_POOL;
}

export interface InitializeRejectedAction {
  type:
    | typeof INITIALIZE_REJECTED
    | typeof INITIALIZE_REJECTED_OF_DEFI_POOL
    | typeof INITIALIZE_REJECTED_OF_LCRO_POOL;
}

export type TectonicSdkAction =
  | InitializeFulfilledAction
  | InitializeRejectedAction;

export const initState: TectonicSdkState = {
  hasError: false,
  hasErrorOfDeFiPool: false,
  hasErrorOfLCROPool: false,
};

export const INITIALIZE_FULFILLED = 'INITIALIZE_FULFILLED';
export const INITIALIZE_REJECTED = 'INITIALIZE_REJECTED';

export const INITIALIZE_FULFILLED_OF_DEFI_POOL =
  'INITIALIZE_FULFILLED_OF_DEFI_POOL';
export const INITIALIZE_REJECTED_OF_DEFI_POOL =
  'INITIALIZE_REJECTED_OF_DEFI_POOL';

export const INITIALIZE_FULFILLED_OF_LCRO_POOL =
  'INITIALIZE_FULFILLED_OF_LCRO_POOL';
export const INITIALIZE_REJECTED_OF_LCRO_POOL =
  'INITIALIZE_REJECTED_OF_LCRO_POOL';
