import { PoolType } from './base';

export interface TokenInterestInfo {
  collateralFactor: number;
  reserveFactor: number;
  liquidationPenalty: number;
  baseRate: number;
  multiplier: number;
  jumpMultiplier: number;
  kink: number;
  supplyCap: number;
}

export const TOKEN_INTEREST_INFOS: Record<
  PoolType,
  Record<string, TokenInterestInfo>
> = {
  VENO: {
    USDC: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.12,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    USDT: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.12,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    CRO: {
      reserveFactor: 0.1,
      collateralFactor: 0.6,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.15,
      jumpMultiplier: 1,
      kink: 0.7,
      supplyCap: 0,
    },
    LCRO: {
      reserveFactor: 0.15,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.6,
      supplyCap: 0,
    },
    ATOM: {
      reserveFactor: 0.15,
      collateralFactor: 0.6,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 1,
      kink: 0.6,
      supplyCap: 0,
    },
    LATOM: {
      reserveFactor: 0.15,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.6,
      supplyCap: 0,
    },
  },
  MAIN: {
    USDC: {
      reserveFactor: 0.1,
      collateralFactor: 0.8,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.07,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    USDT: {
      reserveFactor: 0.1,
      collateralFactor: 0.8,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.07,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    DAI: {
      reserveFactor: 0.1,
      collateralFactor: 0.8,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.07,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    TUSD: {
      reserveFactor: 0.1,
      collateralFactor: 0,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.07,
      jumpMultiplier: 0,
      kink: 0.8,
      supplyCap: 1,
    },
    CRO: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.08,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    ETH: {
      reserveFactor: 0.2,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.08,
      jumpMultiplier: 0.5,
      kink: 0.75,
      supplyCap: 0,
    },
    WETH: {
      reserveFactor: 0.2,
      collateralFactor: 0.75,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.08,
      jumpMultiplier: 0.5,
      kink: 0.75,
      supplyCap: 0,
    },
    WBTC: {
      reserveFactor: 0.2,
      collateralFactor: 0.75,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.1,
      jumpMultiplier: 0.5,
      kink: 0.75,
      supplyCap: 0,
    },
    TONIC: {
      reserveFactor: 0.15,
      collateralFactor: 0.2,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 1.2,
      kink: 0.55,
      supplyCap: 50_000_000_000_000,
    },
    ATOM: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.15,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 50_000,
    },
    XRP: {
      reserveFactor: 0.2,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.05,
      jumpMultiplier: 0.5,
      kink: 0.65,
      supplyCap: 300_000,
    },
    ADA: {
      reserveFactor: 0.2,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.05,
      jumpMultiplier: 0.5,
      kink: 0.65,
      supplyCap: 0,
    },
    VVS: {
      reserveFactor: 0.15,
      collateralFactor: 0.55,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 1.2,
      kink: 0.55,
      supplyCap: 0,
    },
    LTC: {
      reserveFactor: 0.2,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.05,
      jumpMultiplier: 0.5,
      kink: 0.65,
      supplyCap: 500,
    },
    LCRO: {
      reserveFactor: 0.15,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.6,
      supplyCap: 0,
    },
  },
  DEFI: {
    USDC: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.12,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    USDT: {
      reserveFactor: 0.1,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.12,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    CRO: {
      reserveFactor: 0.1,
      collateralFactor: 0.6,
      liquidationPenalty: 0.1,
      baseRate: 0,
      multiplier: 0.15,
      jumpMultiplier: 1,
      kink: 0.8,
      supplyCap: 0,
    },
    FER: {
      reserveFactor: 0.2,
      collateralFactor: 0.5,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.55,
      supplyCap: 21_000_000,
    },
    VNO: {
      reserveFactor: 0.2,
      collateralFactor: 0.5,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.55,
      supplyCap: 2_800_000,
    },
    FUL: {
      reserveFactor: 0.2,
      collateralFactor: 0.5,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.55,
      supplyCap: 13_000_000,
    },
    BCRO: {
      reserveFactor: 0.15,
      collateralFactor: 0.4,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.25,
      jumpMultiplier: 2,
      kink: 0.6,
      supplyCap: 7_000_000,
    },
    CDCETH: {
      reserveFactor: 0.2,
      collateralFactor: 0.7,
      liquidationPenalty: 0.1,
      baseRate: 0.03,
      multiplier: 0.15,
      jumpMultiplier: 1,
      kink: 0.6,
      supplyCap: 160,
    },
    USC: {
      reserveFactor: 0.2,
      collateralFactor: 0.6,
      liquidationPenalty: 0.1,
      baseRate: 0.0,
      multiplier: 0.15,
      jumpMultiplier: 1,
      kink: 0.6,
      supplyCap: 0,
    },
  },
} as const;
