import { Text } from '@tectonicfi/tectonic-ui-kit';

export type SeverityType = 'severe' | 'normal' | null;
interface BorrowCautionMessageProps {
  severity: SeverityType;
}

function BorrowCautionMessage({
  severity = null,
}: BorrowCautionMessageProps): JSX.Element | null {
  if (severity === 'severe') {
    return (
      <Text className="text-onSurface60" variant="small">
        Please note that the amount you wish to borrow will bring your account
        close to liquidation. This means your account may be liquidated more
        easily if the market drops. In general, it is recommended to keep your
        account LTV to below 50%.
      </Text>
    );
  }

  if (severity === 'normal') {
    return (
      <Text className="text-onSurface60" variant="small">
        By borrowing the amount above, your account&apos;s LTV will be above
        50%. In general, it is recommended to keep your account LTV to below
        50%.
      </Text>
    );
  }

  return null;
}

export default BorrowCautionMessage;
